import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var CartValidationActionsService = /** @class */ (function () {
    function CartValidationActionsService() {
        /**
         *
         * CART VALIDATION
         *
         */
        this.validateCart = function () { return ({
            type: CartValidationActionsService.VALIDATE_CART
        }); };
        this.setValidity = function (payload) { return ({
            type: CartValidationActionsService.SET_VALIDITY,
            payload: payload
        }); };
    }
    CartValidationActionsService.VALIDATE_CART = '@cartValidation/VALIDATE_CART';
    CartValidationActionsService.VALIDATE_CART_QUEUE = '@cartValidation/VALIDATE_CART_QUEUE';
    CartValidationActionsService.SET_VALIDITY = '@cartValidation/SET_VALIDITY';
    CartValidationActionsService.FETCH_CART_CONFIG = '@cartValidation/FETCH_CART_CONFIG';
    CartValidationActionsService.CART_CONFIG_LOADING = '@cartValidation/CART_CONFIG_LOADING';
    CartValidationActionsService.CART_CONFIG_SUCCESS = '@cartValidation/CART_CONFIG_SUCCESS';
    CartValidationActionsService.CART_CONFIG_ERROR = '@cartValidation/CART_CONFIG_ERROR';
    CartValidationActionsService.CART_CONFIG_READY = '@cartValidation/CART_CONFIG_READY';
    CartValidationActionsService.CART_CONFIG_EXPIRED = '@cartValidation/CART_CONFIG_EXPIRED';
    CartValidationActionsService.SHOW_VALIDATION_RESULT = '@cartValidation/SHOW_VALIDATION_RESULT';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartValidationActionsService.prototype, "validateCart", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartValidationActionsService.prototype, "setValidity", void 0);
    return CartValidationActionsService;
}());
export { CartValidationActionsService };
