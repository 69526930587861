import { Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { RootComponent } from './shared/components/root/root.component';
import { TenantGuard } from './shared/guards/tenant/tenant.guard';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./sales/sales.module.ngfactory").then(function (m) { return m.SalesModuleNgFactory; }); }, ɵ2 = function () { return import("./support/support.module.ngfactory").then(function (m) { return m.SupportModuleNgFactory; }); }, ɵ3 = function () { return import("./orders/orders.module.ngfactory").then(function (m) { return m.OrdersModuleNgFactory; }); }, ɵ4 = function () { return import("./messages/messages.module.ngfactory").then(function (m) { return m.MessagesModuleNgFactory; }); }, ɵ5 = function () { return import("./user/user.module.ngfactory").then(function (m) { return m.UserModuleNgFactory; }); }, ɵ6 = function () { return import("./favourites/favourites.module.ngfactory").then(function (m) { return m.FavouritesModuleNgFactory; }); }, ɵ7 = function () { return import("./pages/pages.module.ngfactory").then(function (m) { return m.PagesModuleNgFactory; }); }, ɵ8 = function () { return import("./usatoAld/usatoAld.module.ngfactory").then(function (m) { return m.UsatoAldModuleNgFactory; }); }, ɵ9 = { allowedTenantIds: ['jlri'] }, ɵ10 = function () { return import("./regolamento/regolamento.module.ngfactory").then(function (m) { return m.RegolamentoModuleNgFactory; }); }, ɵ11 = { allowedTenantIds: ['bmwpt'] }, ɵ12 = function () { return import("./termsPrivacy/terms.module.ngfactory").then(function (m) { return m.TermsModuleNgFactory; }); }, ɵ13 = function () { return import("./error/error.module.ngfactory").then(function (m) { return m.ErrorModuleNgFactory; }); };
var routes = [{
        path: '',
        component: RootComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', loadChildren: ɵ0 },
            { path: 'sales', loadChildren: ɵ1 },
            { path: 'support', loadChildren: ɵ2 },
            { path: 'orders', loadChildren: ɵ3 },
            { path: 'messages', loadChildren: ɵ4 },
            // { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
            { path: 'user', loadChildren: ɵ5 },
            { path: 'favourites', loadChildren: ɵ6 },
            { path: 'pages', loadChildren: ɵ7 },
            { path: 'usatoAld', loadChildren: ɵ8 },
            {
                path: 'regolamento',
                canActivate: [TenantGuard],
                data: ɵ9,
                loadChildren: ɵ10
            },
            {
                path: 'terms',
                canActivate: [TenantGuard],
                data: ɵ11,
                loadChildren: ɵ12
            },
            // Must be last, because of wildcard
            { path: '', loadChildren: ɵ13 }
        ]
    }];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
