/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safe-html/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./modal-message.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalMessageComponent = [i0.styles];
var RenderType_ModalMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalMessageComponent, data: {} });
export { RenderType_ModalMessageComponent as RenderType_ModalMessageComponent };
function View_ModalMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal__content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.message)); _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalMessageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal--is-communication"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "modal__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalMessageComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "modal__close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--transparent"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon icon--out icon--close-thin-grey"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "icon icon--hover icon--close-dark-grey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }); }
export function View_ModalMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-message", [], null, null, null, View_ModalMessageComponent_0, RenderType_ModalMessageComponent)), i1.ɵdid(1, 49152, null, 0, i5.ModalMessageComponent, [i6.NgbActiveModal], null, null)], null, null); }
var ModalMessageComponentNgFactory = i1.ɵccf("app-modal-message", i5.ModalMessageComponent, View_ModalMessageComponent_Host_0, { id: "id", title: "title", message: "message" }, {}, []);
export { ModalMessageComponentNgFactory as ModalMessageComponentNgFactory };
