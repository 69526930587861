import { CmsMenuActionsService } from '../actions/cms-menu.actions';

import { AppAction, CmsMenuState } from '../../../interfaces/store.interface';

const INITIAL_STATE: CmsMenuState = {

    menuTree: [],

    wrapIndex: -1,

    _loading: false,
    _loaded: false
};

export default function cmsMenuReducer(state: CmsMenuState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * MENU
         *
         */

        case CmsMenuActionsService.MENU_LOADING: {

            const nextState = {
                ...state,

                _loading: true,
                loaded: false
            };

            return nextState;

        }

        case CmsMenuActionsService.MENU_SUCCESS: {

            const nextState = {
                ...state,
                menuTree: action.payload,

                _loading: false,
                _loaded: true,
            };

            return nextState;

        }

        case CmsMenuActionsService.MENU_ERROR: {

            const nextState = {
                ...state,

                _loading: false,
                _loaded: false,
            };

            return nextState;

        }

        case CmsMenuActionsService.SET_MENU_WRAP_INDEX: {

            const nextState = {
                ...state,

                wrapIndex: action.payload
            };

            return nextState;

        }

    }

    return state;

}
