import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { Observable, empty, from } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';
import { ExceptionActionsService } from '../actions/exception.actions';

import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalExceptionComponent } from '../../shared/components/modal-exception/modal-exception.component';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class ExceptionEpicsService {

    constructor (private modal: ModalService) {}

    showExceptionModal = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        return action$.ofType(ExceptionActionsService.SHOW_EXCEPTION_MODAL).pipe(
            switchMap(({
                error
            }) => {

                const modalRef = this.modal.open(ModalExceptionComponent);
                const modalInstance = modalRef.componentInstance;

                // pass data
                modalInstance.errorMessage = error;
                modalInstance.errorIsObject = error !== null && typeof error === 'object';

                return from(modalRef.result).pipe(
                    // modals only use dismiss which leads to 'catch'
                    catchError(() => {
                        // User clicked CANCEL in the modal - kill the stream
                        return empty();
                    }));

            }));

    }


}
