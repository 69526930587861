/// <reference path='../../../../../node_modules/@types/googlemaps/index.d.ts' />

import { ElementRef, Injectable } from '@angular/core';

import { CONFIG } from '../../config/config.const';

@Injectable()
export class GoogleMapsService {

    private mapElement: HTMLElement;
    private map: google.maps.Map;

    private geocoder: google.maps.Geocoder;

    private loading: boolean;
    private promise: Promise<boolean>;

    private src = 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG.KEYS.GOOGLE_MAPS + '&callback=googleMapsInitialized';

    private mapMarkers: google.maps.Marker[];

    private mapOptions = {
        zoom: 10,
        center: null
    };

    // default location
    private latlng = {
        lat: 45.4627338,
        lng: 9.1777323
    };

    constructor () {

        this.mapMarkers = [];

        // create the main promise
        this.promise = new Promise((resolve, reject) => {

            // expose the resolve method for gmaps to call
            window['googleMapsInitialized'] = resolve;

        });

        this.promise.then(() => {
            // create our geocoder
            this.geocoder = new window['google'].maps.Geocoder();
        });

    }

    geocode (options: { address: string }) {

        const promise = new Promise(
            (resolve: (location: google.maps.LatLng) => void, reject: (status: google.maps.GeocoderStatus) => void) => {

                this.geocoder.geocode(options, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {

                    if (status === window['google'].maps.GeocoderStatus.OK) {

                        resolve(results[0].geometry.location);

                    } else {

                        reject(status);

                    }

            });

        });

        return promise;
    }

    getMap (
        options: { address: string, latLng?: google.maps.LatLng },
        callback: (status?: google.maps.GeocoderStatus) => void
    ) {

        if (!this.mapElement) {
            // naughty!
            this.mapElement = document.createElement('div');
            this.mapElement.style.height = '100%';
        }

        if (!this.map) {
            // create the map
            this.map = new google.maps.Map(this.mapElement, this.mapOptions);
        }

        // clear markers
        this.mapMarkers.forEach((marker: google.maps.Marker) => {
            marker.setMap(null);
        });

        // set to default position
        this.map.setCenter(this.latlng);
        this.map.setZoom(this.mapOptions.zoom);

        // skip geocode if we already have a location for this branch
        if (options.latLng) {

            this.positionMap(options.latLng);
            callback(null);

        } else {

            // lookup the address
            this.geocode(options).then((location: google.maps.LatLng) => {

                this.positionMap(location);

                callback(null);

            })
            .catch((status: google.maps.GeocoderStatus) => {
                callback(status);
            });

        }

        // append the map
        return this.mapElement;

    }

    positionMap (location: google.maps.LatLng) {

        this.map.setCenter(location);

        this.map.setZoom(14);

        this.mapMarkers.push(new google.maps.Marker({
            map: this.map,
            position: location
        }));

    }

    getPreviewUrl (location: google.maps.LatLng) {
        return'https://maps.googleapis.com/maps/api/staticmap?size=70x70&zoom=12&center='
            + location.toUrlValue()
            + '&markers=size:mid|'
            + location.toUrlValue()
            + '&key=' + CONFIG.KEYS.GOOGLE_MAPS;
    }

    load () {

        if (!this.loading) {
            this.loading = true;

            const script = document.createElement('script');
            script.src = this.src;
            document.body.appendChild(script);

        }

        return this.promise;
    }

}
