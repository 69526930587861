import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';
import { SalesVehicleUnavailableReason } from '../../../interfaces/sales.interface';

@Injectable()
export class EntitiesActionsService {

    static readonly SET_VEHICLE_BRANCH_LATLNG = '@entities/SET_VEHICLE_BRANCH_LATLNG';

    static readonly SET_VEHICLE_IN_CART = '@entities/SET_VEHICLE_IN_CART';
    static readonly SET_VEHICLE_UNAVAILABLE = '@entities/SET_VEHICLE_UNAVAILABLE';

    static readonly HIDE_VEHICLE = '@entities/HIDE_VEHICLE';

    static readonly SET_VEHICLE_AS_PURCHASABLE = '@entities/SET_VEHICLE_AS_PURCHASABLE';
    static readonly SET_VEHICLE_AS_NOT_PURCHASABLE = '@entities/SET_VEHICLE_AS_NOT_PURCHASABLE';


    /**
     *
     * VEHICLES
     *
     */

    @dispatch()
    vehicleSetBranchLatLng = (payload: { vehicleId: string, branchIndex: number, latLng: any, previewImg: string }): AppAction => ({
        type: EntitiesActionsService.SET_VEHICLE_BRANCH_LATLNG,
        payload
    })

    @dispatch()
    vehicleSetInCart = (payload: { vehicleId: string, status: boolean }): AppAction => ({
        type: EntitiesActionsService.SET_VEHICLE_IN_CART,
        payload
    })

    @dispatch()
    vehicleSetUnavailable = (payload: { vehicleId: string, status: boolean }): AppAction => ({
        type: EntitiesActionsService.SET_VEHICLE_UNAVAILABLE,
        payload
    })

    @dispatch()
    hideVehicle = (payload: { vehicleId: string, reason?: SalesVehicleUnavailableReason }): AppAction => ({
        type: EntitiesActionsService.HIDE_VEHICLE,
        payload
    })

    @dispatch()
    setVehicleAsPuchasable = (payload: { vehicleId: string }): AppAction => ({
        type: EntitiesActionsService.SET_VEHICLE_AS_PURCHASABLE,
        payload
    })

    @dispatch()
    setVehicleAsNotPuchasable = (payload: { vehicleId: string }): AppAction => ({
        type: EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE,
        payload
    })

}
