/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../pipes/date/date.pipe";
import * as i3 from "../../../services/date/date.service";
import * as i4 from "@angular/common";
import * as i5 from "./listing-stats.component";
var styles_ListingStatsComponent = [];
var RenderType_ListingStatsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListingStatsComponent, data: {} });
export { RenderType_ListingStatsComponent as RenderType_ListingStatsComponent };
function View_ListingStatsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "span", [["class", "listing__secondary px-1 truncate"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [": "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "listing__value"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SellingItem.Info.Plate")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.vehicle.plate; _ck(_v, 6, 0, currVal_1); }); }
function View_ListingStatsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [["class", "listing__secondary px-1 truncate "]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [": "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "listing__value"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SellingItem.Info.MatriculationDate")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), _co.vehicle.matriculationDate, "dd.MM.yyyy")); _ck(_v, 6, 0, currVal_1); }); }
function View_ListingStatsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [["class", "listing__secondary px-1 truncate "]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [": "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "listing__value"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SellingItem.Info.Km")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 1), ((_co.vehicle == null) ? null : _co.vehicle.km))); _ck(_v, 6, 0, currVal_1); }); }
function View_ListingStatsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [["class", "listing__secondary px-1 truncate "]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [": "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "listing__value"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", " ", ""])), i0.ɵppd(7, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SellingItem.Info.Damages")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.currency; var currVal_2 = i0.ɵunv(_v, 6, 1, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 1), _co.vehicle.prices.dealer.damages, "1.2-2")); _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_ListingStatsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.DatePipe, [i3.DateService]), i0.ɵpid(0, i4.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(2, 0, null, null, 12, "span", [["class", "listing__stats cf d-flex flex-row flex-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingStatsComponent_1)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingStatsComponent_2)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "span", [["class", "block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingStatsComponent_3)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "span", [["class", "block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingStatsComponent_4)), i0.ɵdid(14, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vehicle.plate; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.vehicle.matriculationDate; _ck(_v, 8, 0, currVal_1); var currVal_2 = ((_co.vehicle == null) ? null : _co.vehicle.km); _ck(_v, 11, 0, currVal_2); var currVal_3 = ((_co.vehicle.prices == null) ? null : _co.vehicle.prices.dealer.damages); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_ListingStatsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-listing-stats", [], null, null, null, View_ListingStatsComponent_0, RenderType_ListingStatsComponent)), i0.ɵdid(1, 49152, null, 0, i5.ListingStatsComponent, [], null, null)], null, null); }
var ListingStatsComponentNgFactory = i0.ɵccf("app-listing-stats", i5.ListingStatsComponent, View_ListingStatsComponent_Host_0, { vehicle: "vehicle", currency: "currency" }, {}, []);
export { ListingStatsComponentNgFactory as ListingStatsComponentNgFactory };
