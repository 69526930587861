/// <reference path="../../node_modules/oidc-client/index.d.ts" />

import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationStart } from '@angular/router';

import { NgRedux, select } from '@angular-redux/store';

import { UserProfile } from '../interfaces/user.interface';
import { AppState, AsyncState } from '../interfaces/store.interface';

import { AuthService } from './shared/services/auth/auth.service';
import { WindowRefService } from './shared/services/window-ref/window-ref.service';
import { ScrollActionsService } from './store/actions/scroll.actions';
import { UiActionsService } from './store/actions/ui.actions';
import { EnvironmentService } from './shared/services/environment/environment.service';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { FaviconService } from './shared/services/favicon/favicon.service';
import { IAnalyticsConfig } from '../interfaces/TenantConfiguration.interface';
import { GoogleAnalyticsService } from './shared/services/google-analytics/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { TenantActionsService } from './store/actions/tenant.actions';
import { BootingActionsService } from './store/actions/booting.actions';
import { SellingAreasActionsService } from './store/actions/selling-areas.actions';
import { UserActionsService } from './store/actions/user.actions';
import { MessagesActionsService } from './store/actions/messages.actions';
import { registerLocaleData } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    isAuthenticated: boolean;
    hasProfile: boolean;
    isTenantLoaded: boolean;
    areSellingAreasLoaded: boolean;
    currentLocale: string;
    userDisabledAnalytics: boolean;

    private environment: typeof environment;

    loadingRoute: boolean;

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private titleService: Title,
        private translateService: TranslateService,
        private faviconService: FaviconService,
        private ngRedux: NgRedux<AppState>,
        private scrollActions: ScrollActionsService,
        private uiActions: UiActionsService,
        private windowRef: WindowRefService,
        private environmentService: EnvironmentService,
        private googleAnalytics: GoogleAnalyticsService,
        private bootingActions: BootingActionsService,
        private sellingAreaActions: SellingAreasActionsService,
        private userActions: UserActionsService,
        private messagesActions: MessagesActionsService
    ) {
        this.environment = this.environmentService.getEnvironment();
        this._applyThemeByDomain();
        // user analytics disabled?
        const storageValue = this.windowRef.nativeWindow.localStorage.getItem('privacy_policy');
        if (storageValue !== undefined) {
            this.userDisabledAnalytics = storageValue !== '2';
        } else {
            // disable by default
            this.userDisabledAnalytics = true;
        }
    }

    ngOnInit() {

        this.ngRedux.select(['user', 'identity']).subscribe((identity: AsyncState<Oidc.User>) => {
            // check the identity status
            this.isAuthenticated = !!identity.payload && !identity.error && !identity.loading;
        });

        this.ngRedux.select(['user', 'profile']).subscribe((profile: AsyncState<UserProfile>) => {
            // check the profile status
            this.hasProfile = !!profile.payload && !profile.error && !profile.loading;

            if (this.hasProfile) {
                this.setLocale(profile.payload.culture);
            }
        });

        this.ngRedux.select(['tenant', 'title']).subscribe((title: string) => {
            // check the domain title
            if (title) {
                this.titleService.setTitle(title);
            }
        });

        this.ngRedux.select(['tenant', 'id']).subscribe((tenantId: string) => {
            // check the domain tenant id
            if (tenantId) {
                this.faviconService.setFavicon(tenantId.toLowerCase());

                if (!this.isTenantLoaded) {
                    this.sellingAreaActions.fetchSellingAreas();
                }

                this.isTenantLoaded = true;
            }
        });

        this.ngRedux.select(['tenant', 'analytics']).subscribe((tenantAnalytics: IAnalyticsConfig) => {
            // check the domain tenant id
            if (!this.userDisabledAnalytics && tenantAnalytics && tenantAnalytics.enabled && tenantAnalytics.script) {
                this.googleAnalytics.init(tenantAnalytics.script);
            }
        });

        this.ngRedux.select(['sellingAreas', 'results', 'loaded']).subscribe((loaded: string) => {
            // check the domain tenant id
            if (loaded && !this.areSellingAreasLoaded) {
                this.userActions.flagAsReady();
                this.messagesActions.fetchMessagesStatistics();
                this.areSellingAreasLoaded = true;
            }
        });

        // watch routing
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loadingRoute = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingRoute = false;
            }
            // always close modal when navigating
            if (event instanceof NavigationStart) {
                this.uiActions.dismissModal();
                this.scrollActions.scrollTo(null);
                this.scrollActions.scrollToTop();
            }
        });

        /**
         *
         * TOUCH DETECTION (required for legacy CSS)
         *
         */

        if ('ontouchstart' in this.windowRef.nativeWindow) {
            this.renderer.removeClass(this.windowRef.nativeWindow.document.documentElement, 'no-touch');
            this.renderer.addClass(this.windowRef.nativeWindow.document.documentElement, 'touch');
        }

        // this.bootingActions.loadTenantConfiguration();
        this.bootingActions.loadUser();

    }

    private _applyThemeByDomain() {
        let themeFileName = this.environment.domain;
        const dashIndex = themeFileName.indexOf('-');
        if (dashIndex !== -1) {
            themeFileName = this.environment.domain.slice(0, dashIndex);
        }
        themeFileName = `${themeFileName}.css`;
        const linkElem: HTMLElement = (this.windowRef.nativeWindow.document as HTMLDocument).createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        linkElem.setAttribute('href', themeFileName);
        (this.windowRef.nativeWindow.document.getElementsByTagName('head')[0] as HTMLElement).appendChild(linkElem);
    }

    private setLocale(locale: string) {
        const shortLocaleId = locale.split('-')[0].toLowerCase();
        console.log('Locale', `Setting locale to ${locale} (${shortLocaleId})`);
        this.translateService.setDefaultLang(locale);
        // angular locale
        this.localeInitializer(shortLocaleId).then(() => { });
        // moment locale
        this.setMomentLocale(shortLocaleId).then(() => { });
        // Bootstrap locale
        // this.setBSLocale(shortLocaleId);
    }

    private localeInitializer(localeId: string): Promise<any> {
        switch (localeId) {
            case 'en':
                return new Promise((resolve) => { resolve(true); });
            case 'it':
                return import(
                    /* webpackInclude: /(it)\.js$/ */
                    `@angular/common/locales/it.js`
                ).then(module => registerLocaleData(module.default));
            case 'pt':
                return import(
                    /* webpackInclude: /(pt)\.js$/ */
                    `@angular/common/locales/pt.js`
                ).then(module => registerLocaleData(module.default));
        }
        return new Promise((resolve) => { resolve(true); });
    }

    private setMomentLocale(localeId) {
        switch (localeId) {
            case 'en':
                moment.locale('en');
                return new Promise((resolve) => { resolve(true); });
            case 'it':
                return import(
                    /* webpackInclude: /(it)\.js$/ */
                    `moment/locale/${localeId}`
                ).then(module => {
                    moment.locale(localeId);
                });
            case 'pt':
                return import(
                    /* webpackInclude: /(pt)\.js$/ */
                    `moment/locale/${localeId}`
                ).then(module => {
                    moment.locale(localeId);
                });
        }
    }

    // private setBSLocale(localeId) {
    //     let bsLocale = localeId;
    //     switch (localeId) {
    //         case 'pt': {
    //             bsLocale = 'pt-br';
    //             break;
    //         }
    //         default: {
    // 
    //         }
    //     }
    //     this.localeService.use(bsLocale);
    // }

    @HostListener('document:click', [])
    resetUi() {
        // closes all dropdowns - unless otherwise caught with StopPropagation
        this.uiActions.closeDropdown();
    }

}
