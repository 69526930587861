import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class TenantActionsService {

    static readonly LOAD_TENANT_CONFIG = '@tenant/LOAD_TENANT_CONFIG';
    static readonly TENANT_CONFIG_LOADING = '@tenant/TENANT_CONFIG_LOADING';
    static readonly TENANT_CONFIG_LOADED = '@tenant/TENANT_CONFIG_LOADED';
    static readonly TENANT_CONFIG_LOAD_ERROR = '@tenant/TENANT_CONFIG_LOAD_ERROR';

    /**
     *
     * LOADING TENANT CONFIG
     *
     */

    @dispatch()
    loadTenantConfiguration = (): AppAction => ({
        type: TenantActionsService.LOAD_TENANT_CONFIG
    })

}
