import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class UserActionsService {

    static readonly DESTROY = '@user/DESTROY';
    static readonly LOGGED_IN = '@user/LOGGED_IN';
    static readonly READY = '@user/READY';

    static readonly LOAD_USER = '@user/LOAD_USER';

    static readonly LOGIN = '@user/LOGIN';
    static readonly LOGOUT = '@user/LOGOUT';

    static readonly FETCH_IDENTITY = '@user/FETCH_IDENTITY';
    static readonly IDENTITY_LOADING = '@user/IDENTITY_LOADING';
    static readonly IDENTITY_SUCCESS = '@user/IDENTITY_SUCCESS';
    static readonly IDENTITY_ERROR = '@user/IDENTITY_ERROR';

    static readonly REFRESH_TOKEN = '@user/REFRESH_TOKEN';
    static readonly REFRESH_TOKEN_SUCCESS = '@user/REFRESH_TOKEN_SUCCESS';
    static readonly REFRESH_TOKEN_ERROR = '@user/REFRESH_TOKEN_ERROR';

    static readonly FETCH_PROFILE = '@user/FETCH_PROFILE';
    static readonly PROFILE_LOADING = '@user/PROFILE_LOADING';
    static readonly PROFILE_SUCCESS = '@user/PROFILE_SUCCESS';
    static readonly PROFILE_ERROR = '@user/PROFILE_ERROR';

    static readonly FETCH_USER_INFO = '@user/FETCH_USER_INFO';
    static readonly USER_INFO_SUCCESS = '@user/USER_INFO_SUCCESS';
    static readonly USER_INFO_ERROR = '@user/USER_INFO_ERROR';

    static readonly SAVE_USER_INFO = '@user/SAVE_USER_INFO';
    static readonly SAVE_USER_INFO_SAVING = '@user/SAVE_USER_INFO_SAVING';
    static readonly SAVE_USER_INFO_SUCCESS = '@user/SAVE_USER_INFO_SUCCESS';
    static readonly SAVE_USER_INFO_ERROR = '@user/SAVE_USER_INFO_ERROR';

    static readonly AUCTION_DISCLAIMER_ACCEPTED = '@user/AUCTION_DISCLAIMER_ACCEPTED';

    /**
     *
     * DESTROY USER
     *
     */

    @dispatch()
    destroy = (): AppAction => ({
        type: UserActionsService.DESTROY
    })

    /**
     *
     * LOAD USER
     *
     */

    @dispatch()
    loadUser = (): AppAction => ({
        type: UserActionsService.LOAD_USER
    })

    /**
     *
     * LOGIN / LOGOUT
     *
     */

    @dispatch()
    login = (): AppAction => ({
        type: UserActionsService.LOGIN
    })

    @dispatch()
    logout = (): AppAction => ({
        type: UserActionsService.LOGOUT
    })

    /**
     *
     * REFRESH TOKEN
     *
     */

    @dispatch()
    refreshToken = (): AppAction => ({
        type: UserActionsService.REFRESH_TOKEN
    })

    /**
     *
     * SAVE USER INFO
     *
     */

    @dispatch()
    saveUSerInfo = (params): AppAction => ({
        type: UserActionsService.SAVE_USER_INFO,
        payload: params
    })

    @dispatch()
    flagAsReady = (): AppAction => ({
        type: UserActionsService.READY
    })

}
