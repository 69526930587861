<div class="modal__inner">
    <h1 class="modal__title" i18n>{{'Modals.CheckoutError.ModalTitle' | translate}}</h1>
    <div class="modal__content pt-4">
        <ng-container *ngIf="!errors || errors.length == 0; else hasErrors">
            <p i18n>{{'Modals.CheckoutError.ImpossibleComplete' | translate}}.</p>
        </ng-container>

        <ng-template #hasErrors>
            <ng-container *ngIf="errors.length == 1; else hasMultipleErrors">
                <p class="h6">{{errors[0]}}</p>
            </ng-container>
        </ng-template>

        <ng-template #hasMultipleErrors>
            <ul>
                <li *ngFor="let error of errors" class="mb-1">
                    <p class="h6">{{error}}</p>
                </li>
            </ul>
        </ng-template>

    </div>
</div>

<div class="modal__actions">
    <button type="button" class="button button--modal" appTouchable (click)="activeModal.dismiss()">
        <span class="button__label">{{'Commons.Ok' | translate}}</span>
    </button>
</div>