import { Injectable, ElementRef, NgZone } from '@angular/core';

import { NgRedux, select } from '@angular-redux/store';

import { Observable, Observer, fromEvent } from 'rxjs';

import * as IScroll from 'iscroll';

import { CONFIG } from '../../config/config.const';
import { WindowRefService } from '../window-ref/window-ref.service';

import { AppState, ScrollState } from '../../../../interfaces/store.interface';
import { ScrolledTo } from '../../../../interfaces/scroll.interface';
import { ScrollActionsService } from '../../../store/actions/scroll.actions';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class ScrollService {

    private iScroll: IScrollApp; // <-- using our custom type from typings.d.ts

    private scrolledTo: string;
    private scrolledToOffset: number;

    private maxScrollY: number;
    private locked: boolean;

    private checkScrollTimeout: number;
    private scrollToTimeout: number;
    private monitorScrollPositionInterval: number;

    private showCart: boolean;

    private inview: any = {};

    constructor(
        private ngRedux: NgRedux<AppState>,
        private scrollActions: ScrollActionsService,
        private windowRef: WindowRefService,
        private zone: NgZone
    ) {

        this.ngRedux.select<ScrolledTo>(['scroll', 'scrolledTo']).pipe(
            distinctUntilChanged())
            .subscribe((state: ScrolledTo) => {
                // reset scrolled to
                this.scrolledTo = null;
                this.scrolledToOffset = 0;

                if (state) {
                    this.scrollToElement(state);
                } else {
                    this.scrollActions.unlock();
                    this.scrollActions.refresh();
                }
            });

        this.ngRedux.select<boolean>(['scroll', 'locked'])
            .subscribe((state: boolean) => {
                this.lock(state);
            });

        this.ngRedux.select<number>(['scroll', 'refresh'])
            .subscribe(() => {
                this.refresh();
            });

        this.ngRedux.select<number>(['scroll', 'unhook'])
            .subscribe(() => {
                this.unhook();
            });

        this.ngRedux.select<number>(['scroll', 'scrollToTop'])
            .subscribe(() => {
                this.scrollToTop();
            });

        this.ngRedux.select<any>(['scroll', 'inview'])
            .subscribe((inview: any) => {
                this.inview = inview || {};
            });

        this.ngRedux.select<boolean>(['sales', 'showCart'])
            .subscribe((showCart: boolean) => {
                this.showCart = showCart;
            });

        this.zone.runOutsideAngular(() => {
            fromEvent(window, 'resize').pipe(
                map(() => {
                    // compare width with breakpoints
                    // the point of this is that the subscribe only fires when we change breakpoint
                    return CONFIG.DIMENSIONS.BREAKPOINTS.findIndex((value: number) => {
                        return value > window.innerWidth;
                    });
                }),
                distinctUntilChanged()
            )
                .subscribe((value) => {

                    this.zone.run(() => {
                        // update the scroll position
                        this.refreshScroll();
                    });

                });
        });

    }

    init(element: HTMLElement) {

        this.iScroll = new IScroll(element, {
            mouseWheel: true,

            // disable click AND tap
            click: false,
            tap: false,

            // tap: $rootScope.globalConfig.iScroll.tapEvent,
            scrollbars: !('ontouchstart' in this.windowRef.nativeWindow), // only have scrollbars on non-touch devices
            interactiveScrollbars: true,
            eventPassthrough: false,

            preventDefault: false, // to fix problems with rangeslider in filters. It was set to true

            // maximise performance
            useTransition: false,
            bindToWrapper: true,
            // disable auto-resize
            resizePolling: 1000 * 60 * 60, // 1 hour
            // momentum: false,
            bounce: false,
            fadeScrollbars: false,
            shrinkScrollbars: 'clip',

            disablePointer: true, // to fix problems with rangeslider in filters
            disableMouse: false, // to fix problems with rangeslider in filters
            disableTouch: false // to fix problems with rangeslider in filters
        });

        // save the maxScrollY
        this.maxScrollY = this.iScroll.maxScrollY;

        // ensure it starts in the correct state
        if (this.locked) {
            this.iScroll.disable();
        }

        // monitor the scroll position
        this.iScroll.on('scrollStart', () => {
            this.startMonitoringScrollPosition();
        });

        this.iScroll.on('scrollEnd', () => {
            this.stopMonitoringScrollPosition();
        });

        this.iScroll.on('scrollCancel', () => {
            this.stopMonitoringScrollPosition();
        });

    }

    destroy() {

        window.clearTimeout(this.scrollToTimeout);
        window.clearTimeout(this.checkScrollTimeout);

        if (this.iScroll) {
            this.iScroll.destroy();
        }

        this.iScroll = null;

    }

    registerInview(id: string, el: ElementRef) {

        // register and check visibility
        this.scrollActions.registerInview({
            id: id,
            visible: this.isElementInViewport(el),
            element: el
        });

    }

    deregisterInview(id: string) {

        this.scrollActions.deregisterInview({
            id
        });
    }

    private refresh() {

        // allow redraw
        window.setTimeout(() => {
            this.refreshScroll();
        });

    }

    private unhook() {

        //
        // Sometimes iScroll gets stuck to the mouse (e.g. after selecting
        // a date using the datepicker, this 'unhooks' the mouse)
        //

        if (this.iScroll) {
            this.iScroll.initiated = 0;
        }
    }

    private lock(state: boolean) {
        this.locked = state;
        if (this.iScroll) {
            if (state) {
                this.iScroll.disable();
            } else {
                this.iScroll.enable();
                // clear scrolled to value
                this.scrolledTo = null;
            }

            // show / hide the scrollbars
            (this.iScroll.indicators || []).forEach((indicator) => {
                indicator.wrapper.style.display = state ? 'none' : 'block';
            });
        }
    }

    private scrollToElement(scrollTo: ScrolledTo) {

        if (this.iScroll) {

            window.clearTimeout(this.scrollToTimeout);
            window.clearTimeout(this.checkScrollTimeout);

            this.iScroll.maxScrollY = this.maxScrollY - this.iScroll.wrapperHeight;
            this.iScroll.hasVerticalScroll = true;

            const selector = '#' + scrollTo.id;

            // keep track of our scroll point
            this.scrolledTo = selector;
            this.scrolledToOffset = this.showCart ? 0 - CONFIG.DIMENSIONS.CART_HEADER_HEIGHT : 0;

            // attempt to scroll
            this.iScroll.scrollToElement(selector, CONFIG.TRANSITION.DETAILS, 0, this.scrolledToOffset);

            // recheck the scroll position
            this.checkScrollTimeout = window.setTimeout(() => {
                this.checkScrollPosition();
            }, CONFIG.TRANSITION.DETAILS + 60);

            if (scrollTo.lock) {
                // simulate the scroll duration
                this.scrollToTimeout = window.setTimeout(() => {
                    // lock the scroll
                    this.scrollActions.lock();
                }, CONFIG.TRANSITION.DETAILS);
            }

        }

    }

    private scrollToTop() {

        if (this.iScroll) {
            // reset scroll to top
            this.iScroll.scrollTo(0, 0, 0);
        }

    }

    private refreshScroll() {
        if (this.iScroll) {
            this.iScroll.refresh();
            // save latest maxScrollY value
            this.maxScrollY = this.iScroll.maxScrollY;
            // check if we are currently scrolled to an element
            if (this.scrolledTo) {
                this.iScroll.maxScrollY = this.maxScrollY - this.iScroll.wrapperHeight;
                this.iScroll.hasVerticalScroll = true;
            }
            // recheck scroll positions
            this.checkScrollPosition();
            // ensure scrollTo is maintained
            window.requestAnimationFrame(() => {
                // maintain position of any scrolled elements
                this.maintainScrollToPostion();
            });
        }
    }

    private maintainScrollToPostion() {
        if (this.iScroll && this.scrolledTo) {
            this.iScroll.scrollToElement(this.scrolledTo, 0, 0, this.scrolledToOffset);
        }
    }

    private startMonitoringScrollPosition() {

        this.stopMonitoringScrollPosition();

        this.monitorScrollPositionInterval = window.setInterval(() => {
            this.checkScrollPosition();
        }, 60);

    }

    private stopMonitoringScrollPosition() {
        window.clearInterval(this.monitorScrollPositionInterval);
        // one last check
        window.setTimeout(() => {
            this.checkScrollPosition();
        });
    }

    private checkScrollPosition() {
        Object.keys(this.inview).forEach((key: string) => {
            // only check items with an element
            if (this.inview[key].element) {
                const inview = this.isElementInViewport(this.inview[key].element);
                if (this.inview[key].visible !== inview) {
                    // update the state if the status has changed
                    this.scrollActions.setInview({
                        id: key,
                        visible: inview
                    });
                }
            }
        });
    }

    private isElementInViewport(el: ElementRef) {

        const rect = el.nativeElement.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

}
