import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, concat } from 'rxjs';
import { ActionsObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';

import { TenantActionsService } from '../actions/tenant.actions';
import { switchMap, catchError, takeUntil, tap } from 'rxjs/operators';
import { UserActionsService } from '../actions/user.actions';

@Injectable()
export class TenantEpicsService {

    constructor(
        private http: HttpClient,
        private tenantActions: TenantActionsService,
        private userActionsService: UserActionsService
    ) { }

    /**
     *
     * LOAD THE CONFIG
     *
     */

    loadTenantConfiguration = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        const cancel$ = action$.ofType(TenantActionsService.LOAD_TENANT_CONFIG);

        return action$.ofType(TenantActionsService.LOAD_TENANT_CONFIG).pipe(
            switchMap(({ }) => {

                const url = '/TenantConfigurations/Get';

                return concat(
                    // flag as loading
                    of({
                        type: TenantActionsService.TENANT_CONFIG_LOADING
                    }),
                    // make the request
                    this.http.get(url, {
                        observe: 'body',
                        responseType: 'json',
                        headers: {
                            unauthenticatedRoute: 'true'
                        }
                    }).pipe(
                        switchMap((response: Observable<Object>) => {
                            return concat(
                                of(
                                    {
                                        type: TenantActionsService.TENANT_CONFIG_LOADED,
                                        payload: {
                                            response
                                        }
                                    }
                                ),
                                of(
                                    {
                                        type: UserActionsService.LOAD_USER
                                    }
                                )
                            //    ,
                            //    of(
                            //        {
                            //            type: UserActionsService.FETCH_PROFILE,
                            //            payload: {
                            //                response
                            //            }
                            //        }
                            //    )
                            );
                        }),
                        catchError((response: HttpErrorResponse) => {
                            return of({
                                type: TenantActionsService.TENANT_CONFIG_LOAD_ERROR,
                                error: {
                                    response
                                }
                            });
                        }),
                        takeUntil(cancel$)
                    ));

            }));

    }

}



