import { concat, of } from 'rxjs';
import { StatisticsActionsService } from '../actions/statistics.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
var StatisticsEpicsService = /** @class */ (function () {
    function StatisticsEpicsService(http) {
        var _this = this;
        this.http = http;
        this.fetchStatistics = function (action$) {
            var cancel$ = action$.ofType(StatisticsActionsService.FETCH_STATISTICS);
            return action$.ofType(StatisticsActionsService.FETCH_STATISTICS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var mode = payload.mode;
                var query = new HttpParams();
                var url = '/Statistics/GetRemarketingStatistics';
                // use concat so these actions happen sequentially
                return concat(of({
                    type: StatisticsActionsService.STATISTICS_LOADING,
                    payload: {
                        mode: mode
                    }
                }), _this.http.get(url, { params: query }).pipe(switchMap(function (response) {
                    return of({
                        type: StatisticsActionsService.STATISTICS_SUCCESS,
                        payload: {
                            response: response
                        }
                    });
                }), catchError(function (error) { return of({
                    type: StatisticsActionsService.STATISTICS_ERROR,
                    error: error
                }); }), takeUntil(cancel$)));
            }));
        };
    }
    return StatisticsEpicsService;
}());
export { StatisticsEpicsService };
