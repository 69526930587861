import {
    asyncLoadingReducer,
    asyncSuccessReducer,
    asyncErrorReducer,
    extractUserRoles,
    asyncSavingReducer,
    asyncSavingSuccessReducer
} from './helpers';

import { UserActionsService } from '../actions/user.actions';
import { BootingActionsService } from '../actions/booting.actions';

import { AppAction, UserState } from '../../../interfaces/store.interface';
import { UserRoles } from '../../../interfaces/user.interface';

const INITIAL_STATE: UserState = {
    identity: {
        payload: null,
        loading: false,
        error: null,
        hasError: false
    },
    profile: {
        payload: null,
        loading: false,
        saving: false,
        error: null,
        hasError: false
    },
    loading: false,
    isReady: false,
    isLoggedIn: false,
    isDestroyed: false,

    isBuyer: false,
    isAdmin: false,
    hasAcceptedAuctionDisclaimer: false
};

export default function userReducer(state: UserState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * DESTROY
         *
         */

        case BootingActionsService.USER_DESTROY:
        case UserActionsService.DESTROY: {
            return {
                ...INITIAL_STATE,
                isDestroyed: true
            };
        }

        /**
         *
         * LOAD USER
         *
         */

        case BootingActionsService.LOAD_USER:
        case UserActionsService.LOAD_USER: {
            return {
                ...INITIAL_STATE,
                loading: true
            };
        }

        /**
         *
         * IDENTITY (OIDC)
         *
         */

        case BootingActionsService.USER_LOGGED_IN:
        case UserActionsService.LOGGED_IN: {
            return {
                ...state,
                isLoggedIn: true
            };
        }

        case BootingActionsService.BOOT_SUCCESS:
        case BootingActionsService.USER_READY:
        case UserActionsService.READY: {
            return {
                ...state,
                isReady: true,
                loading: false
            };
        }

        /**
         *
         * IDENTITY (OIDC)
         *
         */

        case BootingActionsService.IDENTITY_LOADING:
        case UserActionsService.IDENTITY_LOADING: {
            return asyncLoadingReducer('identity')(state);
        }

        case BootingActionsService.IDENTITY_SUCCESS:
        case UserActionsService.IDENTITY_SUCCESS: {
            return asyncSuccessReducer('identity')(state, action.payload.response);
        }

        case BootingActionsService.IDENTITY_ERROR:
        case UserActionsService.IDENTITY_ERROR: {
            return asyncErrorReducer('identity')(state, action.error);
        }

        /**
         *
         * PROFILE
         *
         */

        case BootingActionsService.PROFILE_LOADING:
        case UserActionsService.PROFILE_LOADING: {
            return asyncLoadingReducer('profile')(state);
        }

        case BootingActionsService.PROFILE_SUCCESS:
        case UserActionsService.PROFILE_SUCCESS: {
            const nextState = asyncSuccessReducer('profile')(state, action.payload.response);

            nextState.profile.payload.roles = extractUserRoles(nextState.identity.payload.profile);

            nextState.isBuyer = nextState.profile.payload.roles &&
                nextState.profile.payload.roles.www_directselling &&
                nextState.profile.payload.roles.www_directselling.writer &&
                nextState.profile.payload.roles.www_directselling.writer.length > 0;


            nextState.isAdmin = nextState.profile.payload.roles &&
                Object.keys(nextState.profile.payload.roles).some(
                    el => {
                        return el.startsWith('admin_');
                    }
                );

            return nextState;
        }

        case BootingActionsService.PROFILE_ERROR:
        case UserActionsService.PROFILE_ERROR: {
            return asyncErrorReducer('profile')(state, action.error);
        }

        case UserActionsService.SAVE_USER_INFO_SAVING: {
            return asyncSavingReducer('profile')(state);
        }

        case UserActionsService.SAVE_USER_INFO_SUCCESS: {
            return asyncSavingSuccessReducer('profile')(state, action.payload.response);
        }

        case UserActionsService.SAVE_USER_INFO_ERROR: {
            return asyncErrorReducer('profile')(state, action.error);
        }

        /**
         *
         * AUCTION DISCLAIMER
         *
         */

        case UserActionsService.AUCTION_DISCLAIMER_ACCEPTED: {
            const nextState = {
                ...state,
                hasAcceptedAuctionDisclaimer: true
            };

            return nextState;
        }

        /**
         *
         * REFRESH TOKEN
         *
         */

        case UserActionsService.REFRESH_TOKEN_SUCCESS: {
            return asyncSuccessReducer('identity')(state, action.payload.response);
        }

    }

    return state;

}
