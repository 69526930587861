import { HttpClient, HttpParams } from '@angular/common/http';
import { FavouritesActionsService } from '../actions/favourites.actions';
import { concat, of } from 'rxjs';
import { ModalService } from '../../shared/services/modal/modal.service';
import { UserService } from '../../shared/services/user/user.service';
import { switchMap, catchError } from 'rxjs/operators';
import { ScrollActionsService } from '../actions/scroll.actions';
var FavouritesEpicsService = /** @class */ (function () {
    function FavouritesEpicsService(http, modal, user) {
        var _this = this;
        this.http = http;
        this.modal = modal;
        this.user = user;
        this.setParams = function (action$, state$) {
            return action$.ofType(FavouritesActionsService.SET_PARAMS, FavouritesActionsService.SET_PARAMS_NEXT_PAGE).pipe(switchMap(function (_a) {
                var type = _a.type;
                if (type === FavouritesActionsService.SET_PARAMS) {
                    // we need to reset the scroll
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    }, {
                        type: FavouritesActionsService.FETCH_FAVOURITES,
                        payload: null
                    });
                }
                return of({
                    type: FavouritesActionsService.FETCH_FAVOURITES
                });
            }));
        };
        /**
         *
         * FAVOURITES
         *
         */
        this.fetchFavourites = function (action$, state$) {
            return action$.ofType(FavouritesActionsService.FETCH_FAVOURITES).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var favouritesState = state$.value.favourites;
                var query = new HttpParams();
                // apply all values to the query string
                Object.keys(favouritesState.params).forEach(function (key) {
                    if (favouritesState.params[key] || favouritesState.params[key] === 0) {
                        query = query.append(key, favouritesState.params[key].toString());
                    }
                });
                // use concat so these actions happen sequentially
                return concat(of({
                    type: favouritesState.params.pageIndex > 0 ?
                        FavouritesActionsService.FAVOURITES_LOADING_MORE : FavouritesActionsService.FAVOURITES_LOADING,
                    payload: null
                }), _this.http.get('/Favourites/List', { params: query }).pipe(switchMap(function (response) {
                    return of({
                        type: FavouritesActionsService.FAVOURITES_SUCCESS,
                        payload: {
                            response: response
                        }
                    });
                }), catchError(function (error) { return of({
                    type: FavouritesActionsService.FAVOURITES_ERROR,
                    error: error
                }); })));
            }));
        };
        this.toggleFavourite = function (action$, state$) {
            return action$.ofType(FavouritesActionsService.TOGGLE_FAVOURITE).pipe(switchMap(function (_a) {
                // const sellingItem = state$.value.entities.sellingItems[payload.sellingItemId];
                var payload = _a.payload;
                var params = new HttpParams({
                    fromObject: {
                        id: payload.sellingItemId
                    }
                });
                // fake response
                // return of({}).pipe(
                var call;
                if (payload.enable) {
                    call = _this.http.post('/Favourites/Add', {}, {
                        params: params
                    });
                }
                else {
                    call = _this.http.delete('/Favourites/Remove', {
                        params: params
                    });
                }
                return call.pipe(switchMap(function (payload2) { return of({
                    type: FavouritesActionsService.TOGGLE_FAVOURITE_SUCCESS,
                    payload: payload
                }); }), catchError(function (err) { return of({
                    type: FavouritesActionsService.TOGGLE_FAVOURITE_ERROR,
                    payload: payload
                }); }));
            }));
        };
    }
    return FavouritesEpicsService;
}());
export { FavouritesEpicsService };
