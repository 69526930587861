/// <reference path="../../../../../node_modules/oidc-client/index.d.ts" />

declare var Oidc: any;

import { Injectable } from '@angular/core';

import { EnvironmentService } from '../../../shared/services/environment/environment.service';
import { UserActionsService } from '../../actions/user.actions';
import { AppState } from '../../../../interfaces/store.interface';
import { NgRedux } from '@angular-redux/store';
import { WindowRefService } from '../../../shared/services/window-ref/window-ref.service';

@Injectable()
export class UserManagerService {

    private userManager: Oidc.UserManager;
    private ui_locales: string[];

    constructor(
        private environmentService: EnvironmentService,
        private userActionsService: UserActionsService,
        private appState: NgRedux<AppState>,
        private windowRef: WindowRefService
    ) {



    }

    /**
     *
     * Get user
     *
     */

    getUser(): Promise<Oidc.User> {

        /**
         *
         * Use this is you want to bypass authentication
         *
         */

        // return new Promise((resolve) => {
        //     // resolve with a fake user
        //     resolve({
        //         id_token: '12345',
        //         session_state: 'any',
        //         access_token: 'abcde',
        //         token_type: 'Bearer',
        //         scope: 'scope',
        //         profile: {
        //             sub: 'Fake User'
        //         },
        //         expires_at: Date.now() + (24 * 60 * 60 * 1000),
        //         state: 'any',
        //         toStorageString: () => {
        //             return 'string'
        //         },
        //         expires_in: (24 * 60 * 60 * 1000),
        //         expired: false,
        //         scopes: []
        //     });
        // });

        //        return this.userManager.clearStaleState().then(() => {
        //            return this.userManager.getUser();
        //            //    const user = this.userManager.getUser();
        //            //    if (!user) {
        //            //        return this.userManager.signinRedirectCallback();
        //            //    }
        //            //    return user;
        //        });


        return this.getUserManagerInstance().clearStaleState().then(() => {
            return this.getUserManagerInstance().getUser().then(
                (user) => {
                    if (user && !user.access_token) {
                        this.getUserManagerInstance().signinSilent().then(
                            (userS) => {
                                // refresh access token and return the user
                                return new Promise<Oidc.User>(
                                    (resolve, reject) => {
                                        resolve(userS);
                                    });
                            }
                        );
                    } else {
                        return new Promise<Oidc.User>(
                            (resolve, reject) => {
                                resolve(user);
                            });
                    }

                });

        });

    }

    /**
     *
     * Logout
     *
     */

    logout(): Promise<any> {

        return this.getUserManagerInstance().clearStaleState().then(() => {
            return this.getUserManagerInstance().signoutRedirect();
        });

    }

    /**
     *
     * Start login
     *
     */

    login(): Promise<any> {

        // start the sign in
        return this.getUserManagerInstance().clearStaleState().then(() => {
            return this.getUserManagerInstance().signinRedirect();
        });

    }

    refreshToken(): Promise<any> {
        return this.getUserManagerInstance().signinSilent();
    }

    private getUserManagerInstance() {
        if (!this.userManager) {

            // inject locales in querystring
            const theState = this.appState.getState();
            // get browser locale
            const browserLanguage = navigator.language;

            // check if browser locale is supported
            let ui_locales = [theState.tenant.enabledCultures[0]];
            if (theState.tenant && theState.tenant.enabledCultures.includes(browserLanguage)) {
                ui_locales = [browserLanguage];
            }

            this.ui_locales = ui_locales;
            // create our user manager instance
            this.userManager = new Oidc.UserManager({
                authority: this.environmentService.getEnvironment().oidc.authority,
                client_id: this.environmentService.getEnvironment().oidc.clientId,
                redirect_uri: location.protocol + '//' + location.host + '/callback.html',
                response_type: this.environmentService.getEnvironment().oidc.responseType,
                scope: this.environmentService.getEnvironment().oidc.scope,
                loadUserInfo: true,
                // silent renewal
                silent_redirect_uri: location.protocol + '//' + location.host + '/silent-renew.html',
                automaticSilentRenew: false,
                silentRequestTimeout: 10000,
                ui_locales: this.ui_locales.join(' ')
            });

            this.userManager.events.addAccessTokenExpiring(() => {
                // trigger refresh access token in redux
                this.userActionsService.refreshToken();
            });
        }
        return this.userManager;
    }
}
