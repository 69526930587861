/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../listing-item/listing-item.component.ngfactory";
import * as i3 from "../listing-item/listing-item.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i6 from "../../../store/actions/sales.actions";
import * as i7 from "../../../store/actions/order.actions";
import * as i8 from "../../../store/actions/ui.actions";
import * as i9 from "../../services/google-analytics/google-analytics.service";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./modal-cart-add.component";
import * as i12 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalCartAddComponent = [];
var RenderType_ModalCartAddComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalCartAddComponent, data: {} });
export { RenderType_ModalCartAddComponent as RenderType_ModalCartAddComponent };
function View_ModalCartAddComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "listing__unavailable"]], null, null, null, null, null))], null, null); }
function View_ModalCartAddComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalCartAddComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-listing-item", [], [[2, "listing__item--disabled", null], [2, "listing__item--removed", null]], null, null, i2.View_ListingItemComponent_0, i2.RenderType_ListingItemComponent)), i0.ɵdid(4, 704512, null, 0, i3.ListingItemComponent, [i4.Router, i5.NgRedux, i6.SalesActionsService, i7.OrderActionsService, i8.UiActionsService, i9.GoogleAnalyticsService], { sellingItem: [0, "sellingItem"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.vehicle._isUnavailable || _co.vehicle._isInCart) || _co.vehicle._addingToCart) || ((_co.vehicle.auctionInfo == null) ? null : _co.vehicle.auctionInfo._updating)); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.vehicle; var currVal_4 = "modal-cart"; _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 4).disabledStatus; var currVal_2 = i0.ɵnov(_v, 4).removedStatus; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_ModalCartAddComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h1", [["class", "modal__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "modal__vehicle"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalCartAddComponent_1)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 13, "div", [["class", "modal__actions cf"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 12, "div", [["class", "grid-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 5, "div", [["class", "grid grid--6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "grid-gutter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(19, 0, null, null, 5, "div", [["class", "grid grid--6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 4, "div", [["class", "grid-gutter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal button--primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(23, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(25, 0, null, null, 4, "div", [["class", "modal__close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--transparent"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 0, "i", [["class", "icon icon--out icon--close-thin-grey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 0, "i", [["class", "icon icon--hover icon--close-thin-red"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u00A0 "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !!_co.vehicle; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Commons.Confirm")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("Modals.AddToCart.AreYouSure")); _ck(_v, 6, 0, currVal_1); var currVal_3 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform("Commons.Cancel")); _ck(_v, 17, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 23, 0, i0.ɵnov(_v, 24).transform("Commons.Confirm")); _ck(_v, 23, 0, currVal_4); }); }
export function View_ModalCartAddComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-cart-add", [], null, null, null, View_ModalCartAddComponent_0, RenderType_ModalCartAddComponent)), i0.ɵdid(1, 114688, null, 0, i11.ModalCartAddComponent, [i12.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalCartAddComponentNgFactory = i0.ɵccf("app-modal-cart-add", i11.ModalCartAddComponent, View_ModalCartAddComponent_Host_0, { vehicleEntity: "vehicleEntity" }, {}, []);
export { ModalCartAddComponentNgFactory as ModalCartAddComponentNgFactory };
