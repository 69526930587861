<ng-container *ngIf="visible">
    <div class="preloader" [@fadeInOut] [ngClass]="{
      'preloader--opaque': opaque,
      'preloader--add-to-cart': addToCart,
      'preloader--black': gallery
    }" [ngStyle]="{ 'z-index': zIndex || '' }">
      <div [ngClass]="{ 'preloader__container': container }">
        <div class="preloader__spinner" [ngClass]="{
          'preloader__spinner--add-to-cart': addToCart,
          'preloader__spinner--black': gallery
        }"></div>
        <div class="preloader__label">
            <ng-content></ng-content>
        </div>
      </div>
    </div>
</ng-container>

