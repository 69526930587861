import { BootingActionsService } from '../actions/booting.actions';
import { TenantActionsService } from '../actions/tenant.actions';

import { AppAction, TenantState } from '../../../interfaces/store.interface';

const INITIAL_STATE: TenantState = {
    id: null,
    title: null,
    companyInfo: {
        address: null,
        city: null,
        code: null,
        country: null,
        district: null,
        fax: null,
        name: null,
        phone: null,
        vat: null,
        zipcode: null
    },
    enabledCultures: [],
    timezoneInfo: null,
    defaultPaymentMethod: [],
    defaultPaymentTerms: [],
    wallpapers: [],
    analytics: null,

    _loading: false,
    _loaded: false
};

export default function tenantReducer(state: TenantState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * TENANT CONFIG
         *
         */

        case BootingActionsService.TENANT_CONFIG_LOADING:
        case TenantActionsService.TENANT_CONFIG_LOADING: {
            const nextState = {
                ...INITIAL_STATE,

                _loading: true
            };
            return nextState;
        }

        case BootingActionsService.TENANT_CONFIG_SUCCESS:
        case TenantActionsService.TENANT_CONFIG_LOADED: {
            const response = action.payload.response;

            let nextState = {
                ...state,

                _loading: false,
                _loaded: true,
            };

            // update the state
            if (response) {

                nextState = {
                    ...nextState,
                    ...response
                };
            }

            return nextState;
        }

        case BootingActionsService.TENANT_CONFIG_ERROR:
        case TenantActionsService.TENANT_CONFIG_LOAD_ERROR: {
            const nextState = {
                ...state,

                _loading: false,
                _loaded: false
            };

            return nextState;
        }

    }

    return state;

}
