import {
    asyncLoadingReducer,
    asyncSuccessReducer,
    asyncErrorReducer,
    paginatedAsyncLoadingReducer,
    paginatedAsyncLoadingMoreReducer,
    paginatedAsyncSuccessReducer,
    paginatedAsyncErrorReducer,
    removeArrayItem
} from './helpers';

import { CONFIG } from '../../shared/config/config.const';

import { AppAction, SalesState } from '../../../interfaces/store.interface';

import { SalesActionsService } from '../actions/sales.actions';
import { CartValidationActionsService } from '../actions/cart-validation.actions';
import { durationToMinutes } from '../../shared/helpers/duration.helper';

const INITIAL_STATE: SalesState = {
    area: null,
    filterOptions: {
        payload: null,
        loading: false,
        error: null,
        hasError: false
    },
    filterManufacturer: null,
    filterSeries: null,
    filtersApplied: false,
    visibleFilter: null,
    params: {
        sortColumn: null,
        sortMode: false,
        pageIndex: 0,
        pageSize: CONFIG.PAGINATION.PAGE_SIZE
    },
    results: {
        entity: 'sellingItems',
        payload: {
            pageIndex: -1,
            pageSize: CONFIG.PAGINATION.PAGE_SIZE,
            result: [],
            totalCount: -1,
            totalPages: -1
        },
        loadedCount: 0, // how many results have actually been loaded?
        loading: false,
        loadingMore: false,
        lastLoad: new Date(0),
        error: null,
        hasError: false,
        hasNextPage: false
    },
    selected: {
        index: -1, // when this is > -1 that means the panel is visible
        vehicleId: null,
        thumbsIndex: 0,
        isFullyOpen: false // true when the open animation completes
    },
    bundleSelected: {
        index: 0
    },
    showCart: false,
    cartDuration: null,
    activeAuctionsForUser: null
};

export default function salesReducer(state: SalesState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * SET
         *
         */

        case SalesActionsService.SET_AREA: {

            const nextState = {
                ...INITIAL_STATE,
                area: action.payload
            };

            // should we should the cart?
            nextState.showCart = true;
            // TODO
            // nextState.showCart = (
            //     action.payload === 'SaleOl' ||
            //     action.payload === 'SaleYuc' ||
            //     action.payload === 'PreSale'
            // );

            // is this an auction?
            //            nextState.isAuction = (
            //                action.payload === 'OpenAuctionOl' ||
            //                action.payload === 'OpenAuctionYuc' ||
            //                action.payload === 'ClosedAuctionOl' ||
            //                action.payload === 'ClosedAuctionYuc'
            //            );

            return nextState;
        }

        case SalesActionsService.SET_PARAMS: {

            const nextState = {
                ...state,
                params: {
                    // only keep the required values
                    sortColumn: state.params.sortColumn,
                    sortMode: state.params.sortMode,
                    // always start from first page
                    pageIndex: 0,
                    pageSize: state.params.pageSize,
                    /**
                     *
                     * MERGE IN THE PAYLOAD VALUES
                     */
                    ...action.payload
                },
                // prepopulate filters so everything is in sync
                filterManufacturer: action.payload.manufacturer,
                filterSeries: action.payload.series
            };

            // check length of keys to determine if other filters have been applied
            // too basic??? @TODO
            nextState.filtersApplied = Object.keys(nextState.params).length > 4;

            return nextState;
        }

        case SalesActionsService.SET_PARAMS_NEXT_PAGE: {
            const nextState = {
                ...state,
                params: {
                    ...state.params,
                    // increment the page index
                    pageIndex: state.params.pageIndex + 1
                }
            };

            return nextState;
        }

        case SalesActionsService.SET_MANUFACTURER: {

            const isChanging = state.filterManufacturer !== action.payload;

            const nextState = {
                ...state,
                filterManufacturer: action.payload
            };

            if (isChanging) {
                nextState.filterSeries = null;
            }

            return nextState;
        }

        case SalesActionsService.SET_SERIES: {
            return {
                ...state,
                filterSeries: action.payload
            };
        }

        case SalesActionsService.SET_SELECTED_INDEX: {
            return {
                ...state,
                selected: {
                    ...INITIAL_STATE.selected,
                    index: action.payload,
                    // set fully open value (allow for already open)
                    isFullyOpen: action.payload > -1 ? state.selected.isFullyOpen || false : false
                }
            };
        }

        case SalesActionsService.SET_SELECTED_BUNDLE_INDEX: {
            return {
                ...state,
                bundleSelected: {
                    ...state.bundleSelected,
                    index: action.payload,
                }
            };
        }

        case SalesActionsService.SET_SELECTED_RESULT: {

            return {
                ...state,
                selected: {
                    ...state.selected,
                    vehicleId: action.payload.vehicleId
                }
            };
        }

        case SalesActionsService.SET_SELECTED_FULLY_OPEN: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    // set the fully open state
                    isFullyOpen: action.payload
                }
            };
        }

        case SalesActionsService.SET_THUMBS_INDEX: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    thumbsIndex: action.payload
                }
            };
        }

        case SalesActionsService.RELOAD_SELLINGITEMS: {
            const nextState = {
                ...state,
                params: {
                    ...state.params,
                    // reset the page index
                    pageIndex: 0
                }
            };

            return nextState;
        }

        /**
         *
         * FILTERS
         *
         */

        case SalesActionsService.SELLINGITEM_FILTERS_LOADING: {
            return asyncLoadingReducer('filterOptions')(state);
        }

        case SalesActionsService.SELLINGITEM_FILTERS_SUCCESS: {
            return asyncSuccessReducer('filterOptions')(state, action.payload.response);
        }

        case SalesActionsService.SELLINGITEM_FILTERS_ERROR: {
            return asyncErrorReducer('filterOptions')(state, action.error);
        }

        /**
         *
         * VEHICLES (PAGINATED)
         *
         */

        // Loading first page
        case SalesActionsService.SELLINGITEMS_LOADING: {
            return paginatedAsyncLoadingReducer('results')(state);
        }

        // Loading next page
        case SalesActionsService.SELLINGITEMS_LOADING_MORE: {
            return paginatedAsyncLoadingMoreReducer('results')(state);
        }

        case SalesActionsService.SELLINGITEMS_SUCCESS: {
            return paginatedAsyncSuccessReducer('results')(state, action.payload.response);
        }

        case SalesActionsService.SELLINGITEMS_ERROR: {
            return paginatedAsyncErrorReducer('results')(state, action.error);
        }

        /**
         *
         * REMOVE VEHICLE FROM RESULTS
         *
         */

        case SalesActionsService.REMOVE_SELLINGITEM_FROM_RESULTS: {
            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const index = nextState.results.payload.result.indexOf(vehicleId);

            // check the result exists
            if (index > -1) {

                nextState.results = {
                    ...state.results,
                    payload: {
                        ...state.results.payload,
                        result: [
                            ...state.results.payload.result.slice(0, index),
                            // rather than actually remove the item we simply hide it (to prevent issues with pagination)
                            CONFIG.PAGINATION.REMOVED_FLAG,
                            ...state.results.payload.result.slice(index + 1)
                        ]
                    }
                };

                // we also need to reduce the total count
                nextState.results.payload.totalCount = Math.max(nextState.results.payload.totalCount - 1, 0);

                // Note - the API doesn't allow us to load items from a specific index, only by pageIndex
                // meaning we can't fetch items 7-10 (for example) to fill the removed spaces

            }

            return nextState;
        }

        case CartValidationActionsService.CART_CONFIG_SUCCESS: {

            const nextState = {
                ...state,
                cartDuration: durationToMinutes(action.payload.response.duration)
            };

            return nextState;
        }

        case SalesActionsService.ACTIVE_AUCTIONS_FOR_USER_SUCCESS: {
            const nextState = {
                ...state,
                activeAuctionsForUser: action.payload.response.sellingItemIds
            };

            return nextState;
        }

    }

    return state;

}
