import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';
import { IOrdersArea } from '../../../interfaces/order/OrdersArea.interface';
import { IOrderParams } from '../../../interfaces/order/OrderParams.interface';

@Injectable()
export class OrderActionsService {

    static readonly SET_AREA = '@order/SET_AREA';
    static readonly SET_PARAMS = '@order/SET_PARAMS';
    static readonly SET_PARAMS_NEXT_PAGE = '@order/SET_PARAMS_NEXT_PAGE';

    static readonly TOGGLE_ORDER_VEHICLES = '@order/TOGGLE_ORDER_VEHICLES';
    static readonly TOGGLE_ORDER_VEHICLE_DETAILS = '@order/TOGGLE_ORDER_VEHICLE_DETAILS';

    static readonly FETCH_ORDERS = '@order/FETCH_ORDERS';
    static readonly ORDERS_LOADING = '@order/ORDERS_LOADING';
    static readonly ORDERS_LOADING_MORE = '@order/ORDERS_LOADING_MORE';
    static readonly ORDERS_SUCCESS = '@order/ORDERS_SUCCESS';
    static readonly ORDERS_ERROR = '@order/ORDERS_ERROR';

    static readonly SET_SELECTED_INDEX = '@order/SET_SELECTED_INDEX';
    static readonly SET_SELECTED_BUNDLE_INDEX = '@order/SET_SELECTED_BUNDLE_INDEX';
    static readonly SET_SELECTED_RESULT = '@order/SET_SELECTED_RESULT';
    static readonly SET_SELECTED_FULLY_OPEN = '@order/SET_SELECTED_FULLY_OPEN';
    static readonly SET_THUMBS_INDEX = '@order/SET_THUMBS_INDEX';


    /**
     *
     * ORDERS
     *
     */

    @dispatch()
    setArea = (payload: { area: IOrdersArea }): AppAction => ({
        type: OrderActionsService.SET_AREA,
        payload
    })

    @dispatch()
    setParams = (payload: IOrderParams): AppAction => ({
        type: OrderActionsService.SET_PARAMS,
        payload
    })

    @dispatch()
    setParamsNextPage = (): AppAction => ({
        type: OrderActionsService.SET_PARAMS_NEXT_PAGE
    })

    @dispatch()
    toggleOrderVehicles = (payload: { orderId: string; }): AppAction => ({
        type: OrderActionsService.TOGGLE_ORDER_VEHICLES, // picked up by entities
        payload
    })

    @dispatch()
    toggleOrderVehicleDetails = (payload: { orderId: string; vehicleId: string }): AppAction => ({
        type: OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS,
        payload
    })

    @dispatch()
    setSelectedResult = (payload: { vehicleId: string }): AppAction => ({
        type: OrderActionsService.SET_SELECTED_RESULT,
        payload
    })

    @dispatch()
    setSelectedIndex = (payload: { orderId: string, index: number, isDeleted: boolean }): AppAction => ({
        type: OrderActionsService.SET_SELECTED_INDEX,
        payload
    })

    @dispatch()
    setSelectedBundleIndex = (payload: number): AppAction => ({
        type: OrderActionsService.SET_SELECTED_BUNDLE_INDEX,
        payload
    })
}
