import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { NgRedux } from '@angular-redux/store';

import { AppState } from '../../../../interfaces/store.interface';
import {
    CartContents,
    CartConfigRestriction,
    CartConfigDependency,
    CartConfigDependencySelector,
    CartConfigSeriesRestriction,
    CartValidationMessage,
    CartValidationMessageData,
    CartValidationResult
} from '../../../../interfaces/cart.interface';

import { CONFIG } from '../../../shared/config/config.const';
import { ISellingItemStore } from '../../../../interfaces/SellingItem.interface';
import { StateObservable } from 'redux-observable';

@Injectable()
export class CartValidationService {

    private messages = [];

    constructor(
        private decimalPipe: DecimalPipe
    ) { }

    /**
     *
     * Validate the cart and the first item in the queue
     *
     */

    validateCartQueue(store$: StateObservable<AppState>) {

        return this.validateCart(store$, true);

    }

    /**
     *
     * This method compares the cart with the cart config rules
     *
     * You can pass it a modified cart for theoretical validation (i.e. when adding a vehicle)
     *
     */

    validateCart(state$: StateObservable<AppState>, includeQueue: boolean = false) {

        const cartConfig = state$.value.cartValidation.config.settings;
        const cartQueue = state$.value.cart.queue;

        const existingContents = state$.value.cart.contents;

        // take a copy (because we modify it with our queue)
        const cartContents = {
            ...existingContents,
            vehicles: [...existingContents.vehicles] // ensure the vehicles array is also cloned
        };

        let addedVehicle;

        /**
         *
         * RESET MESSAGES
         *
         */

        this.messages = [];

        /********************************************
         ***
         ***  ADD QUEUED VEHICLE TO CART
         ***
         ********************************************/

        if (includeQueue && cartQueue.length) {

            // lookup added vehicle
            const entities = state$.value.entities.sellingItems;

            // use the first item in the queue
            addedVehicle = entities[cartQueue[0]];

            cartContents.vehicles.push(addedVehicle.id);
            cartContents.totalVehicles++;
            cartContents.total += addedVehicle.props.price;

        }


        /********************************************
         ***
         ***  RUN TESTS ON THE BASKET RULES
         ***
         ********************************************/

        /**
         *
         *  ENABLED / DISABLED
         *
         */

        // new test
        this.validateRule('DISABLED', true)
            .error(!cartConfig.basket.enabled)
            .save({
                silent: true,
                applies: true,
                description: cartConfig.basket.enabled.description
            });

        if (cartConfig.orders) {
            /**
             *
             *  ORDERS (check if we've reached the max allowed orders)
             *
             */

            const maxOrders = this.isNumeric(cartConfig.orders.max) ? cartConfig.orders.max : Infinity;

            this.validateRule('ORDER_COUNT', true)
                .error(cartConfig.orders.count >= maxOrders)
                .save({
                    silent: true,
                    applies: true,
                    max: maxOrders,
                    value: cartConfig.orders.count,
                    description: cartConfig.orders.description
                });
        }

        if (cartConfig.basket.items && (cartConfig.basket.items.min || cartConfig.basket.items.max)) {
            /**
             *
             *  MIN / MAX ITEM COUNT
             *
             */

            const minBasketItem = this.isNumeric(cartConfig.basket.items.min) ? cartConfig.basket.items.min : 0;
            const maxBasketItem = this.isNumeric(cartConfig.basket.items.max) ? cartConfig.basket.items.max : 0;

            this.validateRule('COUNT', true)
                .warn((cartContents.totalVehicles < minBasketItem && !!addedVehicle) || cartContents.totalVehicles === maxBasketItem)
                .error(cartContents.totalVehicles > maxBasketItem || (cartContents.totalVehicles < minBasketItem && !addedVehicle))
                .save({
                    silent: true,
                    applies: true,
                    min: minBasketItem,
                    max: maxBasketItem,
                    value: cartContents.totalVehicles - (!!addedVehicle ? 1 : 0),
                    description: cartConfig.basket.items.description
                });
        }

        if (cartConfig.basket.value) {
            /**
             *
             *  MIN / MAX VALUE
             *
             */

            const minBasketValue = this.isNumeric(cartConfig.basket.value.min) ? cartConfig.basket.value.min : 0;
            const maxBasketValue = this.isNumeric(cartConfig.basket.value.max) ? cartConfig.basket.value.max : Infinity;

            this.validateRule('VALUE', true)
                .warn(cartContents.total < minBasketValue && !!addedVehicle)
                .error(cartContents.total > maxBasketValue || (cartContents.total < minBasketValue && !addedVehicle))
                .save({
                    applies: true,
                    max: maxBasketValue,
                    value: cartContents.total - (!!addedVehicle ? addedVehicle.props.price : 0),
                    description: cartConfig.basket.value.description
                });
        }

        /********************************************
         ***
         ***  RUN TESTS ON THE BASKET dependencies
         ***
         ********************************************/

        cartConfig.basket.restrictions.forEach((restriction: CartConfigRestriction) => {
            this.validateRestriction(state$, restriction, cartContents, addedVehicle);
        });

        /********************************************
         ***
         ***  RUN TESTS ON THE BASKET RESTRICTIONS
         ***
         ********************************************/

        cartConfig.basket.dependencies.forEach((dependency: CartConfigDependency) => {
            this.validateDependency(state$, dependency, cartContents, addedVehicle);
        });

        /**
         *
         * VALIDATION COMPLETE
         *
         */

        return this.messages.slice();

    }

    /**
     *
     * RESTRICTION VALIDATION
     *
     * @param restriction
     * @param cartContents
     * @param addedVehicle
     */

    private validateRestriction(
        store$: StateObservable<AppState>,
        restriction: CartConfigRestriction,
        cartContents: CartContents,
        addedVehicle?: ISellingItemStore
    ) {

        const min = this.isNumeric(restriction.min) ? restriction.min : 0;
        const max = this.isNumeric(restriction.max) ? restriction.max : Infinity;

        const checkingVehicle = !!addedVehicle;

        const vehicles = store$.value.entities.sellingItems;

        const matchingVehicles = cartContents.vehicles.map((vehicleId: string) => {
            // find vehicle in entities
            return vehicles[vehicleId];
        }).filter((vehicle: ISellingItemStore) => {
            return !!vehicle && vehicle.props._brand === restriction._brand;
        });

        const manufacturerApplies = !!(checkingVehicle && addedVehicle.props._brand === restriction._brand);

        const offsetManufacturer = manufacturerApplies ? 1 : 0;

        const message = {
            id: restriction.id,
            description: restriction.description,
            brand: restriction.brand,
            min,
            max,
            value: matchingVehicles.length - offsetManufacturer,
            applies: manufacturerApplies
        };

        /**
         *
         *  MAX (warn when equal to max)
         *
         */

        this.validateRule('RESTRICTION_COUNT')
            .warn(matchingVehicles.length === max || (matchingVehicles.length < min && checkingVehicle))
            .error(matchingVehicles.length > max || (matchingVehicles.length < min && !checkingVehicle))
            .save(message);


        /**
         *
         *  NOW CHECK THE SERIES COUNTS
         *
         */


        if (restriction.series && restriction.series.length) {

            // reset counts
            restriction.series.forEach((series: CartConfigSeriesRestriction) => {
                series._counter = 0;
            });

            // loop through cars and assign them to series
            matchingVehicles.forEach((vehicle: ISellingItemStore) => {
                restriction.series.every((series: CartConfigSeriesRestriction) => {
                    let loop = true;
                    // match found
                    if (series._series === vehicle.props._series) {
                        // inc counter
                        series._counter += 1;
                        // stop loop
                        loop = false;
                    }
                    return loop;
                });
            });

            // now loop through the series and validate
            restriction.series.forEach((series: CartConfigSeriesRestriction) => {

                const seriesMin = this.isNumeric(series.min) ? series.min : 0;
                const seriesMax = this.isNumeric(series.max) ? series.max : Infinity;

                const seriesCounter = series._counter || 0;

                const seriesApplies = !!(manufacturerApplies && series._series === addedVehicle.props._series);

                const offsetSeries = seriesApplies ? 1 : 0;

                const message = {
                    id: restriction.id,
                    description: series.description,
                    manufacturer: restriction.brand,
                    series: series.name,
                    min: seriesMin,
                    max: seriesMax,
                    value: seriesCounter - offsetSeries,
                    applies: seriesApplies // does this restriction apply to the car we are adding?
                };

                /**
                 *
                 *  MAX (warn when equal to max)
                 *
                 */

                this.validateRule('RESTRICTION_SERIES_COUNT')
                    .error(seriesCounter > seriesMax || (seriesCounter < seriesMin && !addedVehicle))
                    .save(message);


            });

        }

    }

    /**
     *
     * DEPENDENCY VALIDATION
     *
     * @param dependency
     * @param cartContents
     * @param addedVehicle
     */
    private validateDependency(
        store$: StateObservable<AppState>,
        dependency: CartConfigDependency,
        cartContents: CartContents,
        addedVehicle?: ISellingItemStore
    ) {

        const checkingVehicle = !!addedVehicle;

        const applyTo = this.testSelector(store$, cartContents, dependency.applyTo);

        const message = {
            id: dependency.id,
            description: dependency.description,
            applyTo,
            requirements: null,
            applies: false, // does this apply to anything in the cart?
            silent: true // only show dependencies if they apply
        };

        /**
         *
         * Does it apply?
         *
         */

        let applies = applyTo._met;

        /**
         *
         * Check if it applies to our added vehicle
         *
         */

        if (checkingVehicle) {

            if (applyTo.brand && applyTo._brand !== addedVehicle.props._brand) {
                applies = false;
            }

            if (applyTo.series && applyTo._series !== addedVehicle.props._series) {
                applies = false;
            }

            if (applyTo.vehicleIds.indexOf(addedVehicle.id) === -1) {
                applies = false;
            }

        }

        /**
         *
         * Update the message
         *
         */

        message.applies = applies;

        /**
         *
         * Check requirements
         *
         */

        message.requirements = dependency.requires.map((selector: CartConfigDependencySelector) => {
            return this.testSelector(store$, cartContents, selector);
        });

        /**
         *
         * Check if ALL requirements have been met
         *
         */

        const requirementsMet = message.requirements.filter((selector: CartConfigDependencySelector) => selector._met).length === message.requirements.length;

        /**
         *
         * Output message
         *
         */

        if (applies) {

            this.validateRule('DEPENDENCY')
                .warn(!requirementsMet && !!addedVehicle)
                .error(!requirementsMet && !addedVehicle)
                .save(message);

        } else {

            this.validateRule('DEPENDENCY')
                .save(message);

        }
    }

    /**
     *
     * RULE VALIDATION
     *
     * @param name
     * @param global
     */
    private validateRule(name: string, global: boolean = false) {

        const self = {

            // name of the rule
            name,

            // does this affect any item? (i.e. is it basket-wide)
            global,

            // has it passed the test?
            status: <0 | 1 | 2>0, // assume 0 = pass, 1 = warn, 2 = error

            warn: function (expression: boolean) { // Note: standard function scope

                // only warn if currently valid (otherwise a warn could overwrite an error)
                if (expression && self.status === 0) {
                    // test is truthy, so we need to fail it
                    self.status = 1;
                }

                return self;
            },

            error: function (expression: boolean) { // Note: standard function scope

                if (expression) {
                    // test is truthy, so fail it
                    self.status = 2;
                }

                return self;
            },

            save: (data: CartValidationMessageData = null) => { // Note ARROW FUNCTION changes scope

                // use helper to structure the message
                this.saveValidationMessage({
                    scope: self.global ? 'GLOBAL' : 'SPECIFIC',
                    name: self.name,
                    status: self.status,
                    data
                });

            }
        }

        return self;

    }

    private saveValidationMessage(result: CartValidationResult) {

        const message: CartValidationMessage = {
            ...result,
            _children: []
        };

        let isCurrency = false;

        // add / calculate additional properties
        if (message.name) {

            // read name from config
            message._item = CONFIG.CART.RULES[message.name] || CONFIG.CART.RULES.UNKNOWN;

            message._isRestriction = !!(message.name.indexOf('RESTRICTION') > -1);
            message._isDependency = !!(message.name.indexOf('DEPENDENCY') > -1);

            /**
             *
             * Determine icon
             *
             */
            switch (message.name) {
                case 'VALUE': {
                    message._icon = 'value';
                    message._isCurrency = true;
                    break;
                }
                case 'DISABLED': {
                    message._icon = 'disabled';
                    break;
                }
                case 'ORDER_COUNT': {
                    message._icon = 'order';
                    break;
                }
                case 'COUNT': {
                    message._icon = 'size';
                    break;
                }
            }

            if (message._isDependency) {
                message._icon = 'dependency';
            }

            if (message._isRestriction) {
                message._icon = 'restriction';
            }

        }

        if (message.data) {

            if (message.data.description) {

                const context = {
                    min: this.formatNumber(message.data.min, isCurrency),
                    max: this.formatNumber(message.data.max, isCurrency),
                    value: this.formatNumber(message.data.value, isCurrency)
                };

                message.data.description = this.interpolate(message.data.description, context);

            }

            //
            // DEPENDENCY
            //

            if (message._isDependency && message.data.applyTo) {

                // Read what is being matched
                if (message.data.applyTo.vehicleIds && message.data.applyTo.vehicleIds.length) {
                    message._item = message.data.applyTo.vehicleIds.join(', ');
                } else {
                    message._item = `${message.data.applyTo.brand || ''} ${message.data.applyTo.series || ''}`;
                }

                // map the children
                message._children = message.data.requirements.map((requirement: CartConfigDependencySelector) => {
                    if (requirement.vehicleIds && requirement.vehicleIds.length) {
                        requirement._item = requirement.vehicleIds.join(', ');
                    } else {
                        requirement._item = `${requirement.brand || ''} ${requirement.series || ''}`;
                    }
                    return requirement;
                });

            } else if (message._isRestriction) {

                // Read what is being matched
                if (message.data.vehicleIds && message.data.vehicleIds.length) {
                    message._item = message.data.vehicleIds.join(', ');
                } else {
                    message._item = `${message.data.brand || ''} ${message.data.series || ''}`;
                }

            }
        }

        /**
         *
         * DISABLED
         *
         */

        if (message.name === 'DISABLED') {
            message.data.min = null;
            message.data.max = null;
            message.data.value = null;
        }

        /**
         *
         * VISIBLE
         *
         */

        if (message.status > 0 || !message.data.silent) {
            message._visible = true;
        }

        /**
         *
         * Report message to redux
         *
         */

        this.messages.push(message);

    }

    /**
     *
     * Check if the selector applies
     *
     * @param selector
     */
    private testSelector(
        store$: StateObservable<AppState>,
        cartContents: CartContents,
        selector: CartConfigDependencySelector
    ): CartConfigDependencySelector {

        const _selector = this.parseSelector(selector);

        let counter = 0;

        /**
         *
         * Compare the selector to our cart contents
         *
         */

        const vehicles = store$.value.entities.sellingItems;

        const matches = cartContents.vehicles.map((vehicleId: string) => {
            return vehicles[vehicleId];
        }).filter((vehicle: ISellingItemStore) => {

            if (!vehicle) {
                return false;
            }

            // TODO: Change vehicles[0] to appropriate logic to manage the possible bundle

            // discard vehicles that don't match
            if (selector.brand && selector.brand !== vehicle.props.vehicles[0].brand) {
                return false;
            }

            if (selector.series && selector.series !== vehicle.props.vehicles[0].series) {
                return false;
            }

            if (selector.vehicleIds && selector.vehicleIds.indexOf(vehicle.id) === -1) {
                return false;
            }

            return true;
        });

        // set values
        _selector._met = (matches.length >= _selector.min) && (matches.length <= _selector.max);
        _selector._counter = matches.length;

        return _selector;

    }

    /**
     *
     * Ensure selector is correctly structured
     *
     * @param selector
     */

    private parseSelector(selector: CartConfigDependencySelector): CartConfigDependencySelector {

        const brand = selector.brand || null;
        const series = selector.series || null;

        return {
            brand,
            series,
            vehicleIds: selector.vehicleIds || [],
            min: this.isNumeric(selector.min) ? selector.min : 0,
            max: this.isNumeric(selector.max) ? selector.max : Infinity,
            // internal properties
            _met: !!selector._met,
            _counter: this.isNumeric(selector._counter) ? selector._counter : 0,
            // capitalised for testing
            _brand: brand ? brand.toUpperCase() : null,
            _series: series ? series.toUpperCase() : null,
        };

    }

    /**
     *
     * HELPERS
     *
     */
    private isNumeric(value: any) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    private interpolate(template: string, args: Object) {
        return template.replace(/\${(\w+)}/g, (_, v) => args[v]);
    }

    private formatNumber(value: number, isCurrency: boolean) {
        return isCurrency ? '€ ' + this.decimalPipe.transform(value) : this.decimalPipe.transform(value);
    }
}
