import { Injectable } from '@angular/core';

import { LocalStorageService } from 'angular-2-local-storage';

import { CONFIG } from '../../../shared/config/config.const';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class UserService {

    constructor (
        private auth: AuthService,
        private localStorage: LocalStorageService
    ) {}



    /**
     *
     * AUCTIONS DISCLAIMER
     *
     */

    hasAcceptedAuctionDisclaimer (): boolean {

        return !!this.localStorage.get(this.getAuctionDisclaimerTokenName());

    }

    acceptAuctionDisclaimer () {

        this.localStorage.set(this.getAuctionDisclaimerTokenName(), '1');

    }

    /**
     *
     * TOKEN NAMES
     *
     */

    private getAuctionDisclaimerTokenName () {

        const username = this.auth.getUsername() || 'NO_USER';

        return CONFIG.PERSISTED_TOKENS.AUCTION_DISCLAIMER + '_' + username;
    }

    private getMessageTokenName (id: string) {

        const username = this.auth.getUsername() || 'NO_USER';

        return CONFIG.PERSISTED_TOKENS.COMMUNICATIONS + '_' + username + '_' + id;
    }

}
