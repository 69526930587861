import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class SellingAreasActionsService {

    static readonly FETCH_SELLING_AREAS = '@selling_areas/FETCH_SELLING_AREAS';
    static readonly FETCH_SELLING_AREAS_SUCCESS = '@selling_areas/FETCH_SELLING_AREAS_SUCCESS';
    static readonly FETCH_SELLING_AREAS_FAILURE = '@selling_areas/FETCH_SELLING_AREAS_FAILURE';

    static readonly FETCH_SELLING_AREA_FULL = '@selling_areas/FETCH_SELLING_AREA_FULL';
    static readonly FETCH_SELLING_AREA_FULL_SUCCESS = '@selling_areas/FETCH_SELLING_AREA_FULL_SUCCESS';
    static readonly FETCH_SELLING_AREA_FULL_FAILURE = '@selling_areas/FETCH_SELLING_AREA_FULL_FAILURE';

    /**
     *
     * FETCH SELLING AREAS
     *
     */

    @dispatch()
    fetchSellingAreas = (): AppAction => ({
        type: SellingAreasActionsService.FETCH_SELLING_AREAS
    })

    @dispatch()
    fetchSellingAreaFull = (payload: { id: string; }): AppAction => ({
        type: SellingAreasActionsService.FETCH_SELLING_AREA_FULL,
        payload
    })

}
