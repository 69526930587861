import { of, concat, from, empty } from 'rxjs';
import { async } from 'rxjs/scheduler/async';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartValidationActionsService } from '../actions/cart-validation.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { ModalCartValidationComponent } from '../../shared/components/modal-cart-validation/modal-cart-validation.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, take, mergeMap, startWith, delay, takeUntil, catchError } from 'rxjs/operators';
var CartValidationEpicsService = /** @class */ (function () {
    function CartValidationEpicsService(http, modal) {
        var _this = this;
        this.http = http;
        this.modal = modal;
        this.validateCartQueue = function (action$, store$, _a) {
            var cartValidation = _a.cartValidation;
            return action$.ofType(CartValidationActionsService.VALIDATE_CART_QUEUE).pipe(switchMap(function () {
                // wait for the config to be ready
                return action$.ofType(CartValidationActionsService.CART_CONFIG_READY).pipe(take(1), // don't listen forever! Important!
                mergeMap(function () {
                    // use the service to run the validation
                    var messages = cartValidation.validateCartQueue(store$);
                    return of({
                        type: CartValidationActionsService.SET_VALIDITY,
                        payload: {
                            messages: messages,
                            fromQueue: true
                        }
                    });
                }), startWith({
                    type: CartValidationActionsService.FETCH_CART_CONFIG
                }));
            }));
        };
        this.validateCart = function (action$, state$, _a) {
            var cartValidation = _a.cartValidation;
            return action$.ofType(CartValidationActionsService.VALIDATE_CART).pipe(switchMap(function () {
                // wait for the config to be ready
                return action$.ofType(CartValidationActionsService.CART_CONFIG_READY).pipe(take(1), // don't listen forever! Important!
                mergeMap(function () {
                    // use the service to run the validation
                    var messages = cartValidation.validateCart(state$);
                    return of({
                        type: CartValidationActionsService.SET_VALIDITY,
                        payload: {
                            messages: messages,
                            fromQueue: false
                        }
                    });
                }), startWith({
                    type: CartValidationActionsService.FETCH_CART_CONFIG
                }));
            }));
        };
        /**
         *
         * CART VALIDITY - from queue
         *
         */
        //    cartValidityFromQueue = (action$: ActionsObservable<any>) => {
        //        return action$.ofType(CartValidationActionsService.SET_VALIDITY).pipe(
        //            switchMap(({
        //                payload
        //            }) => {
        //                if (!payload.fromQueue) {
        //                    return empty();
        //                }
        //
        //                const found = payload.messages.find((message: CartValidationMessage) => {
        //                    // warning message for max count reached found!
        //                    return message.scope === 'GLOBAL' &&
        //                        message.name === 'COUNT' &&
        //                        message.status === 1;
        //                });
        //                return empty();
        //            }));
        //    }
        /**
         *
         * CART CONFIG EXPIRED - reload the config
         *
         */
        this.cartConfigExpired = function (action$) {
            return action$.ofType(CartValidationActionsService.CART_CONFIG_EXPIRED).pipe(switchMap(function () {
                // reload the config
                return of({
                    type: CartValidationActionsService.FETCH_CART_CONFIG
                });
            }));
        };
        /**
         *
         * CART CONFIG SUCCESS - watch the expiry
         *
         */
        this.cartConfigSuccess = function (action$, store) {
            var cancel$ = action$.ofType(CartValidationActionsService.CART_CONFIG_LOADING);
            return action$.ofType(CartValidationActionsService.CART_CONFIG_SUCCESS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                if (payload && payload.updateSalesOnly) {
                    return empty();
                }
                var state = store.value;
                var diff = state.cartValidation.config.expiry - Date.now();
                return of({
                    type: CartValidationActionsService.CART_CONFIG_EXPIRED
                }).pipe(
                // delay the emission until the config expires
                delay(diff), 
                // cancel the timer if a new load is triggered
                takeUntil(cancel$));
            }));
        };
        /**
         *
         * LOAD THE CART CONFIG
         *
         */
        this.fetchCartConfig = function (action$, store) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartValidationActionsService.FETCH_CART_CONFIG).pipe(
            // must use switchMap to enable request cancellation
            switchMap(function (_a) {
                var payload = _a.payload;
                var state = store.value;
                var updateSalesOnly = false;
                if (payload && payload.updateSalesOnly) {
                    // fetch cart config to obtain the maximun cart duration
                    updateSalesOnly = true;
                }
                if (state.cartValidation.config &&
                    state.cartValidation.config.settings &&
                    state.cartValidation.config.expiry > Date.now()) {
                    // we already have what we need so we can fire the success action
                    return of({
                        type: CartValidationActionsService.CART_CONFIG_READY
                    }, async); // VERY IMPORTANT! This little line ensure this fires asynchronously! See: https://stackoverflow.com/questions/46450262/chained-redux-observable-epic-only-fires-correctly-once/46455271#46455271
                }
                var queryParams = new HttpParams();
                queryParams = queryParams.append('AreaIdentifier', state.sales.area);
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartValidationActionsService.CART_CONFIG_LOADING
                }), 
                // make request
                _this.http.get("/Selling/GetBasketConfiguration", {
                    params: queryParams
                }).pipe(switchMap(function (response) {
                    return concat(of({
                        type: CartValidationActionsService.CART_CONFIG_SUCCESS,
                        payload: {
                            response: response,
                            updateSalesOnly: updateSalesOnly
                        }
                    }), of({
                        type: CartValidationActionsService.CART_CONFIG_READY
                    }));
                }), catchError(function (error) { return of({
                    type: CartValidationActionsService.CART_CONFIG_ERROR,
                    error: error
                }); }), 
                // cancel this if the area changes
                takeUntil(cancel$)));
            }));
        };
        /**
         *
         * Show the validation modal
         *
         */
        this.showValidationResult = function (action$) {
            return action$.ofType(CartValidationActionsService.SHOW_VALIDATION_RESULT).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var vehicleId = payload;
                // open modal
                var modalRef = _this.modal.open(ModalCartValidationComponent);
                // set vehicleId
                modalRef.componentInstance.vehicleId = vehicleId;
                return from(modalRef.result).pipe(switchMap(function () {
                    // User has clicked OK in the modal
                    return of({
                        type: CartValidationActionsService.VALIDATE_CART // revalidate cart
                    });
                }), catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return of({
                        type: CartValidationActionsService.VALIDATE_CART // revalidate cart
                    });
                }));
            }));
        };
    }
    return CartValidationEpicsService;
}());
export { CartValidationEpicsService };
