import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgRedux } from '@angular-redux/store';

import { AppState } from '../../../../interfaces/store.interface';
import { ISellingAreaFull } from '../../../../interfaces/SellingArea.interface';

@Component({
    selector: 'app-modal-cart-disclaimer',
    templateUrl: './modal-cart-disclaimer.component.html',
    styleUrls: ['./modal-cart-disclaimer.component.scss']
})
export class ModalCartDisclaimerComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('scroll', { static: false }) scrollEl: ElementRef;

    disclaimer: { text: string, mustBeAccepted: boolean };

    scrollListenerFn;

    disableButton = true;

    constructor(
        public activeModal: NgbActiveModal,
        private ngRedux: NgRedux<AppState>,
        private renderer: Renderer2,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        const sellingAreaId = this.ngRedux.getState().sales.area;
        this.disclaimer = (this.ngRedux.getState()
            .entities.sellingAreas[sellingAreaId].props as ISellingAreaFull).disclaimer;
    }

    ngAfterViewInit(): void {
        if (this.disclaimer.mustBeAccepted) {
            setTimeout(() => { this.addScrollListener(); });
        } else {
            this.disableButton = false;
        }
        this.changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.scrollListenerFn) {
            this.scrollListenerFn();
        }
    }

    private addScrollListener() {
        const hasVerticalScrollbar = this.hasScrollBar();
        if (hasVerticalScrollbar) {
            this.scrollListenerFn = this.renderer.listen(this.scrollEl.nativeElement, 'scroll', (event) => { this.onScroll(event); });
        } else {
            this.disableButton = false;
        }
    }

    private onScroll(event: Event) {
        const top = this.scrollEl.nativeElement.scrollTop;
        const offSetHeight = this.scrollEl.nativeElement.offsetHeight;
        const scrollHeight = this.scrollEl.nativeElement.scrollHeight;
        if (top > scrollHeight - offSetHeight - 1) {
            this.disableButton = false;
        }
    }

    private hasScrollBar() {
        const { scrollTop } = this.scrollEl.nativeElement;

        if (scrollTop > 0) {
            return true;
        }

        this.scrollEl.nativeElement.scrollTop += 10;

        if (scrollTop === this.scrollEl.nativeElement.scrollTop) {
            return false;
        }

        // undoing the change
        this.scrollEl.nativeElement.scrollTop = scrollTop;
        return true;
    }
}
