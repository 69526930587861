import * as tslib_1 from "tslib";
import { of, empty, concat, from } from 'rxjs';
import { ExceptionActionsService } from '../actions/exception.actions';
import { UserActionsService } from '../actions/user.actions';
import { CmsMenuActionsService } from '../actions/cms-menu.actions';
import { UserManagerService } from '../services/user-manager/user-manager.service';
import { UserService } from '../../shared/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { MessagesActionsService } from '../actions/messages.actions';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
var UserEpicsService = /** @class */ (function () {
    function UserEpicsService(user, userManager, http) {
        var _this = this;
        this.user = user;
        this.userManager = userManager;
        this.http = http;
        /**
         *
         * READY
         *
         */
        this.ready = function (action$, store) {
            return action$.ofType(UserActionsService.READY).pipe(switchMap(function () {
                // immediately trigger login
                return empty();
            }));
        };
        /**
         *
         * DESTROYED
         *
         */
        this.destroy = function (action$, store) {
            return action$.ofType(UserActionsService.DESTROY).pipe(switchMap(function () {
                // immediately trigger login
                return of({
                    type: UserActionsService.LOGIN
                });
            }));
        };
        /**
         *
         * LOGIN / LOGOUT
         *
         */
        this.login = function (action$, store) {
            return action$.ofType(UserActionsService.LOGIN).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                return from(_this.userManager.login()).pipe(switchMap(function () {
                    // prevent type error
                    return empty();
                }), 
                // show exception
                catchError(function (error) {
                    // Failed to redirect
                    // open error modal
                    return of({
                        type: ExceptionActionsService.SHOW_EXCEPTION_MODAL,
                        error: error
                    });
                }));
            }));
        };
        this.logout = function (action$, store) {
            return action$.ofType(UserActionsService.LOGOUT).pipe(switchMap(function () {
                return from(_this.userManager.logout()).pipe(switchMap(function () {
                    // prevent type error
                    return empty();
                }), 
                // show exception
                catchError(function (error) {
                    // Failed to redirect
                    // open error modal
                    return of({
                        type: ExceptionActionsService.SHOW_EXCEPTION_MODAL,
                        error: error
                    });
                }));
            }));
        };
        /**
         *
         * FETCH USER
         *
         */
        this.loadUser = function (action$, store$) {
            return action$.ofType(UserActionsService.LOAD_USER).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var user = store$.value.user;
                if (user.isReady) {
                    return of({
                        type: UserActionsService.READY
                    });
                }
                // load already in progress, wait for it to complete
                if (user.identity.loading || user.profile.loading) {
                    return empty();
                }
                return of({
                    type: UserActionsService.FETCH_IDENTITY
                });
            }));
        };
        /**
         *
         * IDENTITY
         *
         */
        this.fetchIdentity = function (action$, store) {
            return action$.ofType(UserActionsService.FETCH_IDENTITY).pipe(switchMap(function () {
                return concat(of({
                    type: UserActionsService.IDENTITY_LOADING
                }), from(_this.userManager.getUser()).pipe(switchMap(function (response) {
                    //    if (!response) {
                    //        return from(this.userManager.signinRedirectCallback()).pipe(
                    //            switchMap((callbackResponse) => {
                    //                return of(
                    //                    {
                    //                        type: UserActionsService.IDENTITY_SUCCESS,
                    //                        payload: {
                    //                            callbackResponse
                    //                        }
                    //                    },
                    //                    {
                    //                        type: TenantActionsService.LOAD_TENANT_CONFIG,
                    //                        payload: undefined
                    //                    }
                    //                );
                    //            }),
                    //            catchError((error: any) => {
                    //                return of({
                    //                    type: UserActionsService.IDENTITY_ERROR,
                    //                    error
                    //                });
                    //            })
                    //        );
                    //    }
                    return concat(of({
                        type: UserActionsService.IDENTITY_SUCCESS,
                        payload: {
                            response: response
                        }
                    }), of({
                        type: UserActionsService.FETCH_PROFILE,
                        payload: {
                            response: response
                        }
                    }));
                }), catchError(function (error) {
                    return of({
                        type: UserActionsService.IDENTITY_ERROR,
                        error: error
                    });
                })));
            }));
        };
        /**
         *
         * PROFILE / USER INFO
         *
         */
        this.fetchProfile = function (action$, store) {
            var cancel$ = action$.ofType(UserActionsService.DESTROY);
            return action$.ofType(UserActionsService.FETCH_PROFILE).pipe(switchMap(function (_a) {
                var payload = _a.payload // this is the IDENTITY response AKA user object
                ;
                // if the user doesnt exist stop the stream
                if (!payload.response) {
                    return of({
                        type: UserActionsService.READY
                    });
                }
                return concat(of({
                    type: UserActionsService.PROFILE_LOADING
                }), _this.http.get('/User/GetProfile').pipe(switchMap(function (response) {
                    return concat(of({
                        type: UserActionsService.PROFILE_SUCCESS,
                        payload: {
                            response: response
                        }
                    }), of({
                        type: UserActionsService.LOGGED_IN,
                        payload: null
                    }), of({
                        type: SellingAreasActionsService.FETCH_SELLING_AREAS,
                        payload: undefined
                    }), of({
                        type: UserActionsService.READY,
                        payload: null
                    }), of({
                        type: CmsMenuActionsService.FETCH_MENU,
                        payload: null
                    }));
                }), catchError(function (error) { return of({
                    type: UserActionsService.PROFILE_ERROR,
                    error: error
                }); }), takeUntil(cancel$)));
            }));
        };
        this.saveUserInfo = function (action$, store) {
            var cancel$ = action$.ofType(UserActionsService.SAVE_USER_INFO);
            return action$.ofType(UserActionsService.SAVE_USER_INFO).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                return concat(of({
                    type: UserActionsService.SAVE_USER_INFO_SAVING
                }), _this.http.put('/User/EditProfile', tslib_1.__assign({}, payload)).pipe(switchMap(function (response) {
                    return concat(of({
                        type: UserActionsService.SAVE_USER_INFO_SUCCESS,
                        payload: {
                            response: payload
                        }
                    }), of({
                        type: SellingAreasActionsService.FETCH_SELLING_AREAS
                    }), of({
                        type: CmsMenuActionsService.FETCH_MENU
                    }));
                }), catchError(function (error) { return of({
                    type: UserActionsService.SAVE_USER_INFO_ERROR,
                    error: error
                }); }), takeUntil(cancel$)));
            }));
        };
        /**
         *
         * USER LOGGED IN
         *
         */
        this.loggedIn = function (action$, store) {
            return action$.ofType(UserActionsService.LOGGED_IN).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var hasAccepted = _this.user.hasAcceptedAuctionDisclaimer();
                // determine if the user has cookie
                if (hasAccepted) {
                    return of({
                        type: UserActionsService.AUCTION_DISCLAIMER_ACCEPTED
                    }, {
                        type: MessagesActionsService.FETCH_MESSAGES_STATISTICS
                    });
                }
                return of({
                    type: MessagesActionsService.FETCH_MESSAGES_STATISTICS
                });
            }));
        };
        /**
         *
         * AUCTIONS COOKIE
         *
         */
        this.auctionDisclaimerAccepted = function (action$, store) {
            return action$.ofType(UserActionsService.AUCTION_DISCLAIMER_ACCEPTED).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // set the cookie
                _this.user.acceptAuctionDisclaimer();
                return empty();
            }));
        };
        /**
         *
         * ACCESS TOKEN
         *
         */
        this.refreshToken = function (action$, store) {
            var cancel$ = action$.ofType(UserActionsService.REFRESH_TOKEN);
            return action$.ofType(UserActionsService.REFRESH_TOKEN).pipe(switchMap(function () {
                return from(_this.userManager.refreshToken())
                    .pipe(switchMap(function (response) {
                    return of({
                        type: UserActionsService.REFRESH_TOKEN_SUCCESS,
                        payload: {
                            response: response
                        }
                    });
                }), catchError(function (error) { return concat(of({
                    type: UserActionsService.REFRESH_TOKEN_ERROR,
                    error: error
                }), of({
                    type: UserActionsService.LOGOUT
                })); }), takeUntil(cancel$));
            }));
        };
    }
    return UserEpicsService;
}());
export { UserEpicsService };
