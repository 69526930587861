import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class UtilitiesActionsService {

    static readonly FETCH_TIMEZONES = '@utilities/FETCH_TIMEZONES';
    static readonly TIMEZONES_LOADING = '@utilities/TIMEZONES_LOADING';
    static readonly TIMEZONES_SUCCESS = '@utilities/TIMEZONES_SUCCESS';
    static readonly TIMEZONES_ERROR = '@utilities/TIMEZONES_ERROR';


    @dispatch()
    fetchTimeZones = (): AppAction => ({
        type: UtilitiesActionsService.FETCH_TIMEZONES
    })
}
