import { of, empty, concat, from } from 'rxjs';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalAuctionEndedComponent } from '../../shared/components/modal-auction-ended/modal-auction-ended.component';
import { ModalVehicleUnavailableComponent } from '../../shared/components/modal-vehicle-unavailable/modal-vehicle-unavailable.component';
import { CartActionsService } from '../actions/cart.actions';
import { EntitiesActionsService } from '../actions/entities.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { ScrollActionsService } from '../actions/scroll.actions';
import { UiActionsService } from '../actions/ui.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, map, catchError, takeUntil } from 'rxjs/operators';
var SalesEpicsService = /** @class */ (function () {
    function SalesEpicsService(http, modal) {
        var _this = this;
        this.http = http;
        this.modal = modal;
        /**
         * SALES AREA
         */
        this.setArea = function (action$, state$) {
            return action$.ofType(SalesActionsService.SET_AREA).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var showCart = state$.value.sales.showCart;
                var userIsBuyer = state$.value.user.isBuyer;
                if (payload && showCart && userIsBuyer) {
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    }, {
                        type: CartActionsService.FETCH_CART,
                        payload: null
                    }, {
                        type: SalesActionsService.FETCH_ACTIVE_AUCTIONS_FOR_USER
                    });
                }
                // reset scroll
                return of({
                    type: ScrollActionsService.SCROLL_TO,
                    payload: null
                });
            }));
        };
        /**
         *
         * Sales params applied (e.g. filters or sort)
         *
         *  - load vehicle filters (if required)
         *  - load vehicles
         *
         */
        this.setParams = function (action$, state$) {
            return action$.ofType(SalesActionsService.SET_PARAMS, SalesActionsService.SET_PARAMS_NEXT_PAGE).pipe(switchMap(function (_a) {
                var type = _a.type;
                // get params from the state
                var salesState = state$.value.sales;
                var resetScroll = type === SalesActionsService.SET_PARAMS ? of({
                    type: ScrollActionsService.SCROLL_TO,
                    payload: null
                }) : empty();
                var actions;
                // check if we already have filters
                if (!salesState.filterOptions.payload) {
                    // we need to load the filters
                    actions = of({
                        type: SalesActionsService.FETCH_SELLINGITEM_FILTERS,
                        payload: null
                    }, {
                        type: SalesActionsService.FETCH_SELLINGITEMS,
                        payload: null
                    });
                }
                else {
                    // we only need the vehicles
                    actions = of({
                        type: SalesActionsService.FETCH_SELLINGITEMS,
                        payload: null
                    });
                }
                return concat(resetScroll, actions);
            }));
        };
        /**
         *
         * Reload the vehicle filters when the model or manufacturer change
         *
         */
        this.setManufacturerModel = function (action$, state$) {
            // reacts to either a MANUFACTURER or MODEL change
            return action$.ofType(SalesActionsService.SET_MANUFACTURER, SalesActionsService.SET_SERIES).pipe(switchMap(function () {
                return of({
                    type: SalesActionsService.FETCH_SELLINGITEM_FILTERS,
                    payload: {
                        filterChange: true
                    }
                });
            }));
        };
        /**
         *
         * Set the result of the selected vehicle
         *
         */
        this.setSelectedResult = function (action$, state$, _a) {
            var googleAnalytics = _a.googleAnalytics;
            return action$.ofType(SalesActionsService.SET_SELECTED_RESULT).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                if (payload) {
                    // tell Google Analytics
                    googleAnalytics.sendEvent({
                        category: 'Button',
                        label: 'Car detail by index: ' + payload.id
                    });
                }
                return empty();
            }));
        };
        /**
         *
         * Set the index of the selected vehicle
         *
         */
        this.setSelectedIndex = function (action$, state$) {
            return action$.ofType(SalesActionsService.SET_SELECTED_INDEX).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // closing
                if (payload === -1) {
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    });
                }
                var salesState = state$.value.sales;
                var entitiesState = state$.value.entities;
                // check for result (null if not available)
                var sellingItemId = salesState.results.payload.result[payload];
                // if we have an ID look for it
                if (sellingItemId) {
                    // in the entities
                    var result = entitiesState.sellingItems[sellingItemId];
                    // if it is found, then we don't need to load more results
                    if (result) {
                        var id = 'sellingItem_' + sellingItemId;
                        // change the next step in the stream
                        return concat(of({
                            type: ScrollActionsService.SCROLL_TO,
                            payload: {
                                id: id,
                                lock: true
                            }
                        }), of({
                            type: SalesActionsService.SET_SELECTED_RESULT,
                            payload: {
                                vehicleId: sellingItemId
                            }
                        }));
                    }
                }
                // if we've reached this point we need to load more data
                return of({
                    type: SalesActionsService.SET_PARAMS_NEXT_PAGE
                });
            }));
        };
        this.reloadVehicles = function (action$, state$) {
            return action$.ofType(SalesActionsService.RELOAD_SELLINGITEMS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                return of({
                    type: SalesActionsService.SET_SELECTED_INDEX,
                    payload: -1
                }, {
                    type: SalesActionsService.FETCH_SELLINGITEMS,
                    payload: null
                });
            }));
        };
        /**
         *
         * VEHICLES
         *
         */
        this.fetchVehicles = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA, SalesActionsService.SET_PARAMS, SalesActionsService.RELOAD_SELLINGITEMS);
            return action$.ofType(SalesActionsService.FETCH_SELLINGITEMS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var salesState = state$.value.sales;
                var query = new HttpParams();
                // apply all values to the query string
                Object.keys(salesState.params).forEach(function (key) {
                    if (salesState.params[key] || salesState.params[key] === 0) {
                        query = query.append(key, salesState.params[key].toString());
                    }
                });
                // TODO FILTERS
                query = query.append('ExcludeUsedVehicle', 'false');
                query = query.append('PriceRange.Type', 'buyed');
                query = query.append('areaIdentifier', salesState.area);
                var url = '/Selling/GetSellingItems';
                // use concat so these actions happen sequentially
                return concat(of({
                    type: salesState.params.pageIndex > 0 ?
                        SalesActionsService.SELLINGITEMS_LOADING_MORE : SalesActionsService.SELLINGITEMS_LOADING,
                    payload: null
                }), _this.http.get(url, { params: query }).pipe(map(function (response) { return ({
                    type: SalesActionsService.SELLINGITEMS_SUCCESS,
                    payload: {
                        response: response,
                    }
                }); }), takeUntil(cancel$), catchError(function (error) { return of({
                    type: SalesActionsService.SELLINGITEMS_ERROR,
                    error: error
                }); })));
            }));
        };
        this.vehiclesSuccess = function (action$, state$) {
            return action$.ofType(SalesActionsService.SELLINGITEMS_SUCCESS).pipe(switchMap(function () {
                var salesState = state$.value.sales;
                // check if we have a selected car
                if (salesState.selected.index > -1) {
                    var entitiesState = state$.value.entities;
                    // check for result (null if not available)
                    var vehicleId = salesState.results.payload.result[salesState.selected.index];
                    // if we have an ID look for it
                    if (vehicleId) {
                        // in the entities
                        var result = entitiesState.sellingItems[vehicleId];
                        // if it is found, then we don't need to load more results
                        if (result) {
                            // change the next step in the stream
                            return of({
                                type: SalesActionsService.SET_SELECTED_RESULT,
                                payload: {
                                    vehicleId: vehicleId
                                }
                            });
                        }
                    }
                }
                return empty();
            }));
        };
        this.fetchSellingItem = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA, SalesActionsService.SET_PARAMS, SalesActionsService.RELOAD_SELLINGITEMS);
            return action$.ofType(SalesActionsService.FETCH_SELLINGITEM).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var query = new HttpParams();
                query = query.append('id', payload.sellingItemId);
                var url = '/Selling/GetById';
                // use concat so these actions happen sequentially
                return concat(of({
                    type: SalesActionsService.SELLINGITEM_LOADING,
                    payload: null
                }), _this.http.get(url, { params: query }).pipe(map(function (response) { return ({
                    type: SalesActionsService.SELLINGITEM_SUCCESS,
                    payload: {
                        response: response,
                    }
                }); }), takeUntil(cancel$), catchError(function (error) { return of({
                    type: SalesActionsService.SELLINGITEM_ERROR,
                    error: error
                }); })));
            }));
        };
        /**
         *
         * VEHICLE FILTERS
         *
         */
        this.fetchVehicleFilters = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA, SalesActionsService.SET_PARAMS, SalesActionsService.SET_MANUFACTURER, SalesActionsService.SET_SERIES);
            return action$.ofType(SalesActionsService.FETCH_SELLINGITEM_FILTERS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var salesState = state$.value.sales;
                var query = new HttpParams();
                if (payload && payload.filterChange) {
                    query.append('manufacturer', salesState.filterManufacturer);
                }
                else if (salesState.params.manufacturer) {
                    query.append('manufacturer', salesState.params.manufacturer);
                }
                if (payload && payload.filterChange) {
                    query.append('series', salesState.filterSeries);
                }
                else if (salesState.params.series) {
                    query.append('series', salesState.params.series);
                }
                var url = '/Selling/GtFilters';
                // use concat so these actions happen sequentially
                return concat(of({
                    type: SalesActionsService.SELLINGITEM_FILTERS_LOADING
                }), _this.http.get(url, { params: query }).pipe(map(function (response) { return ({
                    type: SalesActionsService.SELLINGITEM_FILTERS_SUCCESS,
                    payload: {
                        response: response
                    }
                }); }), takeUntil(cancel$), catchError(function (error) { return of({
                    type: SalesActionsService.SELLINGITEM_FILTERS_ERROR,
                    error: error
                }); })));
            }));
        };
        /**
         *
         * HIDE VEHICLE - does different things depending on the current state
         *
         */
        this.hideVehicle = function (action$, state$) {
            return action$.ofType(SalesActionsService.HIDE_SELLINGITEM).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var vehicleId = payload.vehicleId;
                var reason = payload.reason;
                var selectedIndex = state$.value.sales.selected.index;
                if (selectedIndex > -1) {
                    var selectedVehicleId = state$.value.sales.selected.vehicleId;
                    // check if it was this vehicle that was removed
                    if (selectedVehicleId === vehicleId) {
                        return of({
                            type: SalesActionsService.SET_SELECTED_INDEX,
                            payload: -1
                        }, {
                            type: UiActionsService.CLOSE_DETAILS,
                            payload: null
                        }, {
                            type: EntitiesActionsService.HIDE_VEHICLE,
                            payload: payload
                        }, {
                            type: SalesActionsService.SHOW_SELLINGITEM_UNAVAILABLE_MODAL,
                            payload: payload
                        });
                    }
                    else {
                        return of({
                            type: EntitiesActionsService.HIDE_VEHICLE,
                            payload: payload
                        }, {
                            type: ScrollActionsService.REFRESH,
                            payload: null
                        });
                    }
                }
                if (reason === 'ADD_TO_CART_VEHICLE_UNAVAILABLE') {
                    return of({
                        type: EntitiesActionsService.HIDE_VEHICLE,
                        payload: payload
                    }, {
                        type: SalesActionsService.SHOW_SELLINGITEM_UNAVAILABLE_MODAL,
                        payload: payload
                    });
                }
                return of({
                    type: EntitiesActionsService.HIDE_VEHICLE,
                    payload: payload
                });
            }));
        };
        /**
         *
         * VEHICLE UNAVAILABLE
         *
         */
        this.showVehicleUnavailableModal = function (action$, state$) {
            return action$.ofType(SalesActionsService.SHOW_SELLINGITEM_UNAVAILABLE_MODAL).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var modalToShow = ModalVehicleUnavailableComponent;
                switch (payload.reason) {
                    case 'AUCTION_ENDED':
                        modalToShow = ModalAuctionEndedComponent;
                        break;
                    case 'ADD_TO_CART_UNAVAILABLE':
                        modalToShow = ModalAuctionEndedComponent;
                        break;
                    case 'OPTIONED':
                        // we don't show any modal in this case
                        return empty();
                    default:
                        break;
                }
                // open confirmation modal
                var modalRef = _this.modal.open(modalToShow);
                return from(modalRef.result).pipe(
                // modals only use dismiss which leads to 'catch'
                catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return empty();
                }));
            }));
        };
        /**
         *
         * VEHICLE PURCHASABLE
         *
         */
        //    setVehicleAsPurchasable = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {
        //        return action$.ofType(SalesActionsService.SET_VEHICLE_AS_PURCHASABLE).pipe(
        //            switchMap(({
        //                payload
        //            }) => {
        //                return of({
        //                    type: EntitiesActionsService.SET_VEHICLE_AS_PURCHASABLE,
        //                    payload
        //                });
        //
        //            }));
        //    }
        //
        //    setVehicleAsNotPurchasable = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {
        //        return action$.ofType(SalesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE).pipe(
        //            switchMap(({
        //                payload
        //            }) => {
        //                return of({
        //                    type: EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE,
        //                    payload
        //                });
        //
        //            }));
        //    }
        /**
         *
         * ACTIVE AUCTIONS FOR USER
         *
         */
        this.fetchActiveAuctionsForUser = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(SalesActionsService.FETCH_ACTIVE_AUCTIONS_FOR_USER).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var area = state$.value.sales.area;
                var query = new HttpParams();
                query = query.append('id', area);
                var url = '/Selling/GetUserActiveAuctions';
                return _this.http.get(url, { params: query }).pipe(map(function (response) { return ({
                    type: SalesActionsService.ACTIVE_AUCTIONS_FOR_USER_SUCCESS,
                    payload: {
                        response: response
                    }
                }); }), takeUntil(cancel$), catchError(function (error) { return of({
                    type: SalesActionsService.ACTIVE_AUCTIONS_FOR_USER_ERROR,
                    error: error
                }); }));
            }));
        };
    }
    return SalesEpicsService;
}());
export { SalesEpicsService };
