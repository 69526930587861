<div class="details__bundle-thumbs-change details__bundle-thumbs-change--prev" *ngIf="cars.length > 3">
    <button type="button" class="" appTouchable (click)="previous()" [disabled]="previousDisabled">
        <i class="arrow arrow--left arrow--centered"></i>
        <ng-container *ngIf="cars.length - 4 + index">
            {{cars.length - 4 + index}}
        </ng-container>
    </button>
</div>
<div [ngClass]="{'scrollable': cars.length > 3}" class="details__bundle-container" #container>
    <div class="details__bundle clearfix" #bundle>
        <div *ngFor="let car of cars; index as i" [class.active]="i === currentActive" (click)="activateBundleCar(i)"
            #thumb class="details__bundle-thumb d-flex justify-content-center align-items-center" appTouchable>
            <div class="details__bundle-inner">
                <div class="details__bundle-thumb-img" *ngIf="car?.media?.thumbs">
                    <img src="/assets/images/blank.gif" alt="" [attr.src]="car.media.thumbs[0]">
                </div>
                <span class="details__bundle-name truncate mt-n1" [attr.title]="car._vehicleTitle">
                    <i class="icon icon--listing-marker listing__marker-icon"
                        *ngIf="car.showMarker1 || car.showMarker2"></i>
                    {{ car._vehicleTitle }}
                </span>

                <div class="details__bundle-infos d-flex flex-row flex-wrap">
                    <span class="details__bundle-info truncate" *ngIf="car?.plate">
                        <span>{{'SellingItem.Details.Plate' | translate}}</span>: <span
                            class="listing__value">{{car.plate}}</span>
                    </span>
                    <span class="details__bundle-info truncate" *ngIf="car?.km">
                        {{'SellingItem.Km' | translate}}: <span class="listing__value">{{ car?.km | number }}</span>
                    </span>
                    <span class="details__bundle-info truncate" *ngIf="car?.matriculationDate">
                        <span>{{'SellingItem.Info.MatriculationDate' |
                            translate}}</span>: <span class="listing__value">{{
                            car.matriculationDate | date: 'y' }}</span>
                    </span>
                    <span class="details__bundle-info truncate" *ngIf="car?.prices?.dealer?.damages">
                        <span>{{'SellingItem.Info.Damages' | translate}}</span>: <span class="listing__value">€{{
                            car.prices.dealer.damages | number: '1.2-2' }}</span>
                    </span>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="details__bundle-thumbs-change details__bundle-thumbs-change--next" *ngIf="cars.length > 3">
    <button type="button" class="" appTouchable (click)="next()" [disabled]="nextDisabled">
        <ng-container *ngIf="cars.length - 3 - index">
            {{cars.length - 3 - index}}
        </ng-container>
        <i class="arrow arrow--right arrow--centered"></i>
    </button>
</div>
