// import * as moment from 'moment'; - added globally to prevent all locales being included
import { empty, concat, of, from } from 'rxjs';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalAuctionDisclaimerComponent } from '../../shared/components/modal-auction-disclaimer/modal-auction-disclaimer.component';
import { AuctionActionsService } from '../actions/auction.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { UserActionsService } from '../actions/user.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, map, takeUntil, catchError } from 'rxjs/operators';
var AuctionEpicsService = /** @class */ (function () {
    function AuctionEpicsService(http, modal) {
        var _this = this;
        this.http = http;
        this.modal = modal;
        /**
         *
         * AUCTION CONFIG
         *
         */
        //    fetchAuctionConfig = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {
        //
        //        const cancel$ = action$.ofType(SalesActionsService.SET_AREA);
        //
        //        return action$.ofType(AuctionActionsService.FETCH_AUCTION_CONFIG).pipe(
        //            switchMap(({
        //                payload
        //            }) => {
        //
        //                const area = store$.value.sales.area;
        //                const loaded = store$.value.auction.loaded;
        //
        //                // we already have the config, no need to reload it
        //                if (loaded) {
        //                    return empty();
        //                }
        //
        //                const query = new HttpParams();
        //
        //                const auctionsState = store$.value.auction;
        //
        //                // use concat so these actions happen sequentially
        //                return concat(
        //                    of({
        //                        type: AuctionActionsService.AUCTION_CONFIG_LOADING
        //                    }),
        //                    this.http.get(`/${area}/AuctionConfig`, { params: query }).pipe(
        //                        map(response => ({
        //                            type: AuctionActionsService.AUCTION_CONFIG_SUCCESS,
        //                            payload: {
        //                                response
        //                            }
        //                        })),
        //                        takeUntil(cancel$),
        //                        catchError(error => of({
        //                            type: AuctionActionsService.AUCTION_CONFIG_ERROR,
        //                            error
        //                        })))
        //                );
        //            }));
        //    }
        /**
         *
         * UPDATE AUCTION BY ID
         *
         */
        this.fetchAuctionById = function (action$, store$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(AuctionActionsService.FETCH_AUCTION_BY_ID).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var sellingItemId = payload.sellingItemId;
                var area = store$.value.sales.area;
                // only do this when the user is viewing an auction
                if (!area) {
                    return empty();
                }
                var query = new HttpParams();
                query.append('id', sellingItemId);
                query.append('includeCarInfoInResponse', 'false'); // @TODO param name
                // use concat so these actions happen sequentially
                return concat(of({
                    type: AuctionActionsService.AUCTION_BY_ID_LOADING
                }), _this.http.get("/" + area + "/GetAuctionById", { params: query }).pipe(map(function (response) { return ({
                    type: AuctionActionsService.AUCTION_BY_ID_SUCCESS,
                    payload: {
                        sellingItemId: sellingItemId,
                        response: response,
                        isFromPush: false,
                        now: moment()
                    }
                }); }), takeUntil(cancel$), catchError(function (error) { return of({
                    type: AuctionActionsService.AUCTION_BY_ID_ERROR,
                    error: error
                }); })));
            }));
        };
        /**
         *
         * Make offer confirm
         *
         */
        this.makeOfferConfirm = function (action$, store$) {
            return action$.ofType(AuctionActionsService.AUCTION_MAKE_OFFER_CONFIRM).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var hasCookie = store$.value.user.hasAcceptedAuctionDisclaimer;
                if (hasCookie) {
                    return of({
                        type: AuctionActionsService.AUCTION_MAKE_OFFER,
                        payload: payload
                    });
                }
                // open confirmation modal
                var modalRef = _this.modal.open(ModalAuctionDisclaimerComponent);
                return from(modalRef.result).pipe(switchMap(function () {
                    // User has clicked OK in the modal
                    return of({
                        type: UserActionsService.AUCTION_DISCLAIMER_ACCEPTED,
                        payload: null
                    }, {
                        type: AuctionActionsService.AUCTION_MAKE_OFFER,
                        payload: payload
                    });
                }), catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return empty();
                }));
            }));
        };
        /**
         *
         * Make offer
         *
         */
        this.makeOffer = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(AuctionActionsService.AUCTION_MAKE_OFFER).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var requestPayload = {
                    // just to be clear lets list the properties
                    sellingItemId: payload.vehicleId,
                    maxOffer: payload.offer
                };
                var area = state$.value.sales.area;
                return concat(
                // flag as loading
                of({
                    type: AuctionActionsService.AUCTION_MAKE_OFFER_LOADING,
                    payload: payload
                }), 
                // make request
                _this.http.put("/Selling/MakeOffer", requestPayload).pipe(
                // fake request
                // of({ ...auctionMakeOfferResponse({ request: requestPayload }) }).pipe(
                map(function (response) {
                    // user is the current winner
                    response._result = 0;
                    return ({
                        type: AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS,
                        payload: {
                            request: requestPayload,
                            response: response,
                            now: moment()
                        }
                    });
                }), catchError(function (error) {
                    // handle specific status errors here
                    var errorBody = error.error;
                    // user lost by automatic bid
                    if (error.status === 400) {
                        if (requestPayload.maxOffer === errorBody.currentOffer) {
                            error.error._result = 6;
                        }
                        else {
                            error.error._result = 3;
                        }
                        return of({
                            type: AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS,
                            payload: {
                                request: requestPayload,
                                response: errorBody,
                                now: moment()
                            }
                        });
                    }
                    // auction expired or does not exists
                    if (error.status === 404) {
                        //     error.body._result = 5; // hide vehicle
                        return of({
                            type: AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS,
                            payload: {
                                request: requestPayload,
                                response: {
                                    _result: 5
                                },
                                now: moment()
                            }
                        });
                    }
                    return of({
                        type: AuctionActionsService.AUCTION_MAKE_OFFER_ERROR,
                        error: error
                    });
                }), 
                // cancel this if the area changes
                takeUntil(cancel$)));
            }));
        };
        /**
         *
         * Determine if the auction is unavailable
         *
         */
        this.makeOfferSuccess = function (action$, store$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var result = payload.response._result;
                // auction is unavailable
                if (result === 2 || result === 5) {
                    return of({
                        type: SalesActionsService.HIDE_SELLINGITEM,
                        payload: {
                            vehicleId: payload.request.sellingItemId,
                            reason: 'AUCTION_ENDED'
                        }
                    });
                }
                // do nothing
                return empty();
            }));
        };
    }
    return AuctionEpicsService;
}());
export { AuctionEpicsService };
// export const auctionMakeOfferResponse = (options) => {
//     const request = options.request as AuctionMakeOfferRequest;
//     return {
//         endTime: moment(1687456833),
//         maxOffer: request.maxOffer,
//         currentOffer: request.maxOffer,
//         expireInMinutes: 0
//     } as AuctionMakeOfferResponse;
// };
