import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var UtilitiesActionsService = /** @class */ (function () {
    function UtilitiesActionsService() {
        this.fetchTimeZones = function () { return ({
            type: UtilitiesActionsService.FETCH_TIMEZONES
        }); };
    }
    UtilitiesActionsService.FETCH_TIMEZONES = '@utilities/FETCH_TIMEZONES';
    UtilitiesActionsService.TIMEZONES_LOADING = '@utilities/TIMEZONES_LOADING';
    UtilitiesActionsService.TIMEZONES_SUCCESS = '@utilities/TIMEZONES_SUCCESS';
    UtilitiesActionsService.TIMEZONES_ERROR = '@utilities/TIMEZONES_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UtilitiesActionsService.prototype, "fetchTimeZones", void 0);
    return UtilitiesActionsService;
}());
export { UtilitiesActionsService };
