/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./modal-cart-expired.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalCartExpiredComponent = [];
var RenderType_ModalCartExpiredComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalCartExpiredComponent, data: {} });
export { RenderType_ModalCartExpiredComponent as RenderType_ModalCartExpiredComponent };
export function View_ModalCartExpiredComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h1", [["class", "modal__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", "."])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "modal__actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Modals.CartExpired.ModalTitle")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("Modals.CartExpired.ModalContent")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("Commons.Ok")); _ck(_v, 11, 0, currVal_2); }); }
export function View_ModalCartExpiredComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-cart-expired", [], null, null, null, View_ModalCartExpiredComponent_0, RenderType_ModalCartExpiredComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalCartExpiredComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ModalCartExpiredComponentNgFactory = i0.ɵccf("app-modal-cart-expired", i2.ModalCartExpiredComponent, View_ModalCartExpiredComponent_Host_0, {}, {}, []);
export { ModalCartExpiredComponentNgFactory as ModalCartExpiredComponentNgFactory };
