import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var FavouritesActionsService = /** @class */ (function () {
    function FavouritesActionsService() {
        /**
         *
         * FAVOURITES
         *
         */
        this.fetchFavourites = function () { return ({
            type: FavouritesActionsService.FETCH_FAVOURITES,
        }); };
        this.toggleFavourite = function (payload) { return ({
            type: FavouritesActionsService.TOGGLE_FAVOURITE,
            payload: payload
        }); };
        this.setParams = function (payload) { return ({
            type: FavouritesActionsService.SET_PARAMS,
            payload: payload
        }); };
        this.setParamsNextPage = function () { return ({
            type: FavouritesActionsService.SET_PARAMS_NEXT_PAGE
        }); };
        this.removeFavouriteFromResults = function (payload) { return ({
            type: FavouritesActionsService.REMOVE_FAVOURITE_FROM_RESULTS,
            payload: payload
        }); };
    }
    FavouritesActionsService.FETCH_FAVOURITES = '@favourites/FETCH_FAVOURITES';
    FavouritesActionsService.FAVOURITES_LOADING = '@favourites/FAVOURITES_LOADING';
    FavouritesActionsService.FAVOURITES_LOADING_MORE = '@favourites/FAVOURITES_LOADING_MORE';
    FavouritesActionsService.FAVOURITES_SUCCESS = '@favourites/FAVOURITES_SUCCESS';
    FavouritesActionsService.FAVOURITES_ERROR = '@favourites/FAVOURITES_ERROR';
    FavouritesActionsService.TOGGLE_FAVOURITE = '@favourites/TOGGLE_FAVOURITE';
    FavouritesActionsService.TOGGLE_FAVOURITE_SUCCESS = '@favourites/TOGGLE_FAVOURITE_SUCCESS';
    FavouritesActionsService.TOGGLE_FAVOURITE_ERROR = '@favourites/TOGGLE_FAVOURITE_ERROR';
    FavouritesActionsService.SET_PARAMS = '@favourites/SET_PARAMS';
    FavouritesActionsService.SET_PARAMS_NEXT_PAGE = '@favourites/SET_PARAMS_NEXT_PAGE';
    FavouritesActionsService.REMOVE_FAVOURITE_FROM_RESULTS = '@favourites/REMOVE_FAVOURITE_FROM_RESULTS';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], FavouritesActionsService.prototype, "fetchFavourites", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], FavouritesActionsService.prototype, "toggleFavourite", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], FavouritesActionsService.prototype, "setParams", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], FavouritesActionsService.prototype, "setParamsNextPage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], FavouritesActionsService.prototype, "removeFavouriteFromResults", void 0);
    return FavouritesActionsService;
}());
export { FavouritesActionsService };
