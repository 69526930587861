import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-listing-title',
    templateUrl: './listing-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingTitleComponent {
    @Input() title: string;
}
