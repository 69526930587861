import { ICompany } from './Company.interface';

export type UserRoles = 'Administrator'
    | 'CarBooker'
    // AUCTIONS
    | 'ClosedAuctionYucReader'
    | 'ClosedAuctionYucBooker'
    | 'ClosedAuctionOlReader'
    | 'ClosedAuctionOlBooker'
    | 'OpenAuctionYucReader'
    | 'OpenAuctionYucBooker'
    | 'OpenAuctionOlReader'
    | 'OpenAuctionOlBooker'
    // SALES
    | 'DealerToDealerReader'
    | 'DealerToDealerBooker'
    | 'PreSaleReader'
    | 'PreSaleBooker'
    | 'SaleYucReader'
    | 'SaleYucBooker'
    | 'SaleOlReader'
    | 'SaleOlBooker'
    // ?
    | 'TurboMinkia';

export interface UserProfile {
    addresses: string[];
    branch: ICompany;
    company: ICompany;
    culture: string;
    email: string;
    enabled: boolean;
    firstName: string;
    id: string;
    lastName: string;
    roles?: any;
    timeZoneId: string;
    userType: UserType;
}

export enum UserType {
    None = 0,
    PremiumDealer = 1,
    Dealer = 2,
    Trader = 4,
    Private = 8,
    Staff = 16
}
