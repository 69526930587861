import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, concat, from, empty } from 'rxjs';
import { async } from 'rxjs/scheduler/async';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppState } from '../../../interfaces/store.interface';
import { CartValidationActionsService } from '../actions/cart-validation.actions';
import { SalesActionsService } from '../actions/sales.actions';

import { ModalCartValidationComponent } from '../../shared/components/modal-cart-validation/modal-cart-validation.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, take, mergeMap, startWith, delay, takeUntil, catchError } from 'rxjs/operators';
import { CartValidationMessage } from '../../../interfaces/cart.interface';

@Injectable()
export class CartValidationEpicsService {

    constructor(
        private http: HttpClient,
        private modal: NgbModal
    ) { }

    validateCartQueue = (action$: ActionsObservable<any>, store$: StateObservable<AppState>, { cartValidation }) => {

        return action$.ofType(CartValidationActionsService.VALIDATE_CART_QUEUE).pipe(
            switchMap(() => {
                // wait for the config to be ready
                return action$.ofType(CartValidationActionsService.CART_CONFIG_READY).pipe(
                    take(1), // don't listen forever! Important!
                    mergeMap(() => {

                        // use the service to run the validation
                        const messages = cartValidation.validateCartQueue(store$);

                        return of({
                            type: CartValidationActionsService.SET_VALIDITY,
                            payload: {
                                messages,
                                fromQueue: true
                            }
                        });
                    }),
                    startWith({
                        type: CartValidationActionsService.FETCH_CART_CONFIG
                    }));
            }));
    }

    validateCart = (action$: ActionsObservable<any>, state$: StateObservable<AppState>, { cartValidation }) => {

        return action$.ofType(CartValidationActionsService.VALIDATE_CART).pipe(
            switchMap(() => {
                // wait for the config to be ready
                return action$.ofType(CartValidationActionsService.CART_CONFIG_READY).pipe(
                    take(1), // don't listen forever! Important!
                    mergeMap(() => {

                        // use the service to run the validation
                        const messages = cartValidation.validateCart(state$);

                        return of({
                            type: CartValidationActionsService.SET_VALIDITY,
                            payload: {
                                messages,
                                fromQueue: false
                            }
                        });
                    }),
                    startWith({
                        type: CartValidationActionsService.FETCH_CART_CONFIG
                    }));
            }));

    }

    /**
     *
     * CART VALIDITY - from queue
     *
     */

//    cartValidityFromQueue = (action$: ActionsObservable<any>) => {
//        return action$.ofType(CartValidationActionsService.SET_VALIDITY).pipe(
//            switchMap(({
//                payload
//            }) => {
//                if (!payload.fromQueue) {
//                    return empty();
//                }
//
//                const found = payload.messages.find((message: CartValidationMessage) => {
//                    // warning message for max count reached found!
//                    return message.scope === 'GLOBAL' &&
//                        message.name === 'COUNT' &&
//                        message.status === 1;
//                });
//                return empty();
//            }));
//    }

    /**
     *
     * CART CONFIG EXPIRED - reload the config
     *
     */

    cartConfigExpired = (action$: ActionsObservable<any>) => {
        return action$.ofType(CartValidationActionsService.CART_CONFIG_EXPIRED).pipe(
            switchMap(() => {
                // reload the config
                return of({
                    type: CartValidationActionsService.FETCH_CART_CONFIG
                });
            }));
    }

    /**
     *
     * CART CONFIG SUCCESS - watch the expiry
     *
     */

    cartConfigSuccess = (action$: ActionsObservable<any>, store: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(CartValidationActionsService.CART_CONFIG_LOADING);

        return action$.ofType(CartValidationActionsService.CART_CONFIG_SUCCESS).pipe(
            switchMap(({
                payload
            }) => {

                if (payload && payload.updateSalesOnly) {
                    return empty();
                }

                const state = store.value;
                const diff = state.cartValidation.config.expiry - Date.now();

                return of({
                    type: CartValidationActionsService.CART_CONFIG_EXPIRED
                }).pipe(
                    // delay the emission until the config expires
                    delay(diff),
                    // cancel the timer if a new load is triggered
                    takeUntil(cancel$)
                );
            }));

    }

    /**
     *
     * LOAD THE CART CONFIG
     *
     */

    fetchCartConfig = (action$: ActionsObservable<any>, store: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(SalesActionsService.SET_AREA);

        return action$.ofType(CartValidationActionsService.FETCH_CART_CONFIG).pipe(
            // must use switchMap to enable request cancellation
            switchMap(({ payload }) => {

                const state = store.value;

                let updateSalesOnly = false;
                if (payload && payload.updateSalesOnly) {
                    // fetch cart config to obtain the maximun cart duration
                    updateSalesOnly = true;
                }

                if (state.cartValidation.config &&
                    state.cartValidation.config.settings &&
                    state.cartValidation.config.expiry > Date.now()) {
                    // we already have what we need so we can fire the success action
                    return of({
                        type: CartValidationActionsService.CART_CONFIG_READY
                    }, async); // VERY IMPORTANT! This little line ensure this fires asynchronously! See: https://stackoverflow.com/questions/46450262/chained-redux-observable-epic-only-fires-correctly-once/46455271#46455271
                }

                let queryParams = new HttpParams();
                queryParams = queryParams.append('AreaIdentifier', state.sales.area);

                // start the request
                return concat(
                    // flag as loading
                    of({
                        type: CartValidationActionsService.CART_CONFIG_LOADING
                    }),
                    // make request
                    this.http.get(`/Selling/GetBasketConfiguration`, {
                        params: queryParams
                    }).pipe(
                        switchMap(response => {
                            return concat(
                                of({
                                    type: CartValidationActionsService.CART_CONFIG_SUCCESS,
                                    payload: {
                                        response,
                                        updateSalesOnly
                                    }
                                }),
                                of({
                                    type: CartValidationActionsService.CART_CONFIG_READY
                                })
                            );
                        }),
                        catchError(error => of({
                            type: CartValidationActionsService.CART_CONFIG_ERROR,
                            error
                        })),
                        // cancel this if the area changes
                        takeUntil(cancel$)
                    ));

            }));

    }

    /**
     *
     * Show the validation modal
     *
     */

    showValidationResult = (action$: ActionsObservable<any>) => {

        return action$.ofType(CartValidationActionsService.SHOW_VALIDATION_RESULT).pipe(
            switchMap(({
                payload
            }) => {

                const vehicleId = payload;

                // open modal
                const modalRef = this.modal.open(ModalCartValidationComponent);

                // set vehicleId
                modalRef.componentInstance.vehicleId = vehicleId;

                return from(modalRef.result).pipe(
                    switchMap(() => {
                        // User has clicked OK in the modal
                        return of({
                            type: CartValidationActionsService.VALIDATE_CART // revalidate cart
                        });
                    }),
                    catchError(() => {
                        // User clicked CANCEL in the modal - kill the stream
                        return of({
                            type: CartValidationActionsService.VALIDATE_CART // revalidate cart
                        });
                    }));

            }));
    }

}
