import { Injectable } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import { CONFIG } from '../../config/config.const';

@Injectable()
export class AnimationService {

    // use this for elements that are removed from the DOM
    static fadeInOut (duration: number = 250) {
        return trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void => *', [
                style({
                    opacity: 1
                }),
                animate(`${duration}ms ease-out`)
            ]),
            transition('* => void', [
                animate(`${duration}ms ease-out`, style({
                    opacity: 0
                }))
            ])
        ]);
    }

    // use this one when the element is always present
    static fadeInOutFixed (duration: number = 250) {
        return trigger('fadeInOutFixed', [
            state('inactive', style({
                opacity: 0
            })),
            state('active', style({
                opacity: 1
            })),
            transition('* => active', [
                style({
                    opacity: 0
                }),
                animate(`${duration}ms ease-out`)
            ]),
            transition('* => inactive', [
                style({
                    opacity: 1
                }),
                animate(`${duration}ms ease-out`)
            ])
        ]);
    }

    static slideInOut (duration: number = 250) {
        return trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            transition('void => *', [
                style({
                    transform: 'translate3d(0, 100%, 0)'
                }),
                animate(`${duration}ms ease-out`)
            ]),
            transition('* => void', [
                animate(`${duration}ms ease-out`, style({
                    transform: 'translate3d(0, 100%, 0)'
                }))
            ])
        ])
    }

    // use this one when the element is always present
    static slideOutToRemove (duration: number = 250) {
        return trigger('slideOutToRemove', [
            state('in', style({
                height: '*'
            })),
            state('out', style({
                height: 0
            })),
            transition('in => out', [
                animate(`${duration}ms ease-out`, style({
                    height: 0
                }))
            ])
        ]);
    }

    static dropInOut (duration: number = 250) {
        return trigger('dropInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(0, -100%, 0)'
            })),
            transition('in <=> out', animate(`${duration}ms ease-out`))
        ])
    }

    static expandInOut (duration: number = 250) {
        return trigger('expandInOut', [
            state('in', style({
                height: '*',
                opacity: 1
            })),
            transition('void => *', [
                style({
                    height: 0,
                    opacity: 0
                }),
                animate(`${duration}ms ease-out`)
            ]),
            transition('* => void', [
                animate(`${duration}ms ease-out`, style({
                    height: 0,
                    opacity: 0
                }))
            ])
        ])
    }

    static notificationInOut (duration: number = 250) {
        return trigger('notificationInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            transition('void => *', [
                style({
                    transform: 'translate3d(350px, 0, 0)'
                }),
                animate(`${duration}ms ease-out`)
            ]),
            transition('* => void', [
                animate(`${duration}ms ease-out`, style({
                    transform: 'translate3d(350px, 0, 0)'
                }))
            ])
        ])
    }
}
