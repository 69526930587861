<div appTouchable (click)="open($event)" class="listing__link--order"
    [ngClass]="{ 'listing__link': hasListingLinkClassName(), 'listing__no-link': hasListingNoLinkClassName() }">

    <ng-container *ngIf="sellingItem._isAuction">
        <span class="listing__sash" *ngIf="sellingItem.auctionInfo?._isUserBidder === true" [ngClass]="{
                'listing__sash--positive': sellingItem.auctionInfo?.isLoggedUserBestBidder,
                'listing__sash--negative': !sellingItem.auctionInfo?.isLoggedUserBestBidder
            }"></span>
    </ng-container>

    <app-listing-info [bundleVehicles]="sellingItem.vehicles" [sellingItem]="sellingItem" [mode]="mode"></app-listing-info>

    <app-listing-thumbnails *ngIf="!sellingItem._bundle" [thumbnails]="sellingItem._thumbnails"></app-listing-thumbnails>
    <app-listing-bundle-thumbnails *ngIf="sellingItem._bundle" [cars]="sellingItem.vehicles"></app-listing-bundle-thumbnails>
</div>