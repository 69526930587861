
<div class="modal__inner">
    <h1 class="modal__title" i18n>Error</h1>
    <div class="modal__content">
        <p i18n>Oops, something went wrong.</p>

        <code style="display: block; word-wrap: break-word;">
            <span *ngIf="!errorIsObject">{{ errorMessage }}</span>
        <pre *ngIf="errorIsObject">{{ errorMessage | json }}</pre>
        </code>
    </div>
</div>

<div class="modal__close">
    <button type="button" class="button button--transparent" appTouchable (click)="activeModal.dismiss()">
        <i class="icon icon--out icon--close-dark-grey"></i>
        <i class="icon icon--hover icon--close-dark-grey"></i>
        &nbsp;
    </button>
</div>
