// import * as moment from 'moment'; - added globally to prevent all locales being included

import { AppAction, StatisticsState } from '../../../interfaces/store.interface';
import { SalesArea } from '../../../interfaces/sales.interface';
import { StatisticsResponse, StatisticsResponseSection, StatisticsSection, StatisticsVehicle } from '../../../interfaces/statistics.interface';

import { CONFIG } from '../../shared/config/config.const';

import { StatisticsActionsService } from '../actions/statistics.actions';

const INITIAL_STATE: StatisticsState = {
    loading: false,
    hasError: false,
    error: null,
    sections: [],
    vehicles: [],
    totalCount: 0,
    mode: null,
    loaded: false
};

export default function statisticsReducer (state: StatisticsState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        case StatisticsActionsService.STATISTICS_LOADING: {

            const nextState = {
                ...INITIAL_STATE,
                loading: true,
                mode: action.payload.mode
            };

            return nextState;

        }

        case StatisticsActionsService.STATISTICS_SUCCESS: {

            const nextState = {
                ...state,
                loading: false,
                loaded: true
            };

            /**
             *
             * MAP THE RESPONSE
             *
             */

            const response = action.payload.response as StatisticsResponse;

            let vehicles = [];
            let totalCount = 0;

            const sections = Object.keys(response)
                .filter((key: string) => {
                    return key !== 'date' && typeof response[key] === 'object';
                })
                .map((key: string) => {

                    let area: SalesArea;

                    // ensure key maps to correct area
                    // this is required because the server uses camelcase
                    switch (key) {
                        case 'closedAuctionOl':
                            area = 'ClosedAuctionOl';
                            break;
                        case 'closedAuctionYuc':
                            area = 'ClosedAuctionYuc';
                            break;
                        case 'openAuctionOl':
                            area = 'OpenAuctionOl';
                            break;
                        case 'openAuctionYuc':
                            area = 'OpenAuctionYuc';
                            break;
                        case 'presales':
                            area = 'PreSale';
                            break;
                        case 'salesOl':
                            area = 'SaleOl';
                            break;
                        case 'salesYuc':
                            area = 'SaleYuc';
                            break;
                    }

                    const section = response[key] as StatisticsResponseSection;

                    let areaCount = 0;

                    // add to outer array
                    vehicles = vehicles.concat((section.vehicles || []).map((vehicle: StatisticsVehicle) => {
                        // increment total count
                        areaCount += vehicle.count;
                        // add the area
                        vehicle.area = area;
                        // add the section name
                        vehicle.name = section.name;
                        // add the color
                        vehicle.color = CONFIG.COLORS[area];
                        return vehicle;
                    }));

                    totalCount += areaCount;

                    return {
                        area,
                        name: section.name,
                        color: CONFIG.COLORS[area],
                        count: areaCount,
                        vehicles,
                        orderBy: section.orderId
                    } as StatisticsSection;
                })
                // sort
                .sort((a, b) => {
                    return a.orderBy - b.orderBy;
                });

            nextState.sections = sections;
            nextState.vehicles = vehicles;
            nextState.totalCount = totalCount;

            return nextState;

        }

        case StatisticsActionsService.STATISTICS_ERROR: {

            const nextState = {
                ...INITIAL_STATE,
                loading: false,
                hasError: true,
                error: action.error
            };

            return nextState;

        }


    }

    return state;

}
