import { WindowRefService } from '../window-ref/window-ref.service';
import { EnvironmentService } from '../environment/environment.service';
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(window, environmentService) {
        this.window = window;
        this.environmentService = environmentService;
        this.enabled = false;
    }
    GoogleAnalyticsService.prototype.init = function (script) {
        var scriptWrapper = this.window.nativeWindow.document.getElementById('post-body-scripts');
        if (!scriptWrapper) {
            return;
        }
        var theDocument = this.window.nativeWindow.document;
        var scriptElem1 = theDocument.createElement('div');
        scriptElem1.innerHTML = script;
        var _loop_1 = function (i) {
            var origEl = scriptElem1.children.item(i);
            var newEl = theDocument.createElement(origEl.tagName);
            origEl.getAttributeNames().map(function (name, nameIndex) {
                newEl.setAttribute(name, origEl.getAttribute(name));
            });
            newEl.appendChild(theDocument.createTextNode(origEl.innerHTML));
            scriptWrapper.appendChild(newEl);
        };
        for (var i = 0; i < scriptElem1.children.length; i++) {
            _loop_1(i);
        }
        this.enabled = true;
    };
    GoogleAnalyticsService.prototype.sendEvent = function (payload) {
        if (payload === void 0) { payload = {}; }
        if (!this.enabled) {
            return;
        }
        var options = {
            event_action: payload.action || 'click',
            event_category: payload.category || 'Uncategorized',
            event_label: payload.label || '',
            value: payload.value || 1
        };
        var gtag = this.window.nativeWindow.gtag;
        // send the event
        if (gtag) {
            gtag('send', 'event', options);
        }
        if (!this.environmentService.getEnvironment().production) {
            // log event to console
            this.logEvent('send', 'event', options);
        }
    };
    GoogleAnalyticsService.prototype.logEvent = function (actionType, type, options) {
        console.log('Google Analytics: ' + actionType + ' - ' + type, options);
    };
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
