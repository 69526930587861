/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./relative-date.component";
var styles_RelativeDateComponent = [];
var RenderType_RelativeDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RelativeDateComponent, data: {} });
export { RenderType_RelativeDateComponent as RenderType_RelativeDateComponent };
function View_RelativeDateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["giorni"]))], null, null); }
function View_RelativeDateComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["giorno"]))], null, null); }
function View_RelativeDateComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["giorni"]))], null, null); }
function View_RelativeDateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.NgPlural, [i1.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgPluralCase, [[8, "=0"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgPluralCase, [[8, "=1"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgPluralCase, [[8, "other"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.days; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.days; _ck(_v, 1, 0, currVal_0); }); }
function View_RelativeDateComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["ore"]))], null, null); }
function View_RelativeDateComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["ora"]))], null, null); }
function View_RelativeDateComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["ore"]))], null, null); }
function View_RelativeDateComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.NgPlural, [i1.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_7)), i0.ɵdid(5, 16384, null, 0, i1.NgPluralCase, [[8, "=0"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_8)), i0.ɵdid(7, 16384, null, 0, i1.NgPluralCase, [[8, "=1"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_9)), i0.ɵdid(9, 16384, null, 0, i1.NgPluralCase, [[8, "other"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hours; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hours; _ck(_v, 1, 0, currVal_0); }); }
function View_RelativeDateComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " minuti "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minutes; _ck(_v, 1, 0, currVal_0); }); }
function View_RelativeDateComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " secondi"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.seconds; _ck(_v, 1, 0, currVal_0); }); }
function View_RelativeDateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_10)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_11)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.days; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.hours && !_co.days); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.minutes && !_co.hours) && !_co.days); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((!_co.minutes && !_co.hours) && !_co.days); _ck(_v, 8, 0, currVal_3); }, null); }
function View_RelativeDateComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["---"]))], null, null); }
export function View_RelativeDateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelativeDateComponent_12)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enable; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.enable; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RelativeDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-relative-date", [], null, null, null, View_RelativeDateComponent_0, RenderType_RelativeDateComponent)), i0.ɵdid(1, 704512, null, 0, i2.RelativeDateComponent, [], null, null)], null, null); }
var RelativeDateComponentNgFactory = i0.ɵccf("app-relative-date", i2.RelativeDateComponent, View_RelativeDateComponent_Host_0, { date: "date", enable: "enable" }, {}, []);
export { RelativeDateComponentNgFactory as RelativeDateComponentNgFactory };
