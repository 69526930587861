/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./modal-exception.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalExceptionComponent = [];
var RenderType_ModalExceptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalExceptionComponent, data: {} });
export { RenderType_ModalExceptionComponent as RenderType_ModalExceptionComponent };
function View_ModalExceptionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalExceptionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "pre", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(0, i1.JsonPipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.errorMessage)); _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalExceptionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "modal__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error"])), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Oops, something went wrong."])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "code", [["style", "display: block; word-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalExceptionComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalExceptionComponent_2)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "modal__close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--transparent"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "icon icon--out icon--close-dark-grey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["class", "icon icon--hover icon--close-dark-grey"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u00A0 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.errorIsObject; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.errorIsObject; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_ModalExceptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-exception", [], null, null, null, View_ModalExceptionComponent_0, RenderType_ModalExceptionComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalExceptionComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ModalExceptionComponentNgFactory = i0.ɵccf("app-modal-exception", i2.ModalExceptionComponent, View_ModalExceptionComponent_Host_0, { errorMessage: "errorMessage", errorIsObject: "errorIsObject" }, {}, []);
export { ModalExceptionComponentNgFactory as ModalExceptionComponentNgFactory };
