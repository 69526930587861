/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../relative-date/relative-date.component.ngfactory";
import * as i3 from "../../relative-date/relative-date.component";
import * as i4 from "@angular/common";
import * as i5 from "./listing-days-of-visibility.component";
var styles_ListingDaysOfVisibilityComponent = [];
var RenderType_ListingDaysOfVisibilityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListingDaysOfVisibilityComponent, data: {} });
export { RenderType_ListingDaysOfVisibilityComponent as RenderType_ListingDaysOfVisibilityComponent };
function View_ListingDaysOfVisibilityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "listing__tertiary listing__highlight lowercase"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(4, null, [": ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SellingItem.Info.VisibilityDays")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.visibilityDays; _ck(_v, 4, 0, currVal_1); }); }
function View_ListingDaysOfVisibilityComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [["class", "listing__tertiary listing__highlight lowercase"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-relative-date", [], null, null, null, i2.View_RelativeDateComponent_0, i2.RenderType_RelativeDateComponent)), i0.ɵdid(5, 704512, null, 0, i3.RelativeDateComponent, [], { date: [0, "date"], enable: [1, "enable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co._hideAt; var currVal_2 = true; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("SellingItem.Info.AvailableFor")); _ck(_v, 2, 0, currVal_0); }); }
function View_ListingDaysOfVisibilityComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "listing__tertiary listing__highlight lowercase"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"]))], null, null); }
export function View_ListingDaysOfVisibilityComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingDaysOfVisibilityComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingDaysOfVisibilityComponent_2)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingDaysOfVisibilityComponent_3)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visibilityDays > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.visibilityDays === 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.visibilityDays < 0); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ListingDaysOfVisibilityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-listing-days-of-visibility", [], null, null, null, View_ListingDaysOfVisibilityComponent_0, RenderType_ListingDaysOfVisibilityComponent)), i0.ɵdid(1, 49152, null, 0, i5.ListingDaysOfVisibilityComponent, [], null, null)], null, null); }
var ListingDaysOfVisibilityComponentNgFactory = i0.ɵccf("app-listing-days-of-visibility", i5.ListingDaysOfVisibilityComponent, View_ListingDaysOfVisibilityComponent_Host_0, { showAt: "showAt", hideAt: "hideAt" }, {}, []);
export { ListingDaysOfVisibilityComponentNgFactory as ListingDaysOfVisibilityComponentNgFactory };
