import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { UtilitiesActionsService } from '../actions/utilities.actions';
import { concat, of, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../../../interfaces/store.interface';
import { switchMap, catchError } from 'rxjs/operators';


@Injectable()
export class UtilitiesEpicsService {

  constructor(
    private http: HttpClient,
  ) { }


  /**
   *
   * TIMEZONES
   *
   */

  fetchTimezones = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

    return action$.ofType(UtilitiesActionsService.FETCH_TIMEZONES).pipe(
      switchMap(({
        payload
      }) => {


        // use concat so these actions happen sequentially
        return concat(
          of({
            type: UtilitiesActionsService.TIMEZONES_LOADING,
            payload: null
          }),
          this.http.get('/Utility/GetTimezoneIds').pipe(
            switchMap((response: any) => {
              return of(
                {
                  type: UtilitiesActionsService.TIMEZONES_SUCCESS,
                  payload: {
                    response
                  }
                });
            }),
            catchError(error => of({
              type: UtilitiesActionsService.TIMEZONES_ERROR,
              error
            })))
        );

      }));

  }

}
