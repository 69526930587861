import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var MessagesActionsService = /** @class */ (function () {
    function MessagesActionsService() {
        /**
         *
         * MESSAGES
         *
         */
        this.queueMessages = function (payload) { return ({
            type: MessagesActionsService.QUEUE_MESSAGES,
            payload: payload
        }); };
        this.fetchMessagesStatistics = function () { return ({
            type: MessagesActionsService.FETCH_MESSAGES_STATISTICS,
        }); };
        this.fetchMessages = function () { return ({
            type: MessagesActionsService.FETCH_MESSAGES,
        }); };
        this.fetchModalMessages = function () { return ({
            type: MessagesActionsService.FETCH_MODAL_MESSAGES,
        }); };
        this.queueMessage = function (payload) { return ({
            type: MessagesActionsService.QUEUE_MESSAGE,
            payload: payload
        }); };
        this.popMessage = function () { return ({
            type: MessagesActionsService.POP_MESSAGE
        }); };
        this.toggleMessage = function (payload) { return ({
            type: MessagesActionsService.TOGGLE_MESSAGE,
            payload: payload
        }); };
        this.setParams = function (payload) { return ({
            type: MessagesActionsService.SET_PARAMS,
            payload: payload
        }); };
        this.setParamsNextPage = function () { return ({
            type: MessagesActionsService.SET_PARAMS_NEXT_PAGE
        }); };
        this.setType = function (payload) { return ({
            type: MessagesActionsService.SET_TYPE,
            payload: payload
        }); };
    }
    MessagesActionsService.FETCH_MESSAGES_STATISTICS = '@messages/FETCH_MESSAGES_STATISTICS';
    MessagesActionsService.MESSAGES_STATISTICS_SUCCESS = '@messages/MESSAGES_STATISTICS_SUCCESS';
    MessagesActionsService.FETCH_MESSAGES = '@messages/FETCH_MESSAGES';
    MessagesActionsService.MESSAGES_LOADING = '@messages/MESSAGES_LOADING';
    MessagesActionsService.MESSAGES_LOADING_MORE = '@messages/MESSAGES_LOADING_MORE';
    MessagesActionsService.MESSAGES_SUCCESS = '@messages/MESSAGES_SUCCESS';
    MessagesActionsService.MESSAGES_ERROR = '@messages/MESSAGES_ERROR';
    MessagesActionsService.FETCH_MODAL_MESSAGES = '@messages/FETCH_MODAL_MESSAGES';
    MessagesActionsService.MODAL_MESSAGES_LOADING = '@messages/MODAL_MESSAGES_LOADING';
    MessagesActionsService.MODAL_MESSAGES_SUCCESS = '@messages/MODAL_MESSAGES_SUCCESS';
    MessagesActionsService.MODAL_MESSAGES_ERROR = '@messages/MODAL_MESSAGES_ERROR';
    MessagesActionsService.QUEUE_MESSAGES = '@messages/QUEUE_MESSAGES';
    MessagesActionsService.QUEUE_MESSAGE = '@messages/QUEUE_MESSAGE';
    MessagesActionsService.POP_MESSAGE = '@messages/POP_MESSAGE';
    MessagesActionsService.DISCARD_MESSAGE = '@messages/DISCARD_MESSAGE';
    MessagesActionsService.SET_PARAMS = '@messages/SET_PARAMS';
    MessagesActionsService.SET_PARAMS_NEXT_PAGE = '@messages/SET_PARAMS_NEXT_PAGE';
    MessagesActionsService.TOGGLE_MESSAGE = '@messages/TOGGLE_MESSAGE';
    MessagesActionsService.TOGGLE_MESSAGE_SUCCESS = '@messages/TOGGLE_MESSAGE_SUCCESS';
    MessagesActionsService.TOGGLE_MESSAGE_FAILURE = '@messages/TOGGLE_MESSAGE_FAILURE';
    MessagesActionsService.SET_TYPE = '@messages/SET_TYPE';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "queueMessages", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "fetchMessagesStatistics", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "fetchMessages", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "fetchModalMessages", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "queueMessage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "popMessage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "toggleMessage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "setParams", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "setParamsNextPage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesActionsService.prototype, "setType", void 0);
    return MessagesActionsService;
}());
export { MessagesActionsService };
