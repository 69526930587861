// import * as moment from 'moment'; - added globally to prevent all locales being included

import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { Observable, empty, concat, of, from } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';
import { AuctionMakeOfferRequest, AuctionMakeOfferResponse } from '../../../interfaces/auction.interface';
import { SalesVehicleUnavailableReason } from '../../../interfaces/sales.interface';

import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalAuctionDisclaimerComponent } from '../../shared/components/modal-auction-disclaimer/modal-auction-disclaimer.component';

import { AuctionActionsService } from '../actions/auction.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { UserActionsService } from '../actions/user.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, map, takeUntil, catchError } from 'rxjs/operators';

@Injectable()
export class AuctionEpicsService {

    constructor(
        private http: HttpClient,
        private modal: ModalService
    ) { }

    /**
     *
     * AUCTION CONFIG
     *
     */

    //    fetchAuctionConfig = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {
    //
    //        const cancel$ = action$.ofType(SalesActionsService.SET_AREA);
    //
    //        return action$.ofType(AuctionActionsService.FETCH_AUCTION_CONFIG).pipe(
    //            switchMap(({
    //                payload
    //            }) => {
    //
    //                const area = store$.value.sales.area;
    //                const loaded = store$.value.auction.loaded;
    //
    //                // we already have the config, no need to reload it
    //                if (loaded) {
    //                    return empty();
    //                }
    //
    //                const query = new HttpParams();
    //
    //                const auctionsState = store$.value.auction;
    //
    //                // use concat so these actions happen sequentially
    //                return concat(
    //                    of({
    //                        type: AuctionActionsService.AUCTION_CONFIG_LOADING
    //                    }),
    //                    this.http.get(`/${area}/AuctionConfig`, { params: query }).pipe(
    //                        map(response => ({
    //                            type: AuctionActionsService.AUCTION_CONFIG_SUCCESS,
    //                            payload: {
    //                                response
    //                            }
    //                        })),
    //                        takeUntil(cancel$),
    //                        catchError(error => of({
    //                            type: AuctionActionsService.AUCTION_CONFIG_ERROR,
    //                            error
    //                        })))
    //                );
    //            }));
    //    }

    /**
     *
     * UPDATE AUCTION BY ID
     *
     */

    fetchAuctionById = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(SalesActionsService.SET_AREA);

        return action$.ofType(AuctionActionsService.FETCH_AUCTION_BY_ID).pipe(
            switchMap(({
                payload
            }) => {

                const sellingItemId = payload.sellingItemId;

                const { area } = store$.value.sales;

                // only do this when the user is viewing an auction
                if (!area) {
                    return empty();
                }

                const query = new HttpParams();

                query.append('id', sellingItemId);
                query.append('includeCarInfoInResponse', 'false'); // @TODO param name

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: AuctionActionsService.AUCTION_BY_ID_LOADING
                    }),
                    this.http.get(`/${area}/GetAuctionById`, { params: query }).pipe(
                        map(response => ({
                            type: AuctionActionsService.AUCTION_BY_ID_SUCCESS,
                            payload: {
                                sellingItemId,
                                response,
                                isFromPush: false,
                                now: moment()
                            }
                        })),
                        takeUntil(cancel$),
                        catchError(error => of({
                            type: AuctionActionsService.AUCTION_BY_ID_ERROR,
                            error
                        })))
                );
            }));
    }

    /**
     *
     * Make offer confirm
     *
     */

    makeOfferConfirm = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        return action$.ofType(AuctionActionsService.AUCTION_MAKE_OFFER_CONFIRM).pipe(
            switchMap(({
                payload
            }) => {

                const hasCookie = store$.value.user.hasAcceptedAuctionDisclaimer;

                if (hasCookie) {
                    return of({
                        type: AuctionActionsService.AUCTION_MAKE_OFFER,
                        payload
                    });
                }

                // open confirmation modal
                const modalRef = this.modal.open(ModalAuctionDisclaimerComponent);

                return from(modalRef.result).pipe(
                    switchMap(() => {
                        // User has clicked OK in the modal
                        return of({
                            type: UserActionsService.AUCTION_DISCLAIMER_ACCEPTED,
                            payload: null
                        }, {
                            type: AuctionActionsService.AUCTION_MAKE_OFFER,
                            payload
                        });
                    }),
                    catchError(() => {
                        // User clicked CANCEL in the modal - kill the stream
                        return empty();
                    }));


            }));

    }

    /**
     *
     * Make offer
     *
     */

    makeOffer = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(SalesActionsService.SET_AREA);

        return action$.ofType(AuctionActionsService.AUCTION_MAKE_OFFER).pipe(
            switchMap(({
                payload
            }) => {

                const requestPayload: AuctionMakeOfferRequest = {
                    // just to be clear lets list the properties
                    sellingItemId: payload.vehicleId,
                    maxOffer: payload.offer
                };

                const { area } = state$.value.sales;

                return concat(
                    // flag as loading
                    of({
                        type: AuctionActionsService.AUCTION_MAKE_OFFER_LOADING,
                        payload
                    }),
                    // make request
                    this.http.put(`/Selling/MakeOffer`, requestPayload).pipe(
                        // fake request
                        // of({ ...auctionMakeOfferResponse({ request: requestPayload }) }).pipe(
                        map((response: AuctionMakeOfferResponse) => {
                            // user is the current winner
                            response._result = 0;
                            return ({
                                type: AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS,
                                payload: {
                                    request: requestPayload,
                                    response,
                                    now: moment()
                                }
                            });
                        }),
                        catchError(error => {

                            // handle specific status errors here
                            const errorBody = error.error;

                            // user lost by automatic bid
                            if (error.status === 400) {
                                if (requestPayload.maxOffer === errorBody.currentOffer) {
                                    error.error._result = 6;
                                } else {
                                    error.error._result = 3;
                                }
                                return of({
                                    type: AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS,
                                    payload: {
                                        request: requestPayload,
                                        response: errorBody,
                                        now: moment()
                                    }
                                });
                            }

                            // auction expired or does not exists
                            if (error.status === 404) {
                                //     error.body._result = 5; // hide vehicle
                                return of({
                                    type: AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS,
                                    payload: {
                                        request: requestPayload,
                                        response: {
                                            _result: 5
                                        },
                                        now: moment()
                                    }
                                });
                            }

                            return of({
                                type: AuctionActionsService.AUCTION_MAKE_OFFER_ERROR,
                                error
                            });
                        }),
                        // cancel this if the area changes
                        takeUntil(cancel$)
                    )
                );

            }));

    }

    /**
     *
     * Determine if the auction is unavailable
     *
     */

    makeOfferSuccess = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(SalesActionsService.SET_AREA);

        return action$.ofType(AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS).pipe(
            switchMap(({
                payload
            }) => {

                const result = payload.response._result;

                // auction is unavailable
                if (result === 2 || result === 5) {
                    return of({
                        type: SalesActionsService.HIDE_SELLINGITEM,
                        payload: {
                            vehicleId: payload.request.sellingItemId, // this is different because it is was the endpoint uses
                            reason: 'AUCTION_ENDED' as SalesVehicleUnavailableReason
                        }
                    });
                }

                // do nothing
                return empty();

            }));

    }

}

// export const auctionMakeOfferResponse = (options) => {
//     const request = options.request as AuctionMakeOfferRequest;
//     return {
//         endTime: moment(1687456833),
//         maxOffer: request.maxOffer,
//         currentOffer: request.maxOffer,
//         expireInMinutes: 0
//     } as AuctionMakeOfferResponse;
// };
