import { Injectable } from '@angular/core';

import { NgRedux, select } from '@angular-redux/store';
import { Subscription ,  Observable, timer } from 'rxjs';

import { AppState, CartState } from '../../../../interfaces/store.interface';
import { CartContents, CartValidity } from '../../../../interfaces/cart.interface';

import { CartActionsService } from '../../../store/actions/cart.actions';

@Injectable()
export class CartService {

    expiry: number;
    contents: CartContents;

    private dataSubscription = Subscription.EMPTY;
    private expirySubscription = Subscription.EMPTY;

    constructor (private ngRedux: NgRedux<AppState>, private cartActions: CartActionsService) {}

    start () {
        // listen for cart updates
        this.dataSubscription = this.ngRedux.select<CartState>(['cart'])
            .subscribe((cart: CartState) => {
                this.expiry = cart.expiry;
                this.contents = cart.contents;
                this.monitorCartExpiry();
            });
    }

    stop () {
        this.dataSubscription.unsubscribe();
        this.expirySubscription.unsubscribe();
    }

    private monitorCartExpiry () {

        // cancel existing
        this.expirySubscription.unsubscribe();

        // check we have required info
        if (this.expiry && this.contents) {

            const delay = Math.max(this.expiry - Date.now(), 0); // can't go below zero

            if (delay === 0) {
                return;
            }

            const offset = delay % 1000; // calculate the milleseconds so we count at exactly the right point

            this.expirySubscription = timer(offset, 1000).subscribe(() => {

                let isWarningZone = false;
                let isDangerZone = false;

                if (this.expiry && this.contents) {
                    const diff = Math.max(this.expiry - Date.now(), 0); // not below zero

                    if (diff < (this.contents.warningZone * 1000)) {
                        isWarningZone = true;
                    } else {
                        isWarningZone = false;
                    }

                    if (diff < (this.contents.dangerZone * 1000)) {
                        isDangerZone = true;
                    } else {
                        isDangerZone = false;
                    }
                }

                // determine if we need to fire the actions
                if (this.ngRedux.getState().cart.isDangerZone !== isDangerZone) {
                    this.cartActions.setDangerZone(isDangerZone);
                }

                if (this.ngRedux.getState().cart.isWarningZone !== isWarningZone) {
                    this.cartActions.setWarningZone(isWarningZone);
                }

            });
        }
    }

}
