import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { Observable, of } from 'rxjs';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';

import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';

@Injectable()
export class SellingAreasEpicsService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   *
   * FETCH SELLING AREAS
   *
   */

  fetchSellingAreas = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

    return action$.ofType(SellingAreasActionsService.FETCH_SELLING_AREAS).pipe(
      switchMap(() => {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('pageIndex', '0');
        queryParams = queryParams.append('pageSize', '1000');

        return this.http.get('/SellingAreas/List', {
          params: queryParams
        }).pipe(
          switchMap((response) => {
            return of({
              type: SellingAreasActionsService.FETCH_SELLING_AREAS_SUCCESS,
              payload: response
            });
          }));
      })
    );
  }

  /**
   *
   * FETCH FULL SELLING AREA
   *
   */

  fetchSellingAreaFull = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

    const cancel$ = action$.pipe(
      ofType(SellingAreasActionsService.FETCH_SELLING_AREA_FULL)
    );

    return action$.ofType(SellingAreasActionsService.FETCH_SELLING_AREA_FULL).pipe(
      switchMap(({
        payload
      }) => {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('id', payload.id);

        return this.http.get('/SellingAreas/Get', {
          params: queryParams
        }).pipe(
          switchMap(response => {
            return of({
              type: SellingAreasActionsService.FETCH_SELLING_AREA_FULL_SUCCESS,
              payload: response
            });
          }),
          catchError((response: HttpErrorResponse) => {
            return of({
              type: SellingAreasActionsService.FETCH_SELLING_AREA_FULL_FAILURE,
              error: {
                response
              }
            });
          }),
          takeUntil(cancel$)
        );
      })
    );
  }

}
