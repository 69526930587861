import { Injectable } from '@angular/core';

@Injectable()
export class FaviconService {

    constructor(
    ) { }

    public setFavicon( domain: string ): void {

        if (!domain) {
            return;
        }

        const href = `/themes/${domain.toLowerCase()}/assets/images/favicon.ico`;

        const augmentedHref = this.cacheBustHref(href);

        this.removeFavicons();
        this.addFavicon(augmentedHref);

    }

    private addFavicon( href: string ): void {

        const linkElement = document.createElement('link');
        linkElement.setAttribute( 'rel', 'icon' );
        linkElement.setAttribute( 'type', 'image/x-icon' );
        linkElement.setAttribute( 'href', href );
        document.head.appendChild( linkElement );

    }

    private removeFavicons(): void {
        const linkElements = document.querySelectorAll( 'link[rel ~= icon]' );

        for ( const linkElement of Array.from( linkElements ) ) {
            linkElement.parentNode.removeChild( linkElement );
        }
    }

    private cacheBustHref( href: string ): string {

        const augmentedHref = ( href.indexOf('?') === -1 )
            ? `${ href }?t=${ Date.now() }`
            : `${ href }&t=${ Date.now() }`
        ;
        return( augmentedHref );

    }
}
