export const CONFIG = {
    PAGINATION: {
        PAGE_SIZE: 10,
        REMOVED_FLAG: '__REMOVED__'
    },
    TRANSITION: {
        DETAILS: 300,
        GALLERY: 200,
        THUMBNAIL: 1000,
        THUMBNAIL_PAUSE: 500,
        CART_HEADER: 250,
        LISTING: 250
    },
    KEYS: {
        GOOGLE_MAPS: 'AIzaSyDxa-qviftgsXGJuL5YzAPBzx7CMaU1kok'
    },
    DIMENSIONS: {
        BREAKPOINTS: [768, 1024],
        CART_HEADER_HEIGHT: 48,
        LISTING_HEIGHT: 110,
        ORDERS_TOP_SPACE: 48
    },
    AUCTIONS: {
        MINIMUM_RAISE: 100,
        EXTRA_TIME: 0, // 5 * 60 * 1000, // (5 min)
        DISABLE_CONDITIONS: false,
    },
    PERSISTED_TOKENS: {
        AUCTION_DISCLAIMER: 'AuctionDisclaimer',
        COMMUNICATIONS: 'Communication'
    },
    CART: {
        DISABLE_CONDITIONS: false,
        ENABLE_VALIDATION: true,
        RULES: {
            DISABLED: 'Il carrello è disabilitato',
            ORDER_COUNT: 'Numero massimo di ordini',
            COUNT: 'Numero massimo di veicoli',
            VALUE: 'Valore del carrello',
            UNKNOWN: 'Sconosciuto',
            N_A: 'n/a'
        }
    },
    NOTIFIER: {
        DURATION: 10000
    },
    WEBSOCKETS: {
        ENABLE: true
    },
    COLORS: {
        ClosedAuctionOl: '#61c9d4',
        ClosedAuctionYuc: '#61c9d4',
        OpenAuctionOl: '#bcdc6d',
        OpenAuctionYuc: '#bcdc6d',
        PreSale: '#fdca4d',
        SaleOl: '#7ca5d3',
        SaleYuc: '#7ca5d3'
    },
    SELLINGAREAS: {
        RELOAD_TIME_DIFF: 5000  // 5 secs
    },
    CURRENCY: {
        DEFAULT_CURRENCY: 'EUR'
    },
    DEFAULT_LOCALE: 'it-IT' // used if there is no user locale in a ILocalizedString
};
