import { Pipe, PipeTransform } from '@angular/core';

import { DateService } from '../../services/date/date.service';

@Pipe({
    name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

    constructor (private date: DateService) {}

    transform (value: string): string {

        let result = '';

        if (value) {
          const utc = this.date.toUTC(value);
          result = this.date.formatDateTime(utc);
        }

        return result;
    }

}
