import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
import { SalesArea, SalesParams, SalesVehicleUnavailableReason } from '../../../interfaces/sales.interface';
var SalesActionsService = /** @class */ (function () {
    function SalesActionsService() {
        /**
         *
         * FETCH
         *
         */
        this.fetchSellingItem = function (payload) { return ({
            type: SalesActionsService.FETCH_SELLINGITEM,
            payload: payload
        }); };
        /**
         *
         * SET
         *
         */
        this.setArea = function (payload) { return ({
            type: SalesActionsService.SET_AREA,
            payload: payload
        }); };
        this.setParams = function (payload) { return ({
            type: SalesActionsService.SET_PARAMS,
            payload: payload
        }); };
        this.setParamsNextPage = function () { return ({
            type: SalesActionsService.SET_PARAMS_NEXT_PAGE
        }); };
        this.setManufacturer = function (payload) { return ({
            type: SalesActionsService.SET_MANUFACTURER,
            payload: payload
        }); };
        this.setSeries = function (payload) { return ({
            type: SalesActionsService.SET_SERIES,
            payload: payload
        }); };
        this.setSelectedResult = function (payload) { return ({
            type: SalesActionsService.SET_SELECTED_RESULT,
            payload: payload
        }); };
        this.setSelectedIndex = function (payload) { return ({
            type: SalesActionsService.SET_SELECTED_INDEX,
            payload: payload
        }); };
        this.setSelectedBundleIndex = function (payload) { return ({
            type: SalesActionsService.SET_SELECTED_BUNDLE_INDEX,
            payload: payload
        }); };
        this.setThumbsIndex = function (payload) { return ({
            type: SalesActionsService.SET_THUMBS_INDEX,
            payload: payload
        }); };
        this.setAsForSale = function (payload) { return ({
            type: SalesActionsService.SET_AS_FOR_SALE,
            payload: payload
        }); };
        /**
         *
         * VEHICLE FILTERS
         *
         */
        this.applyVehicleFilters = function (payload) { return ({
            type: SalesActionsService.APPLY_SELLINGITEM_FILTERS,
            payload: payload
        }); };
        /**
         *
         * RELOAD
         *
         */
        this.reloadVehicles = function () { return ({
            type: SalesActionsService.RELOAD_SELLINGITEMS
        }); };
        /**
         *
         * HIDE VEHICLE
         *
         */
        this.hideVehicle = function (payload) { return ({
            type: SalesActionsService.HIDE_SELLINGITEM,
            payload: payload
        }); };
        this.showVehicleUnavailableModal = function (payload) { return ({
            type: SalesActionsService.SHOW_SELLINGITEM_UNAVAILABLE_MODAL,
            payload: payload
        }); };
        this.removeVehicleFromResults = function (payload) { return ({
            type: SalesActionsService.REMOVE_SELLINGITEM_FROM_RESULTS,
            payload: payload
        }); };
        /**
         *
         * CAN/CANNOT BE PURCHASED
         *
         */
        this.setVehicleAsPurchasable = function (payload) { return ({
            type: SalesActionsService.SET_SELLINGITEM_AS_PURCHASABLE,
            payload: payload
        }); };
        this.setVehicleAsNotPurchasable = function (payload) { return ({
            type: SalesActionsService.SET_SELLINGITEM_AS_NOT_PURCHASABLE,
            payload: payload
        }); };
    }
    SalesActionsService.SET_AREA = '@sales/SET_AREA';
    SalesActionsService.SET_PARAMS = '@sales/SET_PARAMS';
    SalesActionsService.SET_PARAMS_NEXT_PAGE = '@sales/SET_PARAMS_NEXT_PAGE';
    SalesActionsService.SET_MANUFACTURER = '@sales/SET_MANUFACTURER';
    SalesActionsService.SET_SERIES = '@sales/SET_SERIES';
    SalesActionsService.SET_SELECTED_INDEX = '@sales/SET_SELECTED_INDEX';
    SalesActionsService.SET_SELECTED_BUNDLE_INDEX = '@sales/SET_SELECTED_BUNDLE_INDEX';
    SalesActionsService.SET_SELECTED_RESULT = '@sales/SET_SELECTED_RESULT';
    SalesActionsService.SET_SELECTED_FULLY_OPEN = '@sales/SET_SELECTED_FULLY_OPEN';
    SalesActionsService.SET_THUMBS_INDEX = '@sales/SET_THUMBS_INDEX';
    SalesActionsService.SET_AS_FOR_SALE = '@sales/SET_AS_FOR_SALE';
    SalesActionsService.FETCH_SELLINGITEM_FILTERS = '@sales/FETCH_SELLINGITEM_FILTERS';
    SalesActionsService.SELLINGITEM_FILTERS_LOADING = '@sales/SELLINGITEM_FILTERS_LOADING';
    SalesActionsService.SELLINGITEM_FILTERS_SUCCESS = '@sales/SELLINGITEM_FILTERS_SUCCESS';
    SalesActionsService.SELLINGITEM_FILTERS_ERROR = '@sales/SELLINGITEM_FILTERS_ERROR';
    SalesActionsService.APPLY_SELLINGITEM_FILTERS = '@sales/APPLY_SELLINGITEM_FILTERS';
    SalesActionsService.RELOAD_SELLINGITEMS = '@sales/RELOAD_SELLINGITEMS';
    SalesActionsService.FETCH_SELLINGITEMS = '@sales/FETCH_SELLINGITEMS';
    SalesActionsService.SELLINGITEMS_LOADING = '@sales/SELLINGITEMS_LOADING';
    SalesActionsService.SELLINGITEMS_LOADING_MORE = '@sales/SELLINGITEMS_LOADING_MORE';
    SalesActionsService.SELLINGITEMS_SUCCESS = '@sales/SELLINGITEMS_SUCCESS';
    SalesActionsService.SELLINGITEMS_ERROR = '@sales/SELLINGITEMS_ERROR';
    SalesActionsService.FETCH_SELLINGITEM = '@sales/FETCH_SELLINGITEM';
    SalesActionsService.SELLINGITEM_LOADING = '@sales/SELLINGITEM_LOADING';
    SalesActionsService.SELLINGITEM_SUCCESS = '@sales/SELLINGITEM_SUCCESS';
    SalesActionsService.SELLINGITEM_ERROR = '@sales/SELLINGITEM_ERROR';
    SalesActionsService.HIDE_SELLINGITEM = '@sales/HIDE_SELLINGITEM';
    SalesActionsService.REMOVE_SELLINGITEM_FROM_RESULTS = '@sales/REMOVE_SELLINGITEM_FROM_RESULTS';
    SalesActionsService.SHOW_SELLINGITEM_UNAVAILABLE_MODAL = '@sales/SHOW_SELLINGITEM_UNAVAILABLE_MODAL';
    SalesActionsService.SET_SELLINGITEM_AS_PURCHASABLE = '@sales/SET_SELLINGITEM_AS_PURCHASABLE';
    SalesActionsService.SET_SELLINGITEM_AS_NOT_PURCHASABLE = '@sales/SET_SELLINGITEM_AS_NOT_PURCHASABLE';
    SalesActionsService.FETCH_ACTIVE_AUCTIONS_FOR_USER = '@sales/FETCH_ACTIVE_AUCTIONS_FOR_USER';
    SalesActionsService.ACTIVE_AUCTIONS_FOR_USER_SUCCESS = '@sales/ACTIVE_AUCTIONS_FOR_USER_SUCCESS';
    SalesActionsService.ACTIVE_AUCTIONS_FOR_USER_ERROR = '@sales/ACTIVE_AUCTIONS_FOR_USER_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "fetchSellingItem", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setArea", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setParams", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setParamsNextPage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setManufacturer", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setSeries", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setSelectedResult", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setSelectedIndex", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setSelectedBundleIndex", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setThumbsIndex", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setAsForSale", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "applyVehicleFilters", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "reloadVehicles", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "hideVehicle", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "showVehicleUnavailableModal", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "removeVehicleFromResults", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setVehicleAsPurchasable", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SalesActionsService.prototype, "setVehicleAsNotPurchasable", void 0);
    return SalesActionsService;
}());
export { SalesActionsService };
