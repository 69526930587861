import { empty, from } from 'rxjs';
import { ExceptionActionsService } from '../actions/exception.actions';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalExceptionComponent } from '../../shared/components/modal-exception/modal-exception.component';
import { switchMap, catchError } from 'rxjs/operators';
var ExceptionEpicsService = /** @class */ (function () {
    function ExceptionEpicsService(modal) {
        var _this = this;
        this.modal = modal;
        this.showExceptionModal = function (action$, store$) {
            return action$.ofType(ExceptionActionsService.SHOW_EXCEPTION_MODAL).pipe(switchMap(function (_a) {
                var error = _a.error;
                var modalRef = _this.modal.open(ModalExceptionComponent);
                var modalInstance = modalRef.componentInstance;
                // pass data
                modalInstance.errorMessage = error;
                modalInstance.errorIsObject = error !== null && typeof error === 'object';
                return from(modalRef.result).pipe(
                // modals only use dismiss which leads to 'catch'
                catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return empty();
                }));
            }));
        };
    }
    return ExceptionEpicsService;
}());
export { ExceptionEpicsService };
