/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./listing-title.component";
var styles_ListingTitleComponent = [];
var RenderType_ListingTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListingTitleComponent, data: {} });
export { RenderType_ListingTitleComponent as RenderType_ListingTitleComponent };
export function View_ListingTitleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "listing__primary truncate"], ["data-attr", "{ 'title': title }"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_ListingTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-listing-title", [], null, null, null, View_ListingTitleComponent_0, RenderType_ListingTitleComponent)), i0.ɵdid(1, 49152, null, 0, i1.ListingTitleComponent, [], null, null)], null, null); }
var ListingTitleComponentNgFactory = i0.ɵccf("app-listing-title", i1.ListingTitleComponent, View_ListingTitleComponent_Host_0, { title: "title" }, {}, []);
export { ListingTitleComponentNgFactory as ListingTitleComponentNgFactory };
