import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, concat, empty, from } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';

import { switchMap, catchError, takeUntil, tap } from 'rxjs/operators';
import { BootingActionsService } from '../actions/booting.actions';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { UserManagerService } from '../services/user-manager/user-manager.service';

@Injectable()
export class BootingEpicsService {

    constructor(
        private http: HttpClient,
        private userManager: UserManagerService,
        private bootingActions: BootingActionsService
    ) { }

    /**
     *
     * FETCH USER
     *
     */

    loadUser = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(BootingActionsService.LOAD_USER).pipe(
            switchMap(({
                payload
            }) => {

                const user = state$.value.user;

                if (user.isReady) {
                    return of({
                        type: BootingActionsService.USER_READY
                    });
                }

                // load already in progress, wait for it to complete
                if (user.identity.loading || user.profile.loading) {
                    return empty();
                }

                return of({
                    type: BootingActionsService.FETCH_IDENTITY
                });

            }));
    }

    /**
     *
     * IDENTITY
     *
     */

    fetchIdentity = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(BootingActionsService.FETCH_IDENTITY).pipe(
            switchMap(() => {

                return concat(
                    of({
                        type: BootingActionsService.IDENTITY_LOADING
                    }),
                    from(this.userManager.getUser()).pipe(
                        switchMap((response) => {

                            return concat(
                                of({
                                    type: BootingActionsService.IDENTITY_SUCCESS,
                                    payload: {
                                        response
                                    }
                                }),
                                of({
                                    type: BootingActionsService.FETCH_PROFILE,
                                    payload: {
                                        response
                                    }
                                })
                            );
                        }),
                        catchError((error: any) => {
                            return of({
                                type: BootingActionsService.IDENTITY_ERROR,
                                error
                            });
                        })
                    ));
            }));
    }

    /**
     *
     * PROFILE / USER INFO
     *
     */

    fetchProfile = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        const cancel$ = action$.ofType(BootingActionsService.USER_DESTROY);

        return action$.ofType(BootingActionsService.FETCH_PROFILE).pipe(
            switchMap(({
                payload // this is the IDENTITY response AKA user object
            }) => {

                // if the user doesnt exist stop the stream
                if (!payload.response) {
                    return of({
                        type: BootingActionsService.USER_READY
                    });
                }

                return concat(
                    of({
                        type: BootingActionsService.PROFILE_LOADING
                    }),
                    this.http.get('/User/GetProfile').pipe(
                        switchMap(response => {
                            return concat(
                                of({
                                    type: BootingActionsService.PROFILE_SUCCESS,
                                    payload: {
                                        response
                                    }
                                }),
                                of({
                                    type: BootingActionsService.USER_LOGGED_IN,
                                    payload: null
                                }),
                                of({
                                    type: BootingActionsService.LOAD_TENANT_CONFIG,
                                    payload: null
                                })
                                //    of({
                                //        type: SellingAreasActionsService.FETCH_SELLING_AREAS,
                                //        payload: undefined
                                //    }),
                                //    of({
                                //        type: BootingActionsService.USER_READY,
                                //        payload: null
                                //    }),
                                //    of({
                                //        type: CmsMenuActionsService.FETCH_MENU,
                                //        payload: null
                                //    })
                            );
                        }),
                        catchError(error => of({
                            type: BootingActionsService.PROFILE_ERROR,
                            error
                        })),
                        takeUntil(cancel$)
                    ));

            }));
    }

    /**
     *
     * LOAD THE CONFIG
     *
     */

    loadTenantConfiguration = (action$: ActionsObservable<any>, state$: NgRedux<AppState>) => {

        const cancel$ = action$.ofType(BootingActionsService.LOAD_TENANT_CONFIG);

        return action$.ofType(BootingActionsService.LOAD_TENANT_CONFIG).pipe(
            switchMap(({ }) => {

                const url = '/TenantConfigurations/Get';

                return concat(
                    // flag as loading
                    of({
                        type: BootingActionsService.TENANT_CONFIG_LOADING
                    }),
                    // make the request
                    this.http.get(url, {
                        observe: 'body',
                        responseType: 'json',
                        headers: {
                            unauthenticatedRoute: 'true'
                        }
                    }).pipe(
                        switchMap((response: Observable<Object>) => {
                            return concat(
                                of({
                                    type: BootingActionsService.TENANT_CONFIG_SUCCESS,
                                    payload: {
                                        response
                                    }
                                })
                            );
                        }),
                        catchError((response: HttpErrorResponse) => {
                            return of({
                                type: BootingActionsService.TENANT_CONFIG_ERROR,
                                error: {
                                    response
                                }
                            });
                        }),
                        takeUntil(cancel$)
                    ));

            }));

    }

}



