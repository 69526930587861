import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AppAction } from '../../../interfaces/store.interface';
import { UiPanel, UiDropdown, UiNotification } from '../../../interfaces/ui.interface';

@Injectable()
export class UiActionsService {

    static readonly RESET = '@ui/RESET';

    static readonly OPEN_PANEL = '@ui/OPEN_PANEL';
    static readonly TOGGLE_PANEL = '@ui/TOGGLE_PANEL';
    static readonly CLOSE_PANEL = '@ui/CLOSE_PANEL';

    static readonly OPEN_DROPDOWN = '@ui/OPEN_DROPDOWN';
    static readonly TOGGLE_DROPDOWN = '@ui/TOGGLE_DROPDOWN';
    static readonly CLOSE_DROPDOWN = '@ui/CLOSE_DROPDOWN';

    static readonly OPEN_DETAILS = '@ui/OPEN_DETAILS';
    static readonly CLOSE_DETAILS = '@ui/CLOSE_DETAILS';
    static readonly DETAILS_READY = '@ui/DETAILS_READY';

    static readonly OPEN_GALLERY = '@ui/OPEN_GALLERY';
    static readonly TOGGLE_GALLERY = '@ui/TOGGLE_GALLERY';
    static readonly CLOSE_GALLERY = '@ui/CLOSE_GALLERY';
    static readonly SET_GALLERY_IMAGES = '@ui/SET_GALLERY_IMAGES';
    static readonly SET_GALLERY_INDEX = '@ui/SET_GALLERY_INDEX';
    static readonly SET_GALLERY_NEXT = '@ui/SET_GALLERY_NEXT';
    static readonly SET_GALLERY_PREVIOUS = '@ui/SET_GALLERY_PREVIOUS';

    static readonly REGISTER_MODAL = '@ui/REGISTER_MODAL';
    static readonly DISMISS_MODAL = '@ui/DISMISS_MODAL';

    static readonly QUEUE_NOTIFICATION = '@ui/QUEUE_NOTIFICATION';
    static readonly QUEUE_NOTIFICATIONS = '@ui/QUEUE_NOTIFICATIONS';
    static readonly POP_NOTIFICATION = '@ui/POP_NOTIFICATION';
    static readonly DISCARD_NOTIFICATION = '@ui/DISCARD_NOTIFICATION';

    static readonly FETCH_PRIVACY = '@ui/FETCH_PRIVACY';
    static readonly PRIVACY_LOADING = '@ui/PRIVACY_LOADING';
    static readonly PRIVACY_SUCCESS = '@ui/PRIVACY_SUCCESS';
    static readonly PRIVACY_ERROR = '@ui/PRIVACY_ERROR';

    /**
     *
     * RESET
     *
     */

    @dispatch()
    reset = (): AppAction => ({
        type: UiActionsService.RESET
    })

    /**
     *
     * PANELS
     *
     */

    @dispatch()
    openPanel = (payload: UiPanel): AppAction => ({
        type: UiActionsService.OPEN_PANEL,
        payload
    })

    @dispatch()
    togglePanel = (payload: UiPanel): AppAction => ({
        type: UiActionsService.TOGGLE_PANEL,
        payload
    })

    @dispatch()
    closePanel = (): AppAction => ({
        type: UiActionsService.CLOSE_PANEL
    })

    /**
     *
     * DROPDOWNS
     *
     */

    @dispatch()
    openDropdown = (payload: UiDropdown): AppAction => ({
        type: UiActionsService.OPEN_DROPDOWN,
        payload
    })

    @dispatch()
    toggleDropdown = (payload: UiDropdown): AppAction => ({
        type: UiActionsService.TOGGLE_DROPDOWN,
        payload
    })

    @dispatch()
    closeDropdown = (): AppAction => ({
        type: UiActionsService.CLOSE_DROPDOWN
    })

    /**
     *
     * DETAILS
     *
     */

    @dispatch()
    openDetails = (): AppAction => ({
        type: UiActionsService.OPEN_DETAILS
    })

    @dispatch()
    closeDetails = (): AppAction => ({
        type: UiActionsService.CLOSE_DETAILS
    })

    @dispatch()
    detailsReady = (): AppAction => ({
        type: UiActionsService.DETAILS_READY
    })

    /**
     *
     * GALLERY
     *
     */

    @dispatch()
    openGallery = (): AppAction => ({
        type: UiActionsService.OPEN_GALLERY
    })

    @dispatch()
    toggleGallery = (): AppAction => ({
        type: UiActionsService.TOGGLE_GALLERY
    })

    @dispatch()
    closeGallery = (): AppAction => ({
        type: UiActionsService.CLOSE_GALLERY
    })

    @dispatch()
    setGalleryImages = (payload: { images: string[] }): AppAction => ({
        type: UiActionsService.SET_GALLERY_IMAGES,
        payload
    })

    @dispatch()
    setGalleryIndex = (payload: { index: number }): AppAction => ({
        type: UiActionsService.SET_GALLERY_INDEX,
        payload
    })

    @dispatch()
    setGalleryNext = (): AppAction => ({
        type: UiActionsService.SET_GALLERY_NEXT
    })

    @dispatch()
    setGalleryPrevious = (): AppAction => ({
        type: UiActionsService.SET_GALLERY_PREVIOUS
    })

    /**
     *
     * MODALS
     *
     */

    @dispatch()
    registerModal = (payload: { modal: NgbModalRef }): AppAction => ({
        type: UiActionsService.REGISTER_MODAL,
        payload
    })

    @dispatch()
    dismissModal = (): AppAction => ({
        type: UiActionsService.DISMISS_MODAL
    })

    /**
     *
     * NOTIFICATIONS
     *
     */

    @dispatch()
    queueNotification = (payload: { notification: UiNotification }): AppAction => ({
        type: UiActionsService.QUEUE_NOTIFICATION,
        payload
    })

    @dispatch()
    queueNotifications = (payload: UiNotification[]): AppAction => ({
        type: UiActionsService.QUEUE_NOTIFICATIONS,
        payload
    })

    @dispatch()
    popNotification = (): AppAction => ({
        type: UiActionsService.POP_NOTIFICATION
    })

    @dispatch()
    discardNotification = (): AppAction => ({
        type: UiActionsService.DISCARD_NOTIFICATION
    })

    /**
     *
     * PRIVACY POLICY
     *
     */

    @dispatch()
    fetchPrivacy = (): AppAction => ({
        type: UiActionsService.FETCH_PRIVACY
    })

}
