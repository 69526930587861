import { Component, OnInit, OnDestroy } from '@angular/core';

import { SignalRService } from '../../services/signalr/signalr.service';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, OnDestroy {

    /**
     *
     * This component will only be activated once the user has been authenticated.
     *
     * Therefore we can use this to start long-running services that require the
     * user to be a logged in. For example our websockets connection.
     *
     */

    constructor (private signalR: SignalRService) {}

    ngOnInit () {

        this.signalR.connect();

    }

    ngOnDestroy () {

        this.signalR.disconnect();

    }

}
