import { TimeSpan } from './classes/timeSpan';

export function durationToMinutes(durationStr: string) {
    const splitted = durationStr.split(':');
    return TimeSpan.fromTime(
        Number.parseInt(splitted[0], 10),
        Number.parseInt(splitted[1], 10),
        Number.parseInt(splitted[2], 10))
        .totalMinutes;
}
