import { HttpClient } from '@angular/common/http';
import { of, concat, empty, from } from 'rxjs';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
import { BootingActionsService } from '../actions/booting.actions';
import { UserManagerService } from '../services/user-manager/user-manager.service';
var BootingEpicsService = /** @class */ (function () {
    function BootingEpicsService(http, userManager, bootingActions) {
        var _this = this;
        this.http = http;
        this.userManager = userManager;
        this.bootingActions = bootingActions;
        /**
         *
         * FETCH USER
         *
         */
        this.loadUser = function (action$, state$) {
            return action$.ofType(BootingActionsService.LOAD_USER).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var user = state$.value.user;
                if (user.isReady) {
                    return of({
                        type: BootingActionsService.USER_READY
                    });
                }
                // load already in progress, wait for it to complete
                if (user.identity.loading || user.profile.loading) {
                    return empty();
                }
                return of({
                    type: BootingActionsService.FETCH_IDENTITY
                });
            }));
        };
        /**
         *
         * IDENTITY
         *
         */
        this.fetchIdentity = function (action$, store) {
            return action$.ofType(BootingActionsService.FETCH_IDENTITY).pipe(switchMap(function () {
                return concat(of({
                    type: BootingActionsService.IDENTITY_LOADING
                }), from(_this.userManager.getUser()).pipe(switchMap(function (response) {
                    return concat(of({
                        type: BootingActionsService.IDENTITY_SUCCESS,
                        payload: {
                            response: response
                        }
                    }), of({
                        type: BootingActionsService.FETCH_PROFILE,
                        payload: {
                            response: response
                        }
                    }));
                }), catchError(function (error) {
                    return of({
                        type: BootingActionsService.IDENTITY_ERROR,
                        error: error
                    });
                })));
            }));
        };
        /**
         *
         * PROFILE / USER INFO
         *
         */
        this.fetchProfile = function (action$, store) {
            var cancel$ = action$.ofType(BootingActionsService.USER_DESTROY);
            return action$.ofType(BootingActionsService.FETCH_PROFILE).pipe(switchMap(function (_a) {
                var payload = _a.payload // this is the IDENTITY response AKA user object
                ;
                // if the user doesnt exist stop the stream
                if (!payload.response) {
                    return of({
                        type: BootingActionsService.USER_READY
                    });
                }
                return concat(of({
                    type: BootingActionsService.PROFILE_LOADING
                }), _this.http.get('/User/GetProfile').pipe(switchMap(function (response) {
                    return concat(of({
                        type: BootingActionsService.PROFILE_SUCCESS,
                        payload: {
                            response: response
                        }
                    }), of({
                        type: BootingActionsService.USER_LOGGED_IN,
                        payload: null
                    }), of({
                        type: BootingActionsService.LOAD_TENANT_CONFIG,
                        payload: null
                    })
                    //    of({
                    //        type: SellingAreasActionsService.FETCH_SELLING_AREAS,
                    //        payload: undefined
                    //    }),
                    //    of({
                    //        type: BootingActionsService.USER_READY,
                    //        payload: null
                    //    }),
                    //    of({
                    //        type: CmsMenuActionsService.FETCH_MENU,
                    //        payload: null
                    //    })
                    );
                }), catchError(function (error) { return of({
                    type: BootingActionsService.PROFILE_ERROR,
                    error: error
                }); }), takeUntil(cancel$)));
            }));
        };
        /**
         *
         * LOAD THE CONFIG
         *
         */
        this.loadTenantConfiguration = function (action$, state$) {
            var cancel$ = action$.ofType(BootingActionsService.LOAD_TENANT_CONFIG);
            return action$.ofType(BootingActionsService.LOAD_TENANT_CONFIG).pipe(switchMap(function (_a) {
                var url = '/TenantConfigurations/Get';
                return concat(
                // flag as loading
                of({
                    type: BootingActionsService.TENANT_CONFIG_LOADING
                }), 
                // make the request
                _this.http.get(url, {
                    observe: 'body',
                    responseType: 'json',
                    headers: {
                        unauthenticatedRoute: 'true'
                    }
                }).pipe(switchMap(function (response) {
                    return concat(of({
                        type: BootingActionsService.TENANT_CONFIG_SUCCESS,
                        payload: {
                            response: response
                        }
                    }));
                }), catchError(function (response) {
                    return of({
                        type: BootingActionsService.TENANT_CONFIG_ERROR,
                        error: {
                            response: response
                        }
                    });
                }), takeUntil(cancel$)));
            }));
        };
    }
    return BootingEpicsService;
}());
export { BootingEpicsService };
