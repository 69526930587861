// import * as moment from 'moment'; - added globally to prevent all locales being included
import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var AuctionActionsService = /** @class */ (function () {
    function AuctionActionsService() {
        /**
         *
         * SET
         *
         */
        this.makeOfferConfirm = function (payload) { return ({
            type: AuctionActionsService.AUCTION_MAKE_OFFER_CONFIRM,
            payload: payload
        }); };
        this.makeOffer = function (payload) { return ({
            type: AuctionActionsService.AUCTION_MAKE_OFFER,
            payload: payload
        }); };
        this.setState = function (payload) { return ({
            type: AuctionActionsService.AUCTION_SET_STATE,
            payload: payload
        }); };
        this.updateAuctionById = function (payload) { return ({
            type: AuctionActionsService.FETCH_AUCTION_BY_ID,
            payload: payload
        }); };
        this.updateAuctionFromPush = function (payload) { return ({
            type: AuctionActionsService.AUCTION_BY_ID_SUCCESS,
            payload: tslib_1.__assign({}, payload, { isFromPush: true, now: moment() })
        }); };
    }
    AuctionActionsService.FETCH_AUCTION_CONFIG = '@auction/FETCH_AUCTION_CONFIG';
    AuctionActionsService.AUCTION_CONFIG_LOADING = '@auction/AUCTION_CONFIG_LOADING';
    AuctionActionsService.AUCTION_CONFIG_SUCCESS = '@auction/AUCTION_CONFIG_SUCCESS';
    AuctionActionsService.AUCTION_CONFIG_ERROR = '@auction/AUCTION_CONFIG_ERROR';
    AuctionActionsService.FETCH_AUCTION_BY_ID = '@auction/FETCH_AUCTION_BY_ID';
    AuctionActionsService.AUCTION_BY_ID_LOADING = '@auction/AUCTION_BY_ID_LOADING';
    AuctionActionsService.AUCTION_BY_ID_SUCCESS = '@auction/AUCTION_BY_ID_SUCCESS';
    AuctionActionsService.AUCTION_BY_ID_ERROR = '@auction/AUCTION_BY_ID_ERROR';
    AuctionActionsService.AUCTION_MAKE_OFFER_CONFIRM = '@auction/AUCTION_MAKE_OFFER_CONFIRM';
    AuctionActionsService.AUCTION_MAKE_OFFER = '@auction/AUCTION_MAKE_OFFER';
    AuctionActionsService.AUCTION_MAKE_OFFER_LOADING = '@auction/AUCTION_MAKE_OFFER_LOADING';
    AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS = '@auction/AUCTION_MAKE_OFFER_SUCCESS';
    AuctionActionsService.AUCTION_MAKE_OFFER_ERROR = '@auction/AUCTION_MAKE_OFFER_ERROR';
    AuctionActionsService.AUCTION_SET_STATE = '@auction/AUCTION_SET_STATE';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], AuctionActionsService.prototype, "makeOfferConfirm", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], AuctionActionsService.prototype, "makeOffer", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], AuctionActionsService.prototype, "setState", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], AuctionActionsService.prototype, "updateAuctionById", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], AuctionActionsService.prototype, "updateAuctionFromPush", void 0);
    return AuctionActionsService;
}());
export { AuctionActionsService };
