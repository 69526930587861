import { Params } from '@angular/router';

import { AsyncState, PaginatedAsyncState } from '../../../interfaces/store.interface';

import { AppAction } from '../../../interfaces/store.interface';
import { CONFIG } from '../../shared/config/config.const';

/**
 *
 * Sort object for comparison
 *
 */

export function sortParamsToString(params: Params, stripKeys: string[] = []): string {
    const obj = {
        ...params
    };

    stripKeys.forEach((key: string) => {
        delete obj[key];
    });

    return JSON.stringify(obj, Object.keys(obj).sort());
}


/**
 *
 * Cast boolean-like values to real boolean
 *
 */

export function castToBoolean(obj: any) {
    const object = {
        ...obj
    };
    Object.keys(object).forEach((key: string) => {
        if (object[key] === 'true') {
            object[key] = true;
        } else if (object[key] === 'false') {
            object[key] = false;
        }
    });
    return object;
}

/**
 *
 * Clone and modify an arrays
 *
 */

export function pushArrayItem(array: any[], item: any) {
    return [
        ...array.slice(),
        item
    ];
}

export function pushArrayItems(array: any[], items: any[]) {
    return [
        ...array.slice(),
        ...items.slice()
    ];
}

export function insertArrayItem(array: any[], index: number, item: any) {
    return [
        ...array.slice(0, index),
        item,
        ...array.slice(index)
    ]
}

export function removeArrayItem(array: any[], index: number) {
    return [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ];
}

/**
 *
 * ASYNC
 *
 */

export function asyncLoadingReducer(property: string) {
    return function asyncLoadingReducerFn(state: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                payload: null, // CLEAR payload when a new load starts - useful for Manufacturer / Series filters
                loading: true,
                error: null,
                hasError: false
            }
        };

    };
};

export function asyncSuccessReducer(property: string) {
    return function asyncSuccessReducerFn(state: any, response: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                payload: {
                    ...response
                },
                loading: false
            }
        };

    };

};

export function asyncErrorReducer(property: string) {
    return function asyncErrorReducerFn(state: any, error: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                loading: false,
                saving: false,
                error,
                hasError: true
            }
        };

    };

};

export function asyncSavingReducer(property: string) {
    return function asyncSavingReducerFn(state: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                saving: true,
                error: null,
                hasError: false
            }
        };

    };
}

export function asyncSavingSuccessReducer(property: string) {
    return function asyncSavingSuccessReducerFn(state: any, response: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                payload: {
                    ...state[property].payload,
                    ...response
                },
                saving: false
            }
        };

    };

}


/**
 *
 * PAGINATED ASYNC
 *
 */

export function paginatedAsyncLoadingReducer(property: string) {
    return function paginatedAsyncLoadingReducerFn(state: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                payload: {
                    pageIndex: -1,
                    pageSize: CONFIG.PAGINATION.PAGE_SIZE,
                    result: [],
                    totalCount: -1,
                    totalPages: -1
                },
                loading: true,
                loadingMore: false,
                loaded: false,
                error: null,
                hasError: false,
                hasNextPage: false
            }
        };

    };
};

export function paginatedAsyncLoadingMoreReducer(property: string) {
    return function paginatedAsyncLoadingMoreReducerFn(state: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                // DO NOT clear the payload when loading more
                loading: false,
                loadingMore: true,
                loaded: false,
                error: null,
                hasError: false,
            }
        };

    };
};

export function paginatedAsyncSuccessReducer(property: string) {
    return function paginatedAsyncSuccessReducerFn(state: any, response: any) {
        const nextState = {
            ...state,
            [property]: {
                ...state[property],
                loading: false,
                loadingMore: false,
                loaded: true,
                lastLoad: new Date(),
                payload: {
                    ...state[property].payload,
                    pageIndex: response.pageIndex,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount,
                    totalPages: response.totalPages
                },
                hasError: false,
                hasNextPage: false
            }
        };

        const results = (response.result || []).slice();

        // calculate where to start from
        const startIndex = (nextState[property].payload.pageIndex * nextState[property].payload.pageSize);

        // first page, clear the existing results
        if (response.pageIndex === 0) {
            nextState[property].payload.result = [];
        } else {
            nextState[property].payload.result = nextState[property].payload.result.slice(); // take a copy
        }

        // write the results into the results array (overwriting any preview items)
        results.forEach((item: any, index: number) => {
            // note - we only store the ID here
            nextState[property].payload.result[startIndex + index] = item.id || item.orderId;
        });

        // save the loaded count
        nextState[property].loadedCount = nextState[property].payload.result.length;

        // check if we have addition pages available
        if (nextState[property].payload.pageIndex < nextState[property].payload.totalPages - 1) {
            // flag
            nextState.results.hasNextPage = true;
            // calculate the remaining items
            const remainingResults = nextState[property].payload.totalCount - (startIndex + results.length);
            // calculate the number of preview items to display
            const previewLength = (remainingResults > nextState[property].payload.pageSize) ? nextState[property].payload.pageSize : remainingResults % nextState[property].payload.pageSize;
            // calculate the loop parameters
            const previewStart = startIndex + nextState[property].payload.pageSize;
            const previewEnd = previewStart + previewLength;
            // add preview items to array
            for (let i = previewStart; i < previewEnd; i++) {
                nextState[property].payload.result.push(null); // null means show a preview - we can use push because we've already cloned the array
            }
        }

        // Note - entities are updated in the entitiesReducer

        return nextState;

    };

};

export function paginatedAsyncErrorReducer(property: string) {
    return function paginatedAsyncErrorReducerFn(state: any, error: any) {

        return {
            ...state,
            [property]: {
                ...state[property],
                loading: false,
                loadingMore: false,
                lastLoad: new Date(),
                error,
                hasError: true,

            }
        };

    };

};


/**
 *
 * USER ROLES
 *
 */
// searches for roles in the form of "www_[scope]_reader" or "www_[scope]_writer"
// returns an object with the sections containing two properties for values (reader and writer)
// { [scopes]: { reader: [theReaderValue/s], writer: [theWriterValue/s], } }
export function extractUserRoles(profile, allowedPrefixes: string[] = ['www_', 'admin_']) {
    if (profile !== Object(profile)) {
        return null;
    }

    let roleKeys = [];

    allowedPrefixes.forEach((prefix) => {
        roleKeys = roleKeys.concat(Object.keys(profile).filter(val => {
            return val.startsWith(prefix);
        }));
    });

    const retRoles = {};

    roleKeys.forEach(val => {
        const splitted = val.split('_');
        const section = `${splitted[0]}_${splitted[1]}`;
        const type = splitted[2];

        if (!retRoles[section]) {
            retRoles[section] = {};
        }

        retRoles[section][type] = profile[val];
    });

    return retRoles;

}
