import { AppAction, FavouritesState } from '../../../interfaces/store.interface';
import {
    castToBoolean, paginatedAsyncSuccessReducer, pushArrayItem, pushArrayItems, removeArrayItem,
    sortParamsToString
} from './helpers';
import { FavouritesActionsService } from '../actions/favourites.actions';
import { CONFIG } from '../../shared/config/config.const';

const INITIAL_STATE: FavouritesState = {
    totalFavourites: 0,
    defaultParams: {
        // populated by SET_AREA
    },
    params: {
        pageIndex: 0,
        pageSize: CONFIG.PAGINATION.PAGE_SIZE,
    },
    results: {
        entity: 'favourites',
        payload: {
            pageIndex: -1,
            pageSize: CONFIG.PAGINATION.PAGE_SIZE,
            result: [],
            totalCount: -1,
            totalPages: -1
        },
        loadedCount: 0, // how many results have actually been loaded?
        loading: false,
        loadingMore: false,
        lastLoad: new Date(0),
        error: null,
        hasError: false,
        hasNextPage: false
    }
};

export default function favouritesReducer(state: FavouritesState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * FAVOURITES
         *
         */


        case FavouritesActionsService.FAVOURITES_SUCCESS: {
            if (action.payload.response.result.length > 0) {
                action.payload.response.result = action.payload.response.result.map((result) => {
                    const retVal = result.sellingItem;
                    retVal.isAvailable = result.isAvailable;
                    return retVal;
                });
            }

            return paginatedAsyncSuccessReducer('results')(state, action.payload.response);
        }

        case FavouritesActionsService.SET_PARAMS: {
            const nextState = {
                ...state,
                params: {
                    ...state.defaultParams,
                    // always start from first page
                    pageIndex: 0,
                    /**
                     *
                     * MERGE IN THE PAYLOAD VALUES
                     *
                     */
                    ...castToBoolean(action.payload)
                }
            };

            return nextState;
        }

        case FavouritesActionsService.SET_PARAMS_NEXT_PAGE: {
            const nextState = {
                ...state,
                params: {
                    ...state.params,
                    // increment the page index
                    pageIndex: state.params.pageIndex + 1
                }
            };

            return nextState;
        }

        /**
         *
         * REMOVE VEHICLE FROM RESULTS
         *
         */

        case FavouritesActionsService.REMOVE_FAVOURITE_FROM_RESULTS: {
            const nextState = {
                ...state
            };

            const sellingItemId = action.payload.sellingItemId;

            const index = nextState.results.payload.result.indexOf(sellingItemId);

            // check the result exists
            if (index > -1) {

                nextState.results = {
                    ...state.results,
                    payload: {
                        ...state.results.payload,
                        result: [
                            ...state.results.payload.result.slice(0, index),
                            CONFIG.PAGINATION.REMOVED_FLAG, // rather than actually remove the item we simply hide it (to prevent issues with pagination)
                            ...state.results.payload.result.slice(index + 1)
                        ]
                    }
                };

                // we also need to reduce the total count
                nextState.results.payload.totalCount = Math.max(nextState.results.payload.totalCount - 1, 0);

                // Note - the API doesn't allow us to load items from a specific index, only by pageIndex
                // meaning we can't fetch items 7-10 (for example) to fill the removed spaces

            }

            return nextState;
        }

    }

    return state;

}
