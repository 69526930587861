import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-cart-expired',
  templateUrl: './modal-cart-expired.component.html',
  // styleUrls: ['./modal-cart-expired.component.scss']
})
export class ModalCartExpiredComponent {
    constructor (public activeModal: NgbActiveModal) {}
}
