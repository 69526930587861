import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, concat, empty } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';

import { CmsMenuActionsService } from '../actions/cms-menu.actions';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { switchMap, catchError } from 'rxjs/operators';
import { UserActionsService } from '../actions/user.actions';

@Injectable()
export class CmsMenuEpicsService {

    constructor(
        private http: HttpClient,
        private cmsMenuActions: CmsMenuActionsService
    ) { }

    /**
     *
     * MENU
     *
     */

    fetchMenu = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(CmsMenuActionsService.FETCH_MENU).pipe(
            switchMap(({
                payload
            }) => {

                // only load it once
                if (state$.value.cmsMenu.menuTree.length > 0) {
                    return empty();
                }

                const url = '/CMS/GetCMSMenu';

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: CmsMenuActionsService.MENU_LOADING,
                        payload: null
                    }),
                    // this.http.get(url)
                    of(FAKE_RESPONSE_TREES_DATA_PUBLISHED_EMPTY)
                        .pipe(
                            switchMap((response: any) => {

                                return of({
                                    type: CmsMenuActionsService.MENU_SUCCESS,
                                    payload: response
                                });
                            }),
                            catchError(error => of({
                                type: CmsMenuActionsService.MENU_ERROR,
                                error
                            }))
                        ));
            }));
    }

    menuSuccess = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(CmsMenuActionsService.MENU_SUCCESS).pipe(
            switchMap(({
                payload
            }) => {
                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: UserActionsService.LOGGED_IN,
                        payload: null
                    }),
                    of({
                        type: UserActionsService.READY,
                        payload: null
                    }));
            }));
    }

}

const FAKE_RESPONSE_TREES_DATA_PUBLISHED = [
    {
        name: 'child3_it',
        order: 1,
        pageId: 1,
        isDraft: true
    },
    {
        name: 'Menu1_it',
        children: [
            {
                name: 'child1_it',
                order: 1,
                pageId: 1,
                isDraft: false
            },
            {
                name: 'sub1_it',
                children: [
                    {
                        name: 'child2_it',
                        order: 1,
                        pageId: 2,
                        isDraft: false
                    },
                ],
                order: 2,
                isDraft: false
            }
        ],
        order: 2,
        isDraft: false
    },
    {
        name: 'Menu2_it',
        children: [
            {
                name: 'child3_it',
                order: 1,
                pageId: 3,
                isDraft: true
            },
        ],
        order: 3,
        isDraft: false
    }
];

const FAKE_RESPONSE_TREES_DATA_PUBLISHED_EMPTY = [];

