import { IDetailedVehicle } from '../../../interfaces/Vehicle.interface';

export class Vehicle {

    id: string;
    props: IDetailedVehicle;

    constructor(data: IDetailedVehicle, overrides: any = {}) {
        // set the ID
        this.id = data.id;

        // set the props
        this.props = {
            // set some defaults
            // _images: [],
            // _thumbnails: [],
            // optionals: [],
            // _optionalsTable: [],
            // now merge in the real data
            ...data,
            // plus any overrides
            ...overrides
        };

        // car title
        this.props._vehicleTitle =
            overrides.title ?
                overrides.title :
                `${data.brand} ${data.model ? data.model : data.series}`;

        //     //
        //     // Parse optionals
        //     //

        //     let row: { code: string, text: string }[] = [];
        //     let hasWinterPack = false;

        //     this.props.optionals.forEach((v: any) => {

        //         const opt = {
        //             code: v.code,
        //             text: v.description
        //         };

        //         if (row.length === 2) {
        //             // push row
        //             this.props._optionalsTable.push(row);
        //             // empty it
        //             row = [];
        //         }

        //         // check for winter pack
        //         if (v.code === 'G01') {
        //             hasWinterPack = true;
        //         }

        //         row.push(opt);

        //     });

        //     // save result to car
        //     this.props._hasWinterPack = hasWinterPack;

        //     if (row.length) {
        //         // push the last row
        //         this.props._optionalsTable.push(row);
        //     }

        //     //
        //     // Conditional series
        //     //

        //     if (
        //         this.props.series &&
        //         this.props.manufacturerName &&
        //         this.props.version &&
        //         (
        //             this.props.series.toUpperCase() === this.props.manufacturerName.toUpperCase() ||
        //             this.props.series.toUpperCase() === this.props.version.toUpperCase()
        //         )
        //     ) {
        //         this.props._conditionalSeries = '';
        //     } else {
        //         this.props._conditionalSeries = ' ' + this.props.series + ' ';
        //     }

        //     //
        //     // Brand / Series for comparison
        //     //

        //     this.props._brand = (this.props.manufacturerName || '').toUpperCase() || null;
        //     this.props._series = (this.props.series || '').toUpperCase() || null;
    }
}
