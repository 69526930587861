import { AppAction, SellingAreasState } from '../../../interfaces/store.interface';
import { CONFIG } from '../../shared/config/config.const';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { paginatedAsyncSuccessReducer } from './helpers';

const INITIAL_STATE: SellingAreasState = {
    results: {
        entity: 'sellingAreas',
        payload: {
            pageIndex: -1,
            pageSize: CONFIG.PAGINATION.PAGE_SIZE,
            result: [],
            totalCount: -1,
            totalPages: -1
        },
        loadedCount: 0, // how many results have actually been loaded?
        loading: false,
        loadingMore: false,
        lastLoad: new Date(0),
        error: null,
        hasError: false,
        hasNextPage: false
    }
};

export default function sellingAreasReducer (state: SellingAreasState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        case SellingAreasActionsService.FETCH_SELLING_AREAS_SUCCESS: {
            return paginatedAsyncSuccessReducer('results')(state, action.payload);
        }

    }

    return state;

}
