/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-vehicle-unavailable.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./modal-vehicle-unavailable.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalVehicleUnavailableComponent = [i0.styles];
var RenderType_ModalVehicleUnavailableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalVehicleUnavailableComponent, data: {} });
export { RenderType_ModalVehicleUnavailableComponent as RenderType_ModalVehicleUnavailableComponent };
export function View_ModalVehicleUnavailableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "modal__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "modal__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", "."])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Modals.UnavailableSellingItem.ModalTitle")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Modals.UnavailableSellingItem.ModalContent")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("Commons.Ok")); _ck(_v, 11, 0, currVal_2); }); }
export function View_ModalVehicleUnavailableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-vehicle-unavailable", [], null, null, null, View_ModalVehicleUnavailableComponent_0, RenderType_ModalVehicleUnavailableComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalVehicleUnavailableComponent, [i4.NgbActiveModal], null, null)], null, null); }
var ModalVehicleUnavailableComponentNgFactory = i1.ɵccf("app-modal-vehicle-unavailable", i3.ModalVehicleUnavailableComponent, View_ModalVehicleUnavailableComponent_Host_0, {}, {}, []);
export { ModalVehicleUnavailableComponentNgFactory as ModalVehicleUnavailableComponentNgFactory };
