import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { dispatch } from '@angular-redux/store';
var ScrollActionsService = /** @class */ (function () {
    function ScrollActionsService() {
        /**
         *
         * SETTINGS
         *
         */
        this.scrollTo = function (payload) { return ({
            type: ScrollActionsService.SCROLL_TO,
            payload: payload
        }); };
        this.scrollToTop = function () { return ({
            type: ScrollActionsService.SCROLL_TO_TOP
        }); };
        this.refresh = function () { return ({
            type: ScrollActionsService.REFRESH
        }); };
        this.unhook = function () { return ({
            type: ScrollActionsService.UNHOOK
        }); };
        this.lock = function () { return ({
            type: ScrollActionsService.LOCK
        }); };
        this.unlock = function () { return ({
            type: ScrollActionsService.UNLOCK
        }); };
        this.registerInview = function (payload) { return ({
            type: ScrollActionsService.REGISTER_INVIEW,
            payload: payload
        }); };
        this.deregisterInview = function (payload) { return ({
            type: ScrollActionsService.DEREGISTER_INVIEW,
            payload: payload
        }); };
        this.setInview = function (payload) { return ({
            type: ScrollActionsService.SET_INVIEW,
            payload: payload
        }); };
    }
    ScrollActionsService.SCROLL_TO = '@scroll/SCROLL_TO';
    ScrollActionsService.SCROLL_TO_TOP = '@scroll/SCROLL_TO_TOP';
    ScrollActionsService.REFRESH = '@scroll/REFRESH';
    ScrollActionsService.UNHOOK = '@scroll/UNHOOK';
    ScrollActionsService.LOCK = '@scroll/LOCK';
    ScrollActionsService.UNLOCK = '@scroll/UNLOCK';
    ScrollActionsService.REGISTER_INVIEW = '@scroll/REGISTER_INVIEW';
    ScrollActionsService.SET_INVIEW = '@scroll/SET_INVIEW';
    ScrollActionsService.DEREGISTER_INVIEW = '@scroll/DEREGISTER_INVIEW';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "scrollTo", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "scrollToTop", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "refresh", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "unhook", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "lock", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "unlock", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "registerInview", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "deregisterInview", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ScrollActionsService.prototype, "setInview", void 0);
    return ScrollActionsService;
}());
export { ScrollActionsService };
