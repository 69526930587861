import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { CartValidity } from '../../../../interfaces/cart.interface';

@Component({
  selector: 'app-sales-cart-validity',
  templateUrl: './sales-cart-validity.component.html',
  // styleUrls: ['./sales-cart-validity.component.scss']
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesCartValidityComponent {
  @Input() validity: CartValidity;
  @Input() showDescription: boolean;
  @Input() expiry: string;
}
