import { AfterViewInit, ElementRef, OnInit, QueryList, Renderer2, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SalesActionsService } from '../../../store/actions/sales.actions';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { OrderActionsService } from '../../../store/actions/order.actions';
import { NgRedux } from '@angular-redux/store';
var BundleThumbsComponent = /** @class */ (function () {
    function BundleThumbsComponent(renderer, salesActions, ordersActions, changeDetector, ngRedux) {
        var _this = this;
        this.renderer = renderer;
        this.salesActions = salesActions;
        this.ordersActions = ordersActions;
        this.changeDetector = changeDetector;
        this.ngRedux = ngRedux;
        this.activeCarChange = new EventEmitter();
        this.currentActive = 0;
        this.index = 0;
        this.thumbWidth = 156;
        this.groupSize = 3;
        this.groupWidth = this.thumbWidth * this.groupSize;
        this.width = 0;
        this.currentParentWidth = 0;
        this.resizeSubscription = Subscription.EMPTY;
        this.selectedIndexSubscription = Subscription.EMPTY;
        this.resizeSubscription = fromEvent(window, 'resize').pipe(debounceTime(500), distinctUntilChanged()).subscribe(function (event) {
            if (!_this.container) {
                return;
            }
            _this.recalculateWidth();
        });
    }
    Object.defineProperty(BundleThumbsComponent.prototype, "thumbElements", {
        set: function (el) {
            if (el) {
                this.thumbs = el;
                this.recalculateWidth();
            }
        },
        enumerable: true,
        configurable: true
    });
    BundleThumbsComponent.prototype.ngOnInit = function () {
        // disable / enable controls
        this.checkControls();
        if (this.init) {
            this.activateBundleCar(0);
        }
        // set the style
        var style = 'translate3d(' + (0 - (this.groupWidth * this.index)) + 'px, 0, 0)';
        if (this.container) {
            this.renderer.setStyle(this.bundle.nativeElement, 'transform', style);
        }
    };
    BundleThumbsComponent.prototype.ngOnChanges = function (changes) {
    };
    BundleThumbsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        window.setTimeout(function () {
            _this.recalculateWidth();
        });
        this.selectedIndexSubscription = this.ngRedux.select(['sales', 'selected', 'index'])
            .pipe(filter(function (index) { return index === -1; })).subscribe(function () {
            setTimeout(function () {
                _this.recalculateWidth();
            }, 100);
        });
    };
    BundleThumbsComponent.prototype.ngOnDestroy = function () {
        this.resizeSubscription.unsubscribe();
        this.selectedIndexSubscription.unsubscribe();
    };
    BundleThumbsComponent.prototype.next = function () {
        if (!this.nextDisabled) {
            this.change(1);
        }
    };
    BundleThumbsComponent.prototype.previous = function () {
        if (!this.previousDisabled) {
            this.change(-1);
        }
    };
    BundleThumbsComponent.prototype.activateBundleCar = function (index) {
        //    console.log('activateBundleCar:' + index, this.cars);
        if (this.context === 'orders') {
            this.ordersActions.setSelectedBundleIndex(index);
        }
        else {
            this.salesActions.setSelectedBundleIndex(index);
        }
        this.currentActive = index;
        this.activeCarChange.emit(index);
    };
    BundleThumbsComponent.prototype.recalculateWidth = function () {
        if (this.container) {
            var parentWidth = this.container.nativeElement.offsetWidth;
            // if (parentWidth === 0) {
            //     setTimeout(() => {
            //         this.recalculateWidth();
            //     }, 200);
            // }
            if (this.currentParentWidth !== parentWidth) {
                this.currentParentWidth = parentWidth;
            }
        }
        if (this.thumbs) {
            this.maxIndex = this.thumbs.toArray().length - 3;
            this.setThumbWidth();
        }
    };
    BundleThumbsComponent.prototype.change = function (direction) {
        this.index = this.index + direction;
        this.checkControls();
        var style = 'translate3d(' + (0 - (this.width * this.index)) + 'px, 0, 0)';
        this.renderer.setStyle(this.bundle.nativeElement, 'transform', style);
    };
    BundleThumbsComponent.prototype.setThumbWidth = function () {
        var _this = this;
        var parent = this.container.nativeElement;
        var thumbs = this.thumbs.toArray();
        //    console.log(thumbs);
        var width = thumbs.length > 2
            ? thumbs.length === 3
                ? (parent.offsetWidth - 30) / 3
                : parent.offsetWidth / 3
            : (parent.offsetWidth - 30) / 2;
        this.width = Math.round(width * 100) / 100;
        thumbs.forEach(function (thumb) { return _this.renderer.setStyle(thumb.nativeElement, 'width', _this.width + "px"); });
    };
    BundleThumbsComponent.prototype.checkControls = function () {
        this.nextDisabled = this.index === this.maxIndex;
        this.previousDisabled = this.index === 0;
    };
    return BundleThumbsComponent;
}());
export { BundleThumbsComponent };
