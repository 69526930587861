import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var TenantActionsService = /** @class */ (function () {
    function TenantActionsService() {
        /**
         *
         * LOADING TENANT CONFIG
         *
         */
        this.loadTenantConfiguration = function () { return ({
            type: TenantActionsService.LOAD_TENANT_CONFIG
        }); };
    }
    TenantActionsService.LOAD_TENANT_CONFIG = '@tenant/LOAD_TENANT_CONFIG';
    TenantActionsService.TENANT_CONFIG_LOADING = '@tenant/TENANT_CONFIG_LOADING';
    TenantActionsService.TENANT_CONFIG_LOADED = '@tenant/TENANT_CONFIG_LOADED';
    TenantActionsService.TENANT_CONFIG_LOAD_ERROR = '@tenant/TENANT_CONFIG_LOAD_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], TenantActionsService.prototype, "loadTenantConfiguration", void 0);
    return TenantActionsService;
}());
export { TenantActionsService };
