import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-listing-days-of-visibility',
    templateUrl: './listing-days-of-visibility.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingDaysOfVisibilityComponent {
    visibilityDays: number;
    _hideAt: string;

    @Input() showAt: number;

    @Input() set hideAt(value: string) {
        const date1 = new Date(value);
        const date2 = new Date();
        const timeDiff = date1.getTime() - date2.getTime();
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.visibilityDays = diffDays;
        this._hideAt = value;
    }
}
