import * as tslib_1 from "tslib";
import { ScrollActionsService } from '../actions/scroll.actions';
var INITIAL_STATE = {
    scrolledTo: null,
    locked: false,
    refresh: 0,
    unhook: 0,
    scrollToTop: 0,
    inview: {}
};
export default function scrollReducer(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        /**
         *
         * SCROLL TO
         *
         */
        case ScrollActionsService.SCROLL_TO: {
            var nextState = tslib_1.__assign({}, state, { scrolledTo: action.payload });
            return nextState;
        }
        /**
         *
         * REFRESH
         *
         */
        case ScrollActionsService.REFRESH: {
            var nextState = tslib_1.__assign({}, state, { 
                // increment refresh to trigger refresh
                refresh: state.refresh + 1 });
            return nextState;
        }
        case ScrollActionsService.UNHOOK: {
            var nextState = tslib_1.__assign({}, state, { 
                // increment unhook to trigger unhook
                unhook: state.unhook + 1 });
            return nextState;
        }
        case ScrollActionsService.SCROLL_TO_TOP: {
            var nextState = tslib_1.__assign({}, state, { 
                // increment scrollToTop
                scrollToTop: state.scrollToTop + 1 });
            return nextState;
        }
        case ScrollActionsService.LOCK: {
            var nextState = tslib_1.__assign({}, state, { locked: true });
            return nextState;
        }
        case ScrollActionsService.UNLOCK: {
            var nextState = tslib_1.__assign({}, state, { locked: false });
            return nextState;
        }
        case ScrollActionsService.REGISTER_INVIEW: {
            var nextState = tslib_1.__assign({}, state, { inview: tslib_1.__assign({}, state.inview, (_a = {}, _a[action.payload.id] = {
                    visible: action.payload.visible,
                    element: action.payload.element
                }, _a)) });
            return nextState;
        }
        case ScrollActionsService.DEREGISTER_INVIEW: {
            var nextState = tslib_1.__assign({}, state);
            // flag as invisibile and delete reference to element
            if (nextState.inview[action.payload.id]) {
                nextState.inview = tslib_1.__assign({}, state.inview, (_b = {}, _b[action.payload.id] = {
                    visible: false
                }, _b));
            }
            return nextState;
        }
        case ScrollActionsService.SET_INVIEW: {
            var nextState = tslib_1.__assign({}, state);
            if (nextState.inview[action.payload.id]) {
                nextState.inview = tslib_1.__assign({}, state.inview, (_c = {}, _c[action.payload.id] = tslib_1.__assign({}, state.inview[action.payload.id], { visible: action.payload.visible }), _c));
            }
            return nextState;
        }
    }
    return state;
}
