import * as tslib_1 from "tslib";
import { OnChanges, SimpleChanges, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { FavouritesActionsService } from '../../../../store/actions/favourites.actions';
import { UserType } from '../../../../../interfaces/user.interface';
var ListingInfoComponent = /** @class */ (function () {
    function ListingInfoComponent(favouritesActions, changeDetector) {
        this.favouritesActions = favouritesActions;
        this.changeDetector = changeDetector;
        this.bundleVehicles = [];
        this.userTypes = UserType;
    }
    ListingInfoComponent.prototype.ngOnChanges = function (changes) {
        if (this.sellingItem && this.sellingItem._isAuction) {
            var bid = Math.max(this.sellingItem.auctionInfo.bestOffer || 0, this.sellingItem.auctionInfo.maxOffer || 0, this.sellingItem.auctionInfo.basePrice || 0);
            // include minimum raise if there has already been a bid
            if (this.sellingItem.auctionInfo.bestOffer || 0 > 0) {
                bid = bid + this.sellingItem.auctionInfo.bidStep;
            }
            this.auctionValueToShow = bid;
            if (!this.changeDetector.destroyed) {
                this.changeDetector.detectChanges();
            }
        }
    };
    ListingInfoComponent.prototype.setActiveCar = function (index) {
    };
    ListingInfoComponent.prototype.toggleFavourite = function ($event) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        var params = {
            sellingItemId: this.sellingItem.id,
            enable: !this.sellingItem.isFavourite
        };
        this.favouritesActions.toggleFavourite(params);
    };
    tslib_1.__decorate([
        select(['user', 'identity', 'payload', 'profile', 'user_type']),
        tslib_1.__metadata("design:type", Observable)
    ], ListingInfoComponent.prototype, "userType$", void 0);
    return ListingInfoComponent;
}());
export { ListingInfoComponent };
