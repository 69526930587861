// import * as moment from 'moment'; - added globally to prevent all locales being included

import { AppAction, CartValidationState } from '../../../interfaces/store.interface';
import {
    CartConfigSettings,
    CartContents,
    CartConfigDependency,
    CartConfigDependencySelector,
    CartConfigRestriction,
    CartConfigSeriesRestriction,
    CartValidationMessage
} from '../../../interfaces/cart.interface';

import { CartValidationActionsService } from '../actions/cart-validation.actions';
import { SalesActionsService } from '../actions/sales.actions';

const INITIAL_STATE: CartValidationState = {
    validity: {
        messages: [],
        action: null,
        hasErrors: false,
        hasWarnings: false,
        hasVisible: false
    },
    config: {
        loading: false,
        hasError: false,
        error: null,
        expiry: null,
        settings: null
    }
};

export default function cartReducer(state: CartValidationState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * CART VALIDATION
         *
         */


        case CartValidationActionsService.SET_VALIDITY: {

            const nextState = {
                ...state,
                // reset state
                validity: {
                    ...INITIAL_STATE.validity,
                    messages: action.payload.messages.slice()
                }
            };

            // calculate if we have errors
            nextState.validity.hasErrors = nextState.validity.messages.filter((message: CartValidationMessage) => {
                return message.status === 2;
            }).length > 0;

            // calculate if we have warnings
            nextState.validity.hasWarnings = nextState.validity.messages.filter((message: CartValidationMessage) => {
                return message.status === 1;
            }).length > 0;

            // sort them into priority order
            nextState.validity.messages = nextState.validity.messages.sort((a, b) => {
                if (a.status < b.status) { return 1; }
                if (a.status > b.status) { return -1; }
                return 0;
            });

            // check if we have any visible errors or warnings
            nextState.validity.hasVisible = nextState.validity.messages.some((message: CartValidationMessage) => {
                return message._visible && message.status > 0;
            });

            // actions
            // default to null, overwritten below
            nextState.validity.action = {
                redirectTo: null
            };
            // if I just added something && there is no error && there is some warning (the limit 1 is a warning)
            if (action.payload.fromQueue && !nextState.validity.hasErrors && nextState.validity.hasWarnings) {
                const found = action.payload.messages.find((message: CartValidationMessage) => {
                    // warning message for max count reached found!
                    return message.scope === 'GLOBAL' &&
                        message.name === 'COUNT' &&
                        message.status === 1;
                });
                if (found) {
                    nextState.validity.action = {
                        redirectTo: 'cart'
                    };
                }
            }

            return nextState;

        }


        /**
         *
         * CART CONFIG
         *
         */

        case SalesActionsService.SET_AREA: {

            const nextState = {
                ...INITIAL_STATE
            };

            return nextState;

        }


        case CartValidationActionsService.CART_CONFIG_LOADING: {

            const nextState = {
                ...state,
                // loading means we should clear the existing values
                config: {
                    ...INITIAL_STATE.config,
                    loading: true
                },
                validity: {
                    ...INITIAL_STATE.validity
                }
            };

            return nextState;

        }

        case CartValidationActionsService.CART_CONFIG_SUCCESS: {

            const updateSalesOnly = !!action.payload.updateSalesOnly;
            if (updateSalesOnly) {
                return { ...state };
            }

            const settings = {
                basket: {
                    ...action.payload.response
                }
            } as CartConfigSettings;

            // map (rename) values
            settings.basket.dependencies = (settings.basket.dependencies || []).map((dependency: CartConfigDependency) => {

                // applyTo
                if (dependency.applyTo) {
                    dependency.applyTo.vehicleIds = dependency.applyTo.vehicleIds || dependency.applyTo.carIds;
                }

                // requirements
                dependency.requires = (dependency.requires || []).map((selector: CartConfigDependencySelector) => {
                    // rename property
                    selector.vehicleIds = selector.vehicleIds || selector.carIds;
                    return selector;
                });

                return dependency;
            });

            // map values so we always have uppercase for comparison
            settings.basket.restrictions = (settings.basket.restrictions || [].map((restriction: CartConfigRestriction) => {
                restriction._brand = (restriction.brand ? restriction.brand.trim() : '').toUpperCase() || null;
                restriction.series = (restriction.series || []).map((series: CartConfigSeriesRestriction) => {
                    series._series = (series.name ? series.name.trim() : '').toUpperCase() || null;
                    return series;
                });
                return restriction;
            }));

            const expiry = settings.expireAt ? moment(settings.expireAt).valueOf() : moment().add(30, 'minutes');

            const nextState = {
                ...state,
                config: {
                    ...INITIAL_STATE.config,
                    expiry,
                    settings
                }
            };

            return nextState;

        }

        case CartValidationActionsService.CART_CONFIG_ERROR: {

            const nextState = {
                ...state,
                config: {
                    ...INITIAL_STATE.config,
                    hasError: true,
                    error: action.error
                }
            };

            return nextState;

        }

        case CartValidationActionsService.CART_CONFIG_EXPIRED: {

            const nextState = {
                ...state,
                config: {
                    ...INITIAL_STATE.config,
                }
            };

            return nextState;

        }
    }

    return state;

}
