import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { FavouritesActionsService } from '../actions/favourites.actions';
import { concat, of, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../../../interfaces/store.interface';
import { ModalService } from '../../shared/services/modal/modal.service';
import { UserService } from '../../shared/services/user/user.service';
import { switchMap, catchError } from 'rxjs/operators';
import { ScrollActionsService } from '../actions/scroll.actions';


@Injectable()
export class FavouritesEpicsService {

  constructor(
    private http: HttpClient,
    private modal: ModalService,
    private user: UserService,
  ) { }

  setParams = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

    return action$.ofType(
      FavouritesActionsService.SET_PARAMS,
      FavouritesActionsService.SET_PARAMS_NEXT_PAGE
    ).pipe(
      switchMap(({
        type
      }) => {

        if (type === FavouritesActionsService.SET_PARAMS) {
          // we need to reset the scroll
          return of(
            {
              type: ScrollActionsService.SCROLL_TO,
              payload: null
            }, {
              type: FavouritesActionsService.FETCH_FAVOURITES,
              payload: null
            }
          );
        }

        return of({
          type: FavouritesActionsService.FETCH_FAVOURITES
        });

      }));

  }

  /**
   *
   * FAVOURITES
   *
   */


  fetchFavourites = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

    return action$.ofType(FavouritesActionsService.FETCH_FAVOURITES).pipe(
      switchMap(({
        payload
      }) => {

        const favouritesState = state$.value.favourites;

        let query = new HttpParams();

        // apply all values to the query string
        Object.keys(favouritesState.params).forEach((key: string) => {
          if (favouritesState.params[key] || favouritesState.params[key] === 0) {
            query = query.append(key, favouritesState.params[key].toString());
          }
        });

        // use concat so these actions happen sequentially
        return concat(
          of({
            type: favouritesState.params.pageIndex > 0 ?
              FavouritesActionsService.FAVOURITES_LOADING_MORE : FavouritesActionsService.FAVOURITES_LOADING,
            payload: null
          }),
          this.http.get('/Favourites/List', { params: query }).pipe(
            switchMap((response: any) => {
              return of(
                {
                  type: FavouritesActionsService.FAVOURITES_SUCCESS,
                  payload: {
                    response
                  }
                });
            }),
            catchError(error => of({
              type: FavouritesActionsService.FAVOURITES_ERROR,
              error
            })))
        );

      }));

  }

  toggleFavourite = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

    return action$.ofType(FavouritesActionsService.TOGGLE_FAVOURITE).pipe(
      switchMap(({
        payload
      }) => {

        // const sellingItem = state$.value.entities.sellingItems[payload.sellingItemId];

        const params = new HttpParams({
          fromObject: {
            id: payload.sellingItemId
          }
        });

        // fake response
        // return of({}).pipe(

        let call;
        if (payload.enable) {
          call = this.http.post('/Favourites/Add', {}, {
            params
          });
        } else {
          call = this.http.delete('/Favourites/Remove', {
            params
          });
        }

        return call.pipe(
          switchMap(payload2 => of({
            type: FavouritesActionsService.TOGGLE_FAVOURITE_SUCCESS,
            payload: payload
          })),
          catchError(err => of({
            type: FavouritesActionsService.TOGGLE_FAVOURITE_ERROR,
            payload
          })));

      }));

  }
}
