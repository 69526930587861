import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-datepicker',
  templateUrl: './modal-datepicker.component.html',
  styleUrls: ['./modal-datepicker.component.scss']
})
export class ModalDatepickerComponent {

    @Input() model: NgbDateStruct;

    @Input() minDate: NgbDateStruct;
    @Input() maxDate: NgbDateStruct;

    constructor (public activeModal: NgbActiveModal) { }

    onDateChange (date: NgbDateStruct) {
        // close the modal with the selected date
        this.activeModal.close(date);
    }

    cancel () {
        // clear value and close
        this.activeModal.close(null);
    }

    setToday () {
        const now = new Date();

        this.activeModal.close({
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            day: now.getDate()
        });
    }

}
