import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var ExceptionActionsService = /** @class */ (function () {
    function ExceptionActionsService() {
        this.showExceptionModal = function (error) { return ({
            type: ExceptionActionsService.SHOW_EXCEPTION_MODAL,
            error: error
        }); };
    }
    ExceptionActionsService.SHOW_EXCEPTION_MODAL = '@exception/SHOW_EXCEPTION_MODAL';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], ExceptionActionsService.prototype, "showExceptionModal", void 0);
    return ExceptionActionsService;
}());
export { ExceptionActionsService };
