import { NgModule } from '@angular/core';

// Angular-redux ecosystem stuff.
// @angular-redux/form and @angular-redux/router are optional
// extensions that sync form and route location state between
// our store and Angular.
import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';

// Redux ecosystem stuff.
import { createLogger } from 'redux-logger';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import { environment } from '../../environments/environment';

let middleware = [];

// do this up here to it can be removed during the build process
// if (!environment.production) {
//     const logger = require('redux-logger');
//     middleware.push(logger.createLogger());
// }

import { SharedModule } from '../shared/shared.module';

import { AppState } from '../../interfaces/store.interface';

// ACTIONS
import { TenantActionsService } from './actions/tenant.actions';
import { AuctionActionsService } from './actions/auction.actions';
import { CartActionsService } from './actions/cart.actions';
import { CartValidationActionsService } from './actions/cart-validation.actions';
import { EntitiesActionsService } from './actions/entities.actions';
import { ExceptionActionsService } from './actions/exception.actions';
import { FavouritesActionsService } from './actions/favourites.actions';
import { OrderActionsService } from './actions/order.actions';
import { SalesActionsService } from './actions/sales.actions';
import { ScrollActionsService } from './actions/scroll.actions';
import { StatisticsActionsService } from './actions/statistics.actions';
import { UserActionsService } from './actions/user.actions';
import { UiActionsService } from './actions/ui.actions';
import { CmsMenuActionsService } from './actions/cms-menu.actions';
import { CmsPagesActionsService } from './actions/cms-pages.actions';
import { SellingAreasActionsService } from './actions/selling-areas.actions';
import { MessagesActionsService } from './actions/messages.actions';
import { UtilitiesActionsService } from './actions/utilities.actions';

// EPICS
import { TenantEpicsService } from './epics/tenant.epics';
import { AuctionEpicsService } from './epics/auction.epics';
import { CartEpicsService } from './epics/cart.epics';
import { CartValidationEpicsService } from './epics/cart-validation.epics';
import { ExceptionEpicsService } from './epics/exception.epics';
import { FavouritesEpicsService } from './epics/favourites.epics';
import { OrderEpicsService } from './epics/order.epics';
import { SalesEpicsService } from './epics/sales.epics';
import { StatisticsEpicsService } from './epics/statistics.epics';
import { UiEpicsService } from './epics/ui.epics';
import { UserEpicsService } from './epics/user.epics';
import { CmsMenuEpicsService } from './epics/cms-menu.epics';
import { CmsPagesEpicsService } from './epics/cms-page.epics';
import { SellingAreasEpicsService } from './epics/selling-areas.epics';
import { MessagesEpicsService } from './epics/messages.epics';
import { UtilitiesEpicsService } from './epics/utilities.epics';

// SERVICES
import { CartValidationService } from './services/cart-validation/cart-validation.service';
import { UserManagerService } from './services/user-manager/user-manager.service';
import { GoogleAnalyticsService } from '../shared/services/google-analytics/google-analytics.service';

import { rootReducer } from './store.reducers';
import { BootingEpicsService } from './epics/booting.epics';
import { BootingActionsService } from './actions/booting.actions';

@NgModule({
    imports: [
        NgReduxModule,
        SharedModule
    ],
    providers: [
        // ACTIONS
        BootingActionsService,
        TenantActionsService,
        AuctionActionsService,
        CartActionsService,
        CartValidationActionsService,
        EntitiesActionsService,
        ExceptionActionsService,
        FavouritesActionsService,
        OrderActionsService,
        SalesActionsService,
        ScrollActionsService,
        StatisticsActionsService,
        MessagesActionsService,
        UiActionsService,
        UserActionsService,
        SellingAreasActionsService,
        CmsMenuActionsService,
        CmsPagesActionsService,
        UtilitiesActionsService,
        // EPICS
        BootingEpicsService,
        TenantEpicsService,
        AuctionEpicsService,
        CartEpicsService,
        CartValidationEpicsService,
        ExceptionEpicsService,
        FavouritesEpicsService,
        OrderEpicsService,
        SalesEpicsService,
        SellingAreasEpicsService,
        StatisticsEpicsService,
        UiEpicsService,
        MessagesEpicsService,
        UserEpicsService,
        CmsMenuEpicsService,
        CmsPagesEpicsService,
        UtilitiesEpicsService,
        // SERVICES
        CartValidationService,
        UserManagerService
    ]
})
export class StoreModule {
    constructor(
        // EPICS
        bootingEpics: BootingEpicsService,
        tenantEpics: TenantEpicsService,
        auctionEpics: AuctionEpicsService,
        cartEpics: CartEpicsService,
        cartValidationEpics: CartValidationEpicsService,
        exceptionEpics: ExceptionEpicsService,
        favouriteEpics: FavouritesEpicsService,
        orderEpics: OrderEpicsService,
        salesEpics: SalesEpicsService,
        sellingAreasEpics: SellingAreasEpicsService,
        statisticsEpics: StatisticsEpicsService,
        uiEpics: UiEpicsService,
        messagesEpics: MessagesEpicsService,
        userEpics: UserEpicsService,
        cmsMenuEpics: CmsMenuEpicsService,
        cmsPagesEpics: CmsPagesEpicsService,
        utilitiesEpics: UtilitiesEpicsService,
        // SERVICS
        cartValidation: CartValidationService,
        googleAnalytics: GoogleAnalyticsService,
        // OTHER
        ngRedux: NgRedux<AppState>,
        devTools: DevToolsExtension
    ) {

        const epicMiddleware = createEpicMiddleware(
            {
                dependencies: {
                    cartValidation,
                    googleAnalytics
                }
            }
        );
        const rootEpic = combineEpics(

            bootingEpics.loadUser,
            bootingEpics.fetchIdentity,
            bootingEpics.fetchProfile,
            bootingEpics.loadTenantConfiguration,

            tenantEpics.loadTenantConfiguration,

            //    auctionEpics.fetchAuctionConfig,
            auctionEpics.fetchAuctionById,
            auctionEpics.makeOfferConfirm,
            auctionEpics.makeOffer,
            auctionEpics.makeOfferSuccess,

            cartEpics.fetchCart,
            cartEpics.cartSuccess,
            cartEpics.cartExpired,
            cartEpics.addToCartConfirm, // <-- this one opens a confirmation modal
            cartEpics.addToCartQueue, // <-- this one validates the cart
            cartEpics.addToCart, // <-- this one makes the API request
            cartEpics.addToCartFromQueue, // <-- this one gets the first item in the queue and adds it

            cartEpics.checkoutCartConfirm,
            cartEpics.checkoutCart,
            cartEpics.showCheckoutErrorModal,

            cartEpics.removeFromCart,
            cartEpics.removeFromCartSuccess,
            cartEpics.deleteCart,

            cartEpics.fetchPaymentMethods,
            cartEpics.fetchCartBranches,

            cartValidationEpics.cartConfigExpired,
            cartValidationEpics.cartConfigSuccess,
            cartValidationEpics.fetchCartConfig,
            cartValidationEpics.validateCart as any, // uses additional dependency
            cartValidationEpics.validateCartQueue as any, // uses additional dependency
            cartValidationEpics.showValidationResult,
            // cartValidationEpics.cartValidityFromQueue,  // validity check done from queue

            exceptionEpics.showExceptionModal,

            favouriteEpics.setParams,
            favouriteEpics.fetchFavourites,
            favouriteEpics.toggleFavourite,

            orderEpics.fetchOrders,
            orderEpics.setParams,
            orderEpics.setSelectedResult,
            orderEpics.setSelectedIndex,
            orderEpics.toggleOrderVehicles,

            salesEpics.setArea,
            salesEpics.setParams,
            salesEpics.setManufacturerModel,
            salesEpics.setSelectedResult,
            salesEpics.setSelectedIndex,
            salesEpics.vehiclesSuccess,
            salesEpics.fetchVehicles,
            salesEpics.fetchSellingItem,
            salesEpics.fetchVehicleFilters,
            salesEpics.reloadVehicles,
            salesEpics.hideVehicle,
            salesEpics.showVehicleUnavailableModal,
            // salesEpics.setVehicleAsPurchasable,
            // salesEpics.setVehicleAsNotPurchasable,
            salesEpics.fetchActiveAuctionsForUser,

            statisticsEpics.fetchStatistics,

            sellingAreasEpics.fetchSellingAreas,
            sellingAreasEpics.fetchSellingAreaFull,

            uiEpics.onLayoutChange,
            uiEpics.queueNotification,
            uiEpics.queueNotifications,
            uiEpics.discardNotification,
            uiEpics.fetchPrivacy,

            messagesEpics.setParams,
            messagesEpics.fetchMessagesStatistics,
            messagesEpics.fetchModalMessages,
            messagesEpics.fetchMessages,
            messagesEpics.queueMessages,
            messagesEpics.popMessage,
            messagesEpics.toggleMessage,

            userEpics.ready,
            userEpics.destroy,
            userEpics.login,
            userEpics.logout,
            userEpics.loadUser,
            userEpics.fetchIdentity,
            userEpics.fetchProfile,
            userEpics.loggedIn,
            userEpics.auctionDisclaimerAccepted,
            userEpics.refreshToken,
            userEpics.saveUserInfo,

            cmsMenuEpics.fetchMenu,
            cmsMenuEpics.menuSuccess,

            cmsPagesEpics.fetchPage,

            utilitiesEpics.fetchTimezones
        );
        middleware = [
            ...middleware,
            epicMiddleware
        ];

        const enhancers = [];

        /**
         *
         * DEV MODE ONLY
         *
         */

        if (!environment.production) {
            if (devTools.isEnabled()) {
                enhancers.push(devTools.enhancer());
            }
        }

        // Tell Redux about our reducers and epics. If the Redux DevTools
        // chrome extension is available in the browser, tell Redux about
        // it too.
        ngRedux.configureStore(
            rootReducer,
            {},
            middleware,
            enhancers
        );

        epicMiddleware.run(rootEpic);
    }

}
