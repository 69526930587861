import { AppAction, ScrollState } from '../../../interfaces/store.interface';

import { ScrollActionsService } from '../actions/scroll.actions';

const INITIAL_STATE: ScrollState = {
    scrolledTo: null,
    locked: false,
    refresh: 0,
    unhook: 0,
    scrollToTop: 0,
    inview: {}
};

export default function scrollReducer (state: ScrollState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * SCROLL TO
         *
         */

        case ScrollActionsService.SCROLL_TO: {

            const nextState = {
                ...state,
                scrolledTo: action.payload
            };

            return nextState;

        }

        /**
         *
         * REFRESH
         *
         */

        case ScrollActionsService.REFRESH: {

            const nextState = {
                ...state,
                // increment refresh to trigger refresh
                refresh: state.refresh + 1
            };

            return nextState;

        }

        case ScrollActionsService.UNHOOK: {

            const nextState = {
                ...state,
                // increment unhook to trigger unhook
                unhook: state.unhook + 1
            };

            return nextState;

        }

        case ScrollActionsService.SCROLL_TO_TOP: {

            const nextState = {
                ...state,
                // increment scrollToTop
                scrollToTop: state.scrollToTop + 1
            };

            return nextState;

        }

        case ScrollActionsService.LOCK: {

            const nextState = {
                ...state,
                locked: true
            };

            return nextState;

        }

        case ScrollActionsService.UNLOCK: {

            const nextState = {
                ...state,
                locked: false
            };

            return nextState;

        }

        case ScrollActionsService.REGISTER_INVIEW: {

            const nextState = {
                ...state,
                inview: {
                    ...state.inview,
                    [action.payload.id]: {
                        visible: action.payload.visible,
                        element: action.payload.element
                    }
                }
            };

            return nextState;

        }

        case ScrollActionsService.DEREGISTER_INVIEW: {

            const nextState = {
                ...state
            };

            // flag as invisibile and delete reference to element
            if (nextState.inview[action.payload.id]) {
                nextState.inview = {
                    ...state.inview,
                    [action.payload.id]: {
                        visible: false
                    }
                }
            }

            return nextState;

        }

        case ScrollActionsService.SET_INVIEW: {

            const nextState = {
                ...state
            };

            if (nextState.inview[action.payload.id]) {
                nextState.inview = {
                    ...state.inview,
                    [action.payload.id]: {
                        ...state.inview[action.payload.id],
                        visible: action.payload.visible
                    }
                };
            }

            return nextState;

        }

    }

    return state;

}
