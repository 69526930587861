import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class StatisticsActionsService {

    static readonly FETCH_STATISTICS = '@statistics/FETCH_STATISTICS';
    static readonly STATISTICS_LOADING = '@statistics/STATISTICS_LOADING';
    static readonly STATISTICS_SUCCESS = '@statistics/STATISTICS_SUCCESS';
    static readonly STATISTICS_ERROR = '@statistics/STATISTICS_ERROR';

    /**
     *
     * SET
     *
     */

    @dispatch()
    fetchStatistics = (payload: { mode: string }): AppAction => ({
        type: StatisticsActionsService.FETCH_STATISTICS,
        payload
    });

}
