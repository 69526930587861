import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class ExceptionActionsService {

    static readonly SHOW_EXCEPTION_MODAL = '@exception/SHOW_EXCEPTION_MODAL';

    @dispatch()
    showExceptionModal = (error: any): AppAction => ({
        type: ExceptionActionsService.SHOW_EXCEPTION_MODAL,
        error
    })

}
