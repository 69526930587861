<span class="listing__info cf" [class.bundle]="sellingItem._bundle">
    <span class="block grid grid--8">
        <app-listing-title [title]="sellingItem._vehicleTitle"></app-listing-title>

        <app-listing-days-of-visibility
            *ngIf="(userType$ | async) !== userTypes[userTypes.Private]; else noVisibilityDays"
            [hideAt]="sellingItem._hideAt" [showAt]="sellingItem._showAt">
        </app-listing-days-of-visibility>
        <ng-template #noVisibilityDays>
            <span class="listing__tertiary listing__highlight lowercase">
                <span>&nbsp;</span>
            </span>
        </ng-template>

        <app-listing-stats *ngIf="!sellingItem._bundle"
            [vehicle]="sellingItem.vehicles ? sellingItem.vehicles[0] : sellingItem" [currency]="sellingItem.currency">
        </app-listing-stats>

        <span class="listing__info--bookmark h5 mb-0" i18n-title title="Aggiungi/rimuovi preferito"
            (click)="toggleFavourite($event)">
            <i class="fa-bookmark" [ngClass]="{'fas': sellingItem.isFavourite, 'far': !sellingItem.isFavourite}"></i>
        </span>
    </span>
    <span class="block grid grid--4 text-right">

        <ng-container *ngIf="mode !== 'orders'; else orderPrices">
            <ng-container *ngIf="sellingItem._isAuction && mode !== 'modal-cart' && mode !== 'cart'; else auctionModalCart">
                <small class="listing__buynow-price nowrap">
                    <ng-container
                        *ngIf="sellingItem.auctionInfo?.buyNowEnabled && sellingItem.auctionInfo.buyNowPrice && auctionValueToShow && auctionValueToShow < sellingItem.auctionInfo.buyNowPrice">
                        <span class="text-lowercase">{{'Sales.BuyNow' | translate}}:
                            {{sellingItem.auctionInfo?.buyNowPrice | currencySpace:sellingItem.currency}} *</span>
                    </ng-container>
                </small>
                <span class="listing__auction-price listing__listing-price nowrap" *ngIf="auctionValueToShow"
                    [ngStyle]="{'line-height': (!sellingItem.auctionInfo?.buyNowEnabled || !sellingItem.auctionInfo?.buyNowPrice || !auctionValueToShow || auctionValueToShow >= sellingItem.auctionInfo?.buyNowPrice) ? '28px' : null}">
                    <span>{{auctionValueToShow | currencySpace:sellingItem.currency}} *</span>
                </span>
            </ng-container>
            <ng-template #auctionModalCart>
                <ng-container *ngIf="sellingItem._isAuction && mode === 'modal-cart'; else noAuction">
                    <span class="listing__primary listing__listing-price nowrap" *ngIf="sellingItem.auctionInfo?.buyNowPrice">
                        <span>{{sellingItem.auctionInfo.buyNowPrice | currencySpace:sellingItem.currency}} *</span>
                    </span>
                </ng-container>
            </ng-template>
            <ng-template #noAuction>
                <ng-container *ngIf="sellingItem.discount; else noDiscount">
                    <small class="listing__oldPrice nowrap" *ngIf="sellingItem.price">
                        {{sellingItem.price | currencySpace:sellingItem.currency}} *
                    </small>
                    <span class="listing__newPrice nowrap ml-2" *ngIf="sellingItem.calculatedEndPrice">
                        <span>{{sellingItem.calculatedEndPrice | currencySpace:sellingItem.currency}} *</span>
                    </span>
                </ng-container>
                <ng-template #noDiscount>
                    <span class="listing__primary listing__listing-price nowrap" *ngIf="sellingItem.calculatedEndPrice">
                        <span>{{sellingItem.calculatedEndPrice | currencySpace:sellingItem.currency}} *</span>
                    </span>
                </ng-template>
            </ng-template>

        </ng-container>

        <ng-template #orderPrices>
            <ng-container *ngIf="sellingItem.discount; else orderPricesNoDiscount">
                <small class="listing__oldPrice nowrap" *ngIf="sellingItem.price">
                    &euro; {{sellingItem.price | number: '1.2-2'}} *
                </small>
                <span class="listing__newPrice nowrap ml-2" *ngIf="sellingItem?.prices?.dealer?.value">
                    <span>&euro; {{sellingItem.prices.dealer.value | number: '1.2-2'}} *</span>
                </span>
            </ng-container>
            <ng-template #orderPricesNoDiscount>
                <span class="listing__primary listing__listing-price nowrap" *ngIf="sellingItem?.prices?.dealer?.value">
                    <span>&euro; {{sellingItem.prices.dealer.value | number: '1.2-2'}}</span>
                </span>
            </ng-template>
        </ng-template>

        <ng-container
            *ngIf="!sellingItem._bundle && sellingItem.vehicles && sellingItem.vehicles[0]; else catalogFromProps">
            <ng-container *ngIf="sellingItem.vehicles[0]?.prices?.catalog">
                <span class="listing__secondary listing__listing-secondary-price nowrap lowercase">
                    <span i18n>{{'SellingItem.Info.Catalog' | translate}}</span>
                    {{ sellingItem.vehicles[0].prices.catalog.value | currencySpace:sellingItem.currency }}
                </span>
                <span *ngIf="sellingItem.vehicles[0]?.prices?.dealer?.equipments"
                    class="listing__secondary listing__listing-secondary-price nowrap lowercase">
                    <span i18n>{{'SellingItem.Info.OptionalsValue' | translate}}</span>
                    {{ sellingItem.vehicles[0].prices.dealer.equipments | currencySpace:sellingItem.currency }}
                </span>
            </ng-container>
        </ng-container>

        <ng-template #catalogFromProps>
            <ng-container *ngIf="!sellingItem._bundle && sellingItem.prices?.catalog">
                <span class="listing__secondary listing__listing-secondary-price nowrap lowercase">
                    <span i18n>{{'SellingItem.Info.Catalog' | translate}}</span>
                    {{ sellingItem.prices.catalog.value | currencySpace:sellingItem.currency }}
                </span>
                <span *ngIf="!sellingItem._bundle && sellingItem.prices?.dealer?.equipments"
                    class="listing__secondary listing__listing-secondary-price nowrap lowercase">
                    <span i18n>{{'SellingItem.Info.OptionalsValue' | translate}}</span>
                    {{ sellingItem.prices.dealer.equipments | currencySpace:sellingItem.currency }}
                </span>
            </ng-container>
        </ng-template>

    </span>


    <app-bundle-thumbs *ngIf="sellingItem._bundle" class="grid" [context]="'listing'" [cars]="bundleVehicles"
        (activeCarChange)="setActiveCar($event)">
    </app-bundle-thumbs>
</span>