import { concat, of, empty, from } from 'rxjs';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalCartAddComponent } from '../../shared/components/modal-cart-add/modal-cart-add.component';
import { ModalCartExpiredComponent } from '../../shared/components/modal-cart-expired/modal-cart-expired.component';
import { CartActionsService } from '../actions/cart.actions';
import { CartValidationActionsService } from '../actions/cart-validation.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { UiActionsService } from '../actions/ui.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, switchMap, map, takeUntil, delay, take, mergeMap, startWith } from 'rxjs/operators';
import { ModalCheckoutErrorComponent } from '../../shared/components/modal-checkout-error/modal-checkout-error.component';
import { ModalCartDisclaimerComponent } from '../../shared/components/modal-cart-disclaimer/modal-cart-disclaimer.component';
var CartEpicsService = /** @class */ (function () {
    function CartEpicsService(http, modal, cartValidationActions, salesActions, uiActions) {
        var _this = this;
        this.http = http;
        this.modal = modal;
        this.cartValidationActions = cartValidationActions;
        this.salesActions = salesActions;
        this.uiActions = uiActions;
        /**
         *
         * LOAD THE CART
         *
         */
        this.fetchCart = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartActionsService.FETCH_CART).pipe(switchMap(function () {
                var salesState = state$.value.sales;
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartActionsService.CART_LOADING
                }), 
                // make request
                _this.http.get("/Selling/GetBasket", {
                    params: {
                        areaIdentifier: salesState.area
                    }
                }).pipe(// 404 is used for an empty cart
                map(function (response) { return ({
                    type: CartActionsService.CART_SUCCESS,
                    payload: {
                        response: response
                    }
                }); }), catchError(function (error) {
                    // handle the empty cart 'error'
                    if (error.status === 404) {
                        return concat(of({
                            type: CartValidationActionsService.FETCH_CART_CONFIG,
                            payload: {
                                updateSalesOnly: true
                            }
                        }), of({
                            type: CartActionsService.CART_SUCCESS_EMPTY
                        }));
                    }
                    return of({
                        type: CartActionsService.CART_ERROR,
                        error: error
                    });
                }), 
                // cancel this if the area changes
                takeUntil(cancel$)));
            }));
        };
        /**
         *
         * CART HAS LOADED - MONITOR EXPIRY
         *
         */
        this.cartSuccess = function (action$, state$) {
            var cancel$ = action$.ofType(CartActionsService.CART_LOADING);
            return action$.ofType(CartActionsService.CART_SUCCESS).pipe(switchMap(function () {
                var state = state$.value;
                var diff = state.cart.expiry - Date.now();
                // when the cart loads we also need to validate it
                _this.cartValidationActions.validateCart();
                return of({
                    type: CartActionsService.CART_EXPIRED
                }).pipe(
                // delay the emission until the cart expires
                delay(diff), 
                // cancel the timer if a new load is triggered
                takeUntil(cancel$));
            }));
        };
        /**
         *
         * CART EXPIRED - Alert user
         *
         */
        this.cartExpired = function (action$, state$) {
            return action$.ofType(CartActionsService.CART_EXPIRED).pipe(switchMap(function () {
                // open confirmation modal
                var modalRef = _this.modal.open(ModalCartExpiredComponent);
                return concat(
                // delete cart in the background
                of({
                    type: CartActionsService.DELETE_CART
                }), 
                // open the modal to alert the user
                from(modalRef.result).pipe(switchMap(function () {
                    // User has clicked OK in the modal
                    return empty();
                }), catchError(function () {
                    // User clicked CANCEL in the modal
                    return empty();
                })));
            }));
        };
        /**
         *
         * Ask the user for confirmation before adding the vehicle to the cart
         *
         */
        this.addToCartConfirm = function (action$, store) {
            return action$.ofType(CartActionsService.ADD_TO_CART_CONFIRM).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var vehicle = payload;
                // open confirmation modal
                var modalRef = _this.modal.open(ModalCartAddComponent, { size: 'lg' });
                modalRef.componentInstance.vehicleEntity = vehicle;
                return from(modalRef.result).pipe(map(function () {
                    // User has clicked OK in the modal
                    return {
                        type: CartActionsService.ADD_TO_CART_QUEUE,
                        payload: vehicle.id
                    };
                }), catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return empty();
                }));
            }));
        };
        /**
         *
         * This epic receives a vehicle and it checks if it can be added to the cart
         * based on the cart config.
         *
         * If it can then it will add it. If not it will update the rules.
         */
        this.addToCartQueue = function (action$, state$) {
            return action$.ofType(CartActionsService.ADD_TO_CART_QUEUE).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var vehicleId = payload;
                // close the details panel
                _this.uiActions.closeDetails();
                _this.salesActions.setSelectedIndex(-1);
                return action$.ofType(CartValidationActionsService.SET_VALIDITY).pipe(take(1), // don't listen forever! Important!
                mergeMap(function () {
                    var validationState = state$.value.cartValidation.validity;
                    if (validationState.hasErrors) {
                        return of({
                            type: CartValidationActionsService.SHOW_VALIDATION_RESULT,
                            payload: vehicleId
                        }, {
                            type: CartActionsService.ADD_TO_CART_DEQUEUE,
                            payload: vehicleId
                        });
                    }
                    return of({
                        type: CartActionsService.ADD_TO_CART_FROM_QUEUE,
                        payload: vehicleId
                    });
                }), startWith({
                    type: CartValidationActionsService.VALIDATE_CART_QUEUE
                }));
            }));
        };
        this.addToCartFromQueue = function (action$, state$) {
            return action$.ofType(CartActionsService.ADD_TO_CART_FROM_QUEUE).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var vehicleId = payload;
                // look up item from queue
                var queue = state$.value.cart.queue;
                // check the vehicle ID exists
                if (queue.length && queue.indexOf(vehicleId) > -1) {
                    return of({
                        type: CartActionsService.ADD_TO_CART,
                        payload: vehicleId
                    });
                }
                // nothing in queue
                return of({
                    type: CartActionsService.FETCH_CART
                });
            }));
        };
        /**
         *
         * This epic is only called AFTER cart validation
         * It makes the real request to the server
         */
        this.addToCart = function (action$, state$, _a) {
            var googleAnalytics = _a.googleAnalytics;
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartActionsService.ADD_TO_CART).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var itemIdentifier = payload;
                var areaIdentifier = state$.value.sales.area;
                var url = "/Selling/AddItemToBasket";
                // tell Google Analytics
                googleAnalytics.sendEvent({
                    category: 'HttpRequest',
                    action: url,
                    label: 'Add car to basket: ' + itemIdentifier
                });
                return concat(
                // flag as add in progress
                of({
                    type: CartActionsService.ADD_TO_CART_LOADING,
                    payload: itemIdentifier
                }), 
                // make request
                _this.http.post(url, {
                    itemIdentifier: itemIdentifier,
                    areaIdentifier: areaIdentifier
                }).pipe(switchMap(function (response) {
                    // tell Google Analytics
                    googleAnalytics.sendEvent({
                        category: 'HttpRequest',
                        action: url,
                        label: 'Car added to basket: ' + itemIdentifier
                    });
                    return of({
                        type: CartActionsService.ADD_TO_CART_SUCCESS,
                        payload: {
                            response: response
                        }
                    }).pipe(switchMap(function () {
                        return concat(of({
                            type: CartActionsService.ADD_TO_CART_IN_CART,
                            payload: itemIdentifier
                        }), of({
                            type: SalesActionsService.HIDE_SELLINGITEM,
                            payload: {
                                itemIdentifier: itemIdentifier,
                                reason: 'ADDED_TO_CART'
                            }
                        }), 
                        // reload the cart
                        of({
                            type: CartActionsService.FETCH_CART
                        }));
                    }));
                }), catchError(function (error) {
                    // handle server-side validation errors
                    if (error.status === 400) {
                        return of({
                            type: CartActionsService.ADD_TO_CART_REJECTED,
                            payload: error
                        });
                    }
                    // unavailable car
                    if (error.status === 406 || error.status === 404) {
                        return of({
                            type: SalesActionsService.HIDE_SELLINGITEM,
                            payload: {
                                vehicleId: itemIdentifier,
                                reason: 'ADD_TO_CART_VEHICLE_UNAVAILABLE'
                            }
                        }
                        //    {
                        //        type: CartActionsService.ADD_TO_CART_UNAVAILABLE,
                        //        payload: itemIdentifier
                        //    }
                        );
                    }
                    return of({
                        type: CartActionsService.ADD_TO_CART_ERROR,
                        payload: null,
                        error: error
                    });
                }), 
                // cancel this if the area changes
                takeUntil(cancel$)), 
                // finally ensure it is no longer in the queue
                of({
                    type: CartActionsService.ADD_TO_CART_DEQUEUE,
                    payload: itemIdentifier
                }));
            }));
        };
        /**
         *
         * Ask the user for confirmation before checking out the cart
         *
         */
        this.checkoutCartConfirm = function (action$, state$) {
            return action$.ofType(CartActionsService.CHECKOUT_CART_CONFIRM).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // open confirmation modal
                var modalRef = _this.modal.open(ModalCartDisclaimerComponent);
                return from(modalRef.result).pipe(map(function (r) {
                    // User has clicked OK in the modal
                    return {
                        type: CartActionsService.CHECKOUT_CART,
                        payload: payload
                    };
                }), catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return empty();
                }));
            }));
        };
        /**
         *
         * Make the request to checkout the cart
         *
         */
        this.checkoutCart = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartActionsService.CHECKOUT_CART).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var cartContents = state$.value.cart.contents;
                var area = state$.value.sales.area;
                var params = {
                    basketIdentifier: cartContents.id,
                    areaIdentifier: area
                };
                if (payload) {
                    if (payload.selectedPaymentMethodId) {
                        params['paymentMethodId'] = payload.selectedPaymentMethodId;
                    }
                    if (payload.selectedBranchId) {
                        params['branchId'] = payload.selectedBranchId;
                    }
                }
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartActionsService.CHECKOUT_CART_LOADING
                }), 
                // make request
                _this.http.post("/Selling/CheckoutBasket", params).pipe(switchMap(function (response) {
                    return of({
                        type: CartActionsService.CHECKOUT_CART_SUCCESS // triggers redirect
                    }, {
                        type: CartActionsService.CART_RESET // @TODO is this correct?
                    });
                }), catchError(function (error) {
                    // unavailable car
                    if (error.status === 400) {
                        var errors = [];
                        if (error.error) {
                            errors = Object.values(error.error);
                        }
                        return of({
                            type: CartActionsService.SHOW_CHECKOUT_ERROR_MODAL,
                            payload: {
                                error: errors,
                                reason: 'CHECKOUT_CART_BAD_REQUEST'
                            }
                        }, {
                            type: CartActionsService.CHECKOUT_CART_ERROR,
                            error: error
                        });
                    }
                    return of({
                        type: CartActionsService.CHECKOUT_CART_ERROR,
                        error: error
                    });
                }), 
                // cancel this if the area changes
                takeUntil(cancel$)));
            }));
        };
        /**
         *
         * Remove vehicle from cart
         *
         */
        this.removeFromCart = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartActionsService.REMOVE_FROM_CART).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var vehicle = payload;
                var vehicleId = vehicle.id;
                var cartContents = state$.value.cart.contents;
                var areaIdentifier = state$.value.sales.area;
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartActionsService.REMOVE_FROM_CART_LOADING
                }), 
                // make request
                _this.http.put("/Selling/RemoveItemToBasket", {
                    basketIdentifier: cartContents.id,
                    itemIdentifier: vehicleId,
                    areaIdentifier: areaIdentifier
                }).pipe(switchMap(function (response) {
                    // console.log(response);
                    return of({
                        type: CartActionsService.REMOVE_FROM_CART_SUCCESS,
                        payload: {
                            // No response from this endpoint
                            vehicle: vehicle // pass the vehicle to the reducer
                        }
                    });
                }), catchError(function (error) {
                    // TODO BUGGED investigate
                    return of({
                        type: CartActionsService.REMOVE_FROM_CART_SUCCESS,
                        payload: {
                            // No response from this endpoint
                            vehicle: vehicle // pass the vehicle to the reducer
                        }
                    });
                }), 
                // cancel this if the area changes
                takeUntil(cancel$)));
            }));
        };
        /**
         *
         * Remove vehicle from cart
         *
         */
        this.removeFromCartSuccess = function (action$, state$) {
            return action$.ofType(CartActionsService.REMOVE_FROM_CART_SUCCESS).pipe(switchMap(function () {
                var cartContents = state$.value.cart.contents;
                // check if it is worth reloading the cart
                if (cartContents && cartContents.vehicles.length) {
                    return of({
                        type: CartActionsService.FETCH_CART
                    });
                }
                // flag the cart as empty
                return of({
                    type: CartActionsService.CART_SUCCESS_EMPTY
                });
            }));
        };
        /**
         *
         * Make the request to delete the user's cart
         *
         */
        this.deleteCart = function (action$, state$) {
            return action$.ofType(CartActionsService.DELETE_CART).pipe(switchMap(function () {
                var cartContents = state$.value.cart.contents;
                var area = state$.value.sales.area;
                var params = new HttpParams({
                    fromObject: {
                        id: cartContents.id
                    }
                });
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartActionsService.DELETE_CART_LOADING
                }), 
                // make request
                _this.http.delete("/Selling/DeleteUserBasket", { params: params }).pipe(switchMap(function (response) {
                    return of({
                        type: CartActionsService.CART_RESET // reset whatever the result
                    }, {
                        type: CartActionsService.FETCH_CART // @TODO is this necessary?
                    });
                }), catchError(function (error) {
                    return of({
                        type: CartActionsService.CART_RESET // reset whatever the result
                    }, {
                        type: CartActionsService.FETCH_CART
                    });
                })));
            }));
        };
        /**
         *
         * CHECKOUT ERROR MODAL
         *
         */
        this.showCheckoutErrorModal = function (action$, state$) {
            return action$.ofType(CartActionsService.SHOW_CHECKOUT_ERROR_MODAL).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var modalToShow = ModalCheckoutErrorComponent;
                switch (payload.reason) {
                    case 'CHECKOUT_CART_BAD_REQUEST':
                        modalToShow = ModalCheckoutErrorComponent;
                        break;
                    default:
                        break;
                }
                // open confirmation modal
                var modalRef = _this.modal.open(modalToShow);
                modalRef.componentInstance.errors = payload.error;
                return from(modalRef.result).pipe(
                // modals only use dismiss which leads to 'catch'
                catchError(function () {
                    // User clicked CANCEL in the modal - kill the stream
                    return empty();
                }));
            }));
        };
        /**
         *
         * LOAD THE PAYMENT METHODS
         *
         */
        this.fetchPaymentMethods = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartActionsService.FETCH_PAYMENTMETHODS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartActionsService.PAYMENTMETHODS_LOADING
                }), 
                // make request
                _this.http.get("/Selling/GetPaymentMethods", {
                    params: {
                        sellingAreaId: payload.area
                    }
                }).pipe(switchMap(function (response) {
                    return of({
                        type: CartActionsService.PAYMENTMETHODS_SUCCESS,
                        payload: response
                    });
                }), catchError(function (error) {
                    // handle the empty branch list 'error'
                    if (error.status === 404) {
                        return of({
                            type: CartActionsService.PAYMENTMETHODS_SUCCESS,
                            payload: []
                        });
                    }
                    return of({
                        type: CartActionsService.PAYMENTMETHODS_ERROR,
                        error: error
                    });
                }), 
                // cancel this if the area changes or a new call is made
                takeUntil(cancel$)));
            }));
        };
        /**
         *
         * LOAD THE CART BRANCHES
         *
         */
        this.fetchCartBranches = function (action$, state$) {
            var cancel$ = action$.ofType(SalesActionsService.SET_AREA);
            return action$.ofType(CartActionsService.FETCH_CART_BRANCHES).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var areaIdentifier = state$.value.sales.area;
                var params = {
                    areaIdentifier: areaIdentifier,
                    latitude: payload.latitude,
                    longitude: payload.longitude
                };
                if (payload && (payload.latitude === null || payload.longitude === null)) {
                    params.latitude = 41.890251;
                    params.longitude = 12.492373;
                }
                // start the request
                return concat(
                // flag as loading
                of({
                    type: CartActionsService.CART_BRANCHES_LOADING
                }), 
                // make request
                // of(FAKE_CART_BRANCHES).pipe(
                _this.http.get("/Selling/GetBranches", {
                    params: params
                }).pipe(// 404 is used for an empty list
                switchMap(function (response) {
                    return of({
                        type: CartActionsService.CART_BRANCHES_SUCCESS,
                        payload: {
                            response: response
                        }
                    });
                }), catchError(function (error) {
                    // handle the empty branch list 'error'
                    if (error.status === 404) {
                    }
                    return of({
                        type: CartActionsService.CART_BRANCHES_ERROR,
                        error: error
                    });
                }), 
                // cancel this if the area changes or a new call is made
                takeUntil(cancel$)));
            }));
        };
    }
    return CartEpicsService;
}());
export { CartEpicsService };
export var FAKE_CART_BRANCHES = [
    {
        userId: 'Roma - Colosseo',
        branch: {
            address: 'Via Roma',
            zipcode: '20100',
            city: 'Roma',
            country: 'Italia',
            latitude: 41.890251,
            longitude: 12.492373,
        }
    },
    {
        userId: 'Milano - Duomo',
        branch: {
            address: 'Via Milano',
            zipcode: '00100',
            city: 'Milano',
            country: 'Italia',
            latitude: 45.464117,
            longitude: 9.1897977,
        }
    },
    {
        userId: 'Palermo',
        branch: {
            address: 'Via Palermo',
            zipcode: '90100',
            city: 'Roma',
            country: 'Italia',
            latitude: 38.1405228,
            longitude: 13.2872488,
        }
    },
    {
        userId: 'Cagliari',
        branch: {
            address: 'Via Cagliari',
            zipcode: '09100',
            city: 'Cagliari',
            country: 'Italia',
            latitude: 39.2253991,
            longitude: 9.0933586,
        }
    }
];
