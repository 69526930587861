// import * as moment from 'moment'; - added globally to prevent all locales being included

import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class AuctionActionsService {

    static readonly FETCH_AUCTION_CONFIG = '@auction/FETCH_AUCTION_CONFIG';
    static readonly AUCTION_CONFIG_LOADING = '@auction/AUCTION_CONFIG_LOADING';
    static readonly AUCTION_CONFIG_SUCCESS = '@auction/AUCTION_CONFIG_SUCCESS';
    static readonly AUCTION_CONFIG_ERROR = '@auction/AUCTION_CONFIG_ERROR';

    static readonly FETCH_AUCTION_BY_ID = '@auction/FETCH_AUCTION_BY_ID';
    static readonly AUCTION_BY_ID_LOADING = '@auction/AUCTION_BY_ID_LOADING';
    static readonly AUCTION_BY_ID_SUCCESS = '@auction/AUCTION_BY_ID_SUCCESS';
    static readonly AUCTION_BY_ID_ERROR = '@auction/AUCTION_BY_ID_ERROR';

    static readonly AUCTION_MAKE_OFFER_CONFIRM = '@auction/AUCTION_MAKE_OFFER_CONFIRM';
    static readonly AUCTION_MAKE_OFFER = '@auction/AUCTION_MAKE_OFFER';
    static readonly AUCTION_MAKE_OFFER_LOADING = '@auction/AUCTION_MAKE_OFFER_LOADING';
    static readonly AUCTION_MAKE_OFFER_SUCCESS = '@auction/AUCTION_MAKE_OFFER_SUCCESS';
    static readonly AUCTION_MAKE_OFFER_ERROR = '@auction/AUCTION_MAKE_OFFER_ERROR';

    static readonly AUCTION_SET_STATE = '@auction/AUCTION_SET_STATE';

    /**
     *
     * SET
     *
     */

    @dispatch()
    makeOfferConfirm = (payload: { vehicleId: string, offer: number }): AppAction => ({
        type: AuctionActionsService.AUCTION_MAKE_OFFER_CONFIRM,
        payload
    })

    @dispatch()
    makeOffer = (payload: { vehicleId: string, offer: number }): AppAction => ({
        type: AuctionActionsService.AUCTION_MAKE_OFFER,
        payload
    })

    @dispatch()
    setState = (payload: { vehicleId: string, state: number }): AppAction => ({
        type: AuctionActionsService.AUCTION_SET_STATE,
        payload
    })

    @dispatch()
    updateAuctionById = (payload: { sellingItemId: string }): AppAction => ({
        type: AuctionActionsService.FETCH_AUCTION_BY_ID,
        payload
    })

    @dispatch()
    updateAuctionFromPush = (payload: { sellingItemId: string, response: any }): AppAction => ({
        type: AuctionActionsService.AUCTION_BY_ID_SUCCESS, // use the same action as the XHR lookup
        payload: {
            ...payload,
            isFromPush: true,
            now: moment()
        }
    })

}
