import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Inject, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';

// import * as moment from 'moment'; - added globally to prevent all locales being included
import { NgReduxModule } from '@angular-redux/store';

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from './store/store.module';
import { CoreModule } from './core/core.module';



declare var System;

export class LocaleService {
    getLocale(): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return undefined;
        }

        let browserLang: any = window.navigator['languages'] ? window.navigator['languages'][0] : null;
        browserLang = browserLang || window.navigator.language || window.navigator['browserLanguage'] || window.navigator['userLanguage'];

        if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
        }

        if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
        }

        const localeId = localStorage.getItem('localeId')

        return localeId ? localeId : browserLang;
    }
}

export function localeIdFactory(localeService: LocaleService) {
    return localeService.getLocale();
}

// export function localeInitializer(localeId: string) {
//     return (): Promise<any> => {
//         return new Promise((resolve, reject) => {
//             import(`@angular/common/locales/${localeId}.js`)
//                 .then(module => {
//                     registerLocaleData(module.default);
//                     resolve();
//                 }, reject);
//         });
//     };
// }

export function HttpLoaderFactory(http: HttpClient) {
    const arr = window.location.href.split('/');
    const url = arr[0] + '//' + arr[2];
    return new TranslateHttpLoader(http, url + '/assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        // CORE
        BrowserModule,
        BrowserAnimationsModule,
        // PACKAGES
        NgReduxModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // APP
        SharedModule,
        CoreModule,
        StoreModule,
        AppRoutingModule
    ],
    providers: [
        // LocaleService,
        // { provide: LOCALE_ID, useFactory: localeIdFactory, deps: [LocaleService] },
        // {
        //     provide: APP_INITIALIZER,
        //     multi: true,
        //     useFactory: localeInitializer,
        //     deps: [LOCALE_ID]
        // }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(@Inject(LOCALE_ID) locale: string) {
        // set the global local of moment
        // moment.locale(locale);
    }
}
