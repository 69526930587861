import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';

import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../../interfaces/store.interface';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, filter, take, switchMap } from 'rxjs/operators';
import { UserActionsService } from '../../store/actions/user.actions';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics/google-analytics.service';
import { ExceptionActionsService } from '../../store/actions/exception.actions';
import { EnvironmentService } from '../../shared/services/environment/environment.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private ngRedux: NgRedux<AppState>,
    private userActions: UserActionsService,
    private googleAnalytics: GoogleAnalyticsService,
    private exceptionActions: ExceptionActionsService,
    private environmentService: EnvironmentService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isAssets = request.url.indexOf('/assets') > -1;
    if (isAssets) {
      return next.handle(request);
    }

    const now = Date.now() / 1000;
    let tokenExpiration = 0;
    const appState = this.ngRedux.getState();

    // check for unauthenticated request
    const isUnauthenticatedRoute = !!request.headers.get('unauthenticatedRoute');

    if (!isUnauthenticatedRoute && appState.user && appState.user.identity && appState.user.identity.payload) {
      if (!appState.user.identity.payload.expires_at) {
        // token does not exist, so go to login
        this.userActions.login();
        return;
      }
      tokenExpiration = appState.user.identity.payload.expires_at;
    }

    const tokenExpired = now > tokenExpiration;

    if (isUnauthenticatedRoute || !tokenExpired) {
      return this.modifyRequest(request, next, { isUnauthenticatedRoute: isUnauthenticatedRoute });
    }

    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // – which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => this.modifyRequest(request, next))
      );
    } else {
      this.refreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      this.userActions.refreshToken();

      return this.ngRedux.select(['user', 'identity', 'payload', 'expires_at']).pipe(
        filter(newExpiration => newExpiration !== tokenExpiration),
        take(1),
        switchMap((newExpires_at) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(true);
          return this.modifyRequest(request, next);
        })
      );
    }

  }

  private modifyRequest(request: HttpRequest<any>, next: HttpHandler, options = null) {
    let url = request.url;

    const isUtility = request.url.indexOf('/Utility') > -1;

    if (request.url.indexOf('/live') === -1) {
      url = this.environmentService.getEnvironment().api.version + `${isUtility ? '' : '/Www'}` + request.url;

      if (!this.hasProtocol(url)) {
        url = this.environmentService.getEnvironment().api.url +
          this.environmentService.getEnvironment().api.version + `${isUtility ? '' : '/Www'}` + request.url;
      }
    }

    const params = this.setCacheBuster(request.params);
    const headers = this.setAuthorizationHeader();

    const update = {
      url,
      headers,
      params,
      // WORKAROUND - see below
      responseType: 'text' as 'text' // ridiculous!
    };

    const modifiedRequest = request.clone(update);

    return next.handle(modifiedRequest).pipe(
      // WORKAROUD - https://github.com/angular/angular/issues/18396#issuecomment-323289437
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: JSON.parse(event.body)
          });
        }
      }),
      catchError((err) => this.onError(err, request)));

  }

  private onError(err: any, request: HttpRequest<any>) {
    if (err instanceof HttpErrorResponse) {

      if (err.status === 401) {
        // user must be destroyed
        this.userActions.destroy();
        return throwError(new HttpErrorResponse({}));
      }

      let parsed = err.error;
      let parseSuccess = true;

      if (parsed) {
        try {
          parsed = JSON.parse(err.error);
        } catch (e) {
          // don't worry
          parseSuccess = false;
        }
      }

      // tell Google Analytics
      this.googleAnalytics.sendEvent({
        category: 'HttpRequest',
        action: request.url,
        label: err.status + ' | ' + request.method + request.params.toString() ? ' | ?' + request.params.toString() : ''
      });

      return throwError(new HttpErrorResponse(
        {
          ...err,
          error: parsed || {}
        }
      ));

    }
  }

  private hasProtocol(url: string) {
    return url.indexOf('://') > -1;
  }

  private setCacheBuster(req_params: HttpParams): HttpParams {

    req_params.append('_', Date.now().toString());

    return req_params;
  }

  private setAuthorizationHeader(): HttpHeaders {

    const token = this.getToken();

    let headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    if (token && token.type && token.value) {
      headers = headers.append('Authorization', `${token.type} ${token.value}`);
    }

    return headers;
  }

  private getToken(): { type: string, value: string } {
    let token = null;

    // get the current state
    const state = this.ngRedux.getState();

    // extract the properties we need from the state
    if (state.user.identity && !!state.user.identity && !state.user.identity.error && !state.user.identity.loading &&
      state.user.identity.payload) {
      token = {
        type: state.user.identity.payload.token_type,
        value: state.user.identity.payload.access_token
      };
    }

    return token;
  }

}
