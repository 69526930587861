import { Injectable } from '@angular/core';
import { ISellingItemStore } from '../../../../interfaces/SellingItem.interface';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../../../interfaces/store.interface';

@Injectable()
export class ListingService {
    constructor(private ngRedux: NgRedux<AppState>) {}

}
