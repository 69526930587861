import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CmsPagesActionsService } from '../actions/cms-pages.actions';
import { concat, of } from 'rxjs';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
var CmsPagesEpicsService = /** @class */ (function () {
    function CmsPagesEpicsService(http, domSanitizer) {
        var _this = this;
        this.http = http;
        this.domSanitizer = domSanitizer;
        /**
         *
         * FETCH PAGE
         *
         */
        this.fetchPage = function (action$, state$) {
            var cancel$ = action$.ofType(CmsPagesActionsService.FETCH_PAGE);
            return action$.ofType(CmsPagesActionsService.FETCH_PAGE).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // use concat so these actions happen sequentially
                return concat(of({
                    type: CmsPagesActionsService.PAGE_LOADING,
                    payload: null
                }), 
                //    this.http.get('/Cms/getPage',
                //        {
                //            params: {
                //                'pageId': payload
                //            }
                //        })
                of(tslib_1.__assign({}, FAKE_PAGE_RESPONSE, { pageId: payload }))
                    .pipe(switchMap(function (response) {
                    response.body = _this.domSanitizer.bypassSecurityTrustHtml(response.body);
                    return concat(of({
                        type: CmsPagesActionsService.PAGE_SUCCESS,
                        payload: response
                    }));
                }), catchError(function (error) { return of({
                    type: CmsPagesActionsService.PAGE_ERROR,
                    error: error
                }); }), 
                // cancel this if a new page load arrives
                takeUntil(cancel$)));
            }));
        };
    }
    return CmsPagesEpicsService;
}());
export { CmsPagesEpicsService };
var FAKE_PAGE_RESPONSE = {
    pageId: 1,
    body: "\n    <p>Testo normale</p>\n\n<h1>Titolo 1</h1>\n\n<h2>Titolo 2</h2>\n\n<h3>Titolo 3</h3>\n\n<h4>Titolo 4</h4>\n\n<h5>Titolo 5</h5>\n\n<h6>Titolo 6</h6>\n\n<pre>\nFormattato</pre>\n\n<address>Indirizzo</address>\n\n<div>Paragrafo DIV</div>\n\n<p>&nbsp;</p>\n\n<h2 style=\"font-style:italic\">Stile Italic Title</h2>\n\n<p>Marker</p>\n\n<p><big>Big</big></p>\n\n<p><small>Small</small></p>\n\n<p><tt>Typewriter</tt></p>\n\n<p><code>Computer code</code></p>\n\n<p><kbd>Keyboard phrase</kbd></p>\n\n<p><samp>Sample text</samp></p>\n\n<p><var>Variable</var></p>\n\n<p><del>Deleted text</del></p>\n\n<p><ins>Inserted text</ins></p>\n\n<p><cite>Cited work</cite></p>\n\n<p><q>Inline quotation</q></p>\n\n<p><span dir=\"rtl\">RTL</span></p>\n\n<p><span dir=\"ltr\">LTR</span></p>\n\n<p>&nbsp;</p>\n\n<p><strong>Bold</strong></p>\n\n<p><em>Italic</em></p>\n\n<p><u>Underlined</u></p>\n\n<p><s>Striked</s></p>\n\n<p><sub>Pedice</sub></p>\n\n<p><sup>Apice</sup></p>\n\n<p>&nbsp;</p>\n\n<ol>\n\t<li>Elenco numerato 1</li>\n\t<li>num 2</li>\n</ol>\n\n<ul>\n\t<li>Elenco puntato 1</li>\n\t<li>num 2</li>\n</ul>\n\n<p>&nbsp;</p>\n\n<blockquote>\n<p>Citazione</p>\n</blockquote>\n\n<p><a href=\"http://www.google.it\">Link</a></p>\n\n<table border=\"1\" cellpadding=\"1\" cellspacing=\"1\" style=\"width:500px\">\n\t<tbody>\n\t\t<tr>\n\t\t\t<td>a1</td>\n\t\t\t<td>a2</td>\n\t\t</tr>\n\t\t<tr>\n\t\t\t<td>b1</td>\n\t\t\t<td>b2</td>\n\t\t</tr>\n\t\t<tr>\n\t\t\t<td>c1</td>\n\t\t\t<td>c2</td>\n\t\t</tr>\n\t</tbody>\n</table>\n\n<p>&nbsp;</p>\n\n<p>&nbsp;</p>\n\n<hr />\n<p>&nbsp;</p>\n\n    "
};
