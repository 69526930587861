import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class BootingActionsService {

    static readonly BOOT_SUCCESS = '@booting/BOOT_SUCCESS';

    static readonly LOAD_USER = '@booting/LOAD_USER';
    static readonly USER_READY = '@booting/USER_READY';
    static readonly USER_LOGGED_IN = '@booting/USER_LOGGED_IN';
    static readonly USER_DESTROY = '@booting/USER_DESTROY';

    static readonly FETCH_IDENTITY = '@booting/FETCH_IDENTITY';
    static readonly IDENTITY_LOADING = '@booting/IDENTITY_LOADING';
    static readonly IDENTITY_SUCCESS = '@booting/IDENTITY_SUCCESS';
    static readonly IDENTITY_ERROR = '@booting/IDENTITY_ERROR';

    static readonly FETCH_PROFILE = '@booting/FETCH_PROFILE';
    static readonly PROFILE_LOADING = '@booting/PROFILE_LOADING';
    static readonly PROFILE_SUCCESS = '@booting/PROFILE_SUCCESS';
    static readonly PROFILE_ERROR = '@booting/PROFILE_ERROR';

    static readonly LOAD_TENANT_CONFIG = '@booting/LOAD_TENANT_CONFIG';
    static readonly TENANT_CONFIG_LOADING = '@booting/TENANT_CONFIG_LOADING';
    static readonly TENANT_CONFIG_SUCCESS = '@booting/TENANT_CONFIG_SUCCESS';
    static readonly TENANT_CONFIG_ERROR = '@booting/TENANT_CONFIG_ERROR';

    /**
     *
     * LOAD USER
     *
     */

    @dispatch()
    loadUser = (): AppAction => ({
        type: BootingActionsService.LOAD_USER
    })

    /**
     *
     * LOAD TENANT CONFIG
     *
     */

    @dispatch()
    loadTenantConfiguration = (): AppAction => ({
        type: BootingActionsService.LOAD_TENANT_CONFIG
    })

    @dispatch()
    setBootSuccess = (): AppAction => ({
        type: BootingActionsService.BOOT_SUCCESS
    })

}
