import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-cart-conditions',
  templateUrl: './modal-cart-conditions.component.html',
  // styleUrls: ['./modal-cart-conditions.component.scss']
})
export class ModalCartConditionsComponent {
  constructor (public activeModal: NgbActiveModal) {}
}
