import { HttpClient, HttpParams } from '@angular/common/http';
import { ModalMessageComponent } from '../../shared/components/modal-message/modal-message.component';
import { MessagesActionsService } from '../actions/messages.actions';
import { concat, of, empty } from 'rxjs';
import { ModalService } from '../../shared/services/modal/modal.service';
import { UserService } from '../../shared/services/user/user.service';
import { switchMap, catchError } from 'rxjs/operators';
import { ScrollActionsService } from '../actions/scroll.actions';
var MessagesEpicsService = /** @class */ (function () {
    function MessagesEpicsService(http, modal, user) {
        var _this = this;
        this.http = http;
        this.modal = modal;
        this.user = user;
        this.setParams = function (action$, state$) {
            return action$.ofType(MessagesActionsService.SET_PARAMS, MessagesActionsService.SET_PARAMS_NEXT_PAGE).pipe(switchMap(function (_a) {
                var type = _a.type;
                if (type === MessagesActionsService.SET_PARAMS) {
                    // we need to reset the scroll
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    }, {
                        type: MessagesActionsService.FETCH_MESSAGES,
                        payload: null
                    });
                }
                return of({
                    type: MessagesActionsService.FETCH_MESSAGES
                });
            }));
        };
        /**
         *
         * MESSAGES
         *
         */
        this.fetchMessagesStatistics = function (action$) {
            return action$.ofType(MessagesActionsService.FETCH_MESSAGES_STATISTICS).pipe(switchMap(function () {
                return _this.http.get('/Inbox/GetInboxStatistics').pipe(switchMap(function (response) {
                    if (response.unreadMessages) {
                        return concat(of({
                            type: MessagesActionsService.MESSAGES_STATISTICS_SUCCESS,
                            payload: response
                        }), of({
                            type: MessagesActionsService.FETCH_MODAL_MESSAGES
                        }));
                    }
                    return of({
                        type: MessagesActionsService.MESSAGES_STATISTICS_SUCCESS,
                        payload: response
                    });
                }), catchError(function (error) { return of({
                    type: MessagesActionsService.MESSAGES_ERROR,
                    error: error
                }); }));
            }));
        };
        this.fetchMessages = function (action$, state$) {
            return action$.ofType(MessagesActionsService.FETCH_MESSAGES).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var messagesState = state$.value.messages;
                var query = new HttpParams();
                // apply all values to the query string
                Object.keys(messagesState.params).forEach(function (key) {
                    if (messagesState.params[key] || messagesState.params[key] === 0) {
                        query = query.append(key, messagesState.params[key].toString());
                    }
                });
                // use concat so these actions happen sequentially
                return concat(of({
                    type: messagesState.params.pageIndex > 0 ?
                        MessagesActionsService.MESSAGES_LOADING_MORE : MessagesActionsService.MESSAGES_LOADING,
                    payload: null
                }), _this.http.get('/Inbox/List', { params: query }).pipe(switchMap(function (response) {
                    return of({
                        type: MessagesActionsService.MESSAGES_SUCCESS,
                        payload: {
                            response: response
                        }
                    });
                    //    const toQueue = response.result.filter((result: IMessage) => {
                    //        // ignore messages that we have already seen
                    //        return !result.read && result.showModal;
                    //    });
                    //
                    //    if (toQueue.length !== 0) {
                    //        return concat(
                    //            of(
                    //                {
                    //                    type: MessagesActionsService.MESSAGES_SUCCESS,
                    //                    payload: {
                    //                        response
                    //                    }
                    //                },
                    //                {
                    //                    type: MessagesActionsService.QUEUE_MESSAGES,
                    //                    payload: {
                    //                        messagesQueue: toQueue,
                    //                        messages: response.result,
                    //                    }
                    //                }
                    //            )
                    //        );
                    //    } else {
                    //        return of(
                    //            {
                    //                type: MessagesActionsService.MESSAGES_SUCCESS,
                    //                payload: {
                    //                    response
                    //                }
                    //            });
                    //    }
                }), catchError(function (error) { return of({
                    type: MessagesActionsService.MESSAGES_ERROR,
                    error: error
                }); })));
            }));
        };
        this.fetchModalMessages = function (action$) {
            return action$.ofType(MessagesActionsService.FETCH_MODAL_MESSAGES).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // use concat so these actions happen sequentially
                return concat(of({
                    type: MessagesActionsService.MODAL_MESSAGES_LOADING,
                    payload: null
                }), _this.http.get('/Inbox/GetUnrealModalMessages', {
                    params: {
                        'pageIndex': '0',
                        'pageSize': '1000'
                    }
                }).pipe(switchMap(function (response) {
                    if (response.totalCount !== 0) {
                        return concat(of({
                            type: MessagesActionsService.MODAL_MESSAGES_SUCCESS,
                            payload: {
                                response: response
                            }
                        }), of({
                            type: MessagesActionsService.QUEUE_MESSAGES,
                            payload: {
                                messagesQueue: response.result
                            }
                        }));
                    }
                    return of({
                        type: MessagesActionsService.MODAL_MESSAGES_SUCCESS,
                        payload: {
                            response: response
                        }
                    });
                }), catchError(function (error) { return of({
                    type: MessagesActionsService.MODAL_MESSAGES_ERROR,
                    error: error
                }); })));
            }));
        };
        /**
         *
         * This is called after some messages have been queued
         *
         */
        this.queueMessages = function (action$, state$) {
            return action$.ofType(MessagesActionsService.QUEUE_MESSAGES).pipe(switchMap(function (_a) {
                // check if we can pop the first message
                var payload = _a.payload;
                var modalInUse = !!state$.value.ui.activeModal;
                if (modalInUse) {
                    return empty();
                }
                return of({
                    type: MessagesActionsService.POP_MESSAGE
                });
            }));
        };
        this.toggleMessage = function (action$, state$) {
            return action$.ofType(MessagesActionsService.TOGGLE_MESSAGE).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var params = new HttpParams({
                    fromObject: {
                        id: payload.id
                    }
                });
                return _this.http.patch(payload.status ? '/Inbox/MarkAsUnread' : '/Inbox/MarkAsRead', {}, {
                    params: params
                }).pipe(switchMap(function (payload2) { return of({
                    type: MessagesActionsService.TOGGLE_MESSAGE_SUCCESS,
                    payload: payload
                }); }), catchError(function (err) { return of({
                    type: MessagesActionsService.TOGGLE_MESSAGE_SUCCESS,
                    payload: payload
                }); }));
            }));
        };
        /**
         *
         * Open message as a modal, and update the queue
         *
         */
        this.popMessage = function (action$, state$) {
            return action$.ofType(MessagesActionsService.POP_MESSAGE).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var messages = state$.value.messages.messagesQueue;
                if (!messages.length) {
                    // nothing to show
                    return empty();
                }
                // use the first item in the queue
                var message = messages[0];
                // the modal will internally call popMessage when it is closed
                var modalRef = _this.modal.open(ModalMessageComponent, {
                    backdrop: 'static',
                    keyboard: false,
                    size: 'lg'
                });
                var componentInstance = modalRef.componentInstance;
                // set the values
                componentInstance.id = message.id;
                componentInstance.title = message.title;
                componentInstance.message = message.message;
                // emit action to remove message from queue
                return concat(of({
                    type: MessagesActionsService.DISCARD_MESSAGE,
                    payload: {
                        id: message.id
                    }
                }), of({
                    type: MessagesActionsService.TOGGLE_MESSAGE,
                    payload: {
                        id: message.id,
                        status: false
                    }
                }));
            }));
        };
    }
    return MessagesEpicsService;
}());
export { MessagesEpicsService };
