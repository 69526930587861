import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';
import { ISellingItemStore } from '../../../interfaces/SellingItem.interface';

@Injectable()
export class CartActionsService {

    static readonly FETCH_CART = '@cart/FETCH_CART';
    static readonly CART_LOADING = '@cart/CART_LOADING';
    static readonly CART_SUCCESS = '@cart/CART_SUCCESS';
    static readonly CART_SUCCESS_EMPTY = '@cart/CART_SUCCESS_EMPTY';
    static readonly CART_ERROR = '@cart/CART_ERROR';
    static readonly CART_EXPIRED = '@cart/CART_EXPIRED';
    static readonly CART_RESET = '@cart/CART_RESET';

    static readonly SET_WARNING_ZONE = '@cart/SET_WARNING_ZONE';
    static readonly SET_DANGER_ZONE = '@cart/SET_DANGER_ZONE';

    static readonly ADD_TO_CART_CONFIRM = '@cart/ADD_TO_CART_CONFIRM';
    static readonly ADD_TO_CART_QUEUE = '@cart/ADD_TO_CART_QUEUE';
    static readonly ADD_TO_CART_DEQUEUE = '@cart/ADD_TO_CART_DEQUEUE';
    static readonly ADD_TO_CART_FROM_QUEUE = '@cart/ADD_TO_CART_FROM_QUEUE';

    static readonly ADD_TO_CART_INVALID_VEHICLE = '@cart/ADD_TO_CART_INVALID_VEHICLE';

    static readonly ADD_TO_CART = '@cart/ADD_TO_CART';
    static readonly ADD_TO_CART_LOADING = '@cart/ADD_TO_CART_LOADING';
    static readonly ADD_TO_CART_SUCCESS = '@cart/ADD_TO_CART_SUCCESS';
    static readonly ADD_TO_CART_ERROR = '@cart/ADD_TO_CART_ERROR';
    static readonly ADD_TO_CART_IN_CART = '@cart/ADD_TO_CART_IN_CART';
    static readonly ADD_TO_CART_UNAVAILABLE = '@cart/ADD_TO_CART_UNAVAILABLE';
    static readonly ADD_TO_CART_REJECTED = '@cart/ADD_TO_CART_REJECTED';

    static readonly REMOVE_FROM_CART = '@cart/REMOVE_FROM_CART';
    static readonly REMOVE_FROM_CART_LOADING = '@cart/REMOVE_FROM_CART_LOADING';
    static readonly REMOVE_FROM_CART_SUCCESS = '@cart/REMOVE_FROM_CART_SUCCESS';
    static readonly REMOVE_FROM_CART_ERROR = '@cart/REMOVE_FROM_CART_ERROR';

    static readonly CHECKOUT_CART_CONFIRM = '@cart/CHECKOUT_CART_CONFIRM';
    static readonly CHECKOUT_CART = '@cart/CHECKOUT_CART';
    static readonly CHECKOUT_CART_LOADING = '@cart/CHECKOUT_CART_LOADING';
    static readonly CHECKOUT_CART_SUCCESS = '@cart/CHECKOUT_CART_SUCCESS';
    static readonly CHECKOUT_CART_ERROR = '@cart/CHECKOUT_CART_ERROR';

    static readonly DELETE_CART = '@cart/DELETE_CART';
    static readonly DELETE_CART_LOADING = '@cart/DELETE_CART_LOADING';
    static readonly DELETE_CART_SUCCESS = '@cart/DELETE_CART_SUCCESS';
    static readonly DELETE_CART_ERROR = '@cart/DELETE_CART_ERROR';

    static readonly FETCH_PAYMENTMETHODS = '@cart/FETCH_PAYMENTMETHODS';
    static readonly PAYMENTMETHODS_LOADING = '@cart/PAYMENTMETHODS_LOADING';
    static readonly PAYMENTMETHODS_SUCCESS = '@cart/PAYMENTMETHODS_SUCCESS';
    static readonly PAYMENTMETHODS_ERROR = '@cart/PAYMENTMETHODS_ERROR';

    static readonly FETCH_CART_BRANCHES = '@cart/FETCH_CART_BRANCHES';
    static readonly CART_BRANCHES_LOADING = '@cart/CART_BRANCHES_LOADING';
    static readonly CART_BRANCHES_SUCCESS = '@cart/CART_BRANCHES_SUCCESS';
    static readonly CART_BRANCHES_ERROR = '@cart/CART_BRANCHES_ERROR';

    static readonly SHOW_CHECKOUT_ERROR_MODAL = '@sales/SHOW_CHECKOUT_ERROR_MODAL';

    static readonly RETURN_TO_SALES = '@cart/RETURN_TO_SALES';

    /**
     *
     * CART
     *
     */

    @dispatch()
    addToCartConfirm = (payload: ISellingItemStore): AppAction => ({
        type: CartActionsService.ADD_TO_CART_CONFIRM,
        payload
    })

    @dispatch()
    addToCart = (payload: ISellingItemStore): AppAction => ({
        type: CartActionsService.ADD_TO_CART,
        payload
    })

    @dispatch()
    removeFromCart = (payload: ISellingItemStore): AppAction => ({
        type: CartActionsService.REMOVE_FROM_CART,
        payload
    })

    @dispatch()
    setWarningZone = (payload: boolean): AppAction => ({
        type: CartActionsService.SET_WARNING_ZONE,
        payload
    })

    @dispatch()
    setDangerZone = (payload: boolean): AppAction => ({
        type: CartActionsService.SET_DANGER_ZONE,
        payload
    })

    @dispatch()
    checkoutCart = (payload: {
        sellingAreaId: string,
        selectedPaymentMethodId?: string
    } = null): AppAction => ({
        type: CartActionsService.CHECKOUT_CART,
        payload
    })

    @dispatch()
    checkoutCartConfirm = (
        payload: {
            sellingAreaId: string,
            selectedPaymentMethodId?: string
        } = null): AppAction => ({
            type: CartActionsService.CHECKOUT_CART_CONFIRM,
            payload
        })

    @dispatch()
    fetchCartBranches = (payload: { latitude: number, longitude: number }): AppAction => ({
        type: CartActionsService.FETCH_CART_BRANCHES,
        payload
    })

    @dispatch()
    fetchPaymentMethods = (payload: { area }): AppAction => ({
        type: CartActionsService.FETCH_PAYMENTMETHODS,
        payload
    })

}
