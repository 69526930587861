import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';
import { PaginatedRequestParams } from '../../../interfaces/http.interface';

@Injectable()
export class FavouritesActionsService {


    static readonly FETCH_FAVOURITES = '@favourites/FETCH_FAVOURITES';
    static readonly FAVOURITES_LOADING = '@favourites/FAVOURITES_LOADING';
    static readonly FAVOURITES_LOADING_MORE = '@favourites/FAVOURITES_LOADING_MORE';
    static readonly FAVOURITES_SUCCESS = '@favourites/FAVOURITES_SUCCESS';
    static readonly FAVOURITES_ERROR = '@favourites/FAVOURITES_ERROR';

    static readonly TOGGLE_FAVOURITE = '@favourites/TOGGLE_FAVOURITE';
    static readonly TOGGLE_FAVOURITE_SUCCESS = '@favourites/TOGGLE_FAVOURITE_SUCCESS';
    static readonly TOGGLE_FAVOURITE_ERROR = '@favourites/TOGGLE_FAVOURITE_ERROR';

    static readonly SET_PARAMS = '@favourites/SET_PARAMS';
    static readonly SET_PARAMS_NEXT_PAGE = '@favourites/SET_PARAMS_NEXT_PAGE';

    static readonly REMOVE_FAVOURITE_FROM_RESULTS = '@favourites/REMOVE_FAVOURITE_FROM_RESULTS';

    /**
     *
     * FAVOURITES
     *
     */

    @dispatch()
    fetchFavourites = (): AppAction => ({
        type: FavouritesActionsService.FETCH_FAVOURITES,
    })

    @dispatch()
    toggleFavourite = (payload): AppAction => ({
        type: FavouritesActionsService.TOGGLE_FAVOURITE,
        payload
    })

    @dispatch()
    setParams = (payload: PaginatedRequestParams): AppAction => ({
        type: FavouritesActionsService.SET_PARAMS,
        payload
    })

    @dispatch()
    setParamsNextPage = (): AppAction => ({
        type: FavouritesActionsService.SET_PARAMS_NEXT_PAGE
    })

    @dispatch()
    removeFavouriteFromResults = (payload: { sellingItemId: string }): AppAction => ({
        type: FavouritesActionsService.REMOVE_FAVOURITE_FROM_RESULTS,
        payload
    })

}
