import { HttpClient } from '@angular/common/http';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { CmsPagesActionsService } from '../actions/cms-pages.actions';
import { concat, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../../../interfaces/store.interface';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CmsPagesEpicsService {

    constructor(
        private http: HttpClient,
        private domSanitizer: DomSanitizer
    ) { }

    /**
     *
     * FETCH PAGE
     *
     */

    fetchPage = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(CmsPagesActionsService.FETCH_PAGE);

        return action$.ofType(CmsPagesActionsService.FETCH_PAGE).pipe(
            switchMap(({
                payload
            }) => {

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: CmsPagesActionsService.PAGE_LOADING,
                        payload: null
                    }),
                    //    this.http.get('/Cms/getPage',
                    //        {
                    //            params: {
                    //                'pageId': payload
                    //            }
                    //        })
                    of({
                        ...FAKE_PAGE_RESPONSE,
                        pageId: payload
                    })
                        .pipe(
                            switchMap((response: any) => {
                                response.body = this.domSanitizer.bypassSecurityTrustHtml(response.body);
                                return concat(
                                    of(
                                        {
                                            type: CmsPagesActionsService.PAGE_SUCCESS,
                                            payload: response
                                        }
                                    )
                                );
                            }),
                            catchError(error => of({
                                type: CmsPagesActionsService.PAGE_ERROR,
                                error
                            })),
                            // cancel this if a new page load arrives
                            takeUntil(cancel$))
                );

            }));

    }

}

const FAKE_PAGE_RESPONSE = {
    pageId: 1,
    body: `
    <p>Testo normale</p>

<h1>Titolo 1</h1>

<h2>Titolo 2</h2>

<h3>Titolo 3</h3>

<h4>Titolo 4</h4>

<h5>Titolo 5</h5>

<h6>Titolo 6</h6>

<pre>
Formattato</pre>

<address>Indirizzo</address>

<div>Paragrafo DIV</div>

<p>&nbsp;</p>

<h2 style="font-style:italic">Stile Italic Title</h2>

<p>Marker</p>

<p><big>Big</big></p>

<p><small>Small</small></p>

<p><tt>Typewriter</tt></p>

<p><code>Computer code</code></p>

<p><kbd>Keyboard phrase</kbd></p>

<p><samp>Sample text</samp></p>

<p><var>Variable</var></p>

<p><del>Deleted text</del></p>

<p><ins>Inserted text</ins></p>

<p><cite>Cited work</cite></p>

<p><q>Inline quotation</q></p>

<p><span dir="rtl">RTL</span></p>

<p><span dir="ltr">LTR</span></p>

<p>&nbsp;</p>

<p><strong>Bold</strong></p>

<p><em>Italic</em></p>

<p><u>Underlined</u></p>

<p><s>Striked</s></p>

<p><sub>Pedice</sub></p>

<p><sup>Apice</sup></p>

<p>&nbsp;</p>

<ol>
	<li>Elenco numerato 1</li>
	<li>num 2</li>
</ol>

<ul>
	<li>Elenco puntato 1</li>
	<li>num 2</li>
</ul>

<p>&nbsp;</p>

<blockquote>
<p>Citazione</p>
</blockquote>

<p><a href="http://www.google.it">Link</a></p>

<table border="1" cellpadding="1" cellspacing="1" style="width:500px">
	<tbody>
		<tr>
			<td>a1</td>
			<td>a2</td>
		</tr>
		<tr>
			<td>b1</td>
			<td>b2</td>
		</tr>
		<tr>
			<td>c1</td>
			<td>c2</td>
		</tr>
	</tbody>
</table>

<p>&nbsp;</p>

<p>&nbsp;</p>

<hr />
<p>&nbsp;</p>

    `
};
