import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';
import { CartValidationMessage } from '../../../interfaces/cart.interface';

@Injectable()
export class CartValidationActionsService {

    static readonly VALIDATE_CART = '@cartValidation/VALIDATE_CART';
    static readonly VALIDATE_CART_QUEUE = '@cartValidation/VALIDATE_CART_QUEUE';

    static readonly SET_VALIDITY = '@cartValidation/SET_VALIDITY';

    static readonly FETCH_CART_CONFIG = '@cartValidation/FETCH_CART_CONFIG';
    static readonly CART_CONFIG_LOADING = '@cartValidation/CART_CONFIG_LOADING';
    static readonly CART_CONFIG_SUCCESS = '@cartValidation/CART_CONFIG_SUCCESS';
    static readonly CART_CONFIG_ERROR = '@cartValidation/CART_CONFIG_ERROR';
    static readonly CART_CONFIG_READY = '@cartValidation/CART_CONFIG_READY';
    static readonly CART_CONFIG_EXPIRED = '@cartValidation/CART_CONFIG_EXPIRED';

    static readonly SHOW_VALIDATION_RESULT = '@cartValidation/SHOW_VALIDATION_RESULT';

    /**
     *
     * CART VALIDATION
     *
     */

    @dispatch()
    validateCart = (): AppAction => ({
        type: CartValidationActionsService.VALIDATE_CART
    })

    @dispatch()
    setValidity = (payload: CartValidationMessage[]): AppAction => ({
        type: CartValidationActionsService.SET_VALIDITY,
        payload
    })

}
