import { Injectable } from '@angular/core';

// import * as moment from 'moment'; - added globally to prevent all locales being included

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateService {

    toUTC (value: any): number {
        return moment(value).valueOf();
    }

    formatDate (utc: number): string {
        return moment(utc).format('L');
    }

    formatDateTime (utc: number): string {
        return moment(utc).format('L LT');
    }

    formatDateStruct (value: NgbDateStruct): string {
        // prepare the date
        const date = {
            year: value.year,
            month: value.month - 1,
            day: value.day
        };

        // convert to milliseconds
        const asDate = this.toUTC(date); // moment (almost) understands NgbDateStruct! https://momentjs.com/docs/#/parsing/object/

        // return the date struct in the localised format
        return this.formatDate(asDate);

    }

}
