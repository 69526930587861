import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

import { SalesArea, SalesFilterPanel, SalesParams, SalesVehicleUnavailableReason } from '../../../interfaces/sales.interface';

@Injectable()
export class SalesActionsService {

    static readonly SET_AREA = '@sales/SET_AREA';
    static readonly SET_PARAMS = '@sales/SET_PARAMS';
    static readonly SET_PARAMS_NEXT_PAGE = '@sales/SET_PARAMS_NEXT_PAGE';


    static readonly SET_MANUFACTURER = '@sales/SET_MANUFACTURER';
    static readonly SET_SERIES = '@sales/SET_SERIES';
    static readonly SET_SELECTED_INDEX = '@sales/SET_SELECTED_INDEX';
    static readonly SET_SELECTED_BUNDLE_INDEX = '@sales/SET_SELECTED_BUNDLE_INDEX';
    static readonly SET_SELECTED_RESULT = '@sales/SET_SELECTED_RESULT';
    static readonly SET_SELECTED_FULLY_OPEN = '@sales/SET_SELECTED_FULLY_OPEN';
    static readonly SET_THUMBS_INDEX = '@sales/SET_THUMBS_INDEX';
    static readonly SET_AS_FOR_SALE = '@sales/SET_AS_FOR_SALE';

    static readonly FETCH_SELLINGITEM_FILTERS = '@sales/FETCH_SELLINGITEM_FILTERS';
    static readonly SELLINGITEM_FILTERS_LOADING = '@sales/SELLINGITEM_FILTERS_LOADING';
    static readonly SELLINGITEM_FILTERS_SUCCESS = '@sales/SELLINGITEM_FILTERS_SUCCESS';
    static readonly SELLINGITEM_FILTERS_ERROR = '@sales/SELLINGITEM_FILTERS_ERROR';

    static readonly APPLY_SELLINGITEM_FILTERS = '@sales/APPLY_SELLINGITEM_FILTERS';

    static readonly RELOAD_SELLINGITEMS = '@sales/RELOAD_SELLINGITEMS';
    static readonly FETCH_SELLINGITEMS = '@sales/FETCH_SELLINGITEMS';
    static readonly SELLINGITEMS_LOADING = '@sales/SELLINGITEMS_LOADING';
    static readonly SELLINGITEMS_LOADING_MORE = '@sales/SELLINGITEMS_LOADING_MORE';
    static readonly SELLINGITEMS_SUCCESS = '@sales/SELLINGITEMS_SUCCESS';
    static readonly SELLINGITEMS_ERROR = '@sales/SELLINGITEMS_ERROR';

    static readonly FETCH_SELLINGITEM = '@sales/FETCH_SELLINGITEM';
    static readonly SELLINGITEM_LOADING = '@sales/SELLINGITEM_LOADING';
    static readonly SELLINGITEM_SUCCESS = '@sales/SELLINGITEM_SUCCESS';
    static readonly SELLINGITEM_ERROR = '@sales/SELLINGITEM_ERROR';

    static readonly HIDE_SELLINGITEM = '@sales/HIDE_SELLINGITEM';
    static readonly REMOVE_SELLINGITEM_FROM_RESULTS = '@sales/REMOVE_SELLINGITEM_FROM_RESULTS';
    static readonly SHOW_SELLINGITEM_UNAVAILABLE_MODAL = '@sales/SHOW_SELLINGITEM_UNAVAILABLE_MODAL';

    static readonly SET_SELLINGITEM_AS_PURCHASABLE = '@sales/SET_SELLINGITEM_AS_PURCHASABLE';
    static readonly SET_SELLINGITEM_AS_NOT_PURCHASABLE = '@sales/SET_SELLINGITEM_AS_NOT_PURCHASABLE';

    static readonly FETCH_ACTIVE_AUCTIONS_FOR_USER = '@sales/FETCH_ACTIVE_AUCTIONS_FOR_USER';
    static readonly ACTIVE_AUCTIONS_FOR_USER_SUCCESS = '@sales/ACTIVE_AUCTIONS_FOR_USER_SUCCESS';
    static readonly ACTIVE_AUCTIONS_FOR_USER_ERROR = '@sales/ACTIVE_AUCTIONS_FOR_USER_ERROR';

    /**
     *
     * FETCH
     *
     */

    @dispatch()
    fetchSellingItem = (payload: { sellingItemId: string }) => ({
        type: SalesActionsService.FETCH_SELLINGITEM,
        payload
    })

    /**
     *
     * SET
     *
     */

    @dispatch()
    setArea = (payload: SalesArea): AppAction => ({
        type: SalesActionsService.SET_AREA,
        payload
    })

    @dispatch()
    setParams = (payload: SalesParams): AppAction => ({
        type: SalesActionsService.SET_PARAMS,
        payload
    })

    @dispatch()
    setParamsNextPage = (): AppAction => ({
        type: SalesActionsService.SET_PARAMS_NEXT_PAGE
    })

    @dispatch()
    setManufacturer = (payload: string): AppAction => ({
        type: SalesActionsService.SET_MANUFACTURER,
        payload
    })

    @dispatch()
    setSeries = (payload: string): AppAction => ({
        type: SalesActionsService.SET_SERIES,
        payload
    })

    @dispatch()
    setSelectedResult = (payload: { vehicleId: string }): AppAction => ({
        type: SalesActionsService.SET_SELECTED_RESULT,
        payload
    })

    @dispatch()
    setSelectedIndex = (payload: number): AppAction => ({
        type: SalesActionsService.SET_SELECTED_INDEX,
        payload
    })

    @dispatch()
    setSelectedBundleIndex = (payload: number): AppAction => ({
        type: SalesActionsService.SET_SELECTED_BUNDLE_INDEX,
        payload
    })

    @dispatch()
    setThumbsIndex = (payload: number): AppAction => ({
        type: SalesActionsService.SET_THUMBS_INDEX,
        payload
    })

    @dispatch()
    setAsForSale = (payload: { vehicleId: string }): AppAction => ({
        type: SalesActionsService.SET_AS_FOR_SALE, // this only triggers the Entities reducer
        payload
    })

    /**
     *
     * VEHICLE FILTERS
     *
     */

    @dispatch()
    applyVehicleFilters = (payload: any): AppAction => ({
        type: SalesActionsService.APPLY_SELLINGITEM_FILTERS,
        payload
    })

    /**
     *
     * RELOAD
     *
     */

    @dispatch()
    reloadVehicles = (): AppAction => ({
        type: SalesActionsService.RELOAD_SELLINGITEMS
    })

    /**
     *
     * HIDE VEHICLE
     *
     */

    @dispatch()
    hideVehicle = (payload: { vehicleId: string, reason?: SalesVehicleUnavailableReason }): AppAction => ({
        type: SalesActionsService.HIDE_SELLINGITEM,
        payload
    })

    @dispatch()
    showVehicleUnavailableModal = (payload: { reason?: SalesVehicleUnavailableReason }): AppAction => ({
        type: SalesActionsService.SHOW_SELLINGITEM_UNAVAILABLE_MODAL,
        payload
    })

    @dispatch()
    removeVehicleFromResults = (payload: { vehicleId: string }): AppAction => ({
        type: SalesActionsService.REMOVE_SELLINGITEM_FROM_RESULTS,
        payload
    })

    /**
     *
     * CAN/CANNOT BE PURCHASED
     *
     */

    @dispatch()
    setVehicleAsPurchasable = (payload: { vehicleId: string }): AppAction => ({
        type: SalesActionsService.SET_SELLINGITEM_AS_PURCHASABLE,
        payload
    })

    @dispatch()
    setVehicleAsNotPurchasable = (payload: { vehicleId: string }): AppAction => ({
        type: SalesActionsService.SET_SELLINGITEM_AS_NOT_PURCHASABLE,
        payload
    })

}
