import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var SellingAreasActionsService = /** @class */ (function () {
    function SellingAreasActionsService() {
        /**
         *
         * FETCH SELLING AREAS
         *
         */
        this.fetchSellingAreas = function () { return ({
            type: SellingAreasActionsService.FETCH_SELLING_AREAS
        }); };
        this.fetchSellingAreaFull = function (payload) { return ({
            type: SellingAreasActionsService.FETCH_SELLING_AREA_FULL,
            payload: payload
        }); };
    }
    SellingAreasActionsService.FETCH_SELLING_AREAS = '@selling_areas/FETCH_SELLING_AREAS';
    SellingAreasActionsService.FETCH_SELLING_AREAS_SUCCESS = '@selling_areas/FETCH_SELLING_AREAS_SUCCESS';
    SellingAreasActionsService.FETCH_SELLING_AREAS_FAILURE = '@selling_areas/FETCH_SELLING_AREAS_FAILURE';
    SellingAreasActionsService.FETCH_SELLING_AREA_FULL = '@selling_areas/FETCH_SELLING_AREA_FULL';
    SellingAreasActionsService.FETCH_SELLING_AREA_FULL_SUCCESS = '@selling_areas/FETCH_SELLING_AREA_FULL_SUCCESS';
    SellingAreasActionsService.FETCH_SELLING_AREA_FULL_FAILURE = '@selling_areas/FETCH_SELLING_AREA_FULL_FAILURE';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SellingAreasActionsService.prototype, "fetchSellingAreas", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], SellingAreasActionsService.prototype, "fetchSellingAreaFull", void 0);
    return SellingAreasActionsService;
}());
export { SellingAreasActionsService };
