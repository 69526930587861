import { NgRedux } from '@angular-redux/store';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, Observer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from '../../../../interfaces/store.interface';

@Injectable()
export class TenantGuard implements CanActivate {

    constructor(private ngRedux: NgRedux<AppState>) { }

    canActivate(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Observable((observer: Observer<boolean>) => {
            this.ngRedux.select(['tenant', 'id']).pipe(
                filter(x => x !== undefined && x !== null)
            ).subscribe((tenantId: string) => {
                let allowedTenantIds = next.data ? next.data.allowedTenantIds as Array<string> : null;
                const allow = !allowedTenantIds || allowedTenantIds.length === 0 || allowedTenantIds.filter( f => {
                    return f.toUpperCase() === tenantId.toUpperCase()
                }).length > 0
                observer.next(allow);
                observer.complete();
            });
        });
    }

}
