import * as tslib_1 from "tslib";
var FaviconService = /** @class */ (function () {
    function FaviconService() {
    }
    FaviconService.prototype.setFavicon = function (domain) {
        if (!domain) {
            return;
        }
        var href = "/themes/" + domain.toLowerCase() + "/assets/images/favicon.ico";
        var augmentedHref = this.cacheBustHref(href);
        this.removeFavicons();
        this.addFavicon(augmentedHref);
    };
    FaviconService.prototype.addFavicon = function (href) {
        var linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/x-icon');
        linkElement.setAttribute('href', href);
        document.head.appendChild(linkElement);
    };
    FaviconService.prototype.removeFavicons = function () {
        var e_1, _a;
        var linkElements = document.querySelectorAll('link[rel ~= icon]');
        try {
            for (var _b = tslib_1.__values(Array.from(linkElements)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var linkElement = _c.value;
                linkElement.parentNode.removeChild(linkElement);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    FaviconService.prototype.cacheBustHref = function (href) {
        var augmentedHref = (href.indexOf('?') === -1)
            ? href + "?t=" + Date.now()
            : href + "&t=" + Date.now();
        return (augmentedHref);
    };
    return FaviconService;
}());
export { FaviconService };
