import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { ModalMessageComponent } from '../../shared/components/modal-message/modal-message.component';
import { IMessage } from '../../../interfaces/messages/Messages.interface';
import { MessagesActionsService } from '../actions/messages.actions';
import { NgRedux } from '@angular-redux/store';
import { Observable, concat, of, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../../../interfaces/store.interface';
import { ModalService } from '../../shared/services/modal/modal.service';
import { UserService } from '../../shared/services/user/user.service';
import { UiActionsService } from '../actions/ui.actions';
import { switchMap, catchError } from 'rxjs/operators';
import { ScrollActionsService } from '../actions/scroll.actions';


@Injectable()
export class MessagesEpicsService {

    constructor(
        private http: HttpClient,
        private modal: ModalService,
        private user: UserService,
    ) { }

    setParams = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(
            MessagesActionsService.SET_PARAMS,
            MessagesActionsService.SET_PARAMS_NEXT_PAGE
        ).pipe(
            switchMap(({
                type
            }) => {

                if (type === MessagesActionsService.SET_PARAMS) {
                    // we need to reset the scroll
                    return of(
                        {
                            type: ScrollActionsService.SCROLL_TO,
                            payload: null
                        }, {
                            type: MessagesActionsService.FETCH_MESSAGES,
                            payload: null
                        }
                    );
                }

                return of({
                    type: MessagesActionsService.FETCH_MESSAGES
                });

            }));

    }

    /**
     *
     * MESSAGES
     *
     */

    fetchMessagesStatistics = (action$: ActionsObservable<any>) => {
        return action$.ofType(MessagesActionsService.FETCH_MESSAGES_STATISTICS).pipe(
            switchMap(() => {
                return this.http.get('/Inbox/GetInboxStatistics').pipe(
                    switchMap((response: any) => {

                        if (response.unreadMessages) {

                            return concat(
                                of({
                                    type: MessagesActionsService.MESSAGES_STATISTICS_SUCCESS,
                                    payload: response
                                }),
                                of({
                                    type: MessagesActionsService.FETCH_MODAL_MESSAGES
                                })
                            );
                        }

                        return of({
                            type: MessagesActionsService.MESSAGES_STATISTICS_SUCCESS,
                            payload: response
                        });
                    }),
                    catchError(error => of({
                        type: MessagesActionsService.MESSAGES_ERROR,
                        error
                    })));
            }));
    }


    fetchMessages = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(MessagesActionsService.FETCH_MESSAGES).pipe(
            switchMap(({
                payload
            }) => {

                const messagesState = state$.value.messages;

                let query = new HttpParams();

                // apply all values to the query string
                Object.keys(messagesState.params).forEach((key: string) => {
                    if (messagesState.params[key] || messagesState.params[key] === 0) {
                        query = query.append(key, messagesState.params[key].toString());
                    }
                });

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: messagesState.params.pageIndex > 0 ?
                            MessagesActionsService.MESSAGES_LOADING_MORE : MessagesActionsService.MESSAGES_LOADING,
                        payload: null
                    }),
                    this.http.get('/Inbox/List', { params: query }).pipe(
                        switchMap((response: any) => {

                            return of(
                                {
                                    type: MessagesActionsService.MESSAGES_SUCCESS,
                                    payload: {
                                        response
                                    }
                                });

                            //    const toQueue = response.result.filter((result: IMessage) => {
                            //        // ignore messages that we have already seen
                            //        return !result.read && result.showModal;
                            //    });
                            //
                            //    if (toQueue.length !== 0) {
                            //        return concat(
                            //            of(
                            //                {
                            //                    type: MessagesActionsService.MESSAGES_SUCCESS,
                            //                    payload: {
                            //                        response
                            //                    }
                            //                },
                            //                {
                            //                    type: MessagesActionsService.QUEUE_MESSAGES,
                            //                    payload: {
                            //                        messagesQueue: toQueue,
                            //                        messages: response.result,
                            //                    }
                            //                }
                            //            )
                            //        );
                            //    } else {
                            //        return of(
                            //            {
                            //                type: MessagesActionsService.MESSAGES_SUCCESS,
                            //                payload: {
                            //                    response
                            //                }
                            //            });
                            //    }

                        }),
                        catchError(error => of({
                            type: MessagesActionsService.MESSAGES_ERROR,
                            error
                        })))
                );

            }));

    }

    fetchModalMessages = (action$: ActionsObservable<any>) => {

        return action$.ofType(MessagesActionsService.FETCH_MODAL_MESSAGES).pipe(
            switchMap(({
                payload
            }) => {

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: MessagesActionsService.MODAL_MESSAGES_LOADING,
                        payload: null
                    }),
                    this.http.get('/Inbox/GetUnrealModalMessages', {
                        params: {
                            'pageIndex': '0',
                            'pageSize': '1000'
                        }
                    }).pipe(
                        switchMap((response: any) => {

                            if (response.totalCount !== 0) {
                                return concat(
                                    of(
                                        {
                                            type: MessagesActionsService.MODAL_MESSAGES_SUCCESS,
                                            payload: {
                                                response
                                            }
                                        }
                                    ),
                                    of(
                                        {
                                            type: MessagesActionsService.QUEUE_MESSAGES,
                                            payload: {
                                                messagesQueue: response.result
                                            }
                                        }
                                    )
                                );
                            }

                            return of(
                                {
                                    type: MessagesActionsService.MODAL_MESSAGES_SUCCESS,
                                    payload: {
                                        response
                                    }
                                }
                            );

                        }),
                        catchError(error => of({
                            type: MessagesActionsService.MODAL_MESSAGES_ERROR,
                            error
                        })))
                );

            }));

    }

    /**
     *
     * This is called after some messages have been queued
     *
     */

    queueMessages = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(MessagesActionsService.QUEUE_MESSAGES).pipe(
            switchMap(({
                payload
            }) => {

                // check if we can pop the first message

                const modalInUse = !!state$.value.ui.activeModal;

                if (modalInUse) {
                    return empty();
                }

                return of({
                    type: MessagesActionsService.POP_MESSAGE
                });

            }));

    }

    toggleMessage = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(MessagesActionsService.TOGGLE_MESSAGE).pipe(
            switchMap(({
                payload
            }) => {

                const params = new HttpParams({
                    fromObject: {
                        id: payload.id
                    }
                });

                return this.http.patch(payload.status ? '/Inbox/MarkAsUnread' : '/Inbox/MarkAsRead', {}, {
                    params
                }).pipe(
                    switchMap(payload2 => of({
                        type: MessagesActionsService.TOGGLE_MESSAGE_SUCCESS,
                        payload: payload
                    })),
                    catchError(err => of({
                        type: MessagesActionsService.TOGGLE_MESSAGE_SUCCESS,
                        payload
                    })));

            }));

    }

    /**
     *
     * Open message as a modal, and update the queue
     *
     */

    popMessage = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(MessagesActionsService.POP_MESSAGE).pipe(
            switchMap(({
                payload
            }) => {

                const messages = state$.value.messages.messagesQueue;

                if (!messages.length) {
                    // nothing to show
                    return empty();
                }

                // use the first item in the queue
                const message = messages[0];

                // the modal will internally call popMessage when it is closed
                const modalRef = this.modal.open(ModalMessageComponent, {
                    backdrop: 'static',
                    keyboard: false,
                    size: 'lg'
                });

                const componentInstance = modalRef.componentInstance;

                // set the values
                componentInstance.id = message.id;
                componentInstance.title = message.title;
                componentInstance.message = message.message;


                // emit action to remove message from queue
                return concat(
                    of({
                        type: MessagesActionsService.DISCARD_MESSAGE,
                        payload: {
                            id: message.id
                        }
                    }),
                    of({
                        type: MessagesActionsService.TOGGLE_MESSAGE,
                        payload: {
                            id: message.id,
                            status: false
                        }
                    })
                );

            }));

    }
}
