<div class="modal__inner">
    <h1 class="modal__title" i18n>{{'Modals.CartExpired.ModalTitle' | translate}}</h1>
    <div class="modal__content">
      <p i18n>{{'Modals.CartExpired.ModalContent' | translate}}.</p>
    </div>
</div>

<div class="modal__actions">
    <button type="button" class="button button--modal" appTouchable (click)="activeModal.dismiss()">
        <span class="button__label">{{'Commons.Ok' | translate}}</span>
    </button>
</div>
