import { Injectable, Renderer2 } from '@angular/core';

import { WindowRefService } from '../window-ref/window-ref.service';

import { EnvironmentService } from '../environment/environment.service';

interface GoogleAnalyticsEvent {
    event_action: string;
    event_category: string;
    event_label: string;
    value: number;
}

interface GoogleAnalyticsPayload {
    action?: string;
    category?: string;
    label?: string;
    value?: number;
}

type GoogleAnalyticsGtag = (actionType: string, type: string, options: GoogleAnalyticsEvent) => void;

@Injectable()
export class GoogleAnalyticsService {

    private enabled = false;

    constructor(
        private window: WindowRefService,
        private environmentService: EnvironmentService
    ) { }

    init(script: string) {

        const scriptWrapper: HTMLElement = this.window.nativeWindow.document.getElementById('post-body-scripts');
        if (!scriptWrapper) {
            return;
        }

        const theDocument = (this.window.nativeWindow.document as HTMLDocument);

        const scriptElem1: HTMLElement = theDocument.createElement('div');
        scriptElem1.innerHTML = script;
        for (let i = 0; i < scriptElem1.children.length; i++) {
            const origEl = scriptElem1.children.item(i);
            const newEl = theDocument.createElement(origEl.tagName);
            origEl.getAttributeNames().map((name, nameIndex) => {
                newEl.setAttribute(name, origEl.getAttribute(name));
            });

            newEl.appendChild(theDocument.createTextNode(origEl.innerHTML));
            scriptWrapper.appendChild(newEl);
        }

        this.enabled = true;

    }

    sendEvent(payload: GoogleAnalyticsPayload = {}) {
        if (!this.enabled) {
            return;
        }

        const options: GoogleAnalyticsEvent = {
            event_action: payload.action || 'click',
            event_category: payload.category || 'Uncategorized',
            event_label: payload.label || '',
            value: payload.value || 1
        };

        const gtag = this.window.nativeWindow.gtag as GoogleAnalyticsGtag;

        // send the event
        if (gtag) {
            gtag('send', 'event', options);
        }

        if (!this.environmentService.getEnvironment().production) {
            // log event to console
            this.logEvent('send', 'event', options);
        }

    }



    private logEvent(actionType: string, type: string, options: GoogleAnalyticsEvent) {
        console.log('Google Analytics: ' + actionType + ' - ' + type, options);
    }



}
