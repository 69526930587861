import { AppAction, CmsPageState } from '../../../interfaces/store.interface';
import { CmsPagesActionsService } from '../actions/cms-pages.actions';

const INITIAL_STATE: CmsPageState = {
    pageId: null,
    _loading: false,
    _loaded: false
};

export default function cmsPagesReducer (state: CmsPageState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * CMS PAGES
         *
         */

        case CmsPagesActionsService.FETCH_PAGE: {

            const nextState = {
                ...state,
                pageId: action.payload
            };

            return nextState;
        }

        case CmsPagesActionsService.PAGE_LOADING: {

            const nextState = {
                ...state,
                _loading: true,
                _loaded: false
            };

            return nextState;
        }

        case CmsPagesActionsService.PAGE_SUCCESS: {

            const nextState = {
                ...state,
                _loading: false,
                _loaded: true
            };

            return nextState;
        }

    }

    return state;

}
