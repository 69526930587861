import { Component, Input, OnChanges, OnDestroy, SimpleChanges, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { NgRedux } from '@angular-redux/store';

import { UiActionsService } from '../../../store/actions/ui.actions';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { AppState } from '../../../../interfaces/store.interface';
import { SalesActionsService } from '../../../store/actions/sales.actions';
import { ISellingItemStore, ISellingItem } from '../../../../interfaces/SellingItem.interface';
import { OrderActionsService } from '../../../store/actions/order.actions';
import { IDetailedVehicle } from '../../../../interfaces/Vehicle.interface';

@Component({
    selector: 'app-listing-item',
    templateUrl: './listing-item.component.html',
})
export class ListingItemComponent implements OnChanges, OnDestroy {

    @Input() sellingItem: ISellingItem;
    @Input() vehicle: IDetailedVehicle;
    @Input() mode = 'sellingItems';
    @Input() index: number;
    @Input() disabled = false;  // applies opacity
    @Input() removed = false;   // selling item removed - Applies strikes
    @Input() orderId: string;

    bundleVehicles: ISellingItem[] = [];
    private _sellingItem: ISellingItemStore;

    orderDetailsShown = false;

    constructor(
        private router: Router,
        private ngRedux: NgRedux<AppState>,
        private salesActions: SalesActionsService,
        private orderActions: OrderActionsService,
        private uiActions: UiActionsService,
        private googleAnalytics: GoogleAnalyticsService) {
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    ngOnDestroy() {
    }

    @HostBinding('class.listing__item--disabled') get disabledStatus() {
        return this.disabled;
    }

    @HostBinding('class.listing__item--removed') get removedStatus() {
        return this.removed;
    }

    open(event: Event) {
        if (this.mode === 'favourites') {
            this._goToSellingItem();
        } else if (this.mode === 'orders' || this.mode === 'sellingItems') {
            this.openDetails(event);
        }
    }

    openVehicleGallery(event: Event) {
        if (this.sellingItem && this.sellingItem._images.length) {
            this.uiActions.setGalleryImages({ images: this.sellingItem._images });
            this.uiActions.setGalleryIndex({ index: 0 });
            this.uiActions.openGallery();

            // tell Google Analytics
            this.googleAnalytics.sendEvent({
                category: 'Button',
                label: 'Car gallery: ' + this.sellingItem.id
            });
        }
    }

    openDetails(event: Event) {
        event.preventDefault();

        if (this.mode === 'orders') {
            this.orderActions.setSelectedIndex({
                orderId: this.orderId,
                index: this.index,
                isDeleted: this.removed
            });
        } else {
            this.salesActions.setSelectedIndex(this.index);
        }

        this.uiActions.openDetails();
    }

    closeDetails(event: Event) {
        event.preventDefault();

        if (this.mode === 'orders') {
            this.orderActions.setSelectedIndex({ orderId: null, index: -1, isDeleted: null }); // -1 mean no selection
        } else {
            this.salesActions.setSelectedIndex(-1); // -1 mean no selection
        }

        this.uiActions.closeDetails();
    }

    detailsShown(shown: boolean) {
        this.orderDetailsShown = shown;
    }

    hasListingLinkClassName() {

        if (this.mode === 'sellingItems' || this.mode === 'orders') {
            return true;
        }

        if (this.mode === 'favourites') {
            return this.sellingItem.isAvailable;
        }

        return false;
    }

    hasListingNoLinkClassName() {

        if (this.mode === 'cart' || this.mode === 'modal-cart') {
            return true;
        }

        return false;
    }

    private _goToSellingItem() {
        this.router.navigate(['/sales', this.sellingItem.sellingAreaId], {
            queryParams: {
                vin: this.sellingItem.vehicles[0].vin
            }
        });
    }
}
