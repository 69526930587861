import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var UserActionsService = /** @class */ (function () {
    function UserActionsService() {
        /**
         *
         * DESTROY USER
         *
         */
        this.destroy = function () { return ({
            type: UserActionsService.DESTROY
        }); };
        /**
         *
         * LOAD USER
         *
         */
        this.loadUser = function () { return ({
            type: UserActionsService.LOAD_USER
        }); };
        /**
         *
         * LOGIN / LOGOUT
         *
         */
        this.login = function () { return ({
            type: UserActionsService.LOGIN
        }); };
        this.logout = function () { return ({
            type: UserActionsService.LOGOUT
        }); };
        /**
         *
         * REFRESH TOKEN
         *
         */
        this.refreshToken = function () { return ({
            type: UserActionsService.REFRESH_TOKEN
        }); };
        /**
         *
         * SAVE USER INFO
         *
         */
        this.saveUSerInfo = function (params) { return ({
            type: UserActionsService.SAVE_USER_INFO,
            payload: params
        }); };
        this.flagAsReady = function () { return ({
            type: UserActionsService.READY
        }); };
    }
    UserActionsService.DESTROY = '@user/DESTROY';
    UserActionsService.LOGGED_IN = '@user/LOGGED_IN';
    UserActionsService.READY = '@user/READY';
    UserActionsService.LOAD_USER = '@user/LOAD_USER';
    UserActionsService.LOGIN = '@user/LOGIN';
    UserActionsService.LOGOUT = '@user/LOGOUT';
    UserActionsService.FETCH_IDENTITY = '@user/FETCH_IDENTITY';
    UserActionsService.IDENTITY_LOADING = '@user/IDENTITY_LOADING';
    UserActionsService.IDENTITY_SUCCESS = '@user/IDENTITY_SUCCESS';
    UserActionsService.IDENTITY_ERROR = '@user/IDENTITY_ERROR';
    UserActionsService.REFRESH_TOKEN = '@user/REFRESH_TOKEN';
    UserActionsService.REFRESH_TOKEN_SUCCESS = '@user/REFRESH_TOKEN_SUCCESS';
    UserActionsService.REFRESH_TOKEN_ERROR = '@user/REFRESH_TOKEN_ERROR';
    UserActionsService.FETCH_PROFILE = '@user/FETCH_PROFILE';
    UserActionsService.PROFILE_LOADING = '@user/PROFILE_LOADING';
    UserActionsService.PROFILE_SUCCESS = '@user/PROFILE_SUCCESS';
    UserActionsService.PROFILE_ERROR = '@user/PROFILE_ERROR';
    UserActionsService.FETCH_USER_INFO = '@user/FETCH_USER_INFO';
    UserActionsService.USER_INFO_SUCCESS = '@user/USER_INFO_SUCCESS';
    UserActionsService.USER_INFO_ERROR = '@user/USER_INFO_ERROR';
    UserActionsService.SAVE_USER_INFO = '@user/SAVE_USER_INFO';
    UserActionsService.SAVE_USER_INFO_SAVING = '@user/SAVE_USER_INFO_SAVING';
    UserActionsService.SAVE_USER_INFO_SUCCESS = '@user/SAVE_USER_INFO_SUCCESS';
    UserActionsService.SAVE_USER_INFO_ERROR = '@user/SAVE_USER_INFO_ERROR';
    UserActionsService.AUCTION_DISCLAIMER_ACCEPTED = '@user/AUCTION_DISCLAIMER_ACCEPTED';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "destroy", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "loadUser", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "login", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "logout", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "refreshToken", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "saveUSerInfo", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UserActionsService.prototype, "flagAsReady", void 0);
    return UserActionsService;
}());
export { UserActionsService };
