import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgRedux, select } from '@angular-redux/store';
import { Subscription } from 'rxjs';

import { AppState } from '../../../../interfaces/store.interface';
import { CartContents, CartValidity } from '../../../../interfaces/cart.interface';
import { ISellingItemStore, ISellingItem } from '../../../../interfaces/SellingItem.interface';

@Component({
    selector: 'app-modal-cart-validation',
    templateUrl: './modal-cart-validation.component.html',
    styleUrls: ['./modal-cart-validation.component.scss']
})
export class ModalCartValidationComponent implements OnInit, OnDestroy, OnChanges {

    @Input() vehicleId: string;

    validity: CartValidity;
    expiry: number;

    vehicle: ISellingItem;

    private _vehicle: ISellingItemStore;

    private cartValidationSubscription = Subscription.EMPTY;
    private configSubscription = Subscription.EMPTY;
    private vehicleSubscription = Subscription.EMPTY;

    constructor (
        private ngRedux: NgRedux<AppState>,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        // listen for cart validation updates
        this.cartValidationSubscription = this.ngRedux.select<CartValidity>(['cartValidation', 'validity'])
            .subscribe((validity: CartValidity) => {
                this.validity = validity;
            });

        // listen for cart validation updates
        this.configSubscription = this.ngRedux.select<number>(['cartValidation', 'config', 'expiry'])
            .subscribe((expiry: number) => {
                this.expiry = expiry;
            });
    }

    ngOnChanges (changes: SimpleChanges) {
        if (changes['vehicleId']) {
            // clear existing subcription
            this.vehicleSubscription.unsubscribe();

            this.vehicleSubscription = this.ngRedux.select<ISellingItemStore>(['entities', 'sellingItems', this.vehicleId])
                .subscribe((vehicle: ISellingItemStore) => {
                    this._vehicle = vehicle;
                    this.vehicle = vehicle.props;
                });

        }
    }

    ngOnDestroy () {
        this.cartValidationSubscription.unsubscribe();
        this.configSubscription.unsubscribe();
    }

}
