import { Injectable } from '@angular/core';

import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UiActionsService } from '../../../store/actions/ui.actions';
import { MessagesActionsService } from "../../../store/actions/messages.actions";

/**
 *
 * Simple absraction of NgbModal that ensures all modals are registered with the Ui state
 *
 */
@Injectable()
export class ModalService {

    constructor (
        private ngbModal: NgbModal,
        private messagesActions: MessagesActionsService,
        private uiActions: UiActionsService
    ) { }

    open (content: any, options?: NgbModalOptions): NgbModalRef {
        const modalRef = this.ngbModal.open(content, options);

        // track result
        modalRef.result.then(() => {
            // clear modal
            this.uiActions.registerModal({
                modal: null
            });
            // pop the communications queue (in case we have something waiting)
            this.messagesActions.popMessage();
        })
        .catch(() => {
            // clear modal
            this.uiActions.registerModal({
                modal: null
            });
            // pop the communications queue (in case we have something waiting)
            this.messagesActions.popMessage();
        });

        // register with ui
        this.uiActions.registerModal({
            modal: modalRef
        });

        return modalRef;
    }

}
