import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { NgRedux } from '@angular-redux/store';
import { Observable, Observer } from 'rxjs';

import { UserRoles } from '../../../../interfaces/user.interface';
import { AppState } from '../../../../interfaces/store.interface';


import { UserActionsService } from '../../../store/actions/user.actions';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private ngRedux: NgRedux<AppState>,
        private userActions: UserActionsService
    ) { }

    /**
     *
     * Load userinfo to check if user is logged in
     *
     */

    checkUserGuard(url: string): Observable<boolean> {

        return new Observable((observer: Observer<boolean>) => {

            // listen to user for updates
            const subscription = this.ngRedux.select<boolean>(['user', 'isReady']).subscribe((isReady: boolean) => {

                if (isReady) {

                    const state = this.ngRedux.getState();

                    // assume access is NOT allowed
                    let result = false;

                    if (state.user.isLoggedIn) {

                        result = true;

                        const redirectUrl = localStorage.getItem('redirectUrl') || null;

                        // clear the redirect
                        localStorage.removeItem('redirectUrl');

                        if (redirectUrl) {
                            // redirect to original page
                            this.router.navigateByUrl(redirectUrl);
                            return;
                        }
                    }

                    // check if we need to kick them out (only if authentication failed)
                    if (!result) {
                        // save the current page so we can redirect
                        localStorage.setItem('redirectUrl', url);
                        // redirect user to login
                        this.userActions.login();
                        return;
                    }

                    // allow / block navigation
                    observer.next(result);
                    observer.complete();
                }

            });

//            const userState = this.ngRedux.getState().user;
//
//            // only load user if a load isn't already in progress
//            if (!userState.loading && !userState.isReady) {
//                // trigger a load (to update user object)
//                this.userActions.loadUser();
//            }

            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };

        });

    }

    /**
     *
     * Ensure user has required roles to access a route
     *
     */

    checkUserRoleGuard(route: ActivatedRouteSnapshot) {

        const byParam = route.data.requiredRolesByParam;

        let roles = route.data.requiredRoles || [];

        // determine which value to check
        if (byParam) {
            // lookup the params to check
            Object.keys(byParam).forEach((key: string) => {
                // check if the param exists
                if (route.params[key]) {
                    // dump the roles
                    roles = [
                        ...(byParam[key][route.params[key]] || [])
                    ];
                }
            });
        }

        return this.checkRoles(roles).pipe(
            tap((result: boolean) => {
                if (!result) {
                    // redirect user to home page
                    this.router.navigate(['/']);
                }
            })
        );
    }

    /**
     *
     * Check role - note ANY match is valid
     *
     */

    checkRoles(requiredRoles: any): Observable<boolean> {

        return new Observable((observer: Observer<boolean>) => {

            // listen to user for updates
            const subscription = this.ngRedux.select<boolean>(['user', 'isReady']).subscribe((isReady: boolean) => {

                if (isReady) {

                    const state = this.ngRedux.getState();

                    // assume access is NOT allowed
                    let result = false;

                    if (state.user.isLoggedIn && state.user.profile.payload) {

                        const profileRoles = state.user.profile.payload.roles;
                        if (profileRoles[requiredRoles[0].section]) {

                            if (requiredRoles[0].payload.anyAccessType) {
                                // any type is ok, return true;
                                result = true;
                            } else {
                                // continue checking the type (reader or writer)
                                if (profileRoles[requiredRoles[0].section][requiredRoles[0].payload.accessType]) {
                                    if (requiredRoles[0].payload.anyValue) {
                                        // any value is ok
                                        result = true;
                                    } else {
                                        // now continue with values
                                        if (Array.isArray(profileRoles[requiredRoles[0].section][requiredRoles[0].payload.accessType])) {
                                            // it's an array, search for the value
                                            (profileRoles[requiredRoles[0].section][requiredRoles[0].payload.accessType] as string[])
                                                .every((profRole: string) => {
                                                    let loop = true;

                                                    const index = requiredRoles[0].payload.values.findIndex((reqRole) => {
                                                        // compare string
                                                        return profRole.toUpperCase() === reqRole.toUpperCase();
                                                    });

                                                    // match found
                                                    if (index > -1) {
                                                        loop = false;
                                                        result = true;
                                                    }

                                                    return loop;
                                                });
                                        } else {
                                            // it's not an array
                                            const index = requiredRoles[0].payload.values.findIndex((reqRole) => {
                                                // compare string
                                                return profileRoles[
                                                    requiredRoles[0].section]
                                                [requiredRoles[0].payload.accessType].toUpperCase() === reqRole.toUpperCase();
                                            });

                                            // match found
                                            if (index > -1) {
                                                result = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        //    requiredRoles.every((role: UserRoles) => {
                        //        let loop = true;
                        //
                        //        const index = state.user.profile.payload.roles.findIndex((profileRole: UserRoles) => {
                        //            // compare string
                        //            return profileRole.toUpperCase() === role.toUpperCase();
                        //        });
                        //
                        //        // match found
                        //        if (index > -1) {
                        //            loop = false;
                        //            result = true;
                        //        }
                        //
                        //        return loop;
                        //    });

                    }

                    // allow / block navigation
                    observer.next(result);
                    observer.complete();
                }

            });

            const userState = this.ngRedux.getState().user;

            // only load user if a load isn't already in progress
            if (!userState.loading && !userState.isReady) {
                // trigger a load (to update user object)
                this.userActions.loadUser();
            }

            return () => {
                subscription.unsubscribe();
            };

        });

    }

    getUsername(): string {
        return (this.ngRedux.getState().user.identity.payload.profile || {}).sub || null; // @TODO confirm property
    }

    private hasIdentity(): boolean {
        let result = false;
        const state = this.ngRedux.getState();

        if (state.user && state.user.identity) {
            if (!!state.user.identity.payload && !state.user.identity.error) {
                result = true;
            }
        }

        return result;
    }

}
