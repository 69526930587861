import { Pipe, PipeTransform } from '@angular/core';

import { DateService } from '../../services/date/date.service';

@Pipe({
    name: 'date'
})
export class DatePipe implements PipeTransform {

    constructor (private date: DateService) {}

    transform (value: string): string {

        let result = '';

        if (value) {
          const utc = this.date.toUTC(value);
          result = this.date.formatDate(utc);
        }

        return result;
    }

}
