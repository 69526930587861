import {
    asyncLoadingReducer,
    asyncSuccessReducer,
    asyncErrorReducer,
    paginatedAsyncLoadingReducer,
    paginatedAsyncLoadingMoreReducer,
    paginatedAsyncSuccessReducer,
    paginatedAsyncErrorReducer,
    removeArrayItem,
    castToBoolean,
    sortParamsToString
} from './helpers';

import { CONFIG } from '../../shared/config/config.const';

import { AppAction, OrderState } from '../../../interfaces/store.interface';

import { OrderActionsService } from '../actions/order.actions';

const INITIAL_STATE: OrderState = {
    defaultParams: {
        pageIndex: 0,
        pageSize: CONFIG.PAGINATION.PAGE_SIZE,

        // filter params
        sellingAreaIdentifier: [],
        beginDate: null,
        endDate: null
    },
    params: {
        pageIndex: 0,
        pageSize: CONFIG.PAGINATION.PAGE_SIZE,

        // filter params
        sellingAreaIdentifier: [],
        beginDate: null,
        endDate: null
    },
    results: {
        entity: 'orders',
        payload: {
            pageIndex: -1,
            pageSize: CONFIG.PAGINATION.PAGE_SIZE,
            result: [],
            totalCount: -1,
            totalPages: -1
        },
        loadedCount: 0, // how many results have actually been loaded?
        loading: false,
        loadingMore: false,
        lastLoad: new Date(0),
        error: null,
        hasError: false,
        hasNextPage: false
    },
    area: null,
    filtersApplied: false,
    selected: {
        index: -1, // when this is > -1 that means the panel is visible
        orderId: null,
        vehicleId: null,
        thumbsIndex: 0,
        isFullyOpen: false // true when the open animation completes
    },
    bundleSelected: {
        index: 0
    },
};

export default function orderReducer(state: OrderState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        case OrderActionsService.SET_AREA: {

            const nextState = {
                ...INITIAL_STATE,
                defaultParams: {},
                params: {
                    ...INITIAL_STATE.params
                },
                area: action.payload.area,
                filtersApplied: false
            };

            // change the default params depending on the area
            if (nextState.area === 'MyOrders') {
                nextState.defaultParams = {
                    ...INITIAL_STATE.params
                };
            }

            return nextState;
        }

        case OrderActionsService.SET_PARAMS: {

            const nextState = {
                ...state,
                params: {
                    ...state.defaultParams,
                    // always start from first page
                    pageIndex: 0,
                    /**
                     *
                     * MERGE IN THE PAYLOAD VALUES
                     *
                     */
                    ...castToBoolean(action.payload)
                }
            };

            // compare params
            nextState.filtersApplied = sortParamsToString(
                nextState.defaultParams) !== sortParamsToString(nextState.params, ['pageIndex', 'pageSize']);

            return nextState;
        }

        case OrderActionsService.SET_PARAMS_NEXT_PAGE: {
            const nextState = {
                ...state,
                params: {
                    ...state.params,
                    // increment the page index
                    pageIndex: state.params.pageIndex + 1
                }
            };

            return nextState;
        }

        case OrderActionsService.SET_SELECTED_INDEX: {
            return {
                ...state,
                selected: {
                    ...INITIAL_STATE.selected,
                    index: action.payload.index,
                    orderId: action.payload.orderId,
                    // set fully open value (allow for already open)
                    isFullyOpen: action.payload > -1 ? state.selected.isFullyOpen || false : false
                }
            };
        }

        case OrderActionsService.SET_SELECTED_BUNDLE_INDEX: {
            return {
                ...state,
                bundleSelected: {
                    ...state.bundleSelected,
                    index: action.payload,
                }
            };
        }

        case OrderActionsService.SET_SELECTED_RESULT: {

            return {
                ...state,
                selected: {
                    ...state.selected,
                    vehicleId: action.payload.vehicleId
                }
            };
        }

        /**
         *
         * ORDERS (PAGINATED)
         *
         */

        // Loading first page
        case OrderActionsService.ORDERS_LOADING: {
            return paginatedAsyncLoadingReducer('results')(state);
        }

        // Loading next page
        case OrderActionsService.ORDERS_LOADING_MORE: {
            return paginatedAsyncLoadingMoreReducer('results')(state);
        }

        case OrderActionsService.ORDERS_SUCCESS: {
            return paginatedAsyncSuccessReducer('results')(state, action.payload.response);
        }

        case OrderActionsService.ORDERS_ERROR: {
            return paginatedAsyncErrorReducer('results')(state, action.error);
        }

    }

    return state;

}
