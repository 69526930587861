import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ng-bootstrap
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerModule, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

// local storage
import { LocalStorageModule } from 'angular-2-local-storage';

// rangeslider
import { NouisliderModule } from 'ng2-nouislider';

// Services
import { AnimationService } from './services/animation/animation.service';
import { AuthGuard } from './services/auth/auth.guard';
import { AuthRoleGuard } from './services/auth/auth-role.guard';
import { AuthService } from './services/auth/auth.service';
import { CartService } from './services/cart/cart.service';
import { DateService } from './services/date/date.service';
import { DatepickerI18nService } from './services/datepicker-i18n/datepicker-i18n.service';
import { FaviconService } from './services/favicon/favicon.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { GoogleMapsService } from './services/google-maps/google-maps.service';
import { ScrollService } from './services/scroll/scroll.service';
import { SignalRService } from './services/signalr/signalr.service';
import { ModalService } from './services/modal/modal.service';
import { TimerService } from './services/timer/timer.service';
import { UserService } from './services/user/user.service';
import { WindowRefService } from './services/window-ref/window-ref.service';
import { EnvironmentService } from './services/environment/environment.service';

// Guards
import { TenantGuard } from './guards/tenant/tenant.guard';

// Modals
import { ModalCartAddComponent } from './components/modal-cart-add/modal-cart-add.component';
import { ModalCartExpiredComponent } from './components/modal-cart-expired/modal-cart-expired.component';
import { ModalCartDisclaimerComponent } from './components/modal-cart-disclaimer/modal-cart-disclaimer.component';
import { ModalAuctionDisclaimerComponent } from './components/modal-auction-disclaimer/modal-auction-disclaimer.component';
import { ModalVehicleUnavailableComponent } from './components/modal-vehicle-unavailable/modal-vehicle-unavailable.component';
import { ModalAuctionEndedComponent } from './components/modal-auction-ended/modal-auction-ended.component';
import { ModalDatepickerComponent } from './components/modal-datepicker/modal-datepicker.component';
import { ModalExceptionComponent } from './components/modal-exception/modal-exception.component';
import { ModalCartConditionsComponent } from './components/modal-cart-conditions/modal-cart-conditions.component';
import { ModalCartValidationComponent } from './components/modal-cart-validation/modal-cart-validation.component';
import { ModalCheckoutErrorComponent } from './components/modal-checkout-error/modal-checkout-error.component';

// Components
import { HeaderComponent } from './components/header/header.component';
import { MenuItemComponent } from './components/header/menu-item/menu-item.component';
import { RootComponent } from './components/root/root.component';
import { PaginatedComponent } from './components/paginated/paginated.component';
import { SelectComponent } from './components/select/select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { ListingThumbnailsComponent } from './components/listing-thumbnails/listing-thumbnails.component';
import { ListingBundleThumbnailsComponent } from './components/listing-bundle-thumbnails/listing-bundle-thumbnails.component';
import { ListingGalleryComponent } from './components/listing-gallery/listing-gallery.component';
import { ListingGalleryCarouselComponent } from './components/listing-gallery-carousel/listing-gallery-carousel.component';
import { SortComponent } from './components/sort/sort.component';
import { SortOptionComponent } from './components/sort-option/sort-option.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { UntilDateComponent } from './components/until-date/until-date.component';
import { RelativeDateComponent } from './components/relative-date/relative-date.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { BackgroundComponent } from './components/background/background.component';
import { BackgroundImageComponent } from './components/background-image/background-image.component';
import { SalesCartValidityComponent } from './components/sales-cart-validity/sales-cart-validity.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { AdvancedSearchMoreOptionsListComponent } from './components/advanced-search/components/more-options-list/advanced-search-more-options-list.component';
import { AdvancedSearchTextComponent } from './components/advanced-search-text/advanced-search-text.component';
import { AdvancedSearchSelectComponent } from './components/advanced-search-select/advanced-search-select.component';
import { AdvancedSearchDateComponent } from './components/advanced-search-date/advanced-search-date.component';
import { AdvancedSearchToggleComponent } from './components/advanced-search-toggle/advanced-search-toggle.component';

// Directives
import { RoleRequiredDirective } from './directives/role-required/role-required.directive';
import { ScrollComponent } from './components/scroll/scroll.component';
import { InviewDirective } from './directives/inview/inview.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { GoogleAnalyticsDirective } from './directives/google-analytics/google-analytics.directive';
import { TouchableDirective } from './directives/touchable/touchable.directive';
import { MenuSizerDirective } from './components/header/directives/menu-sizer.directive';

// Pipes
import { NgbDatePipe } from './pipes/ngb-date/ngb-date.pipe';
import { ResultsCountPipe } from './pipes/results-count/results-count.pipe';
import { ValidationValuePipe } from './pipes/validation-value/validation-value.pipe';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { DatePipe } from './pipes/date/date.pipe';
import { DateTimePipe } from './pipes/date-time/date-time.pipe';
import { CurrencySpacePipe } from './pipes/currency-space/currency-space.pipe';

import { HttpClientModule } from '@angular/common/http';
import { ListingBundleThumbnailRotatorComponent } from './components/listing-bundle-thumbnail-rotator/listing-bundle-thumbnail-rotator.component';
import { BundleThumbsComponent } from './components/bundle-thumbs/bundle-thumbs.component';
import { ListingItemComponent } from './components/listing-item/listing-item.component';
import { ListingService } from './services/listing/listing.service';
import { ListingTitleComponent } from './components/listing/listing-item/listing-title.component';
import { ListingDaysOfVisibilityComponent } from './components/listing/listing-days-of-visibility/listing-days-of-visibility.component';
import { ListingInfoComponent } from './components/listing/listing-info/listing-info.component';
import { ListingStatsComponent } from './components/listing/listing-stats/listing-stats.component';
import { ListingThumbsComponent } from './components/listing-thumbs/listing-thumbs.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        BundleThumbsComponent,
        HeaderComponent,
        MenuItemComponent,
        ModalCartAddComponent,
        ModalCartExpiredComponent,
        ModalExceptionComponent,
        RootComponent,
        PaginatedComponent,
        SelectComponent,
        SwitchComponent,
        RoleRequiredDirective,
        ScrollComponent,
        InviewDirective,
        ScrollableDirective,
        MenuSizerDirective,
        ValidationValuePipe,
        SortComponent,
        SortOptionComponent,
        CountdownComponent,
        ModalCartConditionsComponent,
        PreloaderComponent,
        UntilDateComponent,
        RelativeDateComponent,
        ModalCartDisclaimerComponent,
        ModalAuctionDisclaimerComponent,
        ModalVehicleUnavailableComponent,
        ModalAuctionEndedComponent,
        ModalCheckoutErrorComponent,

        ListingStatsComponent,
        ListingInfoComponent,
        ListingDaysOfVisibilityComponent,
        ListingTitleComponent,
        ListingThumbnailsComponent,
        ListingItemComponent,
        ListingBundleThumbnailsComponent,
        ListingBundleThumbnailRotatorComponent,
        ListingGalleryComponent,
        ListingGalleryCarouselComponent,
        ListingThumbsComponent,
        DatepickerComponent,
        ModalDatepickerComponent,
        NgbDatePipe,
        ResultsCountPipe,
        BackgroundComponent,
        BackgroundImageComponent,
        GoogleAnalyticsDirective,
        ModalMessageComponent,
        SafeHtmlPipe,
        TouchableDirective,
        DatePipe,
        DateTimePipe,
        CurrencySpacePipe,
        ModalCartValidationComponent,
        SalesCartValidityComponent,
        AdvancedSearchComponent,
        AdvancedSearchMoreOptionsListComponent,
        AdvancedSearchTextComponent,
        AdvancedSearchSelectComponent,
        AdvancedSearchDateComponent,
        AdvancedSearchToggleComponent
    ],
    entryComponents: [
        ModalCartAddComponent,
        ModalCartExpiredComponent,
        ModalCartConditionsComponent,
        ModalExceptionComponent,
        ModalCartDisclaimerComponent,
        ModalAuctionDisclaimerComponent,
        ModalVehicleUnavailableComponent,
        ModalAuctionEndedComponent,
        ModalDatepickerComponent,
        ModalMessageComponent,
        ModalCartValidationComponent,
        ModalCheckoutErrorComponent,
    ],
    exports: [
        TranslateModule,
        BundleThumbsComponent,
        HeaderComponent,
        MenuItemComponent,
        ModalCartAddComponent,
        ModalCartExpiredComponent,
        ModalExceptionComponent,
        ModalCartValidationComponent,
        SalesCartValidityComponent,
        AdvancedSearchComponent,
        AdvancedSearchMoreOptionsListComponent,
        AdvancedSearchTextComponent,
        AdvancedSearchSelectComponent,
        AdvancedSearchDateComponent,
        AdvancedSearchToggleComponent,
        RootComponent,
        PaginatedComponent,
        SelectComponent,
        SwitchComponent,
        RoleRequiredDirective,
        ScrollComponent,
        InviewDirective,
        ScrollableDirective,
        MenuSizerDirective,
        ValidationValuePipe,
        SortComponent,
        SortOptionComponent,
        CountdownComponent,
        ModalCartConditionsComponent,
        PreloaderComponent,
        UntilDateComponent,
        RelativeDateComponent,
        ModalCartDisclaimerComponent,
        ModalAuctionDisclaimerComponent,
        ModalVehicleUnavailableComponent,
        ModalAuctionEndedComponent,
        ModalCheckoutErrorComponent,

        ListingStatsComponent,
        ListingInfoComponent,
        ListingDaysOfVisibilityComponent,
        ListingTitleComponent,
        ListingThumbnailsComponent,
        ListingItemComponent,
        ListingBundleThumbnailsComponent,
        ListingBundleThumbnailRotatorComponent,
        ListingGalleryComponent,
        ListingGalleryCarouselComponent,
        ListingThumbsComponent,
        DatepickerComponent,
        ModalDatepickerComponent,
        NgbDatePipe,
        ResultsCountPipe,
        BackgroundComponent,
        BackgroundImageComponent,
        GoogleAnalyticsDirective,
        ModalMessageComponent,
        SafeHtmlPipe,
        TouchableDirective,
        DatePipe,
        DateTimePipe,
        CurrencySpacePipe,

        // ng-bootstrap
        NgbModule,
        NgbModalModule,
        NgbDatepickerModule,
        // ng2-nouislider
        NouisliderModule
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,

        // ng-bootstrap
        NgbModalModule.forRoot(),
        NgbDatepickerModule.forRoot(),
        NgbPopoverModule,
        // ng2-nouislider
        NouisliderModule,
        // angular-2-local-storage
        LocalStorageModule.forRoot({
            prefix: 'BMWRemarketingv4',
            storageType: 'localStorage'
        })
    ],
    providers: [
        {
            provide: NgbDatepickerI18n,
            useClass: DatepickerI18nService
        },
        AnimationService,
        AuthGuard,
        AuthRoleGuard,
        AuthService,
        TenantGuard,
        CartService,
        DateService,
        FaviconService,
        GoogleAnalyticsService,
        GoogleMapsService,
        ModalService,
        TimerService,
        ScrollService,
        SignalRService,
        UserService,
        WindowRefService,
        EnvironmentService,
        ListingService,
        // Angular Pipes
        DecimalPipe
    ]
})
export class SharedModule { }
