import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable()
export class EnvironmentService {

    private environment = environment;
    private isSet = false;

    constructor(private windowRef: WindowRefService) {

    }

    setEnvironment(env: any) {
        if (!env) {
            return;
        }
        this.environment = env;
    }

    private _setEnvironmentByDomain(fullDomain: string) {
        const domain = fullDomain
            .slice(fullDomain.indexOf('-') + 1, fullDomain.indexOf('.'));

        this.environment.domain = domain;

        this.environment.admin.url = environment.admin.url.replace('@domain@', domain);
        this.environment.api.url = environment.api.url.replace('@domain@', domain);
        this.environment.oidc.authority = environment.oidc.authority.replace('@domain@', domain);
        this.environment.www.url = environment.www.url.replace('@domain@', domain);
        this.environment.websocket.url = environment.websocket.url.replace('@domain@', domain);
    }

    getEnvironment() {
        if (!this.isSet) {
            this._setEnvironmentByDomain(this.windowRef.nativeWindow.location.hostname);
            this.isSet = true;
        }
        return this.environment;
    }

    getAssetsPath(tenantId) {
        return `/themes/${tenantId}/assets/`;
    }

    getAssetsFilesPath(tenantId) {
        return `${this.getAssetsPath(tenantId)}files/`;
    }

    getAssetsImagesPath(tenantId) {
        return `${this.getAssetsPath(tenantId)}images/`;
    }

}
