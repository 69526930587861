import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, empty, concat } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';

import { ModalService } from '../../shared/services/modal/modal.service';
import { UserService } from '../../shared/services/user/user.service';


import { SalesActionsService } from '../actions/sales.actions';
import { MessagesActionsService } from '../actions/messages.actions';
import { FavouritesActionsService } from '../actions/favourites.actions';
import { UtilitiesActionsService } from '../actions/utilities.actions';
import { ScrollActionsService } from '../actions/scroll.actions';

import { HttpClient, HttpParams } from '@angular/common/http';
import { UiActionsService } from '../actions/ui.actions';
import { switchMap, catchError } from 'rxjs/operators';


@Injectable()
export class UiEpicsService {

    constructor(
        private http: HttpClient,
        private modal: ModalService,
        private user: UserService,
        private domSanitizer: DomSanitizer
    ) { }

    onLayoutChange = (action$: ActionsObservable<any>) => {

        return action$.ofType(
            // a panel has been opened or closed
            UiActionsService.OPEN_PANEL,
            UiActionsService.TOGGLE_PANEL,
            UiActionsService.CLOSE_PANEL,
            // results list has changed
            SalesActionsService.SELLINGITEMS_SUCCESS,
            // Messages results list has changed
            MessagesActionsService.MESSAGES_SUCCESS,
            // Favourites results list has changed
            FavouritesActionsService.FAVOURITES_SUCCESS,
            // Utilities called by edit user
            UtilitiesActionsService.TIMEZONES_SUCCESS
        ).pipe(
            switchMap(({
                payload
            }) => {

                // if we get a panel change we need to refresh the scroll
                return of({
                    type: ScrollActionsService.REFRESH
                });

            }));

    }


    /**
     *
     * NOTIFICATIONS
     *
     */

    queueNotification = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(UiActionsService.QUEUE_NOTIFICATION).pipe(
            switchMap(({
                payload
            }) => {
                // check if we can pop the first notification
                if (state$.value.ui.activeNotification) {
                    return empty();
                }

                return of({
                    type: UiActionsService.POP_NOTIFICATION
                });

            }));

    }

    queueNotifications = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(UiActionsService.QUEUE_NOTIFICATION).pipe(
            switchMap(({
                payload
            }) => {
                // check if we can pop the first notification
                if (state$.value.ui.activeNotification) {
                    return empty();
                }

                return of({
                    type: UiActionsService.POP_NOTIFICATION
                });

            }));

    }

    discardNotification = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        return action$.ofType(UiActionsService.DISCARD_NOTIFICATION).pipe(
            switchMap(({
                payload
            }) => {

                // get the next notification in the queue
                return of({
                    type: UiActionsService.POP_NOTIFICATION
                });

            }));

    }

    /**
     *
     * PRIVACY
     *
     */

    fetchPrivacy = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        return action$.ofType(UiActionsService.FETCH_PRIVACY).pipe(
            switchMap(({
                payload
            }) => {

                // only load it once
                if (store$.value.ui.privacyContent) {
                    return empty();
                }

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: UiActionsService.PRIVACY_LOADING,
                        payload: null
                    }),
                    of({
                        type: UiActionsService.PRIVACY_SUCCESS,
                        payload: {
                            content: `Privacy`
                        }
                    })
                    //                    this.http.get('https://www.iubenda.com/api/privacy-policy/986140').pipe(
                    //                        switchMap((response: any) => {
                    //
                    //                            return of({
                    //                                type: UiActionsService.PRIVACY_SUCCESS,
                    //                                payload: {
                    //                                    content: this.domSanitizer.bypassSecurityTrustHtml(response.content)
                    //                                }
                    //                            });
                    //
                    //
                    //                        }),
                    //                        catchError(error => of({
                    //                            type: UiActionsService.PRIVACY_ERROR,
                    //                            error
                    //                        }))
                );

            }));

    }

}
