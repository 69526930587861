import { Component, HostListener, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ListingBundleThumbnailRotatorComponent } from '../listing-bundle-thumbnail-rotator/listing-bundle-thumbnail-rotator.component';
import { ISellingItem } from '../../../../interfaces/SellingItem.interface';


@Component({
    selector: 'app-listing-bundle-thumbnails',
    templateUrl: './listing-bundle-thumbnails.component.html',
    styleUrls: ['./listing-bundle-thumbnails.component.scss']
})
export class ListingBundleThumbnailsComponent {
    @Input() cars: ISellingItem[];
    @Input() thumbs: string[][];
    @ViewChildren(ListingBundleThumbnailRotatorComponent) bundleThumbnails: QueryList<ListingBundleThumbnailRotatorComponent>;

    @HostListener('mouseenter', [])
    onMouseenter() {
        this.bundleThumbnails.toArray().forEach(bt => {
            bt.startChanging();
        });
    }

    @HostListener('mouseleave', [])
    onMouseleave() {
        this.bundleThumbnails.toArray().forEach(bt => {
            bt.stopChanging();
        });
    }

}
