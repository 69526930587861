// import * as moment from 'moment'; - added globally to prevent all locales being included
import * as tslib_1 from "tslib";
import { Order } from '../entities/order.class';
import { SellingItem } from '../entities/sellingItem.class';
import { EntitiesActionsService } from '../actions/entities.actions';
import { AuctionActionsService } from '../actions/auction.actions';
import { CartActionsService } from '../actions/cart.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { OrderActionsService } from '../actions/order.actions';
import { FavouritesActionsService } from '../actions/favourites.actions';
import { MessagesActionsService } from '../actions/messages.actions';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { CmsPagesActionsService } from '../actions/cms-pages.actions';
var INITIAL_STATE = {
    sellingItems: {
    // populated using ID as key
    },
    orders: {
    // populated using ID as the key
    },
    messages: {},
    sellingAreas: {},
    favourites: {},
    pages: {}
};
export default function entitiesReducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        /**
         *
         * VEHICLES - automatically create the correct object shape for a car
         *
         */
        //
        // VEHICLES IN THE CART
        //
        case CartActionsService.CART_SUCCESS: {
            var nextState_1 = tslib_1.__assign({}, state, { sellingItems: tslib_1.__assign({}, state.sellingItems) });
            var contents = tslib_1.__assign({}, action.payload.response);
            // add vehicles to store
            (contents.items || []).forEach(function (item) {
                var vehicles = [];
                var itemVehicles = (item.vehicles || []).slice();
                vehicles = tslib_1.__spread(vehicles, itemVehicles);
                nextState_1.sellingItems[item.id] = new SellingItem(item, {
                    // set flags
                    _isInCart: true,
                    _isUnavailable: false,
                    _addingToCart: false,
                    _bundle: item.vehicles.length > 1,
                    title: item.title,
                    _vehicles: item.vehicles.map(function (iv) { return iv.id; })
                }, action.payload.area, action.payload.userBranchCode);
                // now expose the vehicles
                // vehicles.forEach((item2: any) => {
                //     nextState.sellingItems[item2.id] = new SellingItem(item2, {}, action.payload.area, action.payload.userBranchCode);
                // });
            });
            return nextState_1;
        }
        //
        // VEHICLES IN THE RESULTS LIST
        //
        case SalesActionsService.SELLINGITEMS_SUCCESS: {
            var nextState_2 = tslib_1.__assign({}, state, { sellingItems: tslib_1.__assign({}, state.sellingItems) });
            // add vehicles to store
            (action.payload.response.result || []).forEach(function (item) {
                var vehicles = [];
                var itemVehicles = (item.vehicles || []).slice();
                vehicles = tslib_1.__spread(vehicles, itemVehicles);
                nextState_2.sellingItems[item.id] = new SellingItem(item, {
                    _bundle: itemVehicles.length > 1,
                    title: item.title,
                    _vehicles: itemVehicles.map(function (iv) { return iv.id; }),
                    _isDetailed: false
                });
                // now expose the vehicles
                // vehicles.forEach((item2: any) => {
                //     // nextState.sellingItems[item2.id] = new SellingItem(item2, {}, action.payload.area, action.payload.userBranchCode);
                //     nextState.vehicles[item2.id] = { id: item2.id, props: item2 };
                //     nextState.vehicles[item2.id].props._isDetailed = false;
                // });
            });
            return nextState_2;
        }
        /**
         *
         * SELLING ITEM DETAIL LOADED
         *
         */
        case SalesActionsService.SELLINGITEM_SUCCESS: {
            var nextState = tslib_1.__assign({}, state, { sellingItems: tslib_1.__assign({}, state.sellingItems) });
            // update sellingItem
            var item = action.payload.response;
            var itemVehicles = (item.vehicles || []).slice();
            nextState.sellingItems[item.id] = new SellingItem(item, {
                _bundle: itemVehicles.length > 1,
                title: item.title,
                _vehicles: itemVehicles.map(function (iv) { return iv.id; }),
                _isDetailed: true
            });
            return nextState;
        }
        /**
         *
         * SET VALUES ON VEHICLES
         *
         */
        case EntitiesActionsService.SET_VEHICLE_BRANCH_LATLNG: {
            var nextState = tslib_1.__assign({}, state);
            if (nextState.sellingItems[action.payload.vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_a = {}, _a[action.payload.vehicleId] = tslib_1.__assign({}, state.sellingItems[action.payload.vehicleId], { props: tslib_1.__assign({}, state.sellingItems[action.payload.vehicleId].props) }), _a));
                // check the branch exists
                var branch = nextState.sellingItems[action.payload.vehicleId].props.ownerBranches[action.payload.branchIndex];
                if (branch) {
                    // modify the array
                    nextState.sellingItems[action.payload.vehicleId].props.ownerBranches = tslib_1.__spread(nextState.sellingItems[action.payload.vehicleId].props.ownerBranches.slice(0, action.payload.branchIndex), [
                        tslib_1.__assign({}, branch, { _latLng: action.payload.latLng, _previewImg: action.payload.previewImg })
                    ], nextState.sellingItems[action.payload.vehicleId].props.ownerBranches.slice(action.payload.branchIndex));
                }
            }
            return nextState;
        }
        case EntitiesActionsService.HIDE_VEHICLE: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_b = {}, _b[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _hideAndRemove: true // triggers other side effects
                     }) }), _b));
            }
            return nextState;
        }
        case EntitiesActionsService.SET_VEHICLE_AS_PURCHASABLE: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_c = {}, _c[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { canBePurchased: true }) }), _c));
            }
            return nextState;
        }
        case EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_d = {}, _d[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { canBePurchased: false }) }), _d));
            }
            return nextState;
        }
        case EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_e = {}, _e[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { canBePurchased: true }) }), _e));
            }
            return nextState;
        }
        /**
         *
         * MARK VEHICLE AS FOR SALE
         *
         */
        case SalesActionsService.SET_AS_FOR_SALE: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_f = {}, _f[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props) }), _f));
            }
            return nextState;
        }
        /**
         *
         * MARK VEHICLE AUCTION STATE
         *
         */
        case AuctionActionsService.AUCTION_SET_STATE: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_g = {}, _g[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { auctionInfo: tslib_1.__assign({}, state.sellingItems[vehicleId].props.auctionInfo, { _state: action.payload.state }) }) }), _g));
            }
            return nextState;
        }
        /**
         *
         * UPDATE AUCTION STATUS
         *
         */
        case AuctionActionsService.AUCTION_BY_ID_SUCCESS: {
            var nextState = tslib_1.__assign({}, state);
            var sellingItemId = action.payload.sellingItemId;
            var response = action.payload.response;
            var fromPush = action.payload.isFromPush;
            var nowForEnd = action.payload.now;
            var sellingItem = nextState.sellingItems[sellingItemId];
            if (sellingItem) {
                var now = Date.now();
                var beginTime = moment(response.showAt).valueOf();
                var endTime = moment(response.hideAt).valueOf();
                if (response.expireInMinutes) {
                    endTime = nowForEnd.add(Math.round(response.expireInMinutes), 'seconds').valueOf();
                }
                var status_1 = response.status;
                if (beginTime > now) {
                    status_1 = 0; // pending
                }
                else if (endTime > now) {
                    status_1 = 1; // active
                }
                else {
                    status_1 = 2; // expired
                }
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_h = {}, _h[sellingItemId] = tslib_1.__assign({}, state.sellingItems[sellingItemId], { props: tslib_1.__assign({}, state.sellingItems[sellingItemId].props, { _hideAt: endTime, auctionInfo: tslib_1.__assign({}, state.sellingItems[sellingItemId].props.auctionInfo, { 
                            // update relevant properties
                            basePrice: response.auctionInfo.basePrice, bestOffer: response.auctionInfo.bestOffer, 
                            // maxOffer: response.auctionInfo.maxOffer, // managed below
                            isLoggedUserBestBidder: response.auctionInfo.isLoggedUserBestBidder, beginTime: beginTime,
                            endTime: endTime, _state: status_1 }) }) }), _h));
                // manage case from push
                if (!fromPush) {
                    nextState.sellingItems[sellingItemId].props.auctionInfo.maxOffer = response.auctionInfo.maxOffer;
                }
            }
            return nextState;
        }
        /**
         *
         * CART
         *
         */
        case CartActionsService.ADD_TO_CART_QUEUE:
        case CartActionsService.ADD_TO_CART_LOADING: {
            var nextState = tslib_1.__assign({}, state);
            // flag the vehicle as adding
            var vehicleId = action.payload;
            // check it exists
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_j = {}, _j[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _addingToCart: true, _isInCart: false, _isUnavailable: false }) }), _j));
            }
            return nextState;
        }
        case CartActionsService.ADD_TO_CART_DEQUEUE: {
            var nextState = tslib_1.__assign({}, state);
            // flag the vehicle as adding
            var vehicleId = action.payload;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_k = {}, _k[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _addingToCart: false }) }), _k));
            }
            return nextState;
        }
        /**
         *
         * Vehicle has been successfully added to the cart
         *
         */
        case CartActionsService.ADD_TO_CART_IN_CART: {
            var nextState = tslib_1.__assign({}, state);
            // flag the vehicle as added
            var vehicleId = action.payload;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_l = {}, _l[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _addingToCart: false, _isInCart: true, _isUnavailable: false }) }), _l));
            }
            return nextState;
        }
        /**
         *
         * Server rejected vehicle as it is no longer available
         *
         */
        case CartActionsService.ADD_TO_CART_UNAVAILABLE: {
            var nextState = tslib_1.__assign({}, state);
            // flag the vehicle as adding
            var vehicleId = action.payload;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_m = {}, _m[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _addingToCart: false, _isInCart: false, _isUnavailable: true }) }), _m));
            }
            return nextState;
        }
        case CartActionsService.REMOVE_FROM_CART: {
            var nextState = tslib_1.__assign({}, state);
            // flag the vehicle as adding
            var vehicleId = action.payload.id;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_o = {}, _o[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _removingFromCart: true }) }), _o));
            }
            return nextState;
        }
        case CartActionsService.REMOVE_FROM_CART_SUCCESS: {
            var nextState = tslib_1.__assign({}, state);
            // flag the vehicle as adding
            var vehicleId = action.payload.id;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_p = {}, _p[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _removingFromCart: false }) }), _p));
            }
            return nextState;
        }
        /**
         *
         * AUCTIONS
         *
         */
        case AuctionActionsService.AUCTION_MAKE_OFFER_LOADING: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_q = {}, _q[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { auctionInfo: tslib_1.__assign({}, state.sellingItems[vehicleId].props.auctionInfo, { _makeOfferLoading: true }) }) }), _q));
            }
            return nextState;
        }
        case AuctionActionsService.AUCTION_MAKE_OFFER_ERROR: {
            var nextState = tslib_1.__assign({}, state);
            var vehicleId = action.payload.vehicleId;
            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_r = {}, _r[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { auctionInfo: tslib_1.__assign({}, state.sellingItems[vehicleId].props.auctionInfo, { _makeOfferLoading: false }) }) }), _r));
            }
            return nextState;
        }
        case AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS: {
            var nextState = tslib_1.__assign({}, state);
            var request = action.payload.request;
            var response = action.payload.response;
            var nowForEnd = action.payload.now;
            // auction unavailable
            if (response._result === 5) {
                return nextState;
            }
            var vehicleId = request.sellingItemId;
            var offer = request.maxOffer;
            var endTime = moment(response.endTime).valueOf();
            if (response.expireInMinutes) {
                endTime = nowForEnd.add(Math.round(response.expireInMinutes), 'seconds').valueOf();
            }
            var vehicle = nextState.sellingItems[vehicleId];
            if (vehicle) {
                // update properties
                var isLoggedUserBestBidder = !!vehicle.props.auctionInfo.isLoggedUserBestBidder;
                var valueIsLoggedUsersBestOffer = false;
                switch (response._result) {
                    case 0:
                    case 4:
                        // user is definitely best bidder
                        isLoggedUserBestBidder = true;
                        valueIsLoggedUsersBestOffer = true;
                        break;
                    case 3:
                    case 6:
                        // user is definitely NOT the best bidder (this is still counted as a bid in the UI)
                        isLoggedUserBestBidder = false;
                        valueIsLoggedUsersBestOffer = true;
                        break;
                    case 1:
                        // do nothing
                        break;
                }
                // set all nested
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_s = {}, _s[vehicleId] = tslib_1.__assign({}, state.sellingItems[vehicleId], { props: tslib_1.__assign({}, state.sellingItems[vehicleId].props, { _hideAt: endTime, auctionInfo: tslib_1.__assign({}, state.sellingItems[vehicleId].props.auctionInfo, { isLoggedUserBestBidder: isLoggedUserBestBidder, bestOffer: response.currentOffer, maxOffer: offer, _isUserBidder: true, _offerResult: response._result, _errorMessage: response._errorMessage, _makeOfferLoading: false }) }) }), _s));
            }
            return nextState;
        }
        // MESSAGES
        case MessagesActionsService.MESSAGES_SUCCESS: {
            var nextState_3 = tslib_1.__assign({}, state);
            // add messages to store
            action.payload.response.result.forEach(function (item) {
                nextState_3.messages[item.id] = {
                    id: item.id,
                    props: item
                };
            });
            return nextState_3;
        }
        case MessagesActionsService.MODAL_MESSAGES_SUCCESS: {
            var nextState_4 = tslib_1.__assign({}, state);
            // add messages to store
            action.payload.response.result.forEach(function (item) {
                nextState_4.messages[item.id] = {
                    id: item.id,
                    props: item
                };
            });
            return nextState_4;
        }
        // SELLING AREAS
        case SellingAreasActionsService.FETCH_SELLING_AREAS_SUCCESS: {
            var nextState_5 = tslib_1.__assign({}, state);
            // add orders to store
            action.payload.result.forEach(function (item) {
                nextState_5.sellingAreas[item.id] = {
                    id: item.id,
                    props: tslib_1.__assign({}, item, { _hasFullInfo: false })
                };
            });
            return nextState_5;
        }
        case SellingAreasActionsService.FETCH_SELLING_AREA_FULL_SUCCESS: {
            var sellingAreaId = action.payload.id;
            var nextState = tslib_1.__assign({}, state, { sellingAreas: tslib_1.__assign({}, state.sellingAreas, (_t = {}, _t[sellingAreaId] = tslib_1.__assign({}, state.sellingAreas[sellingAreaId], { props: tslib_1.__assign({}, state.sellingAreas[sellingAreaId].props, action.payload, { _hasFullInfo: true }) }), _t)) });
            return nextState;
        }
        case MessagesActionsService.TOGGLE_MESSAGE_SUCCESS: {
            var nextState = tslib_1.__assign({}, state);
            nextState.messages[action.payload.id].props.read = !nextState.messages[action.payload.id].props.read;
            return nextState;
        }
        /**
         *
         * ORDERS
         *
         */
        case OrderActionsService.ORDERS_SUCCESS: {
            var nextState_6 = tslib_1.__assign({}, state, { orders: tslib_1.__assign({}, state.orders) });
            // add orders to store
            action.payload.response.result.forEach(function (item) {
                var items = (item.items || []).slice();
                var deletedItems = (item.deletedItems || []).slice();
                // regular vehicles
                item.items = items.map(function (itm) {
                    var itemVehicles = (itm.vehicles || itm.cars || []).slice();
                    if (itemVehicles.length > 1) {
                        itm = new SellingItem(itm, {
                            _bundle: true,
                            title: itm.title,
                            _vehicles: itemVehicles.map(function (iv) { return iv.id; })
                        });
                    }
                    else {
                        itm = new SellingItem(itm, {
                            _vehicles: itemVehicles.map(function (iv) { return iv.id; })
                        });
                    }
                    return itm.props;
                });
                // deleted vehicles
                item.deletedItems = deletedItems.map(function (itm) {
                    var itemVehicles = (itm.vehicles || itm.cars || []).slice();
                    if (itemVehicles.length > 1) {
                        itm = new SellingItem(itm, {
                            _bundle: true,
                            title: itm.title,
                            _vehicles: itemVehicles.map(function (iv) { return iv.id; })
                        });
                    }
                    else {
                        itm = new SellingItem(itm, {
                            _vehicles: itemVehicles.map(function (iv) { return iv.id; })
                        });
                    }
                    return itm.props;
                });
                // create the Order object
                var order = new Order(item);
                // expose the order
                nextState_6.orders[order.id] = order;
            });
            return nextState_6;
        }
        case OrderActionsService.TOGGLE_ORDER_VEHICLES: {
            var nextState = tslib_1.__assign({}, state);
            var orderId = action.payload.orderId;
            if (nextState.orders[orderId]) {
                // set all nested
                nextState.orders = tslib_1.__assign({}, state.orders, (_u = {}, _u[orderId] = tslib_1.__assign({}, state.orders[orderId], { props: tslib_1.__assign({}, state.orders[orderId].props, { 
                        // reverse the value
                        _showVehicles: !state.orders[orderId].props._showVehicles }) }), _u));
            }
            return nextState;
        }
        case OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS: {
            var nextState = tslib_1.__assign({}, state);
            var orderId = action.payload.orderId;
            var vehicleId = action.payload.vehicleId;
            if (nextState.orders[orderId]) {
                var vehicleShownIndex = nextState.orders[orderId].props._vehiclesDetailsShown.indexOf(vehicleId);
                var nextVehiclesDetailsShown = tslib_1.__spread(nextState.orders[orderId].props._vehiclesDetailsShown.slice());
                if (vehicleShownIndex > -1) {
                    nextVehiclesDetailsShown.splice(vehicleShownIndex, 1);
                }
                else {
                    nextVehiclesDetailsShown.push(vehicleId);
                }
                // set all nested
                nextState.orders = tslib_1.__assign({}, state.orders, (_v = {}, _v[orderId] = tslib_1.__assign({}, state.orders[orderId], { props: tslib_1.__assign({}, state.orders[orderId].props, { 
                        // reverse the value
                        _vehiclesDetailsShown: nextVehiclesDetailsShown }) }), _v));
            }
            return nextState;
        }
        // FAVOURITES
        case FavouritesActionsService.FAVOURITES_SUCCESS: {
            var nextState_7 = tslib_1.__assign({}, state);
            // add favourites to store
            action.payload.response.result.forEach(function (item) {
                var vehicles = [];
                var itemVehicles = (item.sellingItem.vehicles || item.cars || []).slice();
                vehicles = tslib_1.__spread(vehicles, itemVehicles);
                var siObj = {
                    isAvailable: item.isAvailable,
                    isFavourite: true // @TODO: remove when api works
                };
                if (item.sellingItem.title) {
                    siObj.title = item.sellingItem.title;
                }
                if (itemVehicles.length > 1) {
                    siObj._bundle = true;
                    siObj._vehicles = itemVehicles.map(function (iv) { return iv.id; });
                }
                nextState_7.favourites[item.sellingItem.id] = new SellingItem(item.sellingItem, siObj);
                // now expose the vehicles
                // vehicles.forEach((item2: any) => {
                //     nextState.favourites[item2.id] = new SellingItem(item2, {
                //         isAvailable: item.isAvailable,
                //         isFavourite: true // @TODO: remove when api works
                //     });
                // });
            });
            return nextState_7;
        }
        case FavouritesActionsService.TOGGLE_FAVOURITE_SUCCESS: {
            var nextState = tslib_1.__assign({}, state);
            if (state.sellingItems[action.payload.sellingItemId]) {
                nextState.sellingItems = tslib_1.__assign({}, state.sellingItems, (_w = {}, _w[action.payload.sellingItemId] = tslib_1.__assign({}, state.sellingItems[action.payload.sellingItemId], { props: tslib_1.__assign({}, state.sellingItems[action.payload.sellingItemId].props, { isFavourite: action.payload.enable }) }), _w));
            }
            if (state.favourites[action.payload.sellingItemId]) {
                nextState.favourites = tslib_1.__assign({}, state.favourites, (_x = {}, _x[action.payload.sellingItemId] = tslib_1.__assign({}, state.favourites[action.payload.sellingItemId], { props: tslib_1.__assign({}, state.favourites[action.payload.sellingItemId].props, { isFavourite: action.payload.enable }) }), _x));
            }
            return nextState;
        }
        // CMS PAGES
        case CmsPagesActionsService.PAGE_SUCCESS: {
            var nextState = tslib_1.__assign({}, state);
            // add page to store
            nextState.pages[action.payload.pageId] = {
                id: action.payload.pageId,
                props: action.payload
            };
            return nextState;
        }
    }
    return state;
}
