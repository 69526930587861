import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML'
})
export class SafeHtmlPipe implements PipeTransform {

    constructor (private domSanitizer: DomSanitizer) {}

    transform (value: any): any {
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }

}
