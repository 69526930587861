import { AppAction, MessagesState } from '../../../interfaces/store.interface';
import {
    castToBoolean, paginatedAsyncSuccessReducer, pushArrayItem, pushArrayItems, removeArrayItem,
    sortParamsToString
} from './helpers';
import { MessagesActionsService } from '../actions/messages.actions';
import { IMessage } from '../../../interfaces/messages/Messages.interface';
import { CONFIG } from '../../shared/config/config.const';
import { OrderActionsService } from '../actions/order.actions';
import { SalesActionsService } from '../actions/sales.actions';

const INITIAL_STATE: MessagesState = {
    totalMessage: 0,
    unreadMessages: 0,
    messagesQueue: [],
    defaultParams: {
        // populated by SET_AREA
    },
    params: {
        pageIndex: 0,
        pageSize: CONFIG.PAGINATION.PAGE_SIZE,

        // filter params
        startDate: null,
        endDate: null
    },
    results: {
        entity: 'messages',
        payload: {
            pageIndex: -1,
            pageSize: CONFIG.PAGINATION.PAGE_SIZE,
            result: [],
            totalCount: -1,
            totalPages: -1
        },
        loadedCount: 0, // how many results have actually been loaded?
        loading: false,
        loadingMore: false,
        lastLoad: new Date(0),
        error: null,
        hasError: false,
        hasNextPage: false
    },
    filtersApplied: false,
    filterOptions: {
        payload: null,
        loading: false,
        error: null,
        hasError: false
    },
    filterType: 'communication'
};

export default function messagesReducer(state: MessagesState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * MESSAGES
         *
         */

        case MessagesActionsService.MESSAGES_STATISTICS_SUCCESS: {

            const nextState = {
                ...state,
                totalMessage: action.payload.totalMessage,
                unreadMessages: action.payload.unreadMessages,
            };

            return nextState;
        }

        case MessagesActionsService.TOGGLE_MESSAGE_SUCCESS: {

            const nextState = {
                ...state,
                unreadMessages: action.payload.status ? state.unreadMessages + 1 : state.unreadMessages - 1
            };

            return nextState;
        }

        case MessagesActionsService.QUEUE_MESSAGE: {

            const nextState = {
                ...state,
                messagesQueue: pushArrayItem(state.messagesQueue, action.payload.message)
            };

            return nextState;
        }

        case MessagesActionsService.QUEUE_MESSAGES: {

            const nextState = {
                ...state,
                messagesQueue: pushArrayItems(state.messagesQueue, action.payload.messagesQueue)
            };

            return nextState;
        }

        case MessagesActionsService.DISCARD_MESSAGE: {

            // find the index of the item to remove
            const index = state.messagesQueue.findIndex((message: IMessage) => {
                return message.id === action.payload.id;
            });

            const nextState = {
                ...state,
                messagesQueue: removeArrayItem(state.messagesQueue, index)
            };

            return nextState;
        }

        case MessagesActionsService.MESSAGES_SUCCESS: {
            return paginatedAsyncSuccessReducer('results')(state, action.payload.response);
        }

        case MessagesActionsService.SET_PARAMS: {
            const nextState = {
                ...state,
                params: {
                    ...state.defaultParams,
                    // always start from first page
                    pageIndex: 0,
                    /**
                     *
                     * MERGE IN THE PAYLOAD VALUES
                     *
                     */
                    ...castToBoolean(action.payload)
                }
            };

            // compare params
            nextState.filtersApplied = sortParamsToString(
                nextState.defaultParams
            ) !== sortParamsToString(nextState.params, ['pageIndex', 'pageSize']);

            return nextState;
        }

        case MessagesActionsService.SET_TYPE: {

            const isChanging = state.filterType !== action.payload;

            const nextState = {
                ...state,
                filterType: action.payload
            };

            return nextState;
        }

        case MessagesActionsService.SET_PARAMS_NEXT_PAGE: {
            const nextState = {
                ...state,
                params: {
                    ...state.params,
                    // increment the page index
                    pageIndex: state.params.pageIndex + 1
                }
            };

            return nextState;
        }

    }

    return state;

}
