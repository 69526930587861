import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class CmsMenuActionsService {

    static readonly FETCH_MENU = '@cmsMenu/FETCH_MENU';
    static readonly MENU_LOADING = '@cmsMenu/MENU_LOADING';
    static readonly MENU_SUCCESS = '@cmsMenu/MENU_SUCCESS';
    static readonly MENU_ERROR = '@cmsMenu/MENU_ERROR';

    static readonly SET_MENU_WRAP_INDEX = '@cmsMenu/SET_MENU_WRAP_INDEX';

    /**
     *
     * LOAD MENU
     *
     */

    @dispatch()
    fetchMenu = (): AppAction => ({
        type: CmsMenuActionsService.FETCH_MENU
    })

    @dispatch()
    setMenuWrapIndex = (index: number): AppAction => ({
        type: CmsMenuActionsService.SET_MENU_WRAP_INDEX,
        payload: index
    })

}
