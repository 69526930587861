import { Injectable } from '@angular/core';

// import * as moment from 'moment'; - added globally to prevent all locales being included

import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DatepickerI18nService extends NgbDatepickerI18n {

    constructor() {
        super();
    }

    // get the values from Moment, which is already localised

    getWeekdayShortName(weekday: number): string {
        return moment.weekdaysShort()[weekday === 7 ? 0 : weekday];
    }
    getMonthShortName(month: number): string {
        return moment.monthsShort()[month - 1];
    }
    getMonthFullName(month: number): string {
        return moment.months()[month - 1];
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

}
