import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { UserActionsService } from '../../../store/actions/user.actions';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
var AuthService = /** @class */ (function () {
    function AuthService(http, router, ngRedux, userActions) {
        this.http = http;
        this.router = router;
        this.ngRedux = ngRedux;
        this.userActions = userActions;
    }
    /**
     *
     * Load userinfo to check if user is logged in
     *
     */
    AuthService.prototype.checkUserGuard = function (url) {
        var _this = this;
        return new Observable(function (observer) {
            // listen to user for updates
            var subscription = _this.ngRedux.select(['user', 'isReady']).subscribe(function (isReady) {
                if (isReady) {
                    var state = _this.ngRedux.getState();
                    // assume access is NOT allowed
                    var result = false;
                    if (state.user.isLoggedIn) {
                        result = true;
                        var redirectUrl = localStorage.getItem('redirectUrl') || null;
                        // clear the redirect
                        localStorage.removeItem('redirectUrl');
                        if (redirectUrl) {
                            // redirect to original page
                            _this.router.navigateByUrl(redirectUrl);
                            return;
                        }
                    }
                    // check if we need to kick them out (only if authentication failed)
                    if (!result) {
                        // save the current page so we can redirect
                        localStorage.setItem('redirectUrl', url);
                        // redirect user to login
                        _this.userActions.login();
                        return;
                    }
                    // allow / block navigation
                    observer.next(result);
                    observer.complete();
                }
            });
            //            const userState = this.ngRedux.getState().user;
            //
            //            // only load user if a load isn't already in progress
            //            if (!userState.loading && !userState.isReady) {
            //                // trigger a load (to update user object)
            //                this.userActions.loadUser();
            //            }
            return function () {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        });
    };
    /**
     *
     * Ensure user has required roles to access a route
     *
     */
    AuthService.prototype.checkUserRoleGuard = function (route) {
        var _this = this;
        var byParam = route.data.requiredRolesByParam;
        var roles = route.data.requiredRoles || [];
        // determine which value to check
        if (byParam) {
            // lookup the params to check
            Object.keys(byParam).forEach(function (key) {
                // check if the param exists
                if (route.params[key]) {
                    // dump the roles
                    roles = tslib_1.__spread((byParam[key][route.params[key]] || []));
                }
            });
        }
        return this.checkRoles(roles).pipe(tap(function (result) {
            if (!result) {
                // redirect user to home page
                _this.router.navigate(['/']);
            }
        }));
    };
    /**
     *
     * Check role - note ANY match is valid
     *
     */
    AuthService.prototype.checkRoles = function (requiredRoles) {
        var _this = this;
        return new Observable(function (observer) {
            // listen to user for updates
            var subscription = _this.ngRedux.select(['user', 'isReady']).subscribe(function (isReady) {
                if (isReady) {
                    var state = _this.ngRedux.getState();
                    // assume access is NOT allowed
                    var result_1 = false;
                    if (state.user.isLoggedIn && state.user.profile.payload) {
                        var profileRoles_1 = state.user.profile.payload.roles;
                        if (profileRoles_1[requiredRoles[0].section]) {
                            if (requiredRoles[0].payload.anyAccessType) {
                                // any type is ok, return true;
                                result_1 = true;
                            }
                            else {
                                // continue checking the type (reader or writer)
                                if (profileRoles_1[requiredRoles[0].section][requiredRoles[0].payload.accessType]) {
                                    if (requiredRoles[0].payload.anyValue) {
                                        // any value is ok
                                        result_1 = true;
                                    }
                                    else {
                                        // now continue with values
                                        if (Array.isArray(profileRoles_1[requiredRoles[0].section][requiredRoles[0].payload.accessType])) {
                                            // it's an array, search for the value
                                            profileRoles_1[requiredRoles[0].section][requiredRoles[0].payload.accessType]
                                                .every(function (profRole) {
                                                var loop = true;
                                                var index = requiredRoles[0].payload.values.findIndex(function (reqRole) {
                                                    // compare string
                                                    return profRole.toUpperCase() === reqRole.toUpperCase();
                                                });
                                                // match found
                                                if (index > -1) {
                                                    loop = false;
                                                    result_1 = true;
                                                }
                                                return loop;
                                            });
                                        }
                                        else {
                                            // it's not an array
                                            var index = requiredRoles[0].payload.values.findIndex(function (reqRole) {
                                                // compare string
                                                return profileRoles_1[requiredRoles[0].section][requiredRoles[0].payload.accessType].toUpperCase() === reqRole.toUpperCase();
                                            });
                                            // match found
                                            if (index > -1) {
                                                result_1 = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        //    requiredRoles.every((role: UserRoles) => {
                        //        let loop = true;
                        //
                        //        const index = state.user.profile.payload.roles.findIndex((profileRole: UserRoles) => {
                        //            // compare string
                        //            return profileRole.toUpperCase() === role.toUpperCase();
                        //        });
                        //
                        //        // match found
                        //        if (index > -1) {
                        //            loop = false;
                        //            result = true;
                        //        }
                        //
                        //        return loop;
                        //    });
                    }
                    // allow / block navigation
                    observer.next(result_1);
                    observer.complete();
                }
            });
            var userState = _this.ngRedux.getState().user;
            // only load user if a load isn't already in progress
            if (!userState.loading && !userState.isReady) {
                // trigger a load (to update user object)
                _this.userActions.loadUser();
            }
            return function () {
                subscription.unsubscribe();
            };
        });
    };
    AuthService.prototype.getUsername = function () {
        return (this.ngRedux.getState().user.identity.payload.profile || {}).sub || null; // @TODO confirm property
    };
    AuthService.prototype.hasIdentity = function () {
        var result = false;
        var state = this.ngRedux.getState();
        if (state.user && state.user.identity) {
            if (!!state.user.identity.payload && !state.user.identity.error) {
                result = true;
            }
        }
        return result;
    };
    return AuthService;
}());
export { AuthService };
