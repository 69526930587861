// import * as moment from 'moment'; - added globally to prevent all locales being included

import { Order } from '../entities/order.class';
import { SellingItem } from '../entities/sellingItem.class';

import { EntitiesActionsService } from '../actions/entities.actions';
import { AuctionActionsService } from '../actions/auction.actions';
import { CartActionsService } from '../actions/cart.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { OrderActionsService } from '../actions/order.actions';
import { FavouritesActionsService } from '../actions/favourites.actions';

import { AppAction, EntitiesState } from '../../../interfaces/store.interface';
import { CartContents } from '../../../interfaces/cart.interface';
import { AuctionMakeOfferResponse, AuctionMakeOfferRequest } from '../../../interfaces/auction.interface';
import { MessagesActionsService } from '../actions/messages.actions';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { CmsPagesActionsService } from '../actions/cms-pages.actions';


const INITIAL_STATE: EntitiesState = {
    sellingItems: {
        // populated using ID as key
    },
    orders: {
        // populated using ID as the key
    },
    messages: {

    },
    sellingAreas: {

    },
    favourites: {

    },
    pages: {

    }
};

export default function entitiesReducer(state: EntitiesState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * VEHICLES - automatically create the correct object shape for a car
         *
         */

        //
        // VEHICLES IN THE CART
        //

        case CartActionsService.CART_SUCCESS: {

            const nextState = {
                ...state,
                sellingItems: {
                    ...state.sellingItems
                }
            };

            const contents = {
                ...action.payload.response
            } as CartContents;

            // add vehicles to store
            (contents.items || []).forEach((item: any) => {
                let vehicles = [];
                const itemVehicles = (item.vehicles || []).slice();
                vehicles = [
                    ...vehicles,
                    ...itemVehicles
                ];

                nextState.sellingItems[item.id] = new SellingItem(item, {
                    // set flags
                    _isInCart: true,
                    _isUnavailable: false,
                    _addingToCart: false,
                    _bundle: item.vehicles.length > 1,
                    title: item.title,
                    _vehicles: item.vehicles.map(iv => iv.id)
                }, action.payload.area, action.payload.userBranchCode);


                // now expose the vehicles
                // vehicles.forEach((item2: any) => {
                //     nextState.sellingItems[item2.id] = new SellingItem(item2, {}, action.payload.area, action.payload.userBranchCode);
                // });
            });

            return nextState;

        }

        //
        // VEHICLES IN THE RESULTS LIST
        //

        case SalesActionsService.SELLINGITEMS_SUCCESS: {

            const nextState = {
                ...state,
                sellingItems: {
                    ...state.sellingItems
                }
            };


            // add vehicles to store
            (action.payload.response.result || []).forEach((item: any) => {
                let vehicles = [];
                const itemVehicles = (item.vehicles || []).slice();
                vehicles = [
                    ...vehicles,
                    ...itemVehicles
                ];

                nextState.sellingItems[item.id] = new SellingItem(item, {
                    _bundle: itemVehicles.length > 1,
                    title: item.title,
                    _vehicles: itemVehicles.map(iv => iv.id),
                    _isDetailed: false
                });

                // now expose the vehicles
                // vehicles.forEach((item2: any) => {
                //     // nextState.sellingItems[item2.id] = new SellingItem(item2, {}, action.payload.area, action.payload.userBranchCode);
                //     nextState.vehicles[item2.id] = { id: item2.id, props: item2 };
                //     nextState.vehicles[item2.id].props._isDetailed = false;
                // });
            });

            return nextState;

        }

        /**
         *
         * SELLING ITEM DETAIL LOADED
         *
         */

        case SalesActionsService.SELLINGITEM_SUCCESS: {

            const nextState = {
                ...state,
                sellingItems: {
                    ...state.sellingItems
                }
            };

            // update sellingItem
            const item = action.payload.response;

            const itemVehicles = (item.vehicles || []).slice();

            nextState.sellingItems[item.id] = new SellingItem(item, {
                _bundle: itemVehicles.length > 1,
                title: item.title,
                _vehicles: itemVehicles.map(iv => iv.id),
                _isDetailed: true
            });

            return nextState;

        }

        /**
         *
         * SET VALUES ON VEHICLES
         *
         */

        case EntitiesActionsService.SET_VEHICLE_BRANCH_LATLNG: {

            const nextState = {
                ...state
            };

            if (nextState.sellingItems[action.payload.vehicleId]) {

                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [action.payload.vehicleId]: {
                        ...state.sellingItems[action.payload.vehicleId],
                        props: {
                            ...state.sellingItems[action.payload.vehicleId].props
                        }
                    }
                };

                // check the branch exists
                const branch = nextState.sellingItems[action.payload.vehicleId].props.ownerBranches[action.payload.branchIndex];

                if (branch) {

                    // modify the array
                    nextState.sellingItems[action.payload.vehicleId].props.ownerBranches = [
                        ...nextState.sellingItems[action.payload.vehicleId].props.ownerBranches.slice(0, action.payload.branchIndex),
                        {
                            ...branch,
                            _latLng: action.payload.latLng,
                            _previewImg: action.payload.previewImg
                        },
                        ...nextState.sellingItems[action.payload.vehicleId].props.ownerBranches.slice(action.payload.branchIndex)
                    ];

                }
            }

            return nextState;
        }


        case EntitiesActionsService.HIDE_VEHICLE: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _hideAndRemove: true // triggers other side effects
                        }
                    }
                };
            }

            return nextState;
        }

        case EntitiesActionsService.SET_VEHICLE_AS_PURCHASABLE: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            canBePurchased: true
                        }
                    }
                };
            }

            return nextState;
        }

        case EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            canBePurchased: false
                        }
                    }
                };
            }

            return nextState;
        }

        case EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            canBePurchased: true
                        }
                    }
                };
            }

            return nextState;
        }

        /**
         *
         * MARK VEHICLE AS FOR SALE
         *
         */

        case SalesActionsService.SET_AS_FOR_SALE: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props
                        }
                    }
                };
            }

            return nextState;

        }

        /**
         *
         * MARK VEHICLE AUCTION STATE
         *
         */

        case AuctionActionsService.AUCTION_SET_STATE: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            auctionInfo: {
                                ...state.sellingItems[vehicleId].props.auctionInfo,
                                _state: action.payload.state
                            }
                        }
                    }
                };
            }

            return nextState;

        }

        /**
         *
         * UPDATE AUCTION STATUS
         *
         */

        case AuctionActionsService.AUCTION_BY_ID_SUCCESS: {

            const nextState = {
                ...state
            };

            const sellingItemId = action.payload.sellingItemId;
            const response = action.payload.response;
            const fromPush = action.payload.isFromPush;
            const nowForEnd = action.payload.now;

            const sellingItem = nextState.sellingItems[sellingItemId];

            if (sellingItem) {
                const now = Date.now();
                const beginTime = moment(response.showAt).valueOf();
                let endTime = moment(response.hideAt).valueOf();
                if (response.expireInMinutes) {
                    endTime = nowForEnd.add(Math.round(response.expireInMinutes), 'seconds').valueOf();
                }

                let status = response.status;

                if (beginTime > now) {
                    status = 0; // pending
                } else if (endTime > now) {
                    status = 1; // active
                } else {
                    status = 2; // expired
                }

                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [sellingItemId]: {
                        ...state.sellingItems[sellingItemId],
                        props: {
                            ...state.sellingItems[sellingItemId].props,
                            _hideAt: endTime,
                            auctionInfo: {
                                ...state.sellingItems[sellingItemId].props.auctionInfo,
                                // update relevant properties
                                basePrice: response.auctionInfo.basePrice,
                                bestOffer: response.auctionInfo.bestOffer,
                                // maxOffer: response.auctionInfo.maxOffer, // managed below
                                isLoggedUserBestBidder: response.auctionInfo.isLoggedUserBestBidder,
                                beginTime,
                                endTime,
                                _state: status,
                                // UI
                                // _isUserBidder: null // will be updated during listing
                            }
                        }
                    }
                };

                // manage case from push
                if (!fromPush) {
                    nextState.sellingItems[sellingItemId].props.auctionInfo.maxOffer = response.auctionInfo.maxOffer;
                }

            }

            return nextState;

        }

        /**
         *
         * CART
         *
         */

        case CartActionsService.ADD_TO_CART_QUEUE:
        case CartActionsService.ADD_TO_CART_LOADING: {

            const nextState = {
                ...state
            };

            // flag the vehicle as adding
            const vehicleId = action.payload;

            // check it exists
            if (nextState.sellingItems[vehicleId]) {

                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _addingToCart: true,
                            _isInCart: false,
                            _isUnavailable: false
                        }
                    }
                };

            }

            return nextState;

        }

        case CartActionsService.ADD_TO_CART_DEQUEUE: {
            const nextState = {
                ...state
            };

            // flag the vehicle as adding
            const vehicleId = action.payload;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _addingToCart: false
                        }
                    }
                };
            }

            return nextState;

        }

        /**
         *
         * Vehicle has been successfully added to the cart
         *
         */
        case CartActionsService.ADD_TO_CART_IN_CART: {
            const nextState = {
                ...state
            };

            // flag the vehicle as added
            const vehicleId = action.payload;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _addingToCart: false,
                            _isInCart: true,
                            _isUnavailable: false
                        }
                    }
                };
            }

            return nextState;

        }


        /**
         *
         * Server rejected vehicle as it is no longer available
         *
         */
        case CartActionsService.ADD_TO_CART_UNAVAILABLE: {
            const nextState = {
                ...state
            };

            // flag the vehicle as adding
            const vehicleId = action.payload;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _addingToCart: false,
                            _isInCart: false,
                            _isUnavailable: true
                        }
                    }
                };
            }

            return nextState;

        }

        case CartActionsService.REMOVE_FROM_CART: {
            const nextState = {
                ...state
            };

            // flag the vehicle as adding
            const vehicleId = action.payload.id;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _removingFromCart: true
                        }
                    }
                };
            }

            return nextState;

        }

        case CartActionsService.REMOVE_FROM_CART_SUCCESS: {
            const nextState = {
                ...state
            };

            // flag the vehicle as adding
            const vehicleId = action.payload.id;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _removingFromCart: false
                        }
                    }
                };
            }

            return nextState;

        }

        /**
         *
         * AUCTIONS
         *
         */

        case AuctionActionsService.AUCTION_MAKE_OFFER_LOADING: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            auctionInfo: {
                                ...state.sellingItems[vehicleId].props.auctionInfo,
                                _makeOfferLoading: true
                            }
                        }
                    }
                };
            }

            return nextState;

        }

        case AuctionActionsService.AUCTION_MAKE_OFFER_ERROR: {

            const nextState = {
                ...state
            };

            const vehicleId = action.payload.vehicleId;

            if (nextState.sellingItems[vehicleId]) {
                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            auctionInfo: {
                                ...state.sellingItems[vehicleId].props.auctionInfo,
                                _makeOfferLoading: false
                            }
                        }
                    }
                };
            }

            return nextState;

        }

        case AuctionActionsService.AUCTION_MAKE_OFFER_SUCCESS: {

            const nextState = {
                ...state
            };

            const request = action.payload.request as AuctionMakeOfferRequest;
            const response = action.payload.response as AuctionMakeOfferResponse;
            const nowForEnd = action.payload.now;

            // auction unavailable
            if (response._result === 5) {
                return nextState;
            }

            const vehicleId = request.sellingItemId;
            const offer = request.maxOffer;
            let endTime = moment(response.endTime).valueOf();
            if (response.expireInMinutes) {
                endTime = nowForEnd.add(Math.round(response.expireInMinutes), 'seconds').valueOf();
            }

            const vehicle = nextState.sellingItems[vehicleId];

            if (vehicle) {

                // update properties
                let isLoggedUserBestBidder = !!vehicle.props.auctionInfo.isLoggedUserBestBidder;
                let valueIsLoggedUsersBestOffer = false;

                switch (response._result) {
                    case 0:
                    case 4:
                        // user is definitely best bidder
                        isLoggedUserBestBidder = true;
                        valueIsLoggedUsersBestOffer = true;
                        break;
                    case 3:
                    case 6:
                        // user is definitely NOT the best bidder (this is still counted as a bid in the UI)
                        isLoggedUserBestBidder = false;
                        valueIsLoggedUsersBestOffer = true;
                        break;
                    case 1:
                        // do nothing
                        break;
                }

                // set all nested
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [vehicleId]: {
                        ...state.sellingItems[vehicleId],
                        props: {
                            ...state.sellingItems[vehicleId].props,
                            _hideAt: endTime,
                            auctionInfo: {
                                ...state.sellingItems[vehicleId].props.auctionInfo,
                                isLoggedUserBestBidder: isLoggedUserBestBidder,
                                bestOffer: response.currentOffer,
                                maxOffer: offer,    // user max offer
                                _isUserBidder: true,
                                _offerResult: response._result,
                                _errorMessage: response._errorMessage,
                                _makeOfferLoading: false
                            }
                        }
                    }
                };

            }

            return nextState;

        }

        // MESSAGES


        case MessagesActionsService.MESSAGES_SUCCESS: {

            const nextState = {
                ...state
            };

            // add messages to store
            action.payload.response.result.forEach((item: any) => {

                nextState.messages[item.id] = {
                    id: item.id,
                    props: item
                };

            });

            return nextState;

        }

        case MessagesActionsService.MODAL_MESSAGES_SUCCESS: {

            const nextState = {
                ...state
            };

            // add messages to store
            action.payload.response.result.forEach((item: any) => {

                nextState.messages[item.id] = {
                    id: item.id,
                    props: item
                };

            });

            return nextState;

        }

        // SELLING AREAS


        case SellingAreasActionsService.FETCH_SELLING_AREAS_SUCCESS: {

            const nextState = {
                ...state
            };

            // add orders to store
            action.payload.result.forEach((item: any) => {

                nextState.sellingAreas[item.id] = {
                    id: item.id,
                    props: { ...item, _hasFullInfo: false }
                };

            });

            return nextState;

        }

        case SellingAreasActionsService.FETCH_SELLING_AREA_FULL_SUCCESS: {

            const sellingAreaId = action.payload.id;

            const nextState = {
                ...state,
                sellingAreas: {
                    ...state.sellingAreas,
                    [sellingAreaId]: {
                        ...state.sellingAreas[sellingAreaId],
                        props: {
                            ...state.sellingAreas[sellingAreaId].props,
                            ...action.payload,
                            _hasFullInfo: true
                        }
                    }
                }
            };

            return nextState;

        }

        case MessagesActionsService.TOGGLE_MESSAGE_SUCCESS: {

            const nextState = {
                ...state
            };

            nextState.messages[action.payload.id].props.read = !nextState.messages[action.payload.id].props.read;

            return nextState;

        }

        /**
         *
         * ORDERS
         *
         */

        case OrderActionsService.ORDERS_SUCCESS: {

            const nextState = {
                ...state,
                orders: {
                    ...state.orders
                }
            };

            // add orders to store
            action.payload.response.result.forEach((item: any) => {

                const items = (item.items || []).slice();
                const deletedItems = (item.deletedItems || []).slice();

                // regular vehicles
                item.items = items.map(itm => {
                    const itemVehicles = (itm.vehicles || itm.cars || []).slice();

                    if (itemVehicles.length > 1) {
                        itm = new SellingItem(itm, {
                            _bundle: true,
                            title: itm.title,
                            _vehicles: itemVehicles.map(iv => iv.id)
                        });
                    } else {
                        itm = new SellingItem(itm, {
                            _vehicles: itemVehicles.map(iv => iv.id)
                        });
                    }

                    return itm.props;
                });

                // deleted vehicles
                item.deletedItems = deletedItems.map(itm => {
                    const itemVehicles = (itm.vehicles || itm.cars || []).slice();

                    if (itemVehicles.length > 1) {
                        itm = new SellingItem(itm, {
                            _bundle: true,
                            title: itm.title,
                            _vehicles: itemVehicles.map(iv => iv.id)
                        });
                    } else {
                        itm = new SellingItem(itm, {
                            _vehicles: itemVehicles.map(iv => iv.id)
                        });
                    }

                    return itm.props;
                });

                // create the Order object
                const order = new Order(item);

                // expose the order
                nextState.orders[order.id] = order;

            });

            return nextState;

        }

        case OrderActionsService.TOGGLE_ORDER_VEHICLES: {

            const nextState = {
                ...state
            };

            const orderId = action.payload.orderId;

            if (nextState.orders[orderId]) {
                // set all nested
                nextState.orders = {
                    ...state.orders,
                    [orderId]: {
                        ...state.orders[orderId],
                        props: {
                            ...state.orders[orderId].props,
                            // reverse the value
                            _showVehicles: !state.orders[orderId].props._showVehicles
                        }
                    }
                };
            }

            return nextState;

        }

        case OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS: {

            const nextState = {
                ...state
            };

            const orderId = action.payload.orderId;
            const vehicleId = action.payload.vehicleId;

            if (nextState.orders[orderId]) {

                const vehicleShownIndex = nextState.orders[orderId].props._vehiclesDetailsShown.indexOf(vehicleId);
                const nextVehiclesDetailsShown = [...nextState.orders[orderId].props._vehiclesDetailsShown.slice()];

                if (vehicleShownIndex > -1) {
                    nextVehiclesDetailsShown.splice(vehicleShownIndex, 1);
                } else {
                    nextVehiclesDetailsShown.push(vehicleId);
                }

                // set all nested
                nextState.orders = {
                    ...state.orders,
                    [orderId]: {
                        ...state.orders[orderId],
                        props: {
                            ...state.orders[orderId].props,
                            // reverse the value
                            _vehiclesDetailsShown: nextVehiclesDetailsShown
                        }
                    }
                };
            }

            return nextState;

        }

        // FAVOURITES


        case FavouritesActionsService.FAVOURITES_SUCCESS: {

            const nextState = {
                ...state
            };

            // add favourites to store
            action.payload.response.result.forEach((item: any) => {

                let vehicles = [];
                const itemVehicles = (item.sellingItem.vehicles || item.cars || []).slice();
                vehicles = [
                    ...vehicles,
                    ...itemVehicles
                ];

                const siObj: any = {
                    isAvailable: item.isAvailable,
                    isFavourite: true // @TODO: remove when api works
                };

                if (item.sellingItem.title) {
                    siObj.title = item.sellingItem.title;
                }

                if (itemVehicles.length > 1) {
                    siObj._bundle = true;
                    siObj._vehicles = itemVehicles.map(iv => iv.id);
                }
                nextState.favourites[item.sellingItem.id] = new SellingItem(item.sellingItem, siObj);

                // now expose the vehicles
                // vehicles.forEach((item2: any) => {
                //     nextState.favourites[item2.id] = new SellingItem(item2, {
                //         isAvailable: item.isAvailable,
                //         isFavourite: true // @TODO: remove when api works
                //     });
                // });

            });

            return nextState;

        }

        case FavouritesActionsService.TOGGLE_FAVOURITE_SUCCESS: {

            const nextState = {
                ...state
            };

            if (state.sellingItems[action.payload.sellingItemId]) {
                nextState.sellingItems = {
                    ...state.sellingItems,
                    [action.payload.sellingItemId]: {
                        ...state.sellingItems[action.payload.sellingItemId],
                        props: {
                            ...state.sellingItems[action.payload.sellingItemId].props,
                            isFavourite: action.payload.enable
                        }
                    }
                };
            }

            if (state.favourites[action.payload.sellingItemId]) {
                nextState.favourites = {
                    ...state.favourites,
                    [action.payload.sellingItemId]: {
                        ...state.favourites[action.payload.sellingItemId],
                        props: {
                            ...state.favourites[action.payload.sellingItemId].props,
                            isFavourite: action.payload.enable
                        }
                    }
                };
            }

            return nextState;
        }

        // CMS PAGES

        case CmsPagesActionsService.PAGE_SUCCESS: {

            const nextState = {
                ...state
            };

            // add page to store
            nextState.pages[action.payload.pageId] = {
                id: action.payload.pageId,
                props: action.payload
            };

            return nextState;
        }

    }

    return state;

}
