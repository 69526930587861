import { ElementRef, Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class ScrollActionsService {

    static readonly SCROLL_TO = '@scroll/SCROLL_TO';
    static readonly SCROLL_TO_TOP = '@scroll/SCROLL_TO_TOP';
    static readonly REFRESH = '@scroll/REFRESH';
    static readonly UNHOOK = '@scroll/UNHOOK';
    static readonly LOCK = '@scroll/LOCK';
    static readonly UNLOCK = '@scroll/UNLOCK';

    static readonly REGISTER_INVIEW = '@scroll/REGISTER_INVIEW';
    static readonly SET_INVIEW = '@scroll/SET_INVIEW';
    static readonly DEREGISTER_INVIEW = '@scroll/DEREGISTER_INVIEW';

    /**
     *
     * SETTINGS
     *
     */

    @dispatch()
    scrollTo = (payload: { id: string, lock?: boolean }): AppAction => ({
        type: ScrollActionsService.SCROLL_TO,
        payload
    });

    @dispatch()
    scrollToTop = (): AppAction => ({
        type: ScrollActionsService.SCROLL_TO_TOP
    });

    @dispatch()
    refresh = (): AppAction => ({
        type: ScrollActionsService.REFRESH
    });

    @dispatch()
    unhook = (): AppAction => ({
        type: ScrollActionsService.UNHOOK
    });

    @dispatch()
    lock = (): AppAction => ({
        type: ScrollActionsService.LOCK
    });

    @dispatch()
    unlock = (): AppAction => ({
        type: ScrollActionsService.UNLOCK
    });

    @dispatch()
    registerInview = (payload: { id: string, visible: boolean, element: ElementRef }): AppAction => ({
        type: ScrollActionsService.REGISTER_INVIEW,
        payload
    });

    @dispatch()
    deregisterInview = (payload: { id: string }): AppAction => ({
        type: ScrollActionsService.DEREGISTER_INVIEW,
        payload
    });

    @dispatch()
    setInview = (payload: { id: string, visible: boolean }): AppAction => ({
        type: ScrollActionsService.SET_INVIEW,
        payload
    });

}
