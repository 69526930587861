<table class="cart-messages__table">
    <thead>
        <tr>
            <th style="width: 15px;"></th>
            <th i18n>{{'Cart.Validity.Rule' | translate}}</th>
            <th i18n>{{'Cart.Validity.Min' | translate}}</th>
            <th i18n>{{'Cart.Validity.Max' | translate}}</th>
            <th i18n>{{'Cart.Validity.Current' | translate}}</th>
            <th></th>
        </tr>
    </thead>
    <tbody *ngFor="let message of validity.messages" [ngClass]="{ 'cart-messages--ok': message.status === 0, 'cart-messages--warning': message.status === 1, 'cart-messages--error': message.status === 2 }" [attr.title]="message.description">
        <tr class="cart-messages__row">
            <td>
                <i class="icon icon--cart-rule-restriction" *ngIf="message._icon === 'restriction'"></i>
                <i class="icon icon--cart-rule-value" *ngIf="message._icon === 'value'"></i>
                <i class="icon icon--cart-rule-dependency" *ngIf="message._icon === 'dependency'"></i>
                <i class="icon icon--cart-rule-disabled" *ngIf="message._icon === 'disabled'"></i>
                <i class="icon icon--cart-rule-size" *ngIf="message._icon === 'size'"></i>
                <i class="icon icon--cart-rule-order" *ngIf="message._icon === 'order'"></i>
            </td>
            <td class="uppercase">{{ message._item }}</td>
            <td>{{ message.data.min | validationValue:message._isCurrency }}</td>
            <td>{{ message.data.max | validationValue:message._isCurrency }}</td>
            <td>{{ message.data.value | validationValue:message._isCurrency }}</td>
            <td style="text-align: right;"><i class="icon icon--cart-rule-met" *ngIf="message.status < 2"></i></td>
        </tr>
        <ng-container *ngIf="message._children.length">
            <tr class="cart-messages__note">
                <td></td>
                <td colspan="5">
                    <em i18n>{{'Cart.Validity.requires' | translate}}:</em>
                </td>
            </tr>
            <tr class="cart-messages__children" *ngFor="let child of message._children" [ngClass]="{ 'cart-messages--met': child._met && message.status > 0, 'cart-messages--not-met': !child._met && message.status > 0 }">
                <td></td>
                <td class="uppercase"> - {{ child._item }}</td>
                <td>{{ child.min | validationValue:message._isCurrency }}</td>
                <td>{{ child.max | validationValue:message._isCurrency }}</td>
                <td>{{ child.value | validationValue:message._isCurrency }}</td>
                <td style="text-align: right;">
                    <i class="icon icon--cart-rule-not-met" *ngIf="!child._met && message.status > 0"></i>
                    <i class="icon icon--cart-rule-met" *ngIf="child._met && message.status > 0"></i>
                </td>
            </tr>
            <ng-container *ngIf="showDescription">
              <tr class="cart-messages__description">
                  <td></td>
                  <td colspan="5">
                      {{ message.data._description }}
                  </td>
              </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>
<div class="cart-messages__expiry"><span i18n>{{'Cart.Validity.RulesExpire' | translate}}</span>: {{ expiry | dateTime }}</div>
