/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../listing-thumbnails/listing-thumbnails.component.ngfactory";
import * as i3 from "../listing-thumbnails/listing-thumbnails.component";
import * as i4 from "../listing-bundle-thumbnails/listing-bundle-thumbnails.component.ngfactory";
import * as i5 from "../listing-bundle-thumbnails/listing-bundle-thumbnails.component";
import * as i6 from "../listing/listing-info/listing-info.component.ngfactory";
import * as i7 from "../listing/listing-info/listing-info.component";
import * as i8 from "../../../store/actions/favourites.actions";
import * as i9 from "./listing-item.component";
import * as i10 from "@angular/router";
import * as i11 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i12 from "../../../store/actions/sales.actions";
import * as i13 from "../../../store/actions/order.actions";
import * as i14 from "../../../store/actions/ui.actions";
import * as i15 from "../../services/google-analytics/google-analytics.service";
var styles_ListingItemComponent = [];
var RenderType_ListingItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListingItemComponent, data: {} });
export { RenderType_ListingItemComponent as RenderType_ListingItemComponent };
function View_ListingItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "listing__sash"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "listing__sash--positive": 0, "listing__sash--negative": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "listing__sash"; var currVal_1 = _ck(_v, 3, 0, ((_co.sellingItem.auctionInfo == null) ? null : _co.sellingItem.auctionInfo.isLoggedUserBestBidder), !((_co.sellingItem.auctionInfo == null) ? null : _co.sellingItem.auctionInfo.isLoggedUserBestBidder)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ListingItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingItemComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.sellingItem.auctionInfo == null) ? null : _co.sellingItem.auctionInfo._isUserBidder) === true); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ListingItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-listing-thumbnails", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseenter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseleave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ListingThumbnailsComponent_0, i2.RenderType_ListingThumbnailsComponent)), i0.ɵdid(1, 704512, null, 0, i3.ListingThumbnailsComponent, [], { thumbnails: [0, "thumbnails"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sellingItem._thumbnails; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ListingItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-listing-bundle-thumbnails", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseenter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseleave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ListingBundleThumbnailsComponent_0, i4.RenderType_ListingBundleThumbnailsComponent)), i0.ɵdid(1, 49152, null, 0, i5.ListingBundleThumbnailsComponent, [], { cars: [0, "cars"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sellingItem.vehicles; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ListingItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["appTouchable", ""], ["class", "listing__link--order"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "listing__link": 0, "listing__no-link": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingItemComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-listing-info", [], null, null, null, i6.View_ListingInfoComponent_0, i6.RenderType_ListingInfoComponent)), i0.ɵdid(7, 573440, null, 0, i7.ListingInfoComponent, [i8.FavouritesActionsService, i0.ChangeDetectorRef], { sellingItem: [0, "sellingItem"], bundleVehicles: [1, "bundleVehicles"], mode: [2, "mode"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingItemComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListingItemComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "listing__link--order"; var currVal_1 = _ck(_v, 3, 0, _co.hasListingLinkClassName(), _co.hasListingNoLinkClassName()); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.sellingItem._isAuction; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.sellingItem; var currVal_4 = _co.sellingItem.vehicles; var currVal_5 = _co.mode; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = !_co.sellingItem._bundle; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.sellingItem._bundle; _ck(_v, 11, 0, currVal_7); }, null); }
export function View_ListingItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-listing-item", [], [[2, "listing__item--disabled", null], [2, "listing__item--removed", null]], null, null, View_ListingItemComponent_0, RenderType_ListingItemComponent)), i0.ɵdid(1, 704512, null, 0, i9.ListingItemComponent, [i10.Router, i11.NgRedux, i12.SalesActionsService, i13.OrderActionsService, i14.UiActionsService, i15.GoogleAnalyticsService], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).disabledStatus; var currVal_1 = i0.ɵnov(_v, 1).removedStatus; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ListingItemComponentNgFactory = i0.ɵccf("app-listing-item", i9.ListingItemComponent, View_ListingItemComponent_Host_0, { sellingItem: "sellingItem", vehicle: "vehicle", mode: "mode", index: "index", disabled: "disabled", removed: "removed", orderId: "orderId" }, {}, []);
export { ListingItemComponentNgFactory as ListingItemComponentNgFactory };
