import {
    AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, Renderer2,
    ViewChild, ViewChildren, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy
} from '@angular/core';

import { SalesActionsService } from '../../../store/actions/sales.actions';
import { ISellingItem } from '../../../../interfaces/SellingItem.interface';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, take, filter } from 'rxjs/operators';
import { OrderActionsService } from '../../../store/actions/order.actions';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../../../interfaces/store.interface';

@Component({
    selector: 'app-bundle-thumbs',
    templateUrl: './bundle-thumbs.component.html',
    styleUrls: ['./bundle-thumbs.component.scss']
})
export class BundleThumbsComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

    @ViewChild('container', { static: false }) container: ElementRef;
    @ViewChild('bundle', { static: false }) bundle: ElementRef;
    thumbs: QueryList<ElementRef>;
    @ViewChildren('thumb') set thumbElements(el: QueryList<ElementRef>) {
        if (el) {
            this.thumbs = el;
            this.recalculateWidth();
        }
    }

    @Input() sellingItemId: string;
    @Input() orderId: string;
    @Input() cars: ISellingItem[];
    @Input() init: boolean;
    @Input() context: 'listing' | 'detail' | 'orders';

    @Output() activeCarChange = new EventEmitter<number>();

    nextDisabled: boolean;
    previousDisabled: boolean;
    currentActive = 0;

    index = 0;

    private maxIndex: number;
    private thumbWidth = 156;
    private groupSize = 3;
    private groupWidth = this.thumbWidth * this.groupSize;
    private width = 0;

    private currentParentWidth = 0;

    private resizeSubscription = Subscription.EMPTY;
    private selectedIndexSubscription = Subscription.EMPTY;

    constructor(
        private renderer: Renderer2,
        private salesActions: SalesActionsService,
        private ordersActions: OrderActionsService,
        private changeDetector: ChangeDetectorRef,
        private ngRedux: NgRedux<AppState>
    ) {
        this.resizeSubscription = fromEvent(window, 'resize').pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((event) => {
            if (!this.container) {
                return;
            }

            this.recalculateWidth();
        });
    }

    ngOnInit() {
        // disable / enable controls
        this.checkControls();

        if (this.init) {
            this.activateBundleCar(0);
        }

        // set the style
        const style = 'translate3d(' + (0 - (this.groupWidth * this.index)) + 'px, 0, 0)';

        if (this.container) {
            this.renderer.setStyle(this.bundle.nativeElement, 'transform', style);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
    }


    ngAfterViewInit() {
        window.setTimeout(() => {
            this.recalculateWidth();
        });

        this.selectedIndexSubscription = this.ngRedux.select(['sales', 'selected', 'index'])
            .pipe(
                filter(index => index === -1)
            ).subscribe(() => {
                setTimeout(() => {
                    this.recalculateWidth();
                }, 100);
            });
    }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
        this.selectedIndexSubscription.unsubscribe();
    }

    next() {
        if (!this.nextDisabled) {
            this.change(1);
        }
    }

    previous() {
        if (!this.previousDisabled) {
            this.change(-1);
        }
    }

    activateBundleCar(index: number): void {
        //    console.log('activateBundleCar:' + index, this.cars);
        if (this.context === 'orders') {
            this.ordersActions.setSelectedBundleIndex(index);
        } else {
            this.salesActions.setSelectedBundleIndex(index);
        }

        this.currentActive = index;
        this.activeCarChange.emit(index);

    }

    recalculateWidth() {
        if (this.container) {
            const parentWidth = this.container.nativeElement.offsetWidth;
            // if (parentWidth === 0) {
            //     setTimeout(() => {
            //         this.recalculateWidth();
            //     }, 200);
            // }
            if (this.currentParentWidth !== parentWidth) {
                this.currentParentWidth = parentWidth;
            }
        }

        if (this.thumbs) {
            this.maxIndex = this.thumbs.toArray().length - 3;
            this.setThumbWidth();
        }
    }

    private change(direction: 0 | 1 | -1) {
        this.index = this.index + direction;
        this.checkControls();
        const style = 'translate3d(' + (0 - (this.width * this.index)) + 'px, 0, 0)';
        this.renderer.setStyle(this.bundle.nativeElement, 'transform', style);
    }

    private setThumbWidth() {
        const parent = this.container.nativeElement;
        const thumbs = this.thumbs.toArray();
        //    console.log(thumbs);
        const width = thumbs.length > 2
            ? thumbs.length === 3
                ? (parent.offsetWidth - 30) / 3
                : parent.offsetWidth / 3
            : (parent.offsetWidth - 30) / 2;

        this.width = Math.round(width * 100) / 100;
        thumbs.forEach(thumb => this.renderer.setStyle(thumb.nativeElement, 'width', `${this.width}px`));

    }

    private checkControls() {
        this.nextDisabled = this.index === this.maxIndex;
        this.previousDisabled = this.index === 0;
    }

}
