import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ViewRef } from '@angular/core';

import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';

import { ISellingItem } from '../../../../../interfaces/SellingItem.interface';
import { FavouritesActionsService } from '../../../../store/actions/favourites.actions';
import { UserType } from '../../../../../interfaces/user.interface';

@Component({
    selector: 'app-listing-info',
    templateUrl: './listing-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingInfoComponent implements OnChanges {
    @Input() sellingItem: ISellingItem;
    @Input() bundleVehicles: ISellingItem[] = [];
    @Input() mode: string;

    @select(['user', 'identity', 'payload', 'profile', 'user_type']) userType$: Observable<string>;
    userTypes = UserType;

    auctionValueToShow: number;

    constructor(
        private favouritesActions: FavouritesActionsService,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.sellingItem && this.sellingItem._isAuction) {
            let bid = Math.max(
                this.sellingItem.auctionInfo.bestOffer || 0,
                this.sellingItem.auctionInfo.maxOffer || 0,
                this.sellingItem.auctionInfo.basePrice || 0);

            // include minimum raise if there has already been a bid
            if (this.sellingItem.auctionInfo.bestOffer || 0 > 0) {
                bid = bid + this.sellingItem.auctionInfo.bidStep;
            }
            this.auctionValueToShow = bid;
            if (!(this.changeDetector as ViewRef).destroyed) {
                this.changeDetector.detectChanges();
            }
        }
    }

    setActiveCar(index: number) {

    }

    toggleFavourite($event: Event) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        const params = {
            sellingItemId: this.sellingItem.id,
            enable: !this.sellingItem.isFavourite
        };

        this.favouritesActions.toggleFavourite(params);
    }
}
