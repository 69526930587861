import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
var SellingAreasEpicsService = /** @class */ (function () {
    function SellingAreasEpicsService(http) {
        var _this = this;
        this.http = http;
        /**
         *
         * FETCH SELLING AREAS
         *
         */
        this.fetchSellingAreas = function (action$, state$) {
            return action$.ofType(SellingAreasActionsService.FETCH_SELLING_AREAS).pipe(switchMap(function () {
                var queryParams = new HttpParams();
                queryParams = queryParams.append('pageIndex', '0');
                queryParams = queryParams.append('pageSize', '1000');
                return _this.http.get('/SellingAreas/List', {
                    params: queryParams
                }).pipe(switchMap(function (response) {
                    return of({
                        type: SellingAreasActionsService.FETCH_SELLING_AREAS_SUCCESS,
                        payload: response
                    });
                }));
            }));
        };
        /**
         *
         * FETCH FULL SELLING AREA
         *
         */
        this.fetchSellingAreaFull = function (action$, state$) {
            var cancel$ = action$.pipe(ofType(SellingAreasActionsService.FETCH_SELLING_AREA_FULL));
            return action$.ofType(SellingAreasActionsService.FETCH_SELLING_AREA_FULL).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var queryParams = new HttpParams();
                queryParams = queryParams.append('id', payload.id);
                return _this.http.get('/SellingAreas/Get', {
                    params: queryParams
                }).pipe(switchMap(function (response) {
                    return of({
                        type: SellingAreasActionsService.FETCH_SELLING_AREA_FULL_SUCCESS,
                        payload: response
                    });
                }), catchError(function (response) {
                    return of({
                        type: SellingAreasActionsService.FETCH_SELLING_AREA_FULL_FAILURE,
                        error: {
                            response: response
                        }
                    });
                }), takeUntil(cancel$));
            }));
        };
    }
    return SellingAreasEpicsService;
}());
export { SellingAreasEpicsService };
