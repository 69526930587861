import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-checkout-error',
  templateUrl: './modal-checkout-error.component.html',
  styleUrls: ['./modal-checkout-error.component.scss']
})
export class ModalCheckoutErrorComponent {
  @Input() errors: string[] = [];
  constructor (public activeModal: NgbActiveModal) {}
}
