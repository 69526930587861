<div class="modal--is-communication">
  <div class="modal__inner">
    <h1 class="modal__title">{{ title }}</h1>
    <ng-container *ngIf="message">
      <div class="modal__content" [innerHTML]="message | safeHTML"></div>
    </ng-container>
  </div>
</div>


<div class="modal__close">
  <button type="button" class="button button--transparent" appTouchable (click)="activeModal.dismiss()">
      <i class="icon icon--out icon--close-thin-grey"></i>
      <i class="icon icon--hover icon--close-dark-grey"></i>
      &nbsp;
  </button>
</div>
