
import { AppAction, CartState } from '../../../interfaces/store.interface';
import {
    CartContents,
} from '../../../interfaces/cart.interface';

import { insertArrayItem, pushArrayItems, removeArrayItem } from './helpers';

import { CartActionsService } from '../actions/cart.actions';
import { SalesActionsService } from '../actions/sales.actions';
import { ISellingItemStore } from '../../../interfaces/SellingItem.interface';

const INITIAL_STATE: CartState = {
    loading: false,
    hasError: false,
    error: null,
    expiry: null,
    contents: {
        id: null,
        // contents
        items: [],
        vehicles: [],
        totalVehicles: 0,
        // price
        vehiclesPrice: 0,
        discountAmount: 0,
        total: 0,
        grossAmount: 0,
        netAmount: 0,
        transportCosts: 0,
        // time
        creationTime: null,
        expireAt: null,
        currentTime: null,
        // settings
        dangerZone: null,
        warningZone: null,
        paymentTerms: null
    },
    queue: [],
    expiredVehicles: [],
    branches: [],
    isLoadingBranches: false,
    paymentMethods: null,
    isLoadingPaymentMethods: false,
    isWarningZone: false,
    isDangerZone: false,
    isCheckingOut: false,
    isCheckedOut: false,
    isDeleting: false,
    isEmpty: false
};

export default function cartReducer(state: CartState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {


        /**
         *
         * SALES AREA
         *
         */

        case SalesActionsService.SET_AREA:
        case CartActionsService.CART_RESET: {

            const nextState = {
                // reset the cart when the area changes
                ...INITIAL_STATE
            };

            return nextState;

        }

        /**
         *
         * CART - loading
         *
         */

        case CartActionsService.CART_LOADING: {

            const nextState = {
                // loading means we should clear the existing values
                ...INITIAL_STATE,
                loading: true
            };

            return nextState;

        }

        /**
         *
         * CART - load success
         *
         */

        case CartActionsService.CART_SUCCESS: {

            const contents = {
                ...action.payload.response
            } as CartContents;

            // read old property names
            contents.vehicles = (contents.items || []).map((item: any) => {
                // only store the ID here
                return item.id;
            });

            const expiry = moment(contents.expireAt).valueOf();

            return {
                ...state,
                expiry,
                contents,
                isEmpty: contents.items.length === 0, // sometimes this endpoint is empty without sending a 404
                loading: false
            };

        }

        /**
         *
         * CART - load success (empty cart)
         *
         */

        case CartActionsService.CART_SUCCESS_EMPTY: {

            const nextState = {
                ...state,
                isEmpty: true
            };

            return nextState;

        }

        /**
         *
         * CART - load error
         *
         */

        case CartActionsService.CART_ERROR: {

            const nextState = {
                ...INITIAL_STATE,
                hasError: true,
                error: action.error
            };

            return nextState;

        }


        /**
         *
         * CART EXPIRED - track the IDs
         *
         */

        case CartActionsService.CART_EXPIRED: {

            const nextState = {
                ...state
            };

            // track the ids and add a timestamp
            nextState.expiredVehicles = pushArrayItems(nextState.expiredVehicles, nextState.contents.vehicles.map((vehicleId: string) => {
                return {
                    vehicleId,
                    timestamp: Date.now()
                };
            }));

            return nextState;

        }

        /**
         *
         * CART - add a vehicle to the queue so we can validate the cart
         *
         */

        case CartActionsService.ADD_TO_CART_QUEUE: {

            const nextState = {
                ...state
            };

            // add the vehicle's ID to the queue (ensure it is only listed once)
            if (nextState.queue.indexOf(action.payload) === -1) {
                nextState.queue = insertArrayItem(state.queue, state.queue.length, action.payload);
            }

            return nextState;

        }

        /**
         *
         * CART - remove a vehicle from the queue
         *
         */

        case CartActionsService.ADD_TO_CART_DEQUEUE: {
            const nextState = {
                ...state
            };

            const index = nextState.queue.findIndex((item: string) => {
                return item !== action.payload; // (vehicleId)
            });

            if (index > -1) {
                nextState.queue = removeArrayItem(state.queue, index);
            }

            return nextState;

        }

        /**
         *
         * DANGER / WARNING ZONE
         *
         */

        case CartActionsService.SET_WARNING_ZONE: {

            const nextState = {
                ...state,
                isWarningZone: action.payload
            };

            return nextState;

        }

        case CartActionsService.SET_DANGER_ZONE: {

            const nextState = {
                ...state,
                isDangerZone: action.payload
            };

            return nextState;

        }


        /**
         *
         * CART - remove from cart
         *
         */

        case CartActionsService.REMOVE_FROM_CART_SUCCESS: {

            const nextState = {
                ...state,
                contents: {
                    ...state.contents
                }
            };

            const vehicle = {
                ...action.payload.vehicle
            } as ISellingItemStore;

            // find item to remove
            const index = nextState.contents.vehicles.findIndex((vehicleId: string) => {
                return vehicleId !== vehicle.id;
            });

            if (index > -1) {

                // clone the array and remove the item
                nextState.contents.vehicles = removeArrayItem(state.contents.vehicles, index);

                // set the count
                nextState.contents.totalVehicles = nextState.contents.vehicles.length;

                // reduce the price

                // TODO PRICE
                // nextState.contents.vehiclesPrice = Math.max(nextState.contents.vehiclesPrice - vehicle.props.price, 0);

            }

            return nextState;

        }

        /**
         *
         * CART CHECKOUT - checking out
         *
         */

        case CartActionsService.CHECKOUT_CART_LOADING: {

            const nextState = {
                ...state,
                isCheckingOut: true
            };

            return nextState;

        }

        /**
         *
         * CART CHECKOUT - error
         *
         */

        case CartActionsService.CHECKOUT_CART_ERROR: {

            const nextState = {
                // ...INITIAL_STATE,
                ...state,
                hasError: true,
                error: action.error,
                isCheckingOut: false,
                isCheckedOut: false
            };

            return nextState;

        }

        /**
        *
        * CART CHECKOUT - success
        *
        */

        case CartActionsService.CHECKOUT_CART_SUCCESS: {

            const nextState = {
                ...state,
                isCheckingOut: false,
                isCheckedOut: true
            };

            return nextState;

        }

        /**
         *
         * CART - deleting
         *
         */

        case CartActionsService.DELETE_CART_LOADING: {

            const nextState = {
                ...state,
                isDeleting: true
            };

            return nextState;

        }

        /**
         *
         * CART - loading branches
         *
         */

        case CartActionsService.CART_BRANCHES_LOADING: {
            return {
                ...state,
                isLoadingBranches: true
            };
        }

        /**
         *
         * CART - branches loaded successfully
         *
         */

        case CartActionsService.CART_BRANCHES_SUCCESS: {
            return {
                ...state,
                branches: [...action.payload.response],
                isLoadingBranches: false
            };
        }

        /**
         *
         * CART - branches load error
         *
         */

        case CartActionsService.CART_BRANCHES_ERROR: {
            const nextState = {
                ...INITIAL_STATE,
                hasError: true,
                error: action.error
            };

            return nextState;
        }

        /**
         *
         * CART - loading paymentmethods
         *
         */

        case CartActionsService.PAYMENTMETHODS_LOADING: {
            return {
                ...state,
                isLoadingPaymentMethods: true
            };
        }

        /**
         *
         * CART - paymentmethods loaded successfully
         *
         */

        case CartActionsService.PAYMENTMETHODS_SUCCESS: {
            return {
                ...state,
                paymentMethods: [...action.payload],
                isLoadingPaymentMethods: false
            };
        }

        /**
         *
         * CART - paymentmethods load error
         *
         */

        case CartActionsService.PAYMENTMETHODS_ERROR: {
            const nextState = {
                ...INITIAL_STATE,
                hasError: true,
                error: action.error
            };

            return nextState;
        }

    }

    return state;

}
