import * as tslib_1 from "tslib";
// import * as moment from 'moment'; - added globally to prevent all locales being included
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
var DatepickerI18nService = /** @class */ (function (_super) {
    tslib_1.__extends(DatepickerI18nService, _super);
    function DatepickerI18nService() {
        return _super.call(this) || this;
    }
    // get the values from Moment, which is already localised
    DatepickerI18nService.prototype.getWeekdayShortName = function (weekday) {
        return moment.weekdaysShort()[weekday === 7 ? 0 : weekday];
    };
    DatepickerI18nService.prototype.getMonthShortName = function (month) {
        return moment.monthsShort()[month - 1];
    };
    DatepickerI18nService.prototype.getMonthFullName = function (month) {
        return moment.months()[month - 1];
    };
    DatepickerI18nService.prototype.getDayAriaLabel = function (date) {
        return date.day + "-" + date.month + "-" + date.year;
    };
    return DatepickerI18nService;
}(NgbDatepickerI18n));
export { DatepickerI18nService };
