import { Component, HostListener, Input, OnChanges, OnDestroy } from '@angular/core';

import { Observable, Subscription, timer } from 'rxjs';

import { CONFIG } from '../../config/config.const';

@Component({
    selector: 'app-listing-thumbnails',
    templateUrl: './listing-thumbnails.component.html',
    styleUrls: ['./listing-thumbnails.component.scss']
})
export class ListingThumbnailsComponent implements OnChanges, OnDestroy {

    @Input() thumbnails: string[];

    thumbs: { src: string, index: number }[] = [];
    lazyThumbs: { src: string, index: number }[] = [];

    stopAddingLazyThumbs = false;

    visibleIndex: number;

    private subscription = Subscription.EMPTY;

    ngOnChanges() {

        const data = (this.thumbnails || []);

        this.thumbs = data.map((src: string, index: number) => {
            return {
                src,
                index: data.length - index // reverse the index
            };
        });

        this.visibleIndex = 0;
        if (this.thumbs) {
            if (this.thumbs.length > 0) {
                this.lazyThumbs.push(this.thumbs[this.visibleIndex]);
            }
            if (this.thumbs.length > 1) {
                this.lazyThumbs.push(this.thumbs[this.visibleIndex + 1]);
            }
        }


    }

    ngOnDestroy() {
        this.stopChanging();
    }

    @HostListener('mouseenter', [])
    onMouseenter() {
        this.startChanging();
    }

    @HostListener('mouseleave', [])
    onMouseleave() {
        this.stopChanging();
    }

    private startChanging() {

        this.stopChanging();

        this.subscription = timer(CONFIG.TRANSITION.THUMBNAIL_PAUSE, CONFIG.TRANSITION.THUMBNAIL)
            .subscribe(() => {
                let nextIndex = this.visibleIndex + 1;

                if (!this.stopAddingLazyThumbs && nextIndex + 1 < this.thumbs.length) {
                    this.lazyThumbs.push(this.thumbs[nextIndex + 1]);
                } else {
                    this.stopAddingLazyThumbs = true;
                }

                if (nextIndex > this.thumbs.length - 1) {
                    nextIndex = 0;
                }

                this.visibleIndex = nextIndex;
            });

    }

    private stopChanging() {

        this.subscription.unsubscribe();

    }

}
