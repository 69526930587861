import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var CmsMenuActionsService = /** @class */ (function () {
    function CmsMenuActionsService() {
        /**
         *
         * LOAD MENU
         *
         */
        this.fetchMenu = function () { return ({
            type: CmsMenuActionsService.FETCH_MENU
        }); };
        this.setMenuWrapIndex = function (index) { return ({
            type: CmsMenuActionsService.SET_MENU_WRAP_INDEX,
            payload: index
        }); };
    }
    CmsMenuActionsService.FETCH_MENU = '@cmsMenu/FETCH_MENU';
    CmsMenuActionsService.MENU_LOADING = '@cmsMenu/MENU_LOADING';
    CmsMenuActionsService.MENU_SUCCESS = '@cmsMenu/MENU_SUCCESS';
    CmsMenuActionsService.MENU_ERROR = '@cmsMenu/MENU_ERROR';
    CmsMenuActionsService.SET_MENU_WRAP_INDEX = '@cmsMenu/SET_MENU_WRAP_INDEX';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CmsMenuActionsService.prototype, "fetchMenu", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CmsMenuActionsService.prototype, "setMenuWrapIndex", void 0);
    return CmsMenuActionsService;
}());
export { CmsMenuActionsService };
