import { Injectable } from '@angular/core';

import { Observable, concat, of } from 'rxjs';
import { ActionsObservable } from 'redux-observable';
import { StatisticsActionsService } from '../actions/statistics.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';


@Injectable()
export class StatisticsEpicsService {

    constructor (
        private http: HttpClient
    ) {}

    fetchStatistics = (action$: ActionsObservable<any>) => {

        const cancel$ = action$.ofType(StatisticsActionsService.FETCH_STATISTICS);

        return action$.ofType(StatisticsActionsService.FETCH_STATISTICS).pipe(
            switchMap(({
                payload
            }) => {

                const mode = payload.mode;

                const query = new HttpParams();

                const url = '/Statistics/GetRemarketingStatistics';

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: StatisticsActionsService.STATISTICS_LOADING,
                        payload: {
                            mode
                        }
                    }),
                    this.http.get(url, { params: query }).pipe(
                        switchMap(response => {
                            return of({
                                type: StatisticsActionsService.STATISTICS_SUCCESS,
                                payload: {
                                    response
                                }
                            });
                        }),
                        catchError(error => of({
                            type: StatisticsActionsService.STATISTICS_ERROR,
                            error
                        })),
                        takeUntil(cancel$)
                ));
            }));
    }

}
