<span class="listing__thumbs">

    <img src="/assets/images/blank.gif" alt="" class="listing__thumb">

    <span class="listing__thumbs-holder">
        <span class="bundle-tile left">
            <span class="count">
                {{cars ? cars.length : thumbs.length}}
                <span class="lower" i18n>
                    Pack
                </span>
            </span>
        </span>

        <ng-container *ngIf="(cars && cars.length > 2) || (thumbs && thumbs.length > 2); else twoTemp">
            <span class="bundle-tile top">
                <app-listing-bundle-thumbnail-rotator [vehicleId]="cars ? cars[0].id : null" [thumbnails]="cars ? cars[0].media.thumbs : thumbs[0]"></app-listing-bundle-thumbnail-rotator>
            </span>
            <span class="bundle-tile bottom">
                <app-listing-bundle-thumbnail-rotator [vehicleId]="cars ? cars[1].id : null"  [thumbnails]="cars ? cars[1].media.thumbs : thumbs[0]"></app-listing-bundle-thumbnail-rotator>
            </span>
            <span class="bundle-tile right">
                <app-listing-bundle-thumbnail-rotator [vehicleId]="cars ? cars[2].id : null"  [thumbnails]="cars ? cars[2].media.thumbs : thumbs[0]"></app-listing-bundle-thumbnail-rotator>
            </span>
        </ng-container>

        <ng-template #twoTemp>
            <span class="bundle-tile-two top">
                <app-listing-bundle-thumbnail-rotator [vehicleId]="cars ? cars[0].id : null"  [thumbnails]="cars ? cars[0].media.thumbs : thumbs[0]"></app-listing-bundle-thumbnail-rotator>
            </span>
            <span class="bundle-tile-two bottom">
                <app-listing-bundle-thumbnail-rotator [vehicleId]="cars ? cars[1].id : null"  [thumbnails]="cars ? cars[1].media.thumbs : thumbs[0]"></app-listing-bundle-thumbnail-rotator>
            </span>
        </ng-template>
    </span>

</span>
