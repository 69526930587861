import { ElementRef, Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';
import { IMessage } from '../../../interfaces/messages/Messages.interface';
import { IOrderParams } from '../../../interfaces/order/OrderParams.interface';

@Injectable()
export class MessagesActionsService {

    

    static readonly FETCH_MESSAGES_STATISTICS = '@messages/FETCH_MESSAGES_STATISTICS';
    static readonly MESSAGES_STATISTICS_SUCCESS = '@messages/MESSAGES_STATISTICS_SUCCESS';

    static readonly FETCH_MESSAGES = '@messages/FETCH_MESSAGES';
    static readonly MESSAGES_LOADING = '@messages/MESSAGES_LOADING';
    static readonly MESSAGES_LOADING_MORE = '@messages/MESSAGES_LOADING_MORE';
    static readonly MESSAGES_SUCCESS = '@messages/MESSAGES_SUCCESS';
    static readonly MESSAGES_ERROR = '@messages/MESSAGES_ERROR';

    static readonly FETCH_MODAL_MESSAGES = '@messages/FETCH_MODAL_MESSAGES';
    static readonly MODAL_MESSAGES_LOADING = '@messages/MODAL_MESSAGES_LOADING';
    static readonly MODAL_MESSAGES_SUCCESS = '@messages/MODAL_MESSAGES_SUCCESS';
    static readonly MODAL_MESSAGES_ERROR = '@messages/MODAL_MESSAGES_ERROR';

    static readonly QUEUE_MESSAGES = '@messages/QUEUE_MESSAGES';
    static readonly QUEUE_MESSAGE = '@messages/QUEUE_MESSAGE';
    static readonly POP_MESSAGE = '@messages/POP_MESSAGE';
    static readonly DISCARD_MESSAGE = '@messages/DISCARD_MESSAGE';

    static readonly SET_PARAMS = '@messages/SET_PARAMS';
    static readonly SET_PARAMS_NEXT_PAGE = '@messages/SET_PARAMS_NEXT_PAGE';

    static readonly TOGGLE_MESSAGE = '@messages/TOGGLE_MESSAGE';
    static readonly TOGGLE_MESSAGE_SUCCESS = '@messages/TOGGLE_MESSAGE_SUCCESS';
    static readonly TOGGLE_MESSAGE_FAILURE = '@messages/TOGGLE_MESSAGE_FAILURE';
    static readonly SET_TYPE = '@messages/SET_TYPE';

    /**
     *
     * MESSAGES
     *
     */

    @dispatch()
    queueMessages = (payload: { messages: IMessage[] }): AppAction => ({
        type: MessagesActionsService.QUEUE_MESSAGES,
        payload
    })

    @dispatch()
    fetchMessagesStatistics = (): AppAction => ({
        type: MessagesActionsService.FETCH_MESSAGES_STATISTICS,
    })

    @dispatch()
    fetchMessages = (): AppAction => ({
        type: MessagesActionsService.FETCH_MESSAGES,
    })

    @dispatch()
    fetchModalMessages = (): AppAction => ({
        type: MessagesActionsService.FETCH_MODAL_MESSAGES,
    })

    @dispatch()
    queueMessage = (payload: { message: IMessage }): AppAction => ({
        type: MessagesActionsService.QUEUE_MESSAGE,
        payload
    })

    @dispatch()
    popMessage = (): AppAction => ({
        type: MessagesActionsService.POP_MESSAGE
    })

    @dispatch()
    toggleMessage = (payload): AppAction => ({
        type: MessagesActionsService.TOGGLE_MESSAGE,
        payload
    })

    @dispatch()
    setParams = (payload: IOrderParams): AppAction => ({
        type: MessagesActionsService.SET_PARAMS,
        payload
    })

    @dispatch()
    setParamsNextPage = (): AppAction => ({
        type: MessagesActionsService.SET_PARAMS_NEXT_PAGE
    })

    @dispatch()
    setType = (payload: string): AppAction => ({
        type: MessagesActionsService.SET_TYPE,
        payload
    })

}
