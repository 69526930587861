var ListingDaysOfVisibilityComponent = /** @class */ (function () {
    function ListingDaysOfVisibilityComponent() {
    }
    Object.defineProperty(ListingDaysOfVisibilityComponent.prototype, "hideAt", {
        set: function (value) {
            var date1 = new Date(value);
            var date2 = new Date();
            var timeDiff = date1.getTime() - date2.getTime();
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            this.visibilityDays = diffDays;
            this._hideAt = value;
        },
        enumerable: true,
        configurable: true
    });
    return ListingDaysOfVisibilityComponent;
}());
export { ListingDaysOfVisibilityComponent };
