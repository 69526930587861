import { AppAction, TimeZonesState } from '../../../interfaces/store.interface';
import {
    castToBoolean, paginatedAsyncSuccessReducer, pushArrayItem, pushArrayItems, removeArrayItem,
    sortParamsToString
} from './helpers';
import { UtilitiesActionsService } from '../actions/utilities.actions';
import { CONFIG } from '../../shared/config/config.const';

const INITIAL_STATE: TimeZonesState = {
    loading: false,
    results: []
};

export default function timeZonesReducer(state: TimeZonesState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        // TIMEZONES

        case UtilitiesActionsService.TIMEZONES_LOADING: {

            const nextState = {
                ...state,
                loading: true
            };

            return nextState;

        }

        case UtilitiesActionsService.TIMEZONES_SUCCESS: {

            const nextState = {
                ...state,
                loading: false,
                results: [
                    ...action.payload.response.slice()
                ]
            };

            return nextState;

        }

    }

    return state;

}
