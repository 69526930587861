import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISellingItemStore, ISellingItem } from '../../../../interfaces/SellingItem.interface';

@Component({
    selector: 'app-modal-cart-add',
    templateUrl: './modal-cart-add.component.html',
    // styleUrls: ['./modal-cart-add.component.scss']
})
export class ModalCartAddComponent implements OnInit {
    @Input() vehicleEntity: ISellingItemStore;
    vehicle: ISellingItem;

    constructor (public activeModal: NgbActiveModal) {}

    ngOnInit() {
        // this._vehicle = this.vehicleEntity;
        this.vehicle = this.vehicleEntity.props;
        // check if we need to remove ourself
        if (this.vehicleEntity.props._hideAndRemove) {
            // if no details are open then use the animation
            // if (this.selectedIndex === -1) {
            //     this.startHiding = true;
            // } else {
            //     // otherwise remove it immediately
            //     this.salesActions.removeVehicleFromResults({
            //         vehicleId: this.vehicleId
            //     });
            // }
        }
        // commence monitoring
        // if (this.isAuction) {
        //     this.monitorAuctionState();
        // } else {
        //     this.monitorShowHide();
        // }
    }
}
