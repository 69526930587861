import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var BootingActionsService = /** @class */ (function () {
    function BootingActionsService() {
        /**
         *
         * LOAD USER
         *
         */
        this.loadUser = function () { return ({
            type: BootingActionsService.LOAD_USER
        }); };
        /**
         *
         * LOAD TENANT CONFIG
         *
         */
        this.loadTenantConfiguration = function () { return ({
            type: BootingActionsService.LOAD_TENANT_CONFIG
        }); };
        this.setBootSuccess = function () { return ({
            type: BootingActionsService.BOOT_SUCCESS
        }); };
    }
    BootingActionsService.BOOT_SUCCESS = '@booting/BOOT_SUCCESS';
    BootingActionsService.LOAD_USER = '@booting/LOAD_USER';
    BootingActionsService.USER_READY = '@booting/USER_READY';
    BootingActionsService.USER_LOGGED_IN = '@booting/USER_LOGGED_IN';
    BootingActionsService.USER_DESTROY = '@booting/USER_DESTROY';
    BootingActionsService.FETCH_IDENTITY = '@booting/FETCH_IDENTITY';
    BootingActionsService.IDENTITY_LOADING = '@booting/IDENTITY_LOADING';
    BootingActionsService.IDENTITY_SUCCESS = '@booting/IDENTITY_SUCCESS';
    BootingActionsService.IDENTITY_ERROR = '@booting/IDENTITY_ERROR';
    BootingActionsService.FETCH_PROFILE = '@booting/FETCH_PROFILE';
    BootingActionsService.PROFILE_LOADING = '@booting/PROFILE_LOADING';
    BootingActionsService.PROFILE_SUCCESS = '@booting/PROFILE_SUCCESS';
    BootingActionsService.PROFILE_ERROR = '@booting/PROFILE_ERROR';
    BootingActionsService.LOAD_TENANT_CONFIG = '@booting/LOAD_TENANT_CONFIG';
    BootingActionsService.TENANT_CONFIG_LOADING = '@booting/TENANT_CONFIG_LOADING';
    BootingActionsService.TENANT_CONFIG_SUCCESS = '@booting/TENANT_CONFIG_SUCCESS';
    BootingActionsService.TENANT_CONFIG_ERROR = '@booting/TENANT_CONFIG_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], BootingActionsService.prototype, "loadUser", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], BootingActionsService.prototype, "loadTenantConfiguration", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], BootingActionsService.prototype, "setBootSuccess", void 0);
    return BootingActionsService;
}());
export { BootingActionsService };
