/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./listing-thumbnails.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./listing-thumbnails.component";
var styles_ListingThumbnailsComponent = [i0.styles];
var RenderType_ListingThumbnailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListingThumbnailsComponent, data: {} });
export { RenderType_ListingThumbnailsComponent as RenderType_ListingThumbnailsComponent };
function View_ListingThumbnailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["alt", ""], ["class", "listing__thumb lazy"]], [[1, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "z-index": 0, "display": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.index, ((_v.context.index === _co.visibleIndex) ? "block" : "none")); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.src; _ck(_v, 0, 0, currVal_0); }); }
export function View_ListingThumbnailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "listing__thumbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "listing__thumb listing__thumb--masked"], ["src", "/assets/images/blank.gif"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "listing__thumbs-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListingThumbnailsComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lazyThumbs; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ListingThumbnailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-listing-thumbnails", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseenter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseleave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ListingThumbnailsComponent_0, RenderType_ListingThumbnailsComponent)), i1.ɵdid(1, 704512, null, 0, i3.ListingThumbnailsComponent, [], null, null)], null, null); }
var ListingThumbnailsComponentNgFactory = i1.ɵccf("app-listing-thumbnails", i3.ListingThumbnailsComponent, View_ListingThumbnailsComponent_Host_0, { thumbnails: "thumbnails" }, {}, []);
export { ListingThumbnailsComponentNgFactory as ListingThumbnailsComponentNgFactory };
