import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AnimationService } from '../../../shared/services/animation/animation.service';

import { CONFIG } from '../../../shared/config/config.const';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    // styleUrls: ['./preloader.component.scss'],
    animations: [
        AnimationService.fadeInOut()
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreloaderComponent {
    @Input() visible: boolean;
    @Input() opaque: boolean;
    @Input() container: boolean;
    @Input() gallery: boolean;
    @Input() addToCart: boolean;
    @Input() zIndex: string;
}
