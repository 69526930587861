import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'validationValue'
})
export class ValidationValuePipe implements PipeTransform {

    constructor (private decimalPipe: DecimalPipe) {}

    transform(value: any, isCurrency?: boolean): any {

        let result = 'n/a';

        if (typeof value !== 'undefined' && value !== null) {
            if (value >= 0 && value < Infinity) { // Infinity is shown as n/a
                result = value;
                if (isCurrency) {
                    // use built in currency pipe
                    result = '€ ' + this.decimalPipe.transform(value, '1.2-2');
                } else {
                    // use built in decimal pipe
                    result = this.decimalPipe.transform(value);
                }
            }
        }

        return result;

    }

}
