/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-checkout-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./modal-checkout-error.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalCheckoutErrorComponent = [i0.styles];
var RenderType_ModalCheckoutErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalCheckoutErrorComponent, data: {} });
export { RenderType_ModalCheckoutErrorComponent as RenderType_ModalCheckoutErrorComponent };
function View_ModalCheckoutErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "."])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Modals.CheckoutError.ImpossibleComplete")); _ck(_v, 2, 0, currVal_0); }); }
function View_ModalCheckoutErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "h6"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors[0]; _ck(_v, 2, 0, currVal_0); }); }
function View_ModalCheckoutErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalCheckoutErrorComponent_3)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errors.length == 1); var currVal_1 = i1.ɵnov(_v.parent, 8); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModalCheckoutErrorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "h6"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_ModalCheckoutErrorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalCheckoutErrorComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ModalCheckoutErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "modal__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "modal__content pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalCheckoutErrorComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["hasErrors", 2]], null, 0, null, View_ModalCheckoutErrorComponent_2)), (_l()(), i1.ɵand(0, [["hasMultipleErrors", 2]], null, 0, null, View_ModalCheckoutErrorComponent_4)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.errors || (_co.errors.length == 0)); var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Modals.CheckoutError.ModalTitle")); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("Commons.Ok")); _ck(_v, 12, 0, currVal_3); }); }
export function View_ModalCheckoutErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-checkout-error", [], null, null, null, View_ModalCheckoutErrorComponent_0, RenderType_ModalCheckoutErrorComponent)), i1.ɵdid(1, 49152, null, 0, i4.ModalCheckoutErrorComponent, [i5.NgbActiveModal], null, null)], null, null); }
var ModalCheckoutErrorComponentNgFactory = i1.ɵccf("app-modal-checkout-error", i4.ModalCheckoutErrorComponent, View_ModalCheckoutErrorComponent_Host_0, { errors: "errors" }, {}, []);
export { ModalCheckoutErrorComponentNgFactory as ModalCheckoutErrorComponentNgFactory };
