<span class="listing__stats cf d-flex flex-row flex-wrap">
    <span class="block">
        <span class="listing__secondary px-1 truncate" *ngIf="vehicle.plate">
            <span i18n>{{'SellingItem.Info.Plate' | translate}}</span>: <span class="listing__value">{{ vehicle.plate
                }}</span>
        </span>
    </span>
    <span class="block">
        <span class="listing__secondary px-1 truncate " *ngIf="vehicle.matriculationDate">
            <span i18n>{{'SellingItem.Info.MatriculationDate' | translate}}</span>: <span class="listing__value">{{
                vehicle.matriculationDate | date:'dd.MM.yyyy' }}</span>
        </span>
    </span>
    <span class="block">
        <span class="listing__secondary px-1 truncate " *ngIf="vehicle?.km">
            <span i18n>{{'SellingItem.Info.Km' | translate}}</span>: <span class="listing__value">{{ vehicle?.km |
                number }}</span>
        </span>
    </span>
    <span class="block">
        <span class="listing__secondary px-1 truncate " *ngIf="vehicle.prices?.dealer.damages">
            <span i18n>{{'SellingItem.Info.Damages' | translate}}</span>: <span class="listing__value">{{currency}}
                {{ vehicle.prices.dealer.damages | number: '1.2-2' }}</span>
        </span>
    </span>
</span>
