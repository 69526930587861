import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ILocalizedString } from '../../../../interfaces/LocalizedString.interface';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent {

    @Input() id: string;
    @Input() title: ILocalizedString;
    @Input() message: ILocalizedString; // HTML

    constructor (public activeModal: NgbActiveModal) {}

}
