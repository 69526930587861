import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-relative-date',
    templateUrl: './relative-date.component.html',
    //   styleUrls: ['./relative-date.component.scss']
})
export class RelativeDateComponent implements OnChanges, OnDestroy {

    @Input() date: number; // milliseconds since the Unix Epoch, e.g. Date.now() or moment().valueOf()
    @Input() enable: boolean;

    days: number;
    hours: number;
    minutes: number;
    seconds: number;

    // helpers
    private secondUnit = 1000;
    private minuteUnit = 60 * 1000;
    private hourUnit = 60 * 60 * 1000;
    private dayUnit = 24 * 60 * 60 * 1000;

    private timer$: BehaviorSubject<number> = new BehaviorSubject<number>(this.dayUnit);

    private subscription = Subscription.EMPTY;

    ngOnChanges() {
        if (this.enable) {
            this.startTick();
        } else {
            this.stopTick();
        }
    }

    ngOnDestroy() {
        this.stopTick();
    }

    /**
     *
     * TIMING
     *
     */

    private startTick() {

        const diff = this.diff();

        this.stopTick();

        if (diff === 0) {
            this.seconds = 0;
            return;
        }

        // calculate immediately
        this.tick();

        // we need the period to vary based on how much time is remaining
        this.subscription = this.timer$.asObservable().pipe(
            distinctUntilChanged(),
            switchMap((period: number) => {
                // wipe out the milliseconds / seconds on each update
                const diff2 = this.diff();
                return timer(diff2 % period, period);
            })).subscribe(() => {
                this.tick();
            });
    }

    private stopTick() {
        this.subscription.unsubscribe();
    }

    /**
     *
     * TICK
     *
     */

    private tick() {
        const diff = this.diff();

        // calculate values
        const days = diff / this.dayUnit;
        const hours = (days - Math.floor(days)) * 24;
        const minutes = (hours - Math.floor(hours)) * 60;
        const seconds = (minutes - Math.floor(minutes)) * 60;
        const milliseconds = (seconds - Math.floor(seconds)) * 1000;

        // output the floored values to the view
        this.days = Math.floor(days);
        this.hours = Math.floor(hours);
        this.minutes = Math.floor(minutes);
        this.seconds = Math.floor(seconds);

        // cancel the timer when we get to zero
        if (diff === 0) {
            this.stopTick();
            return;
        }

        /**
         *
         * Modify the timer period based on the time remaining
         *
         */
        if (this.days > 0) {
            // switch to checking every day
            this.timer$.next(this.dayUnit);
        } else if (this.hours > 0) {
            // switch to checking every hour
            this.timer$.next(this.hourUnit);
        } else if (this.minutes > 0) {
            // switch to checking every minute
            this.timer$.next(this.minuteUnit);
        } else if (this.seconds > 0) {
            this.timer$.next(this.secondUnit);
        }

    }

    /**
     *
     * HELPERS
     *
     */

    private diff() {
        return Math.max((this.date || 0) - Date.now(), 0);
    }
}
