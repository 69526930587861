import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var OrderActionsService = /** @class */ (function () {
    function OrderActionsService() {
        /**
         *
         * ORDERS
         *
         */
        this.setArea = function (payload) { return ({
            type: OrderActionsService.SET_AREA,
            payload: payload
        }); };
        this.setParams = function (payload) { return ({
            type: OrderActionsService.SET_PARAMS,
            payload: payload
        }); };
        this.setParamsNextPage = function () { return ({
            type: OrderActionsService.SET_PARAMS_NEXT_PAGE
        }); };
        this.toggleOrderVehicles = function (payload) { return ({
            type: OrderActionsService.TOGGLE_ORDER_VEHICLES,
            payload: payload
        }); };
        this.toggleOrderVehicleDetails = function (payload) { return ({
            type: OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS,
            payload: payload
        }); };
        this.setSelectedResult = function (payload) { return ({
            type: OrderActionsService.SET_SELECTED_RESULT,
            payload: payload
        }); };
        this.setSelectedIndex = function (payload) { return ({
            type: OrderActionsService.SET_SELECTED_INDEX,
            payload: payload
        }); };
        this.setSelectedBundleIndex = function (payload) { return ({
            type: OrderActionsService.SET_SELECTED_BUNDLE_INDEX,
            payload: payload
        }); };
    }
    OrderActionsService.SET_AREA = '@order/SET_AREA';
    OrderActionsService.SET_PARAMS = '@order/SET_PARAMS';
    OrderActionsService.SET_PARAMS_NEXT_PAGE = '@order/SET_PARAMS_NEXT_PAGE';
    OrderActionsService.TOGGLE_ORDER_VEHICLES = '@order/TOGGLE_ORDER_VEHICLES';
    OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS = '@order/TOGGLE_ORDER_VEHICLE_DETAILS';
    OrderActionsService.FETCH_ORDERS = '@order/FETCH_ORDERS';
    OrderActionsService.ORDERS_LOADING = '@order/ORDERS_LOADING';
    OrderActionsService.ORDERS_LOADING_MORE = '@order/ORDERS_LOADING_MORE';
    OrderActionsService.ORDERS_SUCCESS = '@order/ORDERS_SUCCESS';
    OrderActionsService.ORDERS_ERROR = '@order/ORDERS_ERROR';
    OrderActionsService.SET_SELECTED_INDEX = '@order/SET_SELECTED_INDEX';
    OrderActionsService.SET_SELECTED_BUNDLE_INDEX = '@order/SET_SELECTED_BUNDLE_INDEX';
    OrderActionsService.SET_SELECTED_RESULT = '@order/SET_SELECTED_RESULT';
    OrderActionsService.SET_SELECTED_FULLY_OPEN = '@order/SET_SELECTED_FULLY_OPEN';
    OrderActionsService.SET_THUMBS_INDEX = '@order/SET_THUMBS_INDEX';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "setArea", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "setParams", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "setParamsNextPage", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "toggleOrderVehicles", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "toggleOrderVehicleDetails", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "setSelectedResult", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "setSelectedIndex", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], OrderActionsService.prototype, "setSelectedBundleIndex", void 0);
    return OrderActionsService;
}());
export { OrderActionsService };
