import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDetailedVehicle } from '../../../../../interfaces/Vehicle.interface';

@Component({
    selector: 'app-listing-stats',
    templateUrl: './listing-stats.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingStatsComponent {
    @Input() vehicle: IDetailedVehicle;
    @Input() currency: string;
}
