import * as tslib_1 from "tslib";
import { CONFIG } from '../../shared/config/config.const';
import { Vehicle } from './vehicle.class';
var SellingItem = /** @class */ (function () {
    function SellingItem(data, overrides, area, userBranchCode) {
        var _this = this;
        if (overrides === void 0) { overrides = {}; }
        var now = Date.now();
        // set the ID
        this.id = data.id;
        // set the props
        this.props = tslib_1.__assign({ 
            // set some defaults
            _bundle: false, _images: [], _thumbnails: [], optionals: [], _optionalsTable: [], ownerBranches: [], promotions: [] }, data, overrides);
        // car title
        this.props._vehicleTitle =
            overrides.title ?
                overrides.title :
                (overrides._bundle || !data.vehicles || data.vehicles.length === 0 ?
                    data.brand + " " + (data.model ? data.model : data.series) :
                    data.vehicles[0].brand + " " + (data.vehicles[0].model ?
                        data.vehicles[0].model :
                        data.vehicles[0].series));
        // map images
        // TODO IMAGES
        var vehicleMedia = (data.vehicles && data.vehicles.length === 1) ? data.vehicles[0].media : data.media;
        var vehicleMediaId = (data.vehicles && data.vehicles.length === 1) ? data.vehicles[0].id : data.id;
        if (vehicleMedia) {
            this.props._thumbnails = vehicleMedia['thumbs'];
            this.props._images = vehicleMedia['images'];
            // vehicleMedia.map((media: string) => {
            //     this.props._images.push(`${environment.api.url}/vehicles/${vehicleMediaId}/640/480/${media}`);
            //     this.props._thumbnails.push(`${environment.api.url}/vehicles/${vehicleMediaId}/110/110/${media}`);
            // });
        }
        // tweak the props
        this.props._vatHighlight = this.props.vatAmount === 'IVA esposta al 40%';
        // if (this.props.carAuctions.length) {
        //     // extract the first auction only
        //     this.props.auction = {
        //         ...this.props.carAuctions[0]
        //     }
        // }
        this.props._showAt = moment(this.props.showAt).valueOf();
        this.props._hideAt = moment(this.props.hideAt).valueOf();
        if (this.props.expireInMinutes) {
            this.props._hideAt = moment().add(Math.round(this.props.expireInMinutes), 'seconds').valueOf();
        }
        // this must be an auction
        if (this.props.auctionInfo) {
            this.props._isAuction = true;
            this.props.auctionInfo._state = 0; // overwritten below
            // config
            this.props.auctionInfo.bidStep = this.props.auctionInfo.bidStep || CONFIG.AUCTIONS.MINIMUM_RAISE;
            // UI only
            this.props.auctionInfo._makeOfferLoading = false;
            this.props.auctionInfo._updating = false;
            this.props.auctionInfo._isUserBidder = null;
            this.props.auctionInfo._offerResult = null;
            this.props.auctionInfo._errorMessage = null;
            if (this.props._showAt > now) {
                this.props.auctionInfo._state = 0; // pending
            }
            else if (this.props._hideAt > now) {
                this.props.auctionInfo._state = 1; // active
            }
            else {
                this.props.auctionInfo._state = 2; // expired
            }
        }
        //
        // Parse optionals
        //
        var row = [];
        var hasWinterPack = false;
        this.props.optionals.forEach(function (v) {
            var opt = {
                code: v.code,
                text: v.description
            };
            if (row.length === 2) {
                // push row
                _this.props._optionalsTable.push(row);
                // empty it
                row = [];
            }
            // check for winter pack
            if (v.code === 'G01') {
                hasWinterPack = true;
            }
            row.push(opt);
        });
        // save result to car
        this.props._hasWinterPack = hasWinterPack;
        if (row.length) {
            // push the last row
            this.props._optionalsTable.push(row);
        }
        //
        // Conditional series
        //
        if (this.props.series &&
            this.props.manufacturerName &&
            this.props.version &&
            (this.props.series.toUpperCase() === this.props.manufacturerName.toUpperCase() ||
                this.props.series.toUpperCase() === this.props.version.toUpperCase())) {
            this.props._conditionalSeries = '';
        }
        else {
            this.props._conditionalSeries = ' ' + this.props.series + ' ';
        }
        //
        // Brand / Series for comparison
        //
        this.props._brand = (this.props.manufacturerName || '').toUpperCase() || null;
        this.props._series = (this.props.series || '').toUpperCase() || null;
        // Vehicles
        this.props.vehicles = this.props.vehicles.map(function (vehicle) {
            return new Vehicle(vehicle).props;
        });
    }
    SellingItem.prototype.getAuctionProperty = function (property, firstChoice, secondChoice, thirdChoice) {
        if (firstChoice === void 0) { firstChoice = {}; }
        if (secondChoice === void 0) { secondChoice = {}; }
        if (thirdChoice === void 0) { thirdChoice = {}; }
        return (firstChoice.auction || {})[property] || (secondChoice.auction || {})[property] || (thirdChoice.auction || {})[property];
    };
    return SellingItem;
}());
export { SellingItem };
