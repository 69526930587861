import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, empty, concat, from } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { AppState } from '../../../interfaces/store.interface';

import { ExceptionActionsService } from '../actions/exception.actions';
import { UiActionsService } from '../actions/ui.actions';
import { UserActionsService } from '../actions/user.actions';
import { CmsMenuActionsService } from '../actions/cms-menu.actions';

import { UserManagerService } from '../services/user-manager/user-manager.service';

import { UserService } from '../../shared/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { MessagesActionsService } from '../actions/messages.actions';
import { SellingAreasActionsService } from '../actions/selling-areas.actions';
import { TenantActionsService } from '../actions/tenant.actions';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';

@Injectable()
export class UserEpicsService {

    constructor(
        private user: UserService,
        private userManager: UserManagerService,
        private http: HttpClient
    ) { }

    /**
     *
     * READY
     *
     */

    ready = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.READY).pipe(
            switchMap(() => {
                // immediately trigger login
                return empty();
            }));

    }

    /**
     *
     * DESTROYED
     *
     */

    destroy = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.DESTROY).pipe(
            switchMap(() => {
                // immediately trigger login
                return of({
                    type: UserActionsService.LOGIN
                });
            }));

    }

    /**
     *
     * LOGIN / LOGOUT
     *
     */

    login = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.LOGIN).pipe(
            switchMap(({
                payload
            }) => {
                return from(this.userManager.login()).pipe(
                    switchMap(() => {
                        // prevent type error
                        return empty();
                    }),
                    // show exception
                    catchError((error: any) => {
                        // Failed to redirect
                        // open error modal
                        return of({
                            type: ExceptionActionsService.SHOW_EXCEPTION_MODAL,
                            error
                        });
                    }));

            }));
    }

    logout = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.LOGOUT).pipe(
            switchMap(() => {

                return from(this.userManager.logout()).pipe(
                    switchMap(() => {
                        // prevent type error
                        return empty();
                    }),
                    // show exception
                    catchError((error: any) => {
                        // Failed to redirect
                        // open error modal
                        return of({
                            type: ExceptionActionsService.SHOW_EXCEPTION_MODAL,
                            error
                        });
                    }));

            }));
    }


    /**
     *
     * FETCH USER
     *
     */

    loadUser = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) => {

        return action$.ofType(UserActionsService.LOAD_USER).pipe(
            switchMap(({
                payload
            }) => {

                const user = store$.value.user;

                if (user.isReady) {
                    return of({
                        type: UserActionsService.READY
                    });
                }

                // load already in progress, wait for it to complete
                if (user.identity.loading || user.profile.loading) {
                    return empty();
                }

                return of({
                    type: UserActionsService.FETCH_IDENTITY
                });

            }));

    }

    /**
     *
     * IDENTITY
     *
     */

    fetchIdentity = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.FETCH_IDENTITY).pipe(
            switchMap(() => {

                return concat(
                    of({
                        type: UserActionsService.IDENTITY_LOADING
                    }),
                    from(this.userManager.getUser()).pipe(
                        switchMap((response) => {

                            //    if (!response) {
                            //        return from(this.userManager.signinRedirectCallback()).pipe(
                            //            switchMap((callbackResponse) => {
                            //                return of(
                            //                    {
                            //                        type: UserActionsService.IDENTITY_SUCCESS,
                            //                        payload: {
                            //                            callbackResponse
                            //                        }
                            //                    },
                            //                    {
                            //                        type: TenantActionsService.LOAD_TENANT_CONFIG,
                            //                        payload: undefined
                            //                    }
                            //                );
                            //            }),
                            //            catchError((error: any) => {
                            //                return of({
                            //                    type: UserActionsService.IDENTITY_ERROR,
                            //                    error
                            //                });
                            //            })
                            //        );
                            //    }


                            return concat(
                                of(
                                    {
                                        type: UserActionsService.IDENTITY_SUCCESS,
                                        payload: {
                                            response
                                        }
                                    }
                                ),
                                of(
                                    {
                                        type: UserActionsService.FETCH_PROFILE,
                                        payload: {
                                            response
                                        }
                                    }
                                )
                            );
                        }),
                        catchError((error: any) => {
                            return of({
                                type: UserActionsService.IDENTITY_ERROR,
                                error
                            });
                        })
                    ));

            }));
    }

    /**
     *
     * PROFILE / USER INFO
     *
     */

    fetchProfile = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        const cancel$ = action$.ofType(UserActionsService.DESTROY);

        return action$.ofType(UserActionsService.FETCH_PROFILE).pipe(
            switchMap(({
                payload // this is the IDENTITY response AKA user object
            }) => {

                // if the user doesnt exist stop the stream
                if (!payload.response) {
                    return of({
                        type: UserActionsService.READY
                    });
                }

                return concat(
                    of({
                        type: UserActionsService.PROFILE_LOADING
                    }),
                    this.http.get('/User/GetProfile').pipe(
                        switchMap(response => {
                            return concat(
                                of({
                                    type: UserActionsService.PROFILE_SUCCESS,
                                    payload: {
                                        response
                                    }
                                }),
                                of({
                                    type: UserActionsService.LOGGED_IN,
                                    payload: null
                                }),
                                of({
                                    type: SellingAreasActionsService.FETCH_SELLING_AREAS,
                                    payload: undefined
                                }),
                                of({
                                    type: UserActionsService.READY,
                                    payload: null
                                }),
                                of({
                                    type: CmsMenuActionsService.FETCH_MENU,
                                    payload: null
                                })
                            );
                        }),
                        catchError(error => of({
                            type: UserActionsService.PROFILE_ERROR,
                            error
                        })),
                        takeUntil(cancel$)
                    ));

            }));
    }

    saveUserInfo = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        const cancel$ = action$.ofType(UserActionsService.SAVE_USER_INFO);

        return action$.ofType(UserActionsService.SAVE_USER_INFO).pipe(
            switchMap(({
                payload
            }) => {

                return concat(
                    of({
                        type: UserActionsService.SAVE_USER_INFO_SAVING
                    }),
                    this.http.put('/User/EditProfile', { ...payload }).pipe(
                        switchMap(response => {
                            return concat(
                                of({
                                    type: UserActionsService.SAVE_USER_INFO_SUCCESS,
                                    payload: {
                                        response: payload
                                    }
                                }),
                                of({
                                    type: SellingAreasActionsService.FETCH_SELLING_AREAS
                                }),
                                of({
                                    type: CmsMenuActionsService.FETCH_MENU
                                })
                            );
                        }),
                        catchError(error => of({
                            type: UserActionsService.SAVE_USER_INFO_ERROR,
                            error
                        })),
                        takeUntil(cancel$)
                    ));

            }));
    }

    /**
     *
     * USER LOGGED IN
     *
     */

    loggedIn = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.LOGGED_IN).pipe(
            switchMap(({
                payload
            }) => {

                const hasAccepted = this.user.hasAcceptedAuctionDisclaimer();

                // determine if the user has cookie
                if (hasAccepted) {
                    return of({
                        type: UserActionsService.AUCTION_DISCLAIMER_ACCEPTED
                    }, {
                        type: MessagesActionsService.FETCH_MESSAGES_STATISTICS
                    });
                }

                return of({
                    type: MessagesActionsService.FETCH_MESSAGES_STATISTICS
                });

            }));

    }

    /**
     *
     * AUCTIONS COOKIE
     *
     */

    auctionDisclaimerAccepted = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        return action$.ofType(UserActionsService.AUCTION_DISCLAIMER_ACCEPTED).pipe(
            switchMap(({
                payload
            }) => {

                // set the cookie
                this.user.acceptAuctionDisclaimer();

                return empty();

            }));

    }

    /**
     *
     * ACCESS TOKEN
     *
     */

    refreshToken = (action$: ActionsObservable<any>, store: NgRedux<AppState>) => {

        const cancel$ = action$.ofType(UserActionsService.REFRESH_TOKEN);

        return action$.ofType(UserActionsService.REFRESH_TOKEN).pipe(
            switchMap(() => {

                return from(this.userManager.refreshToken())
                    .pipe(
                        switchMap(response => {
                            return of(
                                {
                                    type: UserActionsService.REFRESH_TOKEN_SUCCESS,
                                    payload: {
                                        response
                                    }
                                }
                            );
                        }),
                        catchError(error => concat(
                            of(
                                {
                                    type: UserActionsService.REFRESH_TOKEN_ERROR,
                                    error
                                }
                            ),
                            of(
                                {
                                    type: UserActionsService.LOGOUT
                                }
                            )
                        )),
                        takeUntil(cancel$)
                    );

            }));
    }

}
