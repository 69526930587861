import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './shared/services/auth/auth.guard';
import { RootComponent } from './shared/components/root/root.component';
import { TenantGuard } from './shared/guards/tenant/tenant.guard';

const routes: Routes = [{
    path: '',
    component: RootComponent,
    canActivate: [AuthGuard],
    children: [
        { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
        { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },
        { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
        { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
        { path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule) },
        // { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
        { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
        { path: 'favourites', loadChildren: () => import('./favourites/favourites.module').then(m => m.FavouritesModule) },
        { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },

        { path: 'usatoAld', loadChildren: () => import('./usatoAld/usatoAld.module').then(m => m.UsatoAldModule) },
        {
            path: 'regolamento',
            canActivate: [TenantGuard],
            data: { allowedTenantIds: ['jlri'] },
            loadChildren: () => import('./regolamento/regolamento.module').then(m => m.RegolamentoModule)
        },
        {
            path: 'terms',
            canActivate: [TenantGuard],
            data: { allowedTenantIds: ['bmwpt'] },
            loadChildren: () => import('./termsPrivacy/terms.module').then(m => m.TermsModule)
        },
        // Must be last, because of wildcard
        { path: '', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) }
    ]
}];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules // @TODO needs analysis
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
