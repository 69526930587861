import { AppAction, UiState } from '../../../interfaces/store.interface';

import { pushArrayItem, pushArrayItems } from './helpers';
import { SalesActionsService } from '../actions/sales.actions';
import { UiActionsService } from '../actions/ui.actions';

const INITIAL_STATE: UiState = {
    panel: {
        id: null,
        mode: null
    },
    dropdown: {
        id: null,
        mode: null
    },
    details: {
        visible: false,
        ready: false
    },
    gallery: {
        visible: false,
        index: 0,
        images: []
    },
    activeModal: null,
    notificationsQueue: [],
    activeNotification: null,

    privacyLoading: false,
    privacyContent: null
};

export default function uiReducer (state: UiState = INITIAL_STATE, action: AppAction) {

    switch (action.type) {

        /**
         *
         * OPEN PANEL
         *
         */

        case UiActionsService.RESET: {

            const nextState = {
                ...INITIAL_STATE
            };

            return nextState;

        }

        /**
         *
         * OPEN PANEL
         *
         */

        case UiActionsService.OPEN_PANEL: {

            const nextState = {
                ...state,
                // set values
                panel: {
                    id: action.payload.id,
                    mode: action.payload.mode || null
                }
            };

            return nextState;

        }

        /**
         *
         * TOGGLE PANEL
         *
         */

        case UiActionsService.TOGGLE_PANEL: {

            const nextState = {
                ...state,
                // set values
                panel: {
                    id: action.payload.id,
                    mode: action.payload.mode || null
                }
            };

            // check if it is already open and close it
            if (state.panel.id === nextState.panel.id) {
                nextState.panel.id = null;
                nextState.panel.mode = null;
            }

            return nextState;

        }

        /**
         *
         * CLOSE PANEL
         *
         */

        case UiActionsService.CLOSE_PANEL: {

            const nextState = {
                ...state,
                // reset values
                panel: {
                    ...INITIAL_STATE.panel
                }
            };

            return nextState;

        }

        /**
         *
         * OPEN DROPDOWN
         *
         */

        case UiActionsService.OPEN_DROPDOWN: {

            const nextState = {
                ...state,
                // set values
                dropdown: {
                    id: action.payload.id,
                    mode: action.payload.mode || null
                }
            };

            return nextState;

        }

        /**
         *
         * TOGGLE DROPDOWN
         *
         */

        case UiActionsService.TOGGLE_DROPDOWN: {

            const nextState = {
                ...state,
                // set values
                dropdown: {
                    id: action.payload.id,
                    mode: action.payload.mode || null
                }
            };

            // check if it is already open and close it
            if (state.dropdown.id === nextState.dropdown.id) {
                nextState.dropdown.id = null;
                nextState.dropdown.mode = null;
            }

            return nextState;

        }

        /**
         *
         * CLOSE DROPDOWN
         *
         */

        case UiActionsService.CLOSE_DROPDOWN: {

            const nextState = {
                ...state,
                // set values
                dropdown: {
                    ...INITIAL_STATE.dropdown
                }
            };

            return nextState;

        }

        /**
         *
         * OPEN DETAILS
         *
         */

        case UiActionsService.OPEN_DETAILS: {

            const nextState = {
                ...state,
                // set values
                details: {
                    visible: true,
                    ready: false
                }
            };

            return nextState;

        }


        /**
         *
         * CLOSE DETAILS
         *
         */

        case UiActionsService.CLOSE_DETAILS: {

            const nextState = {
                ...state,
                // set values
                details: {
                    visible: false,
                    ready: false
                }
            };

            return nextState;

        }


        /**
         *
         * DETAILS READY
         *
         */

        case UiActionsService.DETAILS_READY: {

            const nextState = {
                ...state,
                // set values
                details: {
                    visible: true,
                    ready: true
                }
            };

            return nextState;

        }

        /**
         *
         * GALLERY
         *
         */

        case UiActionsService.OPEN_GALLERY: {
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    visible: true
                }
            };
        }

        case UiActionsService.TOGGLE_GALLERY: {
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    visible: !state.gallery.visible
                }
            };
        }

        case UiActionsService.CLOSE_GALLERY: {
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    visible: false
                }
            };
        }

        case UiActionsService.SET_GALLERY_IMAGES: {
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    images: action.payload.images.slice()
                }
            };
        }

        case UiActionsService.SET_GALLERY_INDEX: {
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    index: action.payload.index
                }
            };
        }

        case UiActionsService.SET_GALLERY_NEXT: {

            let newIndex = state.gallery.index + 1;

            // reset to start
            if (newIndex > state.gallery.images.length - 1) {
                newIndex = 0;
            }

            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    index: newIndex
                }
            };
        }

        case UiActionsService.SET_GALLERY_PREVIOUS: {

            let newIndex = state.gallery.index - 1;

            // reset to start
            if (newIndex < 0) {
                newIndex = state.gallery.images.length - 1;
            }

            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    index: newIndex
                }
            };
        }

        /**
         *
         * MODALS
         *
         */

        case UiActionsService.REGISTER_MODAL: {
            const nextState = {
                ...state,
                activeModal: action.payload.modal
            };

            return nextState;
        }

        case UiActionsService.DISMISS_MODAL: {

            const nextState = {
                ...state,
                activeModal: null
            };

            if (state.activeModal) {
                state.activeModal.dismiss();
            }

            return nextState;
        }

        /**
         *
         * NOTIFICATIONS
         *
         */

        case UiActionsService.QUEUE_NOTIFICATION: {
            const nextState = {
                ...state,
                notificationsQueue: pushArrayItem(state.notificationsQueue, action.payload.notification)
            };
            return nextState;
        }

        case UiActionsService.QUEUE_NOTIFICATIONS: {
            const nextState = {
                ...state,
                notificationsQueue: pushArrayItems(state.notificationsQueue, action.payload)
            };
            return nextState;
        }

        case UiActionsService.POP_NOTIFICATION: {

            const nextState = {
                ...state
            };

            const queue = nextState.notificationsQueue.slice();

            // get the first item
            nextState.activeNotification = queue.shift() || null;

            // update the array
            nextState.notificationsQueue = [...queue];

            return nextState;

        }

        case SalesActionsService.RELOAD_SELLINGITEMS:
        case SalesActionsService.SET_AREA: {

            const nextState = {
                ...state,
                notificationsQueue: [] // clear the notification queue when we change area
            };

            return nextState;

        }

        /**
         *
         * PRIVACY
         *
         */

        case UiActionsService.PRIVACY_LOADING: {

            const nextState = {
                ...state,
                privacyLoading: true
            };

            return nextState;

        }

        case UiActionsService.PRIVACY_SUCCESS: {

            const nextState = {
                ...state,
                privacyLoading: false,
                privacyContent: action.payload.content
            };

            return nextState;

        }

        case UiActionsService.PRIVACY_ERROR: {

            const nextState = {
                ...state,
                privacyLoading: false
            };

            return nextState;

        }

    }

    return state;

}
