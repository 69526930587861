/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./listing-bundle-thumbnail-rotator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./listing-bundle-thumbnail-rotator.component";
var styles_ListingBundleThumbnailRotatorComponent = [i0.styles];
var RenderType_ListingBundleThumbnailRotatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListingBundleThumbnailRotatorComponent, data: {} });
export { RenderType_ListingBundleThumbnailRotatorComponent as RenderType_ListingBundleThumbnailRotatorComponent };
function View_ListingBundleThumbnailRotatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["alt", ""]], [[1, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "z-index": 0, "display": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.index, ((_v.context.index === _co.visibleIndex) ? "block" : "none")); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_ListingBundleThumbnailRotatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListingBundleThumbnailRotatorComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lazyThumbs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ListingBundleThumbnailRotatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/no-photo.png"]], null, null, null, null, null))], null, null); }
export function View_ListingBundleThumbnailRotatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListingBundleThumbnailRotatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noThumbs", 2]], null, 0, null, View_ListingBundleThumbnailRotatorComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.lazyThumbs && (_co.lazyThumbs.length > 0)) && _co.lazyThumbs[0]); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ListingBundleThumbnailRotatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-listing-bundle-thumbnail-rotator", [], null, null, null, View_ListingBundleThumbnailRotatorComponent_0, RenderType_ListingBundleThumbnailRotatorComponent)), i1.ɵdid(1, 704512, null, 0, i3.ListingBundleThumbnailRotatorComponent, [], null, null)], null, null); }
var ListingBundleThumbnailRotatorComponentNgFactory = i1.ɵccf("app-listing-bundle-thumbnail-rotator", i3.ListingBundleThumbnailRotatorComponent, View_ListingBundleThumbnailRotatorComponent_Host_0, { thumbnails: "thumbnails", vehicleId: "vehicleId" }, {}, []);
export { ListingBundleThumbnailRotatorComponentNgFactory as ListingBundleThumbnailRotatorComponentNgFactory };
