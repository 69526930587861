import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { Observable, of, concat, empty } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { AppState, OrderState, EntitiesState } from '../../../interfaces/store.interface';
import { OrderActionsService } from '../actions/order.actions';
import { ScrollActionsService } from '../actions/scroll.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, map, catchError, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class OrderEpicsService {

    constructor(
        private http: HttpClient
    ) { }

    /**
     *
     * Sales params applied (e.g. filters or sort)
     *
     *  - load vehicle filters (if required)
     *  - load vehicles
     *
     */

    setParams = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(
            OrderActionsService.SET_PARAMS,
            OrderActionsService.SET_PARAMS_NEXT_PAGE
        ).pipe(
            switchMap(({
                type
            }) => {

                if (type === OrderActionsService.SET_PARAMS) {
                    // we need to reset the scroll
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    }, {
                        type: OrderActionsService.FETCH_ORDERS,
                        payload: null
                    });
                }

                // we only need the vehicles
                return of({
                    type: OrderActionsService.FETCH_ORDERS
                });

            }));

    }

    /**
     *
     * ORDERS
     *
     */

    fetchOrders = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        const cancel$ = action$.ofType(OrderActionsService.SET_PARAMS);

        return action$.ofType(OrderActionsService.FETCH_ORDERS).pipe(
            switchMap(({
                payload
            }) => {

                const orderState = state$.value.order;

                const params = {};

                // apply all values to the query string
                Object.keys(orderState.params).forEach((key: string) => {

                    if (!Array.isArray(orderState.params[key])) {
                        if (orderState.params[key] || orderState.params[key] === 0) {
                            params[key] = orderState.params[key].toString();
                        }
                    } else if (orderState.params[key].length) {
                        params[key] = orderState.params[key];
                    }
                });

                // use concat so these actions happen sequentially
                return concat(
                    of({
                        type: orderState.params.pageIndex > 0 ?
                            OrderActionsService.ORDERS_LOADING_MORE : OrderActionsService.ORDERS_LOADING,
                        payload: null
                    }),
                    this.http.get('/Orders/GetOrders', { params: params }).pipe(
                        switchMap(response => {
                            // uncomment for random move item to deleted
                            //    response = {
                            //        ...response,
                            //        result: (response as any).result.map((el) => {
                            //            const remove = Math.random() > 0.5;
                            //            if (remove) {
                            //                const it = el.items.pop();
                            //                el.deletedItems.push(it);
                            //            }
                            //            return el;
                            //        })
                            //    };
                            //    console.log(response);
                            return concat(
                                of(
                                    {
                                        type: OrderActionsService.ORDERS_SUCCESS,
                                        payload: {
                                            response
                                        }
                                    }
                                ),
                                of(
                                    {
                                        type: ScrollActionsService.REFRESH,
                                        payload: null
                                    }
                                )
                            )
                        }),
                        takeUntil(cancel$),
                        catchError(error => of({
                            type: OrderActionsService.ORDERS_ERROR,
                            error
                        }))
                    ));
            }));
    }

    /**
     *
     * Set the result of the selected vehicle
     *
     */
    setSelectedResult = (action$: ActionsObservable<any>, state$: StateObservable<AppState>, { googleAnalytics }) => {

        return action$.ofType(OrderActionsService.SET_SELECTED_RESULT).pipe(
            switchMap(({
                payload
            }) => {

                if (payload) {

                    // tell Google Analytics
                    googleAnalytics.sendEvent({
                        category: 'Button',
                        label: 'Car detail by index: ' + payload.id
                    });

                }

                return empty();

            }));

    }

    /**
     *
     * Set the index of the selected vehicle
     *
     */
    setSelectedIndex = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(OrderActionsService.SET_SELECTED_INDEX).pipe(
            switchMap(({
                payload
            }) => {

                // closing
                if (payload.index === -1) {
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    });
                }

                const orderState: OrderState = state$.value.order;
                const entitiesState: EntitiesState = state$.value.entities;

                // check for result (null if not available)
                const orderId = orderState.results.payload.result.includes(payload.orderId) ? payload.orderId : null;

                // if we have an ID look for it
                if (orderId) {
                    // in the entities
                    const result = entitiesState.orders[orderId];
                    // if it is found, then we don't need to load more results
                    if (result) {

                        // now extract the sellingItemId

                        const sellingItem = payload.isDeleted ?
                            result.props.deletedItems[payload.index] :
                            result.props.items[payload.index];

                        const id = 'sellingItem_' + sellingItem.id;
                        // change the next step in the stream
                        return concat(
                            of({
                                type: ScrollActionsService.SCROLL_TO,
                                payload: {
                                    id,
                                    lock: true
                                }
                            }),
                            of({
                                type: OrderActionsService.SET_SELECTED_RESULT,
                                payload: {
                                    vehicleId: sellingItem.id
                                }
                            })
                        );
                    }
                }

                // if we've reached this point we need to load more data
                return of({
                    type: OrderActionsService.SET_PARAMS_NEXT_PAGE
                });

            }));
    }

    /**
     *
     * TOGGLE
     *
     */

    toggleOrderVehicles = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(OrderActionsService.TOGGLE_ORDER_VEHICLES).pipe(
            switchMap(({
                payload
            }) => {

                const id = 'order_' + payload.orderId;

                // check if the vehicles are already visible, in which case do nothing
                const order = state$.value.entities.orders[payload.orderId];

                // the reducer runs first so the showVehicles value is the opposite of what you'd expect because it has already been changed
                if (order && !order.props._showVehicles) {
                    return empty();
                }

                return of({
                    type: ScrollActionsService.SCROLL_TO,
                    payload: {
                        id,
                        lock: false
                    }
                });

            }));


    }

    toggleOrderVehicleDetails = (action$: ActionsObservable<any>, state$: StateObservable<AppState>) => {

        return action$.ofType(OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS).pipe(
            switchMap(({
                payload
            }) => {

                //    return empty();

                const id = 'order_' + payload.orderId;

                // check if the vehicles are already visible, in which case do nothing
                const order = state$.value.entities.orders[payload.orderId];

                // the reducer runs first so the showVehicles value is the opposite of what you'd expect because it has already been changed
                if (order && !order.props._showVehicles) {
                    return empty();
                }

                return of({
                    type: ScrollActionsService.SCROLL_TO,
                    payload: {
                        id,
                        lock: false
                    }
                });

            }));
    }


}
