<div class="modal__inner" #scroll>
    <div class="modal__content" [innerHTML]="disclaimer?.text">
        
    </div>
</div>

<div class="modal__actions cf" *ngIf="disclaimer?.mustBeAccepted">
    <div class="grid grid--4">
        <button type="button" class="button button--modal button--tertiary" appTouchable
            (click)="activeModal.dismiss()">
            <span class="button__label" i18n>{{'Commons.Cancel' | translate}}</span>
        </button>
    </div>
    <div class="grid grid--8">
        <button type="button" class="button button--modal" [disabled]="disableButton" appTouchable (click)="activeModal.close()">
            <span class="button__label" i18n>{{'Commons.Accept' | translate}}</span>
        </button>
    </div>
</div>

<div class="modal__actions cf" *ngIf="!disclaimer?.mustBeAccepted">
    <div class="grid grid--12">
        <button type="button" class="button button--modal" appTouchable (click)="activeModal.close()">
            <span class="button__label" i18n>{{'Commons.Close' | translate}}</span>
        </button>
    </div>
</div>

<div class="modal__close">
    <button type="button" class="button button--transparent" appTouchable (click)="activeModal.dismiss()">
        <i class="icon icon--out icon--close-white"></i>
        <i class="icon icon--hover icon--close-dark-grey"></i>
        &nbsp;
    </button>
</div>