
import { ISellingItem } from '../../../interfaces/SellingItem.interface';
import { IOrderStore } from '../../../interfaces/order/OrderStore.interface';
import { IOrder } from '../../../interfaces/order/Order.interface';
import { IDetailedVehicle } from '../../../interfaces/Vehicle.interface';

export class Order implements IOrderStore {

    id: string;
    props: IOrder;

    constructor (data: IOrder) {
        const vehicles = [];
        const deletedVehicles = [];
        let vehiclesCount = 0;
        let deletedVehiclesCount = 0;

        // set the ID
        this.id = data.id;

        // regular vehicles
        data.items.map((item) => {
            if (item.vehicles.length === 1) {
                item.vehicles.map((vehicle: IDetailedVehicle) => {
                    vehicles.push(vehicle.id);
                    vehiclesCount++;
                });
            } else {
                vehicles.push(item.id);
                item.vehicles.map((vehicle) => {
                    vehiclesCount++;
                });
            }
        });

        // deleted vehicles
        data.deletedItems.map((item) => {
            if (item.vehicles.length === 1) {
                item.vehicles.map((vehicle: IDetailedVehicle) => {
                    deletedVehicles.push(vehicle.id);
                    deletedVehiclesCount++;
                });
            } else {
                deletedVehicles.push(item.id);
                item.vehicles.map((vehicle) => {
                    deletedVehiclesCount++;
                });
            }
        });
        // set the props
        this.props = {
            id: data.id,
            // only keep the IDs
            vehicles,
            deletedVehicles,
            vehiclesCount,
            deletedVehiclesCount,
            createdOn: data.createdOn,
            discountAmount: data.discountAmount,
            grossAmount: data.grossAmount,
            invoiced: !!data.invoiced,
            netAmount: data.netAmount,
            totalTransportationCosts: data.totalTransportationCosts,
            paymentMethod: data.paymentMethod,
            paymentTerms: data.paymentTerms,
            invoiceUri: data.invoiceUri,
            user: {
                ...data.user
            },
            sellingArea: data.sellingArea,
            isDeleted: data.isDeleted,
            items: data.items,
            deletedItems: data.deletedItems,

            // UI
            _showVehicles: false,
            _vehiclesDetailsShown: [],

            _creationDate: moment(data.createdOn).format('L')
        };

    }

}

