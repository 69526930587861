import { Component, HostListener, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { CONFIG } from '../../config/config.const';


@Component({
    selector: 'app-listing-bundle-thumbnail-rotator',
    templateUrl: './listing-bundle-thumbnail-rotator.component.html',
    styleUrls: ['./listing-bundle-thumbnail-rotator.component.scss']
})
export class ListingBundleThumbnailRotatorComponent implements OnChanges, OnDestroy {
    @Input() thumbnails: string[];
    @Input() vehicleId: string;

    thumbs: Array<string>; // { src: string, index: number }[] = [];
    lazyThumbs: string[] = [];
    stopAddingLazyThumbs = false;

    visibleIndex: number;
    private subscription = Subscription.EMPTY;

    ngOnChanges() {
        this.thumbs = (this.thumbnails || []);
        /*this.thumbs = data.map((src: string, index: number) => {
            const media: string = `${environment.api.url}/vehicles/${this.vehicleId}/110/110/${src}`;
            return {
                src: media,
                index: data.length - index // reverse the index
            };
        });*/

        this.visibleIndex = 0;
        if (this.thumbs) {
            if (this.thumbs.length > 0) {
                this.lazyThumbs.push(this.thumbs[this.visibleIndex]);
            }
            if (this.thumbs.length > 1) {
                this.lazyThumbs.push(this.thumbs[this.visibleIndex + 1]);
            }
        }
    }

    ngOnDestroy() {
        this.stopChanging();
    }

    public startChanging() {

        this.stopChanging();

        this.subscription = timer(CONFIG.TRANSITION.THUMBNAIL_PAUSE, CONFIG.TRANSITION.THUMBNAIL)
            .subscribe(() => {
                let nextIndex = this.visibleIndex + 1;

                if (!this.stopAddingLazyThumbs && nextIndex + 1 < this.thumbs.length) {
                    this.lazyThumbs.push(this.thumbs[nextIndex + 1]);
                } else {
                    this.stopAddingLazyThumbs = true;
                }

                if (nextIndex > this.thumbs.length - 1) {
                    nextIndex = 0;
                }

                this.visibleIndex = nextIndex;
            });

    }

    public stopChanging() {

        this.subscription.unsubscribe();

    }
}
