import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject, timer, empty, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class TimerService {

    limit = 0x7FFFFFFF; // 32-bit limit for timer

    start (completeDateUTC: number) {

        // calculate whichever if biggest - the diff or the limit
        const diff = this.diff(completeDateUTC);

        // create a wrapper to control the delay
        const timer$ = new BehaviorSubject(diff);

        // start with the odd bit first
        return timer$.pipe(
            switchMap((delay: number) => {
                // use the offset as the delay first
                return timer(delay).pipe(
                switchMap(() => {
                    // recalculate the diff
                    const diff2 = this.diff(completeDateUTC);
                    // re-run the timer with the new diff
                    if (diff2 > 0) {
                        timer$.next(diff2);
                        return empty(); // don't let this complete
                    }
                    return of(true);
                }));
            }),
            take(1)); // complete when finished

    }

    private diff (completeDateUTC: number) {
        const now = Date.now();
        return Math.min(Math.max(0, completeDateUTC - now), this.limit);
    }

}
