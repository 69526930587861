import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var CmsPagesActionsService = /** @class */ (function () {
    function CmsPagesActionsService() {
        /**
         *
         * PAGE
         *
         */
        this.fetchPage = function (pageId) { return ({
            type: CmsPagesActionsService.FETCH_PAGE,
            payload: pageId
        }); };
    }
    CmsPagesActionsService.FETCH_PAGE = '@cmsPages/FETCH_PAGE';
    CmsPagesActionsService.PAGE_LOADING = '@cmsPages/PAGE_LOADING';
    CmsPagesActionsService.PAGE_SUCCESS = '@cmsPages/PAGE_SUCCESS';
    CmsPagesActionsService.PAGE_ERROR = '@cmsPages/PAGE_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CmsPagesActionsService.prototype, "fetchPage", void 0);
    return CmsPagesActionsService;
}());
export { CmsPagesActionsService };
