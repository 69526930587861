import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CONFIG } from '../../config/config.const';
var CurrencySpacePipe = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencySpacePipe, _super);
    function CurrencySpacePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CurrencySpacePipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var currencyType = CONFIG.CURRENCY.DEFAULT_CURRENCY;
        if (args && args.length > 0) {
            currencyType = args[0] || currencyType;
            args.splice(0, 1);
        }
        var transformed = _super.prototype.transform.apply(this, tslib_1.__spread([value, currencyType], args));
        return transformed ? transformed.replace(/([^\d.,])(\d)/, '$1 $2') : "" + (value !== undefined ? value : '');
    };
    return CurrencySpacePipe;
}(CurrencyPipe));
export { CurrencySpacePipe };
