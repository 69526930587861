import { combineReducers } from 'redux';

import auctionReducer from './reducers/auction.reducer';
import cartReducer from './reducers/cart.reducer';
import cartValidationReducer from './reducers/cart-validation.reducer';
import entitiesReducer from './reducers/entities.reducer';
import orderReducer from './reducers/order.reducer';
import salesReducer from './reducers/sales.reducer';
import scrollReducer from './reducers/scroll.reducer';
import statisticsReducer from './reducers/statistics.reducer';
import uiReducer from './reducers/ui.reducer';
import userReducer from './reducers/user.reducer';
import messagesReducer from './reducers/messages.reducer';
import sellingAreasReducer from './reducers/selling-areas.reducer';
import tenantReducer from './reducers/tenant.reducer';
import favouritesReducer from './reducers/favourites.reducer';
import cmsMenuReducer from './reducers/cms-menu.reducer';
import cmsPagesReducer from './reducers/cms-pages.reducer';
import timeZoneReducer from './reducers/timezones.reducer.ts';

// Define the global store shape by combining our application's
// reducers together into a given structure.
export const rootReducer = combineReducers({
    // MUST COME FIRST - so it receives the data first
    entities: entitiesReducer,
    // OTHERS
    messages: messagesReducer,
//    auction: auctionReducer,
    cart: cartReducer,
    cartValidation: cartValidationReducer,
    order: orderReducer,
    sales: salesReducer,
    scroll: scrollReducer,
    statistics: statisticsReducer,
    sellingAreas: sellingAreasReducer,
    ui: uiReducer,
    user: userReducer,
    tenant: tenantReducer,
    favourites: favouritesReducer,
    cmsMenu: cmsMenuReducer,
    cmsPage: cmsPagesReducer,
    timeZones: timeZoneReducer
});
