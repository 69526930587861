import * as tslib_1 from "tslib";
import { CONFIG } from '../../shared/config/config.const';
/**
 *
 * Sort object for comparison
 *
 */
export function sortParamsToString(params, stripKeys) {
    if (stripKeys === void 0) { stripKeys = []; }
    var obj = tslib_1.__assign({}, params);
    stripKeys.forEach(function (key) {
        delete obj[key];
    });
    return JSON.stringify(obj, Object.keys(obj).sort());
}
/**
 *
 * Cast boolean-like values to real boolean
 *
 */
export function castToBoolean(obj) {
    var object = tslib_1.__assign({}, obj);
    Object.keys(object).forEach(function (key) {
        if (object[key] === 'true') {
            object[key] = true;
        }
        else if (object[key] === 'false') {
            object[key] = false;
        }
    });
    return object;
}
/**
 *
 * Clone and modify an arrays
 *
 */
export function pushArrayItem(array, item) {
    return tslib_1.__spread(array.slice(), [
        item
    ]);
}
export function pushArrayItems(array, items) {
    return tslib_1.__spread(array.slice(), items.slice());
}
export function insertArrayItem(array, index, item) {
    return tslib_1.__spread(array.slice(0, index), [
        item
    ], array.slice(index));
}
export function removeArrayItem(array, index) {
    return tslib_1.__spread(array.slice(0, index), array.slice(index + 1));
}
/**
 *
 * ASYNC
 *
 */
export function asyncLoadingReducer(property) {
    return function asyncLoadingReducerFn(state) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { payload: null, loading: true, error: null, hasError: false }), _a));
    };
}
;
export function asyncSuccessReducer(property) {
    return function asyncSuccessReducerFn(state, response) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { payload: tslib_1.__assign({}, response), loading: false }), _a));
    };
}
;
export function asyncErrorReducer(property) {
    return function asyncErrorReducerFn(state, error) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { loading: false, saving: false, error: error, hasError: true }), _a));
    };
}
;
export function asyncSavingReducer(property) {
    return function asyncSavingReducerFn(state) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { saving: true, error: null, hasError: false }), _a));
    };
}
export function asyncSavingSuccessReducer(property) {
    return function asyncSavingSuccessReducerFn(state, response) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { payload: tslib_1.__assign({}, state[property].payload, response), saving: false }), _a));
    };
}
/**
 *
 * PAGINATED ASYNC
 *
 */
export function paginatedAsyncLoadingReducer(property) {
    return function paginatedAsyncLoadingReducerFn(state) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { payload: {
                pageIndex: -1,
                pageSize: CONFIG.PAGINATION.PAGE_SIZE,
                result: [],
                totalCount: -1,
                totalPages: -1
            }, loading: true, loadingMore: false, loaded: false, error: null, hasError: false, hasNextPage: false }), _a));
    };
}
;
export function paginatedAsyncLoadingMoreReducer(property) {
    return function paginatedAsyncLoadingMoreReducerFn(state) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { 
            // DO NOT clear the payload when loading more
            loading: false, loadingMore: true, loaded: false, error: null, hasError: false }), _a));
    };
}
;
export function paginatedAsyncSuccessReducer(property) {
    return function paginatedAsyncSuccessReducerFn(state, response) {
        var _a;
        var nextState = tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { loading: false, loadingMore: false, loaded: true, lastLoad: new Date(), payload: tslib_1.__assign({}, state[property].payload, { pageIndex: response.pageIndex, pageSize: response.pageSize, totalCount: response.totalCount, totalPages: response.totalPages }), hasError: false, hasNextPage: false }), _a));
        var results = (response.result || []).slice();
        // calculate where to start from
        var startIndex = (nextState[property].payload.pageIndex * nextState[property].payload.pageSize);
        // first page, clear the existing results
        if (response.pageIndex === 0) {
            nextState[property].payload.result = [];
        }
        else {
            nextState[property].payload.result = nextState[property].payload.result.slice(); // take a copy
        }
        // write the results into the results array (overwriting any preview items)
        results.forEach(function (item, index) {
            // note - we only store the ID here
            nextState[property].payload.result[startIndex + index] = item.id || item.orderId;
        });
        // save the loaded count
        nextState[property].loadedCount = nextState[property].payload.result.length;
        // check if we have addition pages available
        if (nextState[property].payload.pageIndex < nextState[property].payload.totalPages - 1) {
            // flag
            nextState.results.hasNextPage = true;
            // calculate the remaining items
            var remainingResults = nextState[property].payload.totalCount - (startIndex + results.length);
            // calculate the number of preview items to display
            var previewLength = (remainingResults > nextState[property].payload.pageSize) ? nextState[property].payload.pageSize : remainingResults % nextState[property].payload.pageSize;
            // calculate the loop parameters
            var previewStart = startIndex + nextState[property].payload.pageSize;
            var previewEnd = previewStart + previewLength;
            // add preview items to array
            for (var i = previewStart; i < previewEnd; i++) {
                nextState[property].payload.result.push(null); // null means show a preview - we can use push because we've already cloned the array
            }
        }
        // Note - entities are updated in the entitiesReducer
        return nextState;
    };
}
;
export function paginatedAsyncErrorReducer(property) {
    return function paginatedAsyncErrorReducerFn(state, error) {
        var _a;
        return tslib_1.__assign({}, state, (_a = {}, _a[property] = tslib_1.__assign({}, state[property], { loading: false, loadingMore: false, lastLoad: new Date(), error: error, hasError: true }), _a));
    };
}
;
/**
 *
 * USER ROLES
 *
 */
// searches for roles in the form of "www_[scope]_reader" or "www_[scope]_writer"
// returns an object with the sections containing two properties for values (reader and writer)
// { [scopes]: { reader: [theReaderValue/s], writer: [theWriterValue/s], } }
export function extractUserRoles(profile, allowedPrefixes) {
    if (allowedPrefixes === void 0) { allowedPrefixes = ['www_', 'admin_']; }
    if (profile !== Object(profile)) {
        return null;
    }
    var roleKeys = [];
    allowedPrefixes.forEach(function (prefix) {
        roleKeys = roleKeys.concat(Object.keys(profile).filter(function (val) {
            return val.startsWith(prefix);
        }));
    });
    var retRoles = {};
    roleKeys.forEach(function (val) {
        var splitted = val.split('_');
        var section = splitted[0] + "_" + splitted[1];
        var type = splitted[2];
        if (!retRoles[section]) {
            retRoles[section] = {};
        }
        retRoles[section][type] = profile[val];
    });
    return retRoles;
}
