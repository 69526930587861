import { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { UiActionsService } from '../../../store/actions/ui.actions';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { SalesActionsService } from '../../../store/actions/sales.actions';
import { OrderActionsService } from '../../../store/actions/order.actions';
var ListingItemComponent = /** @class */ (function () {
    function ListingItemComponent(router, ngRedux, salesActions, orderActions, uiActions, googleAnalytics) {
        this.router = router;
        this.ngRedux = ngRedux;
        this.salesActions = salesActions;
        this.orderActions = orderActions;
        this.uiActions = uiActions;
        this.googleAnalytics = googleAnalytics;
        this.mode = 'sellingItems';
        this.disabled = false; // applies opacity
        this.removed = false; // selling item removed - Applies strikes
        this.bundleVehicles = [];
        this.orderDetailsShown = false;
    }
    ListingItemComponent.prototype.ngOnChanges = function (changes) {
    };
    ListingItemComponent.prototype.ngOnDestroy = function () {
    };
    Object.defineProperty(ListingItemComponent.prototype, "disabledStatus", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListingItemComponent.prototype, "removedStatus", {
        get: function () {
            return this.removed;
        },
        enumerable: true,
        configurable: true
    });
    ListingItemComponent.prototype.open = function (event) {
        if (this.mode === 'favourites') {
            this._goToSellingItem();
        }
        else if (this.mode === 'orders' || this.mode === 'sellingItems') {
            this.openDetails(event);
        }
    };
    ListingItemComponent.prototype.openVehicleGallery = function (event) {
        if (this.sellingItem && this.sellingItem._images.length) {
            this.uiActions.setGalleryImages({ images: this.sellingItem._images });
            this.uiActions.setGalleryIndex({ index: 0 });
            this.uiActions.openGallery();
            // tell Google Analytics
            this.googleAnalytics.sendEvent({
                category: 'Button',
                label: 'Car gallery: ' + this.sellingItem.id
            });
        }
    };
    ListingItemComponent.prototype.openDetails = function (event) {
        event.preventDefault();
        if (this.mode === 'orders') {
            this.orderActions.setSelectedIndex({
                orderId: this.orderId,
                index: this.index,
                isDeleted: this.removed
            });
        }
        else {
            this.salesActions.setSelectedIndex(this.index);
        }
        this.uiActions.openDetails();
    };
    ListingItemComponent.prototype.closeDetails = function (event) {
        event.preventDefault();
        if (this.mode === 'orders') {
            this.orderActions.setSelectedIndex({ orderId: null, index: -1, isDeleted: null }); // -1 mean no selection
        }
        else {
            this.salesActions.setSelectedIndex(-1); // -1 mean no selection
        }
        this.uiActions.closeDetails();
    };
    ListingItemComponent.prototype.detailsShown = function (shown) {
        this.orderDetailsShown = shown;
    };
    ListingItemComponent.prototype.hasListingLinkClassName = function () {
        if (this.mode === 'sellingItems' || this.mode === 'orders') {
            return true;
        }
        if (this.mode === 'favourites') {
            return this.sellingItem.isAvailable;
        }
        return false;
    };
    ListingItemComponent.prototype.hasListingNoLinkClassName = function () {
        if (this.mode === 'cart' || this.mode === 'modal-cart') {
            return true;
        }
        return false;
    };
    ListingItemComponent.prototype._goToSellingItem = function () {
        this.router.navigate(['/sales', this.sellingItem.sellingAreaId], {
            queryParams: {
                vin: this.sellingItem.vehicles[0].vin
            }
        });
    };
    return ListingItemComponent;
}());
export { ListingItemComponent };
