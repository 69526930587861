import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// https://github.com/aspnet/SignalR/issues/983
import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

import { map } from 'rxjs/operators';

import { NgRedux, select } from '@angular-redux/store';

import { CONFIG } from '../../config/config.const';

import { AppState } from '../../../../interfaces/store.interface';
import { CartContents, ExpiredCartVehicle } from '../../../../interfaces/cart.interface';
import { SalesArea } from '../../../../interfaces/sales.interface';

import { AuctionActionsService } from '../../../store/actions/auction.actions';
import { SalesActionsService } from '../../../store/actions/sales.actions';
import { UiActionsService } from '../../../store/actions/ui.actions';
import { UserActionsService } from '../../../store/actions/user.actions';

// import { WindowRefService } from '../../../shared/services/window-ref/window-ref.service';

import { EnvironmentService } from '../environment/environment.service';
import { IDirectSellingItemResponse } from '../../../../interfaces/DirectSellingItem.interface';
import { SellingItem } from '../../../store/entities/sellingItem.class';
import { ISellingItem } from '../../../../interfaces/SellingItem.interface';
import { UiNotification } from '../../../../interfaces/ui.interface';

@Injectable()
export class SignalRService {

    private salesConnection: HubConnection;
    private usersConnection: HubConnection;

    private httpClient: any;

    constructor(
        private auctionActions: AuctionActionsService,
        private salesActions: SalesActionsService,
        private uiActions: UiActionsService,
        private userActions: UserActionsService,
        private http: HttpClient,
        private ngRedux: NgRedux<AppState>,
        private environmentService: EnvironmentService
        // private window: WindowRefService
    ) {

        this.httpClient = {



            // Note - if LongPolling is used then this HttpClient isn't used
            // https://github.com/aspnet/SignalR/issues/680#issuecomment-317789522
            get: (url: string) => {
                return this.http.get(url, {
                    observe: 'body',
                    responseType: 'json'
                }).pipe(
                    map(
                        (response) => {
                            return JSON.stringify(response); // convert parsed JSON back to string
                        }
                    )
                );
            },

            options: (url: string) => {
                return this.http.options(url, {
                    observe: 'body',
                    responseType: 'json'
                }).pipe(
                    map(
                        (response) => {
                            return JSON.stringify(response); // convert parsed JSON back to string
                        }
                    )
                );
            },

            post: (url: string, body: any) => {
                return this.http.post(url, body, {
                    observe: 'body',
                    responseType: 'json'
                }).pipe(
                    map(
                        (response) => {
                            return JSON.stringify(response); // convert parsed JSON back to string
                        }
                    )
                );
            }

        };

    }

    connect() {

        if (CONFIG.WEBSOCKETS.ENABLE) {
            this.setUpSalesConnection();
            this.setUpUsersConnection();
        }

    }

    disconnect() {

        console.log('Disconnecting from SignalR');

        if (this.salesConnection) {
            this.salesConnection.stop();
            this.salesConnection = null;
        }

        if (this.usersConnection) {
            this.usersConnection.stop();
            this.usersConnection = null;
        }

    }

    private getToken() {
        return this.ngRedux.getState().user.identity.payload.access_token;
    }

    /**
     *
     * SALES HUB
     *
     */

    private setUpSalesConnection() {

        // console.log('SALES - Connecting');

        /**
         *
         * Set up connections
         *
         */

        this.salesConnection = new HubConnectionBuilder()
            .withUrl(this.environmentService.getEnvironment().websocket.url + '/live/sales?token=' + this.getToken(),
                HttpTransportType.WebSockets)
            .withAutomaticReconnect()
            .build();

        //        this.salesConnection = new HubConnection(
        //            this.environmentService.getEnvironment().websocket.url + '/live/sales?token=' + this.getToken(), {
        //                httpClient: this.httpClient,
        //                transport
        //            });

        /**
         *
         * Listen for messages
         *
         */

        this.salesConnection.on('sellingItemAddedToBasket', (data: IDirectSellingItemResponse) => {
            // hide vehicle

            this.salesActions.hideVehicle({
                vehicleId: data.id
            });

            //    if (data.auctionInfo) {
            //        this.salesActions.setVehicleAsNotPurchasable({
            //            vehicleId: data.id
            //        });
            //    } else {
            //        this.salesActions.hideVehicle({
            //            vehicleId: data.id
            //        });
            //    }

        });

        this.salesConnection.on('sellingItemRemovedFromBasket', (data: ISellingItem) => {
            // inform user about this vehicle becoming available
            // this needs to be ignored if the vehicle was in the users cart - i.e. it was their action that triggered this

            const expiredVehicles = this.ngRedux.getState().cart.expiredVehicles;
            const currentArea = this.ngRedux.getState().sales.area;

            const area = data.areaId;

            const wasInCart = expiredVehicles.some((expired: ExpiredCartVehicle) => {
                return expired.vehicleId === data.id;
            });

            if (!wasInCart && currentArea === area) {
                const sellingItem = new SellingItem(data).props;
                const notification: UiNotification = {
                    vehicleId: sellingItem.id,
                    area,
                    price: {
                        value: sellingItem.calculatedEndPrice,
                        damages: null,
                        equipments: null,
                        transportCost: null,
                        vatType: null
                    },
                    vin: data.vehicles[0].vin,
                    title: data.title,
                    thumbnails: [],
                    version: null,
                    numberOfVehicles: sellingItem.vehicles.length, // overwritten below
                    isBundle: sellingItem.vehicles.length > 1 // overwritten below
                };

                for (let i = 0; i < notification.numberOfVehicles; i++) {
                    if (sellingItem.vehicles[i].media.thumbs.length > 0) {
                        notification.thumbnails.push(sellingItem.vehicles[i].media.thumbs[0]);
                    } else {
                        notification.thumbnails.push(null);
                    }
                }

                this.uiActions.queueNotification({
                    notification
                });
            }

        });

        this.salesConnection.on('sellingItemsRemovedFromBasket', (data: ISellingItem[]) => {
            // inform user about this vehicle becoming available
            // this needs to be ignored if the vehicle was in the users cart - i.e. it was their action that triggered this

            const expiredVehicles = this.ngRedux.getState().cart.expiredVehicles;
            const currentArea = this.ngRedux.getState().sales.area;

            const notificationsQueue = [];

            data.map(rawSellingItem => {
                const sellingItem = new SellingItem(rawSellingItem);

                const area = rawSellingItem.areaId;

                const wasInCart = expiredVehicles.some((expired: ExpiredCartVehicle) => {
                    return expired.vehicleId === sellingItem.id;
                });

                if (!wasInCart && currentArea === area) {
                    const notification: UiNotification = {
                        vehicleId: sellingItem.id,
                        area,
                        price: {
                            value: sellingItem.props.calculatedEndPrice,
                            damages: null,
                            equipments: null,
                            transportCost: null,
                            vatType: null
                        },
                        vin: rawSellingItem.vehicles[0].vin,
                        title: rawSellingItem.title,
                        thumbnails: [],
                        version: null,
                        numberOfVehicles: rawSellingItem.vehicles.length, // overwritten below
                        isBundle: rawSellingItem.vehicles.length > 1 // overwritten below
                    };

                    for (let i = 0; i < notification.numberOfVehicles; i++) {
                        if (rawSellingItem.vehicles[i].media.thumbs.length > 0) {
                            notification.thumbnails.push(rawSellingItem.vehicles[i].media.thumbs[0]);
                        } else {
                            notification.thumbnails.push(null);
                        }
                    }
                    notificationsQueue.push(notification);
                }
            });

            if (notificationsQueue.length > 0) {
                this.uiActions.queueNotifications(notificationsQueue);
            }

        });

        this.salesConnection.on('sellingItemOptioned', (data: IDirectSellingItemResponse) => {
            // hide vehicle
            this.salesActions.hideVehicle({
                vehicleId: data.id
            });
        });

        this.salesConnection.on('sellingItemDisabled', (data: IDirectSellingItemResponse) => {
            // hide vehicle
            this.salesActions.hideVehicle({
                vehicleId: data.id
            });
        });

        // auctions
        this.salesConnection.on('bidPlaced', (data: ISellingItem) => {
            const sellingItem = new SellingItem(data).props;
            // update auction
            this.auctionActions.updateAuctionFromPush({
                sellingItemId: sellingItem.id,
                response: sellingItem
            });
        });

        /**
         *
         * Start connections
         *
         */

        this.salesConnection.start()
            .then(() => {
                console.log('SALES - Connected to SignalR');
                // listen for send requests
                // this.sendSubscription = this.send$.subscribe((data: any) => {
                //     console.log('Send message over SignalR', data);
                //     this.connection.send(data);
                // });
            })
            .catch((err: any) => {
                console.log('Error while starting connection: ' + err);
            });

    }

    /**
     *
     * USERS HUB
     *
     */

    //    private setUpUsersConnection(transport: TransportType = TransportType.WebSockets) {
    private setUpUsersConnection() {

        //console.log('USERS - Connecting');

        /**
         *
         * Set up connections
         *
         */

        this.usersConnection = new HubConnectionBuilder()
            .withUrl(this.environmentService.getEnvironment().websocket.url + '/live/users?token=' + this.getToken(),
                HttpTransportType.WebSockets)
            .withAutomaticReconnect()
            .build();

        /**
         *
         * Listen for messages
         *
         */

        this.usersConnection.on('logOff', () => {
            // logout the user
            this.userActions.logout();
        });

        /**
         *
         * Start connections
         *
         */

        this.usersConnection.start()
            .then(() => {
                console.log('USERS - Connected to SignalR');
                // listen for send requests
                // this.sendSubscription = this.send$.subscribe((data: any) => {
                //     console.log('Send message over SignalR', data);
                //     this.connection.send(data);
                // });
            })
            .catch((err: any) => {
                console.log('Error while starting connection: ' + err);
            });

    }

    private sellingItemRemovedFromBasketAction() {

    }
}
