import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CONFIG } from '../../config/config.const';

@Pipe({ name: 'currencySpace' })
export class CurrencySpacePipe extends CurrencyPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    let currencyType = CONFIG.CURRENCY.DEFAULT_CURRENCY;
    if (args && args.length > 0) {
      currencyType = args[0] || currencyType;
      args.splice(0, 1);
    }
    const transformed = super.transform(value, currencyType, ...args);
    return transformed ? transformed.replace(/([^\d.,])(\d)/, '$1 $2') : `${value !== undefined ? value : ''}`;
  }
}
