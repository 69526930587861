// Use this for QA
export const environment = {
    production: true,
    version: '1.0.1',
    environment: 'develop',
    domain: null,
      www: {
        url: 'https://v12-@domain@.ticonet.it'
      },
      admin: {
        url: 'https://admin-@domain@.ticonet.it'
      },
      api: {
        url: 'https://api-@domain@.ticonet.it',
        version: '/v1'
      },
      websocket: {
        url: 'https://api-@domain@.ticonet.it'
      },
      oidc: {
        authority: 'https://auth-@domain@.ticonet.it',
        clientId: 'v12-aspirato-www',
        scope: 'openid www',
        responseType: 'id_token token'
      },
      push: {
        // https://web-push-codelab.glitch.me/
        // https://codelabs.developers.google.com/codelabs/push-notifications/#0
        applicationServerKey: 'BNiQyNA0OUHsO74HYQGPO2CZji4jCEukE4-DF5QoTYi2t9BGKy1IQ5oBveywuWtlpKA_RxUbdTG-7umkUIrIbcM'
      },

      cdn: {
        // NOTE: /login/ is set in the component's array so you can use different folder, e.g. for NoAuction page
        // DEV
        //url: '/assets/'
        url: 'https://storagev12aspirato.blob.core.windows.net'
        // QA (example)
        // url: 'https://media.bmwucportal.it/public/QualityAssurance/'
        // PRODUCTION (example)
        // url: 'https://media.bmwucportal.it/public/Production/'
      }
};
