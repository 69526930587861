/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-cart-disclaimer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./modal-cart-disclaimer.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@angular-redux/store/lib/src/components/ng-redux";
var styles_ModalCartDisclaimerComponent = [i0.styles];
var RenderType_ModalCartDisclaimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalCartDisclaimerComponent, data: {} });
export { RenderType_ModalCartDisclaimerComponent as RenderType_ModalCartDisclaimerComponent };
function View_ModalCartDisclaimerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal__actions cf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "grid grid--4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal button--tertiary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "grid grid--8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Commons.Cancel")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.disableButton; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Commons.Accept")); _ck(_v, 9, 0, currVal_2); }); }
function View_ModalCartDisclaimerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal__actions cf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "grid grid--12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Commons.Close")); _ck(_v, 4, 0, currVal_0); }); }
export function View_ModalCartDisclaimerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scrollEl: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["scroll", 1]], null, 1, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "modal__content"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalCartDisclaimerComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalCartDisclaimerComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "modal__close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["appTouchable", ""], ["class", "button button--transparent"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon icon--out icon--close-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "icon icon--hover icon--close-dark-grey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.disclaimer == null) ? null : _co.disclaimer.mustBeAccepted); _ck(_v, 4, 0, currVal_1); var currVal_2 = !((_co.disclaimer == null) ? null : _co.disclaimer.mustBeAccepted); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.disclaimer == null) ? null : _co.disclaimer.text); _ck(_v, 2, 0, currVal_0); }); }
export function View_ModalCartDisclaimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-cart-disclaimer", [], null, null, null, View_ModalCartDisclaimerComponent_0, RenderType_ModalCartDisclaimerComponent)), i1.ɵdid(1, 4440064, null, 0, i4.ModalCartDisclaimerComponent, [i5.NgbActiveModal, i6.NgRedux, i1.Renderer2, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalCartDisclaimerComponentNgFactory = i1.ɵccf("app-modal-cart-disclaimer", i4.ModalCartDisclaimerComponent, View_ModalCartDisclaimerComponent_Host_0, {}, {}, []);
export { ModalCartDisclaimerComponentNgFactory as ModalCartDisclaimerComponentNgFactory };
