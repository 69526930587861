import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-exception',
    templateUrl: './modal-exception.component.html',
    // styleUrls: ['./modal-exception.component.scss']
})
export class ModalExceptionComponent {

    @Input() errorMessage: any;
    @Input() errorIsObject: boolean;

    constructor (public activeModal: NgbActiveModal) {}

}
