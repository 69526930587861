import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var StatisticsActionsService = /** @class */ (function () {
    function StatisticsActionsService() {
        /**
         *
         * SET
         *
         */
        this.fetchStatistics = function (payload) { return ({
            type: StatisticsActionsService.FETCH_STATISTICS,
            payload: payload
        }); };
    }
    StatisticsActionsService.FETCH_STATISTICS = '@statistics/FETCH_STATISTICS';
    StatisticsActionsService.STATISTICS_LOADING = '@statistics/STATISTICS_LOADING';
    StatisticsActionsService.STATISTICS_SUCCESS = '@statistics/STATISTICS_SUCCESS';
    StatisticsActionsService.STATISTICS_ERROR = '@statistics/STATISTICS_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], StatisticsActionsService.prototype, "fetchStatistics", void 0);
    return StatisticsActionsService;
}());
export { StatisticsActionsService };
