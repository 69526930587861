import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var EntitiesActionsService = /** @class */ (function () {
    function EntitiesActionsService() {
        /**
         *
         * VEHICLES
         *
         */
        this.vehicleSetBranchLatLng = function (payload) { return ({
            type: EntitiesActionsService.SET_VEHICLE_BRANCH_LATLNG,
            payload: payload
        }); };
        this.vehicleSetInCart = function (payload) { return ({
            type: EntitiesActionsService.SET_VEHICLE_IN_CART,
            payload: payload
        }); };
        this.vehicleSetUnavailable = function (payload) { return ({
            type: EntitiesActionsService.SET_VEHICLE_UNAVAILABLE,
            payload: payload
        }); };
        this.hideVehicle = function (payload) { return ({
            type: EntitiesActionsService.HIDE_VEHICLE,
            payload: payload
        }); };
        this.setVehicleAsPuchasable = function (payload) { return ({
            type: EntitiesActionsService.SET_VEHICLE_AS_PURCHASABLE,
            payload: payload
        }); };
        this.setVehicleAsNotPuchasable = function (payload) { return ({
            type: EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE,
            payload: payload
        }); };
    }
    EntitiesActionsService.SET_VEHICLE_BRANCH_LATLNG = '@entities/SET_VEHICLE_BRANCH_LATLNG';
    EntitiesActionsService.SET_VEHICLE_IN_CART = '@entities/SET_VEHICLE_IN_CART';
    EntitiesActionsService.SET_VEHICLE_UNAVAILABLE = '@entities/SET_VEHICLE_UNAVAILABLE';
    EntitiesActionsService.HIDE_VEHICLE = '@entities/HIDE_VEHICLE';
    EntitiesActionsService.SET_VEHICLE_AS_PURCHASABLE = '@entities/SET_VEHICLE_AS_PURCHASABLE';
    EntitiesActionsService.SET_VEHICLE_AS_NOT_PURCHASABLE = '@entities/SET_VEHICLE_AS_NOT_PURCHASABLE';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], EntitiesActionsService.prototype, "vehicleSetBranchLatLng", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], EntitiesActionsService.prototype, "vehicleSetInCart", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], EntitiesActionsService.prototype, "vehicleSetUnavailable", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], EntitiesActionsService.prototype, "hideVehicle", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], EntitiesActionsService.prototype, "setVehicleAsPuchasable", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], EntitiesActionsService.prototype, "setVehicleAsNotPuchasable", void 0);
    return EntitiesActionsService;
}());
export { EntitiesActionsService };
