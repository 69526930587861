import { Injectable } from '@angular/core';

import { dispatch } from '@angular-redux/store';

import { AppAction } from '../../../interfaces/store.interface';

@Injectable()
export class CmsPagesActionsService {

    static readonly FETCH_PAGE = '@cmsPages/FETCH_PAGE';
    static readonly PAGE_LOADING = '@cmsPages/PAGE_LOADING';
    static readonly PAGE_SUCCESS = '@cmsPages/PAGE_SUCCESS';
    static readonly PAGE_ERROR = '@cmsPages/PAGE_ERROR';

    /**
     *
     * PAGE
     *
     */


    @dispatch()
    fetchPage = (pageId: string): AppAction => ({
        type: CmsPagesActionsService.FETCH_PAGE,
        payload: pageId
    })

}
