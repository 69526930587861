import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var UiActionsService = /** @class */ (function () {
    function UiActionsService() {
        /**
         *
         * RESET
         *
         */
        this.reset = function () { return ({
            type: UiActionsService.RESET
        }); };
        /**
         *
         * PANELS
         *
         */
        this.openPanel = function (payload) { return ({
            type: UiActionsService.OPEN_PANEL,
            payload: payload
        }); };
        this.togglePanel = function (payload) { return ({
            type: UiActionsService.TOGGLE_PANEL,
            payload: payload
        }); };
        this.closePanel = function () { return ({
            type: UiActionsService.CLOSE_PANEL
        }); };
        /**
         *
         * DROPDOWNS
         *
         */
        this.openDropdown = function (payload) { return ({
            type: UiActionsService.OPEN_DROPDOWN,
            payload: payload
        }); };
        this.toggleDropdown = function (payload) { return ({
            type: UiActionsService.TOGGLE_DROPDOWN,
            payload: payload
        }); };
        this.closeDropdown = function () { return ({
            type: UiActionsService.CLOSE_DROPDOWN
        }); };
        /**
         *
         * DETAILS
         *
         */
        this.openDetails = function () { return ({
            type: UiActionsService.OPEN_DETAILS
        }); };
        this.closeDetails = function () { return ({
            type: UiActionsService.CLOSE_DETAILS
        }); };
        this.detailsReady = function () { return ({
            type: UiActionsService.DETAILS_READY
        }); };
        /**
         *
         * GALLERY
         *
         */
        this.openGallery = function () { return ({
            type: UiActionsService.OPEN_GALLERY
        }); };
        this.toggleGallery = function () { return ({
            type: UiActionsService.TOGGLE_GALLERY
        }); };
        this.closeGallery = function () { return ({
            type: UiActionsService.CLOSE_GALLERY
        }); };
        this.setGalleryImages = function (payload) { return ({
            type: UiActionsService.SET_GALLERY_IMAGES,
            payload: payload
        }); };
        this.setGalleryIndex = function (payload) { return ({
            type: UiActionsService.SET_GALLERY_INDEX,
            payload: payload
        }); };
        this.setGalleryNext = function () { return ({
            type: UiActionsService.SET_GALLERY_NEXT
        }); };
        this.setGalleryPrevious = function () { return ({
            type: UiActionsService.SET_GALLERY_PREVIOUS
        }); };
        /**
         *
         * MODALS
         *
         */
        this.registerModal = function (payload) { return ({
            type: UiActionsService.REGISTER_MODAL,
            payload: payload
        }); };
        this.dismissModal = function () { return ({
            type: UiActionsService.DISMISS_MODAL
        }); };
        /**
         *
         * NOTIFICATIONS
         *
         */
        this.queueNotification = function (payload) { return ({
            type: UiActionsService.QUEUE_NOTIFICATION,
            payload: payload
        }); };
        this.queueNotifications = function (payload) { return ({
            type: UiActionsService.QUEUE_NOTIFICATIONS,
            payload: payload
        }); };
        this.popNotification = function () { return ({
            type: UiActionsService.POP_NOTIFICATION
        }); };
        this.discardNotification = function () { return ({
            type: UiActionsService.DISCARD_NOTIFICATION
        }); };
        /**
         *
         * PRIVACY POLICY
         *
         */
        this.fetchPrivacy = function () { return ({
            type: UiActionsService.FETCH_PRIVACY
        }); };
    }
    UiActionsService.RESET = '@ui/RESET';
    UiActionsService.OPEN_PANEL = '@ui/OPEN_PANEL';
    UiActionsService.TOGGLE_PANEL = '@ui/TOGGLE_PANEL';
    UiActionsService.CLOSE_PANEL = '@ui/CLOSE_PANEL';
    UiActionsService.OPEN_DROPDOWN = '@ui/OPEN_DROPDOWN';
    UiActionsService.TOGGLE_DROPDOWN = '@ui/TOGGLE_DROPDOWN';
    UiActionsService.CLOSE_DROPDOWN = '@ui/CLOSE_DROPDOWN';
    UiActionsService.OPEN_DETAILS = '@ui/OPEN_DETAILS';
    UiActionsService.CLOSE_DETAILS = '@ui/CLOSE_DETAILS';
    UiActionsService.DETAILS_READY = '@ui/DETAILS_READY';
    UiActionsService.OPEN_GALLERY = '@ui/OPEN_GALLERY';
    UiActionsService.TOGGLE_GALLERY = '@ui/TOGGLE_GALLERY';
    UiActionsService.CLOSE_GALLERY = '@ui/CLOSE_GALLERY';
    UiActionsService.SET_GALLERY_IMAGES = '@ui/SET_GALLERY_IMAGES';
    UiActionsService.SET_GALLERY_INDEX = '@ui/SET_GALLERY_INDEX';
    UiActionsService.SET_GALLERY_NEXT = '@ui/SET_GALLERY_NEXT';
    UiActionsService.SET_GALLERY_PREVIOUS = '@ui/SET_GALLERY_PREVIOUS';
    UiActionsService.REGISTER_MODAL = '@ui/REGISTER_MODAL';
    UiActionsService.DISMISS_MODAL = '@ui/DISMISS_MODAL';
    UiActionsService.QUEUE_NOTIFICATION = '@ui/QUEUE_NOTIFICATION';
    UiActionsService.QUEUE_NOTIFICATIONS = '@ui/QUEUE_NOTIFICATIONS';
    UiActionsService.POP_NOTIFICATION = '@ui/POP_NOTIFICATION';
    UiActionsService.DISCARD_NOTIFICATION = '@ui/DISCARD_NOTIFICATION';
    UiActionsService.FETCH_PRIVACY = '@ui/FETCH_PRIVACY';
    UiActionsService.PRIVACY_LOADING = '@ui/PRIVACY_LOADING';
    UiActionsService.PRIVACY_SUCCESS = '@ui/PRIVACY_SUCCESS';
    UiActionsService.PRIVACY_ERROR = '@ui/PRIVACY_ERROR';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "reset", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "openPanel", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "togglePanel", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "closePanel", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "openDropdown", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "toggleDropdown", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "closeDropdown", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "openDetails", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "closeDetails", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "detailsReady", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "openGallery", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "toggleGallery", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "closeGallery", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "setGalleryImages", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "setGalleryIndex", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "setGalleryNext", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "setGalleryPrevious", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "registerModal", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "dismissModal", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "queueNotification", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "queueNotifications", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "popNotification", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "discardNotification", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], UiActionsService.prototype, "fetchPrivacy", void 0);
    return UiActionsService;
}());
export { UiActionsService };
