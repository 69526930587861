import * as tslib_1 from "tslib";
import { dispatch } from '@angular-redux/store';
var CartActionsService = /** @class */ (function () {
    function CartActionsService() {
        /**
         *
         * CART
         *
         */
        this.addToCartConfirm = function (payload) { return ({
            type: CartActionsService.ADD_TO_CART_CONFIRM,
            payload: payload
        }); };
        this.addToCart = function (payload) { return ({
            type: CartActionsService.ADD_TO_CART,
            payload: payload
        }); };
        this.removeFromCart = function (payload) { return ({
            type: CartActionsService.REMOVE_FROM_CART,
            payload: payload
        }); };
        this.setWarningZone = function (payload) { return ({
            type: CartActionsService.SET_WARNING_ZONE,
            payload: payload
        }); };
        this.setDangerZone = function (payload) { return ({
            type: CartActionsService.SET_DANGER_ZONE,
            payload: payload
        }); };
        this.checkoutCart = function (payload) {
            if (payload === void 0) { payload = null; }
            return ({
                type: CartActionsService.CHECKOUT_CART,
                payload: payload
            });
        };
        this.checkoutCartConfirm = function (payload) {
            if (payload === void 0) { payload = null; }
            return ({
                type: CartActionsService.CHECKOUT_CART_CONFIRM,
                payload: payload
            });
        };
        this.fetchCartBranches = function (payload) { return ({
            type: CartActionsService.FETCH_CART_BRANCHES,
            payload: payload
        }); };
        this.fetchPaymentMethods = function (payload) { return ({
            type: CartActionsService.FETCH_PAYMENTMETHODS,
            payload: payload
        }); };
    }
    CartActionsService.FETCH_CART = '@cart/FETCH_CART';
    CartActionsService.CART_LOADING = '@cart/CART_LOADING';
    CartActionsService.CART_SUCCESS = '@cart/CART_SUCCESS';
    CartActionsService.CART_SUCCESS_EMPTY = '@cart/CART_SUCCESS_EMPTY';
    CartActionsService.CART_ERROR = '@cart/CART_ERROR';
    CartActionsService.CART_EXPIRED = '@cart/CART_EXPIRED';
    CartActionsService.CART_RESET = '@cart/CART_RESET';
    CartActionsService.SET_WARNING_ZONE = '@cart/SET_WARNING_ZONE';
    CartActionsService.SET_DANGER_ZONE = '@cart/SET_DANGER_ZONE';
    CartActionsService.ADD_TO_CART_CONFIRM = '@cart/ADD_TO_CART_CONFIRM';
    CartActionsService.ADD_TO_CART_QUEUE = '@cart/ADD_TO_CART_QUEUE';
    CartActionsService.ADD_TO_CART_DEQUEUE = '@cart/ADD_TO_CART_DEQUEUE';
    CartActionsService.ADD_TO_CART_FROM_QUEUE = '@cart/ADD_TO_CART_FROM_QUEUE';
    CartActionsService.ADD_TO_CART_INVALID_VEHICLE = '@cart/ADD_TO_CART_INVALID_VEHICLE';
    CartActionsService.ADD_TO_CART = '@cart/ADD_TO_CART';
    CartActionsService.ADD_TO_CART_LOADING = '@cart/ADD_TO_CART_LOADING';
    CartActionsService.ADD_TO_CART_SUCCESS = '@cart/ADD_TO_CART_SUCCESS';
    CartActionsService.ADD_TO_CART_ERROR = '@cart/ADD_TO_CART_ERROR';
    CartActionsService.ADD_TO_CART_IN_CART = '@cart/ADD_TO_CART_IN_CART';
    CartActionsService.ADD_TO_CART_UNAVAILABLE = '@cart/ADD_TO_CART_UNAVAILABLE';
    CartActionsService.ADD_TO_CART_REJECTED = '@cart/ADD_TO_CART_REJECTED';
    CartActionsService.REMOVE_FROM_CART = '@cart/REMOVE_FROM_CART';
    CartActionsService.REMOVE_FROM_CART_LOADING = '@cart/REMOVE_FROM_CART_LOADING';
    CartActionsService.REMOVE_FROM_CART_SUCCESS = '@cart/REMOVE_FROM_CART_SUCCESS';
    CartActionsService.REMOVE_FROM_CART_ERROR = '@cart/REMOVE_FROM_CART_ERROR';
    CartActionsService.CHECKOUT_CART_CONFIRM = '@cart/CHECKOUT_CART_CONFIRM';
    CartActionsService.CHECKOUT_CART = '@cart/CHECKOUT_CART';
    CartActionsService.CHECKOUT_CART_LOADING = '@cart/CHECKOUT_CART_LOADING';
    CartActionsService.CHECKOUT_CART_SUCCESS = '@cart/CHECKOUT_CART_SUCCESS';
    CartActionsService.CHECKOUT_CART_ERROR = '@cart/CHECKOUT_CART_ERROR';
    CartActionsService.DELETE_CART = '@cart/DELETE_CART';
    CartActionsService.DELETE_CART_LOADING = '@cart/DELETE_CART_LOADING';
    CartActionsService.DELETE_CART_SUCCESS = '@cart/DELETE_CART_SUCCESS';
    CartActionsService.DELETE_CART_ERROR = '@cart/DELETE_CART_ERROR';
    CartActionsService.FETCH_PAYMENTMETHODS = '@cart/FETCH_PAYMENTMETHODS';
    CartActionsService.PAYMENTMETHODS_LOADING = '@cart/PAYMENTMETHODS_LOADING';
    CartActionsService.PAYMENTMETHODS_SUCCESS = '@cart/PAYMENTMETHODS_SUCCESS';
    CartActionsService.PAYMENTMETHODS_ERROR = '@cart/PAYMENTMETHODS_ERROR';
    CartActionsService.FETCH_CART_BRANCHES = '@cart/FETCH_CART_BRANCHES';
    CartActionsService.CART_BRANCHES_LOADING = '@cart/CART_BRANCHES_LOADING';
    CartActionsService.CART_BRANCHES_SUCCESS = '@cart/CART_BRANCHES_SUCCESS';
    CartActionsService.CART_BRANCHES_ERROR = '@cart/CART_BRANCHES_ERROR';
    CartActionsService.SHOW_CHECKOUT_ERROR_MODAL = '@sales/SHOW_CHECKOUT_ERROR_MODAL';
    CartActionsService.RETURN_TO_SALES = '@cart/RETURN_TO_SALES';
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "addToCartConfirm", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "addToCart", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "removeFromCart", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "setWarningZone", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "setDangerZone", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "checkoutCart", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "checkoutCartConfirm", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "fetchCartBranches", void 0);
    tslib_1.__decorate([
        dispatch(),
        tslib_1.__metadata("design:type", Object)
    ], CartActionsService.prototype, "fetchPaymentMethods", void 0);
    return CartActionsService;
}());
export { CartActionsService };
