import { of, concat, empty } from 'rxjs';
import { OrderActionsService } from '../actions/order.actions';
import { ScrollActionsService } from '../actions/scroll.actions';
import { HttpClient } from '@angular/common/http';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
var OrderEpicsService = /** @class */ (function () {
    function OrderEpicsService(http) {
        var _this = this;
        this.http = http;
        /**
         *
         * Sales params applied (e.g. filters or sort)
         *
         *  - load vehicle filters (if required)
         *  - load vehicles
         *
         */
        this.setParams = function (action$, state$) {
            return action$.ofType(OrderActionsService.SET_PARAMS, OrderActionsService.SET_PARAMS_NEXT_PAGE).pipe(switchMap(function (_a) {
                var type = _a.type;
                if (type === OrderActionsService.SET_PARAMS) {
                    // we need to reset the scroll
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    }, {
                        type: OrderActionsService.FETCH_ORDERS,
                        payload: null
                    });
                }
                // we only need the vehicles
                return of({
                    type: OrderActionsService.FETCH_ORDERS
                });
            }));
        };
        /**
         *
         * ORDERS
         *
         */
        this.fetchOrders = function (action$, state$) {
            var cancel$ = action$.ofType(OrderActionsService.SET_PARAMS);
            return action$.ofType(OrderActionsService.FETCH_ORDERS).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var orderState = state$.value.order;
                var params = {};
                // apply all values to the query string
                Object.keys(orderState.params).forEach(function (key) {
                    if (!Array.isArray(orderState.params[key])) {
                        if (orderState.params[key] || orderState.params[key] === 0) {
                            params[key] = orderState.params[key].toString();
                        }
                    }
                    else if (orderState.params[key].length) {
                        params[key] = orderState.params[key];
                    }
                });
                // use concat so these actions happen sequentially
                return concat(of({
                    type: orderState.params.pageIndex > 0 ?
                        OrderActionsService.ORDERS_LOADING_MORE : OrderActionsService.ORDERS_LOADING,
                    payload: null
                }), _this.http.get('/Orders/GetOrders', { params: params }).pipe(switchMap(function (response) {
                    // uncomment for random move item to deleted
                    //    response = {
                    //        ...response,
                    //        result: (response as any).result.map((el) => {
                    //            const remove = Math.random() > 0.5;
                    //            if (remove) {
                    //                const it = el.items.pop();
                    //                el.deletedItems.push(it);
                    //            }
                    //            return el;
                    //        })
                    //    };
                    //    console.log(response);
                    return concat(of({
                        type: OrderActionsService.ORDERS_SUCCESS,
                        payload: {
                            response: response
                        }
                    }), of({
                        type: ScrollActionsService.REFRESH,
                        payload: null
                    }));
                }), takeUntil(cancel$), catchError(function (error) { return of({
                    type: OrderActionsService.ORDERS_ERROR,
                    error: error
                }); })));
            }));
        };
        /**
         *
         * Set the result of the selected vehicle
         *
         */
        this.setSelectedResult = function (action$, state$, _a) {
            var googleAnalytics = _a.googleAnalytics;
            return action$.ofType(OrderActionsService.SET_SELECTED_RESULT).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                if (payload) {
                    // tell Google Analytics
                    googleAnalytics.sendEvent({
                        category: 'Button',
                        label: 'Car detail by index: ' + payload.id
                    });
                }
                return empty();
            }));
        };
        /**
         *
         * Set the index of the selected vehicle
         *
         */
        this.setSelectedIndex = function (action$, state$) {
            return action$.ofType(OrderActionsService.SET_SELECTED_INDEX).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                // closing
                if (payload.index === -1) {
                    return of({
                        type: ScrollActionsService.SCROLL_TO,
                        payload: null
                    });
                }
                var orderState = state$.value.order;
                var entitiesState = state$.value.entities;
                // check for result (null if not available)
                var orderId = orderState.results.payload.result.includes(payload.orderId) ? payload.orderId : null;
                // if we have an ID look for it
                if (orderId) {
                    // in the entities
                    var result = entitiesState.orders[orderId];
                    // if it is found, then we don't need to load more results
                    if (result) {
                        // now extract the sellingItemId
                        var sellingItem = payload.isDeleted ?
                            result.props.deletedItems[payload.index] :
                            result.props.items[payload.index];
                        var id = 'sellingItem_' + sellingItem.id;
                        // change the next step in the stream
                        return concat(of({
                            type: ScrollActionsService.SCROLL_TO,
                            payload: {
                                id: id,
                                lock: true
                            }
                        }), of({
                            type: OrderActionsService.SET_SELECTED_RESULT,
                            payload: {
                                vehicleId: sellingItem.id
                            }
                        }));
                    }
                }
                // if we've reached this point we need to load more data
                return of({
                    type: OrderActionsService.SET_PARAMS_NEXT_PAGE
                });
            }));
        };
        /**
         *
         * TOGGLE
         *
         */
        this.toggleOrderVehicles = function (action$, state$) {
            return action$.ofType(OrderActionsService.TOGGLE_ORDER_VEHICLES).pipe(switchMap(function (_a) {
                var payload = _a.payload;
                var id = 'order_' + payload.orderId;
                // check if the vehicles are already visible, in which case do nothing
                var order = state$.value.entities.orders[payload.orderId];
                // the reducer runs first so the showVehicles value is the opposite of what you'd expect because it has already been changed
                if (order && !order.props._showVehicles) {
                    return empty();
                }
                return of({
                    type: ScrollActionsService.SCROLL_TO,
                    payload: {
                        id: id,
                        lock: false
                    }
                });
            }));
        };
        this.toggleOrderVehicleDetails = function (action$, state$) {
            return action$.ofType(OrderActionsService.TOGGLE_ORDER_VEHICLE_DETAILS).pipe(switchMap(function (_a) {
                //    return empty();
                var payload = _a.payload;
                var id = 'order_' + payload.orderId;
                // check if the vehicles are already visible, in which case do nothing
                var order = state$.value.entities.orders[payload.orderId];
                // the reducer runs first so the showVehicles value is the opposite of what you'd expect because it has already been changed
                if (order && !order.props._showVehicles) {
                    return empty();
                }
                return of({
                    type: ScrollActionsService.SCROLL_TO,
                    payload: {
                        id: id,
                        lock: false
                    }
                });
            }));
        };
    }
    return OrderEpicsService;
}());
export { OrderEpicsService };
